<template>
	<frp-dialog :value="value"
				:title="$t(`dialogs.selectNextAction.title`)"
				max-width="720"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<p class="primary--text">
				{{
					$t("dialogs.selectNextAction.description1", description1)
				}}
			</p>
			<p class="primary--text">
				{{
					$t("dialogs.selectNextAction.description2", description2)
				}}
			</p>
		</template>
		
		<template #footer>
			<frp-btn elevation="0"
					 color="red"
					 dark
					 @click="handleClick('no')">
				{{ $t("buttons.no") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 @click="handleClick('yes')">
				{{ $t("buttons.yes") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		description1: Object,
		description2: Object
	},
	data() {
		return {};
	},
	methods: {
		handleClick(type) {
			this.$emit(`click:${type}`);
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
