import IPageState from "@/store/shared/base/types/pageState";

export default class EmployeeExpectationsState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
