<template>
	<frp-card v-if="isInitialized" :title="$t('titles.projectsInfo')" class="pb-1">
		<template #actions>
			<frp-btn class="text-none pl-1 pr-2"
					 dense
					 outlined
					 :disabled="isAddNewProjectDisabled"
					 :color="colors.blue.base"
					 @click="addEmptyAccountAgreementsItem">
				<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
				{{ $t("buttons.addProject") }}
			</frp-btn>
		</template>
		
		<template #content>
			<bar-details-group>
				<bar-bank-account-agreements-agreement-item v-for="(item, index) in editableItem.accountAgreements"
															:agreement="item"
															:index="index"
															:key="index">
				</bar-bank-account-agreements-agreement-item>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-agreements-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { namespace } from "@/store/bar/modules/bankAccount";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import { maxLengths } from "@/utils/validation";
import BarBankAccountAgreementsAgreementItem
	from "@/views/bar/bankAccount/sections/agreements/agreementItem/BarBankAccountAgreementsAgreementItem.vue";
import BarBankAccountAgreementsLoader from "@/views/bar/bankAccount/sections/agreements/BarBankAccountAgreementsLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			RouteNames,
			SourceTypeEnum,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			applications: state => state.applications,
			editableItem: state => state.editableItem,
			bankAccountNumber: state => state.editableItem.account.accountNumber,
			isBankAccountSaving: state => state.isBankAccountSaving,
			isBankAccountNumberUnique: state => state.isBankAccountNumberUnique,
			isBankAccountNumberUniqueCheckInProgress: state => state.isBankAccountNumberUniqueCheckInProgress
		}),
		...mapGetters({
			isCardEditingDisabledWithRequests: getterTypes.isCardEditingDisabledWithRequests
		}),
		isAddNewProjectDisabled() {
			return !this.isBankAccountNumberUnique
				|| this.isBankAccountNumberUniqueCheckInProgress
				|| this.isBankAccountSaving
				|| this.bankAccountNumber.length < maxLengths.bankAccountNumber
				|| this.isCardEditingDisabledWithRequests
		}
	},
	methods: {
		...mapActions({
			addEmptyAccountAgreementsItem: actionTypes.addEmptyAccountAgreementsItem
		})
	},
	components: {
		BarBankAccountAgreementsAgreementItem,
		BarBankAccountAgreementsLoader,
		FrpBtn,
		FrpTextBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
