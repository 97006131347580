import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrDiscipline } from "@/api/hr/types/apiHrDiscipline";

export default class EmployeeDisciplineState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public discipline: ApiHrDiscipline = {} as ApiHrDiscipline
	)
	{
	}
}
