import Vue from "vue";

const isServer = Vue.prototype.$isServer;
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;
// @ts-ignore
const ieVersion = isServer ? 0 : Number(document.documentMode);

/* istanbul ignore next */
const trim = function (string: string) {
	return (string || "").replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, "");
};
/* istanbul ignore next */
const camelCase = function (name: string) {
	return name.replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
		return offset ? letter.toUpperCase() : letter;
	}).replace(MOZ_HACK_REGEXP, "Moz$1");
};

/* istanbul ignore next */
export const on = (function () {
	// @ts-ignore
	if(!isServer && document.addEventListener) {
		return function (element: { addEventListener: (arg0: any, arg1: any, arg2: boolean) => void; }, event: any, handler: any) {
			if(element && event && handler) {
				element.addEventListener(event, handler, false);
			}
		};
	} else {
		return function (element: { attachEvent: (arg0: string, arg1: any) => void; }, event: string, handler: any) {
			if(element && event && handler) {
				element.attachEvent("on" + event, handler);
			}
		};
	}
})();

/* istanbul ignore next */
export const off = (function () {
	// @ts-ignore
	if(!isServer && document.removeEventListener) {
		return function (element: { removeEventListener: (arg0: any, arg1: any, arg2: boolean) => void; }, event: any, handler: any) {
			if(element && event) {
				element.removeEventListener(event, handler, false);
			}
		};
	} else {
		return function (element: { detachEvent: (arg0: string, arg1: any) => void; }, event: string, handler: any) {
			if(element && event) {
				element.detachEvent("on" + event, handler);
			}
		};
	}
})();

/* istanbul ignore next */
export const once = function (el: any, event: any, fn: { apply: (arg0: any, arg1: IArguments) => void; }) {
	var listener = function () {
		if(fn) {
			// @ts-ignore
			fn.apply(this, arguments);
		}
		off(el, event, listener);
	};
	on(el, event, listener);
};

/* istanbul ignore next */
export function hasClass(el: { className: any; classList: any; setAttribute?: (arg0: string, arg1: any) => void; },
	cls: string | string[]) {
	if(!el || !cls) return false;
	if(cls.indexOf(" ") !== -1) throw new Error("className should not contain space.");
	if(el.classList) {
		return el.classList.contains(cls);
	} else {
		return (" " + el.className + " ").indexOf(" " + cls + " ") > -1;
	}
};

/* istanbul ignore next */
export function addClass(el: { className: any; classList: { add: (arg0: any) => void; }; setAttribute: (arg0: string, arg1: any) => void; },
	cls: any) {
	if(!el) return;
	var curClass = el.className;
	var classes = (cls || "").split(" ");

	for (var i = 0, j = classes.length; i < j; i++) {
		var clsName = classes[i];
		if(!clsName) continue;

		if(el.classList) {
			el.classList.add(clsName);
		} else if(!hasClass(el, clsName)) {
			curClass += " " + clsName;
		}
	}
	if(!el.classList) {
		el.setAttribute("class", curClass);
	}
};

/* istanbul ignore next */
export function removeClass(el: { className: string; classList: { remove: (arg0: any) => void; }; setAttribute: (arg0: string, arg1: any) => void; },
	cls: string) {
	if(!el || !cls) return;
	var classes = cls.split(" ");
	var curClass = " " + el.className + " ";

	for (var i = 0, j = classes.length; i < j; i++) {
		var clsName = classes[i];
		if(!clsName) continue;

		if(el.classList) {
			el.classList.remove(clsName);
		} else if(hasClass(el, clsName)) {
			curClass = curClass.replace(" " + clsName + " ", " ");
		}
	}
	if(!el.classList) {
		el.setAttribute("class", trim(curClass));
	}
};

/* istanbul ignore next */
export const getStyle = ieVersion < 9 ? function (element: { filters: { item: (arg0: string) => { (): any; new(): any; opacity: number; }; }; style: { [x: string]: any; }; currentStyle: { [x: string]: any; }; },
	styleName: string) {
	if(isServer) return;
	if(!element || !styleName) return null;
	styleName = camelCase(styleName);
	if(styleName === "float") {
		styleName = "styleFloat";
	}
	try {
		switch (styleName) {
			case "opacity":
				try {
					return element.filters.item("alpha").opacity / 100;
				} catch (e) {
					return 1.0;
				}
			default:
				return (element.style[styleName] || element.currentStyle ? element.currentStyle[styleName] : null);
		}
	} catch (e) {
		return element.style[styleName];
	}
} : function (element: Element, styleName: string) {
	if(isServer) return;
	if(!element || !styleName) return null;
	styleName = camelCase(styleName);
	if(styleName === "float") {
		styleName = "cssFloat";
	}
	try {
		// @ts-ignore
		var computed = document.defaultView.getComputedStyle(element, "");
		// @ts-ignore
		return element.style[styleName] || computed ? computed[styleName] : null;
	} catch (e) {
		// @ts-ignore
		return element.style[styleName];
	}
};

/* istanbul ignore next */
export function setStyle(element: any, styleName: any, value: any) {
	if(!element || !styleName) return;

	if(typeof styleName === "object") {
		for (var prop in styleName) {
			if(styleName.hasOwnProperty(prop)) {
				setStyle(element, prop, styleName[prop]);
			}
		}
	} else {
		styleName = camelCase(styleName);
		if(styleName === "opacity" && ieVersion < 9) {
			element.style.filter = isNaN(value) ? "" : "alpha(opacity=" + value * 100 + ")";
		} else {
			element.style[styleName] = value;
		}
	}
};

export const isScroll = (el: any, vertical: null | undefined) => {
	if(isServer) return;

	const determinedDirection = vertical !== null && vertical !== undefined;
	const overflow = determinedDirection
		? vertical
			? getStyle(el, "overflow-y")
			: getStyle(el, "overflow-x")
		: getStyle(el, "overflow");

	return overflow.match(/(scroll|auto|overlay)/);
};

export const getScrollContainer = (el: any, vertical: any) => {
	if(isServer) return;

	let parent = el;
	while (parent) {
		if([window, document, document.documentElement].includes(parent)) {
			return window;
		}
		if(isScroll(parent, vertical)) {
			return parent;
		}
		parent = parent.parentNode;
	}

	return parent;
};

export const isInContainer = (el: any, container: any) => {
	if(isServer || !el || !container) return false;

	const elRect = el.getBoundingClientRect();
	let containerRect;

	if([window, document, document.documentElement, null, undefined].includes(container)) {
		containerRect = {
			top: 0,
			right: window.innerWidth,
			bottom: window.innerHeight,
			left: 0
		};
	} else {
		containerRect = container.getBoundingClientRect();
	}

	return elRect.top < containerRect.bottom &&
		elRect.bottom > containerRect.top &&
		elRect.right > containerRect.left &&
		elRect.left < containerRect.right;
};

export const selectElementText = (node: HTMLElement) => {
	if(document.createRange && getSelection()) {
		const range = document.createRange();
		const selection = getSelection()!;
		
		range.selectNode(node);
		
		selection.removeAllRanges();
		selection.addRange(range);
	} else {
		console.warn("Could not select text in node: Unsupported browser.");
	}
}
