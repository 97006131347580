import { ApiHrVacationPlanApplication } from "@/api/hr/types/vacation/apiHrVacationPlanApplication";

export interface HrNewVacation {
	id?: string;
	previousVacationId?: string;
	startDate: number | null;
	endDate: number | null;
	factDate: number | null;
	days: number;
	vacationApplication?: ApiHrVacationPlanApplication;
}

export class HrNewVacationService {
	static getEmpty(): HrNewVacation {
		return {
			id: "",
			previousVacationId: "",
			factDate: null,
			startDate: null,
			endDate: null,
			days: 0
		};
	}
}
