import { ApiContractInfo } from "@/api/bar/types/apiContractInfo";
import { convertToTimestamp } from "@/utils/dates";

export interface ContractInfo {
	number: string;
	openingDate: number;
}

export class ContractInfoHelper {
	static map(source: ApiContractInfo): ContractInfo {
		return {
			...source,
			openingDate: convertToTimestamp(source.openingDate) as number
		};
	}
	
	static getEmpty(): ContractInfo {
		return {
			number: "",
			openingDate: 0
		};
	}
}