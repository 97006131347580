import { ApiHrImportEmployeesResponse } from "@/api/hr/types/vacation/apiHrImportEmployeesResponse";

export interface HrImportEmployeesResponse {
	totalCount?: number;
	importedCount?: number;
}

export class HrImportEmployeesResponseService {
	static map(source: ApiHrImportEmployeesResponse): HrImportEmployeesResponse {
		return {
			...source
		};
	}
	
	static getEmpty(): HrImportEmployeesResponse {
		return {
			totalCount: undefined,
			importedCount: undefined
		};
	}
}
