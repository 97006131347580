<template>
	<bar-content-layout v-if="isInitialized">
		<v-row>
			<v-fade-transition>
				<v-col v-show="!isMessengerFullscreen || isReturnToDSPDialogOpened || isReturnToUKFPDialogOpened" cols="5">
					<bar-bank-account-application-information/>
				</v-col>
			</v-fade-transition>
			<v-fade-transition>
				<v-col v-show="!isMessengerFullscreen || isReturnToDSPDialogOpened || isReturnToUKFPDialogOpened" cols="7">
					<v-row class="d-flex flex-column">
						<v-col class="pb-1">
							<bar-bank-account-application-information-letter/>
						</v-col>
						
						<v-col class="pb-1"
							   v-if="editableItem.source === SourceTypeEnum.KZ && can([Permissions.BAR_APPLICATION_DOWNLOAD_DOCUMENTS_READ, Permissions.BAR_APPLICATION_UPLOAD_DOCUMENTS_READ, Permissions.BAR_APPLICATION_EDIT_DOCUMENTS_READ])">
							<bar-bank-account-application-documents/>
						</v-col>
						
						<v-col class="pb-1"
							   v-if="editableItem.source === SourceTypeEnum.KZ && can([Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_READ, Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_READ])">
							<bar-bank-account-application-legal-department/>
						</v-col>
						
						<v-col class="pb-6"
							   v-if="editableItem.source === SourceTypeEnum.KZ && can(Permissions.BAR_APPLICATION_MESSAGES_READ)">
							<bar-bank-account-application-messenger/>
						</v-col>
					</v-row>
				</v-col>
			</v-fade-transition>
			
			<v-fade-transition>
				<v-col v-show="isMessengerFullscreen && can(Permissions.BAR_APPLICATION_MESSAGES_READ) && !(isReturnToDSPDialogOpened || isReturnToUKFPDialogOpened)" cols="12">
					<bar-bank-account-application-messenger/>
				</v-col>
			</v-fade-transition>
		</v-row>
		
		<template #footer-actions>
			<frp-btn
				v-if="buttonStatus(BankAccountApplicationButtonTypeEnum.DSP_BUTTON) && can(Permissions.BAR_APPLICATION_STATUS_DSP_UPDATE)"
						 @click="openReturnToDSPDialog"
						 :loading="isReturningToDsp"
						 :disabled="isFetchBankAccountApplicationLoading || isReturningToUfkp || isStatusUpdatingToConnect"
						 class="px-4"
						 elevation="0"
						 dark
						 color="red">
					{{ $t("buttons.returnDSP") }}
				</frp-btn>
				<frp-btn
					v-if="buttonStatus(BankAccountApplicationButtonTypeEnum.UFKP_BUTTON) && can(Permissions.BAR_APPLICATION_STATUS_UFKP_UPDATE)"
					:loading="isReturningToUfkp"
					:disabled="isFetchBankAccountApplicationLoading || isReturningToDsp || isStatusUpdatingToConnect"
					class="px-4"
					dark
					elevation="0"
					@click="openReturnToUKFPDialog"
					color="red">
					{{ $t("buttons.returnUFKP") }}
				</frp-btn>
				
				<frp-btn
					v-if="buttonStatus(BankAccountApplicationButtonTypeEnum.ON_CONNECT_BUTTON) && can(Permissions.BAR_APPLICATION_STATUS_TO_CONNECT_UPDATE)"
					:loading="isStatusUpdatingToConnect"
					:disabled="isFetchBankAccountApplicationLoading || isReturningToDsp || isReturningToUfkp"
					class="px-4"
					elevation="0"
					dark
					@click="toConnect"
					color="blue">
					{{ $t("buttons.onConnect") }}
				</frp-btn>
			<frp-btn v-if="buttonStatus(BankAccountApplicationButtonTypeEnum.EDIT_BUTTON) && can(Permissions.BAR_APPLICATION_UPDATE)"
						 :disabled="isFetchBankAccountApplicationLoading || isStatusUpdatingToConnect || isReturningToUfkp || isReturningToDsp"
						 class="px-4"
						 elevation="0"
						 dark
						 :to="{ name: RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE, query: { loanApplicationNumber: $route.query.loanApplicationNumber, sourceType: $route.query.sourceType }, params: { id: $route.params.id }}"
						 color="blue">
					{{ $t("buttons.edit") }}
				</frp-btn>
		</template>
		<bar-bank-account-application-messenger-dialog
			dialog-name="updateReturnToDspStatus"
			v-if="isReturnToDSPDialogOpened"
			v-model="isReturnToDSPDialogOpened"
			:message-type="MessageType.ReturnToDSP"
			@message:sent="returnToDsp"
		>
		</bar-bank-account-application-messenger-dialog>
		<bar-bank-account-application-messenger-dialog
			dialog-name="updateReturnToUKFPStatus"
			v-if="isReturnToUKFPDialogOpened"
			v-model="isReturnToUKFPDialogOpened"
			:message-type="MessageType.ReturnToUFKP"
			@message:sent="returnToUFKP"
		>
		</bar-bank-account-application-messenger-dialog>
	</bar-content-layout>
	<bar-bank-account-application-details-loader v-else/>


</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/bar/routes";
import { mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { MessageType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/MessageType";
import { getterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import storeManager from "@/store/manager";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import BarBankAccountApplicationDetailsLoader from "@/views/bar/bankAccountApplication/BarBankAccountApplicationDetailsLoader.vue";
import BarBankAccountApplicationMessengerDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationMessengerDialog.vue";
import BarBankAccountApplicationInformation
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformation.vue";
import BarBankAccountApplicationDocuments
	from "@/views/bar/bankAccountApplication/sections/modules/documents/BarBankAccountApplicationDocuments.vue";
import BarBankAccountApplicationInformationLetter
	from "@/views/bar/bankAccountApplication/sections/modules/informationLetter/BarBankAccountApplicationInformationLetter.vue";
import BarBankAccountApplicationLegalDepartment
	from "@/views/bar/bankAccountApplication/sections/modules/legalDepartment/BarBankAccountApplicationLegalDepartment.vue";
import BarBankAccountApplicationMessenger
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessenger.vue";
import { createNamespacedHelpers } from "vuex";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationMessengerHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplicationMessenger.namespace);

export default {
	metaInfo() {
		return {
			title: `${i18n.t("metaTitles.bankAccountApplication",
				{ applicationNumber: this.applicationNumber, createdAt: this.createdAt })}`
		};
	},
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			SourceTypeEnum,
			isReturnToDSPDialogOpened: false,
			isReturnToUKFPDialogOpened: false
		};
	},
	computed: {
		MessageType() {
			return MessageType;
		},
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum
		},
		...mapGetters({
			buttonStatus: getterTypes.buttonUnlocked
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isReturningToDsp: state => state.isReturningToDsp,
			isReturningToUfkp: state => state.isReturningToUfkp,
			isStatusUpdatingToConnect: state => state.isStatusUpdatingToConnect,
			isBankAccountApplicationBankFormValid: state => state.isBankAccountApplicationBankFormValid,
			isBankAccountApplicationAccountsFormValid: state => state.isBankAccountApplicationAccountsFormValid,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading
		}),
		...bankAccountApplicationMessengerHelpers.mapState({
			isMessengerFullscreen: state => state.isMessengerFullscreen
		}),
		applicationNumber() {
			return this.editableItem.applicationNumber || 0;
		},
		createdAt() {
			return formatDate(this.editableItem.createdAt || Date.now(), dateFormat);
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			initializeStore: actionTypes.initialize,
			createBankAccountApplication: actionTypes.createBankAccountApplication,
			returnToDsp: actionTypes.updateStatusToReturnToDSP,
			returnToUFKP: actionTypes.updateStatusToReturnToUFKP,
			toConnect: actionTypes.updateStatusToConnect
		}),
		...bankAccountApplicationMessengerHelpers.mapMutations({
			resetEditableMessageItemMessageType: mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TYPE,
			setEditableMessageItemMessageType: mutationTypes.SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TYPE
		}),
		openReturnToUKFPDialog() {
			this.isReturnToUKFPDialogOpened = true;
		},
		openReturnToDSPDialog() {
			this.isReturnToDSPDialogOpened = true;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		BarBankAccountApplicationMessengerDialog,
		BarBankAccountApplicationDetailsLoader,
		BarBankAccountApplicationMessenger,
		BarBankAccountApplicationLegalDepartment,
		BarBankAccountApplicationDocuments,
		BarBankAccountApplicationInformationLetter,
		BarBankAccountApplicationInformation,
		FrpBtn,
		BarContentLayout,
		FrpDialog
	}
};
</script>

<style scoped>

</style>
