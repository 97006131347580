import { ApiHrGetTasksParameters } from "@/api/hr/types/tasks/apiHrGetTasksParameters";
import TasksState from "@/store/hr/modules/tasks/types/tasksState";

export default class TasksMapper {
	static mapToGetTasksParameters(source: TasksState): ApiHrGetTasksParameters {
		
		return {
			taskTypes: source.filter.taskTypes,
			taskStates: source.filter.taskStates,
			taskAssigneeIds: source.filter.taskAssigneeIds
		};
	}
}
