import IPageState from "@/store/shared/base/types/pageState";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import {
	ApiBankAccountApplicationLegalDepartmentInfo,
	ApiBankAccountApplicationLegalDepartmentInfoHelper
} from "@/api/bar/types/apiBankAccountApplicationLegalDepartmentInfo";
import FormState from "@/store/shared/form/models/formState";
import { DocumentResponse } from "@/store/bar/types/documentResponse";

export default class BankAccountApplicationLegalDepartmentState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isResponsibleLegalDepartmentStaffEmployeeAppointed: boolean = false,
		public isLegalDepartmentRequestLoading: boolean = false,
		public isResponsibleLegalDepartmentStaffEmployeeAppointing: boolean = false,
		public isLegalDepartmentAccepting: boolean = false,
		public isLegalDepartmentDeclining: boolean = false,
		public isLegalDepartmentStatusesResetting: boolean = false,
		public legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo = ApiBankAccountApplicationLegalDepartmentInfoHelper.getEmpty(),
		public legalDepartmentDocumentFileMeta: FileMeta = new FileMeta(),
		public isLegalDepartmentDocumentFileInfoUploading: boolean = false,
		public isLegalDepartmentDocumentFileInfoItemsFetching: boolean = false,
		public legalDepartmentDocumentFileInfoItems: DocumentResponse[] = [],
		public editableResponsibleStaffEmployeeId: string = ""
	)
	{
	}
}
