import { HrVacationPlan } from "@/types/hr/vacation/hrVacationPlan";
import { HrVacationPlansItem } from "@/types/hr/vacation/hrVacationPlansItem";
import { formatFullName } from "@/utils/formatting";
import { HrFormattedEmployeesItem } from "@/store/hr/modules/vacationPlans/types/hrFormattedEmployeesItem";

export interface HrFormattedVacationPlansItem extends HrVacationPlansItem {
	employee: HrFormattedEmployeesItem;
	vacationPlan: HrVacationPlan | null;
}

export class HrFormattedVacationPlansItemMapper {
	static map(source: HrVacationPlansItem): HrFormattedVacationPlansItem {
		return {
			...source,
			employee: {
				...source.employee,
				fullName: formatFullName(source.employee)
			},
			vacationPlan: null
		};
	}
}
