<template>
	<frp-dialog :value="value"
				:title="title"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<p>
				{{ text }}
			</p>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 color="primary"
					 @click="cancel">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn color="secondary"
					 :loading="loading"
					 @click="submit">
				{{ $t("buttons.delete") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { namespace } from "@/store/hr/modules/personnelDocuments";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		loading: Boolean,
		value: Boolean,
		title: String,
		text: String
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		submit() {
			this.$emit("submit");
		},
		cancel() {
			this.$emit("cancel");
		}
	},
	watch: {},
	components: {
		FrpConfirmDialog,
		FrpTextField,
		FrpTextarea,
		FrpDialog,
		FrpBtn
	}
};
</script>
