<template>
	<frp-card  v-if="isInitialized" :title="$t('titles.bankAccountApplications')" class="pb-1">
		<template #content>
			<bar-details-group>
				<template v-if="!applications.length">
					<v-row>
						<v-col class="d-flex align-center flex-column py-8">
							<span class="text-body-2 grey--text">{{ $t("content.noApplications") }}</span>
						</v-col>
					</v-row>
				</template>

				<template v-else>
					<bar-details-item
						v-for="application in applications"
						:key="application.id"
						:title="`${application.number} ${$t('common.from')} ${formatDate(application.createdAt, dateFormat)}`"
						:to="{ name: RouteNames.BANK_ACCOUNT_APPLICATION, params: { projectId: editableItem.loanApplicationNumber, sourceType: SourceTypeEnum.KZ, id: application.id }}"
						link>
					</bar-details-item>
				</template>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-applications-loader v-else/>
</template>

<script>
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import { RouteNames } from "@/router/bar/routes";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { namespace } from "@/store/bar/modules/bankAccount";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import BarBankAccountApplicationsLoader from "@/views/bar/bankAccount/sections/applications/BarBankAccountApplicationsLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	data() {
		return {
			RouteNames,
			SourceTypeEnum,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			applications: state => state.applications,
			editableItem: state => state.editableItem
		})
	},
	components: { BarBankAccountApplicationsLoader, FrpTextBtn, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
