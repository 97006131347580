<template>
	<div>
		<frp-dialog :value="value"
					:title="$t(`dialogs.${isCreateMode ? 'createDocument' : 'updateDocument'}.title`)"
					max-width="496"
					persistent
					v-if="!openConfirmDelete"
					@update:value="$emit('update:value', $event)">
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-model="formValid" v-intersect="onIntersect">
					<frp-text-field v-model="title"
									required
									:color="colors.grey.base"
									:label="$t('fields.createDocument.label')"
									:placeholder="$t('fields.createDocument.placeholder')">
					</frp-text-field>
					<hr-dropzone max-size="25"
								 required
								 @file:add="file = $event"
								 @file:delete="file = null"
								 @file:loading="isFileLoading = $event"
								 :formats="['7z', 'rar', 'zip', 'pdf']"
								 :loading="isFileMetaLoading"
								 :file="tempFile"
								 ref="documentEditableFile"
								 class="bod-dropzone"
								 type="editable">
					</hr-dropzone>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 color="primary"
						 @click="handleClose">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn v-if="!isCreateMode"
						 color="secondary"
						 @click="openConfirmDelete = true">
					{{ $t("buttons.delete") }}
				</frp-btn>
				<frp-btn elevation="0"
						 dark
						 color="blue"
						 :disabled="isProcessingLoading || !formValid || !(file || tempFile) || isFileLoading"
						 :loading="isProcessingLoading || isFileLoading"
						 @click="handleDocument">
					{{ $t(`buttons.${mode.action}`) }}
				</frp-btn>
			</template>
		
		</frp-dialog>
		<hr-confirm-delete-dialog v-model="openConfirmDelete"
								  :title="dialogs.title"
								  :text="dialogs.text"
								  :loading="isProcessingLoading"
								  @submit="handleDelete"
								  @cancel="openConfirmDelete = false">
		</hr-confirm-delete-dialog>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import BodDropzone from "@/components/dropzone/BodDropzone.vue";
import FrpDropzone from "@/components/dropzone/FrpDropzone.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { getterTypes } from "@/store/bar/modules/bankAccount/types";
import { namespace } from "@/store/hr/modules/personnelDocuments";
import { actionTypes, mutationTypes } from "@/store/hr/modules/personnelDocuments/types";
import HrConfirmDeleteDialog from "@/views/hr/personnelDocuments/dialogs/HrConfirmDeleteDialog.vue";
import HrDropzone from "@/components/dropzone/HrDropzone.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, formMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			namespace,
			openConfirmDelete: false,
			file: null,
			tempFile: null,
			isFileLoading: false
		};
	},
	computed: {
		...mapState({
			isProcessingLoading: state => state.isProcessingLoading,
			isCreateMode: state => state.isCreateMode,
			selectedPersonnelDocument: state => state.selectedPersonnelDocument,
			selectedPersonnelPartition: state => state.selectedPersonnelPartition,
			payload: state => state.payloadDocument,
			isFileMetaLoading: state => state.isFileMetaLoading
		}),
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges
		}),
		dialogs() {
			return {
				title: this.$t(`dialogs.deleteDocument.title`),
				text: this.$t(`dialogs.deleteDocument.text`).replace("{{nameDocument}}", this.selectedPersonnelDocument?.title)
			};
		},
		title: {
			get() {
				return this.payload.title;
			},
			set(value) {
				this.setPayloadTitle(value);
			}
		},
		tempFileId: {
			get() {
				return this.payload.tempFileId || this.selectedPersonnelDocument?.fileId;
			},
			set(value) {
				this.setPayloadTempFileId(value);
			}
		},
		mode() {
			if(this.isCreateMode) {
				return {
					title: "createPartition",
					action: "add"
				};
			} else {
				return {
					title: "updatePartition",
					action: "save"
				};
			}
		}
	},
	methods: {
		...mapMutations({
			setSelectedPersonnelPartition: mutationTypes.SET_SELECTED_PERSONNEL_PARTITION,
			setPayload: mutationTypes.SET_PAYLOAD_DOCUMENT,
			setPayloadTitle: mutationTypes.SET_PAYLOAD_DOCUMENT_TITLE,
			setPayloadTempFileId: mutationTypes.SET_PAYLOAD_DOCUMENT_TEMP_FILE_ID,
			resetPayload: mutationTypes.RESET_PAYLOAD_DOCUMENT
		}),
		...mapActions({
			processingDocument: actionTypes.processingDocument,
			deleteDocument: actionTypes.deleteDocument,
			getFileMeta: actionTypes.getFileMeta
		}),
		async handleDelete() {
			await this.deleteDocument({ id: this.selectedPersonnelDocument?.id });
			
			this.handleClose();
		},
		async handleDocument() {
			await this.processingDocument(
				{ id: this.selectedPersonnelDocument?.id, payload: { ...this.payload, order: this.selectedPersonnelDocument?.order, partitionId: this.selectedPersonnelPartition?.id } });
			
			this.handleClose();
		},
		handleClose() {
			this.setSelectedPersonnelPartition({});
			this.resetPayload();
			this.$emit("update:value", false);
		}
	},
	async created() {
		if(!this.isCreateMode) {
			const { name } = await this.getFileMeta(this.selectedPersonnelDocument?.fileId);
			this.tempFile = new File([], name);
		}
		
		this.setPayloadTitle(this.selectedPersonnelDocument?.title);
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		},
		file(value) {
			if(!value) return;
			
			this.tempFileId = value?.id;
			const filename = value.name.substr(0, value.name.lastIndexOf("."));
			
			this.setPayloadTitle(filename);
		}
	},
	components: {
		HrDropzone,
		BodDropzone,
		FrpDropzone,
		HrConfirmDeleteDialog,
		FrpConfirmDialog,
		FrpTextField,
		FrpTextarea,
		FrpDialog,
		FrpBtn
	}
};
</script>
