<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ `${label}${required ? "*" : ""}` }}</span>
		</v-subheader>
		<v-autocomplete :items="visibleItems"
						:placeholder="placeholder"
						outlined
						:return-object="returnObject"
						:background-color="colors.white.base"
						:clearable="clearable"
						dense
						:height="!value && !disabled ? +height + 8 : undefined"
						:item-text="itemText"
						:item-value="itemValue"
						@input="$emit('update:value', $event)"
						:value="value"
						v-mask="mask"
						:rules="[...defaultRules, ...rules]"
						class="frp-field frp-high-autocomplete frp-hidden-input-autocomplete"
						:class="{
							'frp-dense-field': dense,
							'v-input--is-disabled': disabled
						}"
						:disabled="disabled"
						:hide-details="hideDetails"
						:readonly="readonly"
						:hide-selected="hideSelected"
						v-bind="$attrs"
						v-on="$listeners"
						:loading="loading"
						@update:search-input="query = $event"
						@mousedown="open"
						@focus="open"
						@blur="close"
						:menu-props="{ contentClass }"
						:no-data-text="noDataTextResult">
			<template #append>
				<v-fade-transition group hide-on-leave>
					<frp-icon key="up"
							  v-if="isOpened"
							  style="cursor: pointer"
							  :color="colors.primary.base"
							  src="ico_menu-up"
							  @click="close">
					</frp-icon>
					<frp-icon key="down"
							  v-else
							  :style="{ cursor: disabled ? 'default' : '' }"
							  style="cursor: pointer"
							  :color="colors.primary.base"
							  src="ico_menu-down"
							  @click="open">
					</frp-icon>
				</v-fade-transition>
			</template>
			<template #item="{ item, on, attrs }">
				<v-list-item @click="close"
							 :disabled="disabled"
							 :class="{ 'unvisible-option': menuFilterValues && menuFilterValues.includes(item[menuFilterKey]), 'white darken-1': item.strikethrough }"
							 v-on="on"
							 v-bind="attrs">
					<span class="py-2" :class="{ 'text-decoration-line-through': item.strikethrough }">{{ itemText ? item[itemText] : item }}</span>
				</v-list-item>
			</template>
			<template #selection="{ item }">
				<div ref="selection"
					 @click="selectText"
					 class="overflow-hidden d-inline-block" :style="{ 'min-height': disabled ? '' : height + 'px' }">
					{{ item[itemText] }}
				</div>
			</template>
		</v-autocomplete>
	</div>
</template>

<script>
import { selectElementText } from "@/utils/dom";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { requiredAutocompleteValueRule, requiredRule } from "Utils/validation";
import { cloneDeep, isEqual } from "lodash";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isOpened: false,
			internalItems: [],
			query: "",
			isClicked: false
		};
	},
	props: {
		rules: {
			type: Array,
			default: () => []
		},
		placeholder: String,
		height: [String, Number],
		label: String,
		disabled: Boolean,
		returnObject: Boolean,
		required: Boolean,
		clearable: Boolean,
		hideSelected: Boolean,
		selectableText: Boolean,
		items: Array,
		value: [Object, String, Number, Array],
		mask: String,
		hideDetails: Boolean,
		dense: Boolean,
		loading: Boolean,
		itemText: String,
		itemValue: String,
		itemKey: {
			type: String,
			default: "id"
		},
		menuFilterValues: Array,
		menuFilterKey: String,
		readonly: {
			type: Boolean,
			default: false
		},
		minQueryLength: Number,
		color: String
	},
	computed: {
		noDataTextResult() {
			if(!this.minQueryLength || this.query?.length >= this.minQueryLength)
				return "$vuetify.noDataText";
			else
				return this.$t("placeholders.request");
		},
		defaultRules() {
			if(this.required) {
				if(this.returnObject)
					return [requiredAutocompleteValueRule(this.itemText)];
				else
					return [requiredRule()];
			} else {
				return [];
			}
		},
		contentClass() {
			if(this.color === "blue")
				return "frp-menu frp-blue-menu";
			
			return "frp-menu";
		},
		visibleItems() {
			if(!this.disabled || !this.returnObject)
				return this.internalItems;
			else
				return [this.value];
		}
	},
	methods: {
		close() {
			this.isOpened = false;
		},
		open() {
			if(this.disabled) return;
			
			if(!this.isClicked) {
				this.isClicked = true;
				
				if(!this.disabled)
					this.$emit("firstclick");
			}
			
			this.isOpened = true;
		},
		updateItems() {
			if(!this.minQueryLength) {
				this.internalItems = [...this.items];
				
				if(this.returnObject && this.value) {
					if(this.value[this.itemText]) {
						if(!this.items.some(x => isEqual(x, this.value)))
							this.internalItems.push(cloneDeep(this.value));
					}
				}
				
				return;
			}
			
			if(!this.query || this.query.length < this.minQueryLength)
				this.internalItems = [];
			else
				this.internalItems = this.items.filter(x => x[this.itemText].toLowerCase().includes(this.query.toLowerCase()));
			
			if(this.value) {
				const currentItem = this.items.find(x => x[this.itemValue] === this.value);
				if(currentItem && !this.internalItems.some(x => x[this.itemValue] === currentItem[this.itemValue]))
					this.internalItems.push(currentItem);
			}
			
		},
		selectText() {
			if(this.selectableText)
				selectElementText(this.$refs.selection);
		}
	},
	watch: {
		items() {
			this.updateItems();
		},
		query() {
			this.updateItems();
		},
		value() {
			this.updateItems();
		}
	},
	components: {
		FrpIcon
	}
};
</script>

<style scoped>
.unvisible-option {
	display: none;
}
</style>
