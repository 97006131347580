import { actionTypes, mutationTypes, namespace, getterTypes } from "@/store/bar/modules/bankAccount/types";
import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import BankAccountState from "@/store/bar/modules/bankAccount/types/bankAccountState";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/bar/dictionaries";
import { cloneDeep, first } from "lodash";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import router from "@/router/bar";
import { RouteNames } from "@/router/bar/routes";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { i18n } from "@/plugins/index";
import { maxLengths } from "@/utils/validation";
import { BankSelectionModeTypeEnum } from "@/store/bar/types/BankSelectionModeTypeEnum";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { BankInfo, BankInfoMapper } from "@/store/bar/types/bankInfo";
import { BankAccountInfoHelper } from "@/store/bar/types/bankAccountInfo";
import { ApiApplication } from "@/api/bar/types/apiApplication";
import { ApplicationMapper } from "@/store/bar/modules/bankAccount/types/application";
import { AccountAgreement, AccountAgreementHelper } from "@/store/bar/types/accountAgreement";
import { ApiSuggestedProject } from "@/api/bar/types/apiSuggestedProject";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { CurrencyTypeEnum } from "@/store/bar/types/CurrencyTypeEnum";
import { ApiCurrency } from "@/api/bar/types/dictionaries/apiCurrency";
import { ApiChangeBankAccountInfoRequestMapper } from "@/api/bar/types/bankAccountInfo/apiChangeBankAccountInfoRequest";
import BatchService from "@/services/batchService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import rootTypes from "@/store/bar/types";
import { PageModeType } from "@/store/bar/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import storeManager from "@/store/manager";
import userTypes from "@/store/bar/modules/user/types";

const abortService = new AbortService();
const barController = new BarController(abortService);
const dictionariesController = new DictionariesController(abortService);
const requestBatchService = new BatchService(({ interval: 500 }));

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItem"]
		})
	]
})).build();


class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();


let subscribersManager: SubscribersManager<BankAccountState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<BankAccountState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<BankAccountState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(namespace, mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_ACCOUNT_TYPE):
		{
			const { editableItem: { accountAgreements }, currencies } = state;
			
			if(accountAgreements.some((x: AccountAgreement) => x.accountType === BankAccountTypeEnum.PRIMARY)) {
				const rubCurrency = currencies.find((x: ApiCurrency) => x.code === CurrencyTypeEnum.RUB);
				subscribersManager.commit(
					resolveMutation(namespace, mutationTypes.SET_EDITABLE_ITEM_CURRENCY_ID), cloneDeep(rubCurrency?.id || "")
				);
			}
			
			break;
		}
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.isCardEditingDisabledWithRequests]: (state, _, rootState, rootGetters) => {
		const isBarUserAdministrator = rootGetters[resolveGetter(storeManager.bar.barUser.namespace,
			userTypes.getterTypes.isBarUserAdministrator)];
		
		if(isBarUserAdministrator || !rootState.route.params.id) return false;
		
		return state.applications.length !== 0;
	}
};

const actions = <ActionTree<BankAccountState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ state, dispatch, rootState, commit })
	{
		await dispatch(actionTypes.initializeBase);
		
		const id = rootState.route.params.id;
		const loanApplicationNumber = rootState.route.query.loanApplicationNumber;
		
		await Promise.all([
			dispatch(actionTypes.fetchDictionaries),
			dispatch(actionTypes.initProjectStatusByFinancingScheme, loanApplicationNumber)
		]);
		
		if(!id) {
			dispatch(actionTypes.addEmptyAccountAgreementsItem);
			
			if(loanApplicationNumber) {
				await dispatch(actionTypes.fetchSuggestedProjects, { index: 0, query: loanApplicationNumber });
			}
		}
		
		if(id) {
			await dispatch(actionTypes.fetchBankAccount);
			await dispatch(actionTypes.fetchApplicationsForBankAccount);
			
			commit(mutationTypes.SET_BANK_SELECTION_MODE, BankSelectionModeTypeEnum.BANK);
			
			await dispatch(actionTypes.checkBankAccountPayments);
		}
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetchDictionaries]({ commit, state }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const [
				banks,
				monitoringSystemConnectionTypes,
				signStatuses,
				bankAccountTypes,
				applicationStatuses,
				currencies,
				bankResponsibleUsers
			] = await Promise.all([
				dictionariesController.getBanks(),
				dictionariesController.getMonitoringSystemConnectionTypes(),
				dictionariesController.getSignStatuses(),
				dictionariesController.getBankAccountTypes(),
				dictionariesController.getApplicationStatuses(),
				dictionariesController.getCurrencies(),
				dictionariesController.getBankResponsibleUsers()
			]);
			
			commit(mutationTypes.SET_BANKS, banks.map((x: ApiBankInfoPersisted) => BankInfoMapper.map(x)));
			commit(mutationTypes.SET_MONITORING_SYSTEM_CONNECTION_TYPES, monitoringSystemConnectionTypes);
			commit(mutationTypes.SET_SIGN_STATUSES, signStatuses);
			commit(mutationTypes.SET_BANK_ACCOUNT_TYPES, bankAccountTypes.filter(x => x.code !== BankAccountTypeEnum.UNKNOWN));
			commit(mutationTypes.SET_APPLICATION_STATUSES, applicationStatuses);
			commit(mutationTypes.SET_CURRENCIES, currencies);
			commit(mutationTypes.SET_BANK_RESPONSIBLE_USERS, bankResponsibleUsers);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.fetchSuggestedProjects]({ commit }, { index, query }) {
		commit(mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_FETCH_PROJECT_LOADING, { index, value: true });
		
		try {
			const suggestedProjects: ApiSuggestedProject[] = await dictionariesController.getSuggestedProjects(query);
			
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS, { index, value: suggestedProjects });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_FETCH_PROJECT_LOADING, { index, value: false });
		}
	},
	async [actionTypes.fetchSuggestedBankAndBankBranchByBik]({ commit, dispatch }, bik) {
		commit(mutationTypes.SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING, true);
		
		try {
			const apiBankInfoPersisted: ApiBankInfoPersisted = await dictionariesController.getBankAndBankBranchByBik(bik);
			
			const mappedBankInfo: BankInfo = BankInfoMapper.map(apiBankInfoPersisted);
			
			await dispatch(actionTypes.setSelectedBank, mappedBankInfo);
			await dispatch(actionTypes.setSelectedBankBranch, first(mappedBankInfo.branches));
			commit(mutationTypes.SET_BANK_SELECTION_MODE, BankSelectionModeTypeEnum.BIK);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING, false);
		}
	},
	async [actionTypes.fetchBankAccount]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING, true);
		
		try {
			const bankAccount = await barController.getBankAccount(rootState.route.params.id);
			
			commit(mutationTypes.SET_EDITABLE_ITEM, BankAccountInfoHelper.map(bankAccount));
			
			await dispatch(actionTypes.initializeBankInfo);
		} catch (error) {
			console.error(error);
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING, false);
		}
	},
	async [actionTypes.fetchApplicationsForBankAccount]({ commit, state }) {
		commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING, true);
		
		try {
			const applications: ApiApplication[] = await barController.getApplicationsForBankAccount(state.editableItem.account.accountNumber);
			
			commit(mutationTypes.SET_APPLICATIONS, applications.map((x: ApiApplication) => ApplicationMapper.map(x)));
		} catch (error) {
			console.error(error);
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING, false);
		}
	},
	async [actionTypes.checkBankAccountPayments]({ commit, state }) {
		commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING, true);
		
		try {
			const { hasPayments } = await barController.checkBankAccountPayments(state.editableItem.account.accountNumber);
			
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_HAS_PAYMENTS, hasPayments);
		} catch (error) {
			console.error(error);
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING, false);
		}
	},
	async [actionTypes.initializeBankInfo]({ commit, state, dispatch }) {
		const selectedBank = state.banks.find(x => x.id === state.editableItem.bankId);
		
		if(!selectedBank) return;
		
		if(selectedBank.branches.length)
			commit(mutationTypes.SET_BANK_BRANCHES, selectedBank.branches);
		
		if(state.editableItem.bankBranchId) {
			const selectedBranch = state.bankBranches.find(x => x.id === state.editableItem.bankBranchId);
			await dispatch(actionTypes.setSelectedBankBranch, selectedBranch);
		}
	},
	[actionTypes.addEmptyAccountAgreementsItem]({ commit }) {
		const emptyAccountAgreementsItem: AccountAgreement = AccountAgreementHelper.getEmpty();
		commit(mutationTypes.ADD_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM, emptyAccountAgreementsItem);
	},
	async [actionTypes.setSelectedBank]({ commit, dispatch, rootState, state }, bankInfo) {
		commit(mutationTypes.SET_BANK_SELECTION_MODE, BankSelectionModeTypeEnum.BANK);
		commit(mutationTypes.RESET_CORRESPONDENT_ACCOUNT);
		commit(mutationTypes.RESET_BANK_BRANCHES);
		commit(mutationTypes.RESET_EDITABLE_ITEM_BANK_BRANCH_ID);
		commit(mutationTypes.RESET_BIK);
		
		if(!bankInfo || !bankInfo.branches.length) {
			if(!rootState.route.params.id)
				commit(mutationTypes.RESET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID);
			
			commit(mutationTypes.RESET_EDITABLE_ITEM_BANK_ID);
			commit(mutationTypes.SET_BANK_SELECTION_MODE, BankSelectionModeTypeEnum.BIK);
			
			return;
		}
		
		if(!rootState.route.params.id)
			commit(mutationTypes.SET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID, bankInfo.responsibleEmployeeId);
		
		commit(mutationTypes.SET_BANK_BRANCHES, bankInfo.branches);
		
		commit(mutationTypes.SET_EDITABLE_ITEM_BANK_ID, bankInfo.id);
	},
	async [actionTypes.setSelectedBankBranch]({ commit, dispatch, state }, bankBranchInfo) {
		commit(mutationTypes.RESET_BIK);
		commit(mutationTypes.RESET_CORRESPONDENT_ACCOUNT);
		
		if(!bankBranchInfo) {
			commit(mutationTypes.RESET_EDITABLE_ITEM_BANK_BRANCH_ID);
			return;
		}
		
		commit(mutationTypes.SET_BIK, bankBranchInfo.bik);
		commit(mutationTypes.SET_CORRESPONDENT_ACCOUNT, bankBranchInfo.correspondentAccount);
		commit(mutationTypes.SET_EDITABLE_ITEM_BANK_BRANCH_ID, bankBranchInfo.id);
	},
	async [actionTypes.setBik]({ commit, dispatch, state }, bik) {
		commit(mutationTypes.SET_BIK, bik);
		if(!bik.length) return await dispatch(actionTypes.setSelectedBank, null);
		
		if(bik.length === maxLengths.bik && state.bankSelectionMode === BankSelectionModeTypeEnum.BIK) {
			await dispatch(actionTypes.fetchSuggestedBankAndBankBranchByBik, bik);
		}
	},
	async [actionTypes.setLoanApplicationNumber]({ commit, dispatch, state }, { index, value }) {
		// если у нас есть уже введенные номера проектов подстать value, то выводим ошибку и удаляем введенные значения
		const isSimilarProjectExists = state.editableItem.accountAgreements.some(x => (x.number && value) && x.number === value);
		if(isSimilarProjectExists) {
			alertService.addCustomError(`${i18n.t("alerts.errors.agreementNumberAlreadyExists")}`);
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER, { index, value: null });
			commit(mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS, index);
			return;
		}
		
		commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER, { index, value });
		
		// записываем данные, включая olkCounterpartyId из введенного проекта по suggestedProject и чистим лишние проекты у него
		const suggestedProject = state.editableItem.accountAgreements[index]?.suggestedProjects.find(x => x.loanApplicationNumber ===
			value);
		if(suggestedProject) {
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS, { index, value: [suggestedProject] });
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID,
				{ index, value: suggestedProject.olkCounterpartyId });
		}
		
		// данная проверка идет на то, что если у введенного проекта olkCounterpartyId не соответствует с первым элементом, то выводим ошибку и удаляем введенные значения
		const unitedOlkCounterpartyId = state.editableItem.accountAgreements[0]?.olkCounterpartyId;
		const itemOlkCounterpartyId = state.editableItem.accountAgreements[index]?.olkCounterpartyId;
		
		if((unitedOlkCounterpartyId && itemOlkCounterpartyId) && unitedOlkCounterpartyId !== itemOlkCounterpartyId) {
			alertService.addCustomError(`${i18n.t("alerts.errors.agreementNumberBelongsToAnotherCounterparty")}`);
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER, { index, value: null });
			commit(mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS, index);
			commit(mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID, index);
			return;
		}
		
		if(value)
			requestBatchService.push(async () => await dispatch(actionTypes.checkIsAgreementNumberUnique, { index, value }));
	},
	async [actionTypes.createBankAccount]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_BANK_ACCOUNT_SAVING, true);
		try {
			const payload = ApiChangeBankAccountInfoRequestMapper.map(state.editableItem);
			
			await barController.createBankAccount(payload);
			
			await router.push({ name: RouteNames.BANK_ACCOUNTS_LIST }).catch(() => {
			});
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_ITEM_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_SAVING, false);
		}
	},
	async [actionTypes.updateBankAccount]({ commit, dispatch, rootState, state }) {
		commit(mutationTypes.SET_IS_BANK_ACCOUNT_SAVING, true);
		try {
			const payload = ApiChangeBankAccountInfoRequestMapper.map(state.editableItem);
			const bankAccount = await barController.updateBankAccount(rootState.route.params.id, payload);
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_ITEM_SUCCESS_UPDATED);
			
			commit(mutationTypes.SET_EDITABLE_ITEM, BankAccountInfoHelper.map(bankAccount));
			
			await dispatch(actionTypes.initializeBankInfo);
			
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_SAVING, false);
		}
	},
	async [actionTypes.checkIsBankAccountNumberUnique]({ commit, dispatch, state }) {
		try {
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE_CHECK_IN_PROGRESS, true);
			
			const exists = await barController.checkBankAccount({ accountNumber: state.editableItem.account.accountNumber });
			
			if(exists)
				alertService.addCustomError(`${i18n.t("alerts.errors.bankAccountNumberAlreadyExists")}`);
			else {
				const tasks: AccountAgreement[] = [];
				state.editableItem.accountAgreements.forEach((x: AccountAgreement, index: number) => {
					if(x.number && x.suggestedProjects.length)
						tasks.push(x);
				});
				
				await Promise.all(tasks.map((x: AccountAgreement, index: number) => {
					dispatch(actionTypes.checkIsAgreementNumberUnique, { index, value: x.number });
				}));
			}
			
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE, !exists);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE, true);
		} finally {
			commit(mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE_CHECK_IN_PROGRESS, false);
		}
	},
	async [actionTypes.checkIsAgreementNumberUnique]({ commit, rootState, state }, { index, value }) {
		try {
			commit(mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE_CHECK_IN_PROGRESS, { index, value: true });
			
			const parameters = {
				accountNumber: state.editableItem.account.accountNumber,
				loanApplicationNumber: value
			};
			
			const exists = await barController.checkBankAccount(parameters);
			
			if(exists)
				alertService.addCustomError(`${i18n.t("alerts.errors.agreementNumberAlreadyJointByBankAccountNumber")}`);
			
			commit(mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE, { index, value: !exists });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE, { index, value: true });
		} finally {
			commit(mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE_CHECK_IN_PROGRESS, { index, value: false });
		}
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.initProjectStatusByFinancingScheme]({ commit }, projectId) {
		if(!projectId) return;
		
		try {
			const { projectFinancingSchemeInfo } = await dictionariesController.getAgreementInfo(projectId);
			
			const isFinancialSchemeMarkingOrLeasing = projectFinancingSchemeInfo.isLeasing || projectFinancingSchemeInfo.isMarking;
			
			if(isFinancialSchemeMarkingOrLeasing) {
				commit(mutationTypes.SET_EDITABLE_ITEM_MONITORING_SYSTEM_CONNECTION_STATUS,
					BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_REQUIRED);
				commit(mutationTypes.SET_EDITABLE_ITEM_STATUS, BankAccountStatusTypeEnum.CONNECTED);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	}
};

const mutations = <MutationTree<BankAccountState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING](state, value) {
		state.isFetchBankAccountInfoLoading = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_AGREEMENT_TYPES_HISTORY_LOADING](state, value) {
		state.isBankAccountAgreementTypesHistoryLoading = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_SAVING](state, value) {
		state.isBankAccountSaving = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_FORM_VALID](state, value) {
		state.isBankAccountFormValid = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_HAS_PAYMENTS](state, value) {
		state.isBankAccountHasPayments = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE_CHECK_IN_PROGRESS](state, value) {
		state.isBankAccountNumberUniqueCheckInProgress = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE](state, value) {
		state.isBankAccountNumberUnique = value;
	},
	[mutationTypes.SET_APPLICATIONS](state, value) {
		state.applications = value;
	},
	[mutationTypes.SET_BANKS](state, value) {
		state.banks = value;
	},
	[mutationTypes.SET_BANK_BRANCHES](state, value) {
		state.bankBranches = value;
	},
	[mutationTypes.RESET_BANK_BRANCHES](state) {
		state.bankBranches = [];
	},
	[mutationTypes.SET_MONITORING_SYSTEM_CONNECTION_TYPES](state, value) {
		state.monitoringSystemConnectionTypes = value;
	},
	[mutationTypes.SET_SIGN_STATUSES](state, value) {
		state.signStatuses = value;
	},
	[mutationTypes.SET_BANK_ACCOUNT_TYPES](state, value) {
		state.bankAccountTypes = value;
	},
	[mutationTypes.SET_APPLICATION_STATUSES](state, value) {
		state.applicationStatuses = value;
	},
	[mutationTypes.SET_CURRENCIES](state, value) {
		state.currencies = value;
	},
	[mutationTypes.SET_BANK_RESPONSIBLE_USERS](state, value) {
		state.bankResponsibleUsers = value;
	},
	[mutationTypes.SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING](state, value) {
		state.isFetchSuggestedBankAndBankBranchByBikLoading = value;
	},
	[mutationTypes.RESET_BIK](state) {
		state.bik = "";
	},
	[mutationTypes.SET_BIK](state, value) {
		state.bik = value;
	},
	[mutationTypes.RESET_CORRESPONDENT_ACCOUNT](state) {
		state.correspondentAccount = "";
	},
	[mutationTypes.SET_CORRESPONDENT_ACCOUNT](state, value) {
		state.correspondentAccount = value;
	},
	[mutationTypes.SET_BANK_SELECTION_MODE](state, value) {
		state.bankSelectionMode = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM](state) {
		state.editableItem = BankAccountInfoHelper.getEmpty();
	},
	[mutationTypes.SET_EDITABLE_ITEM](state, value) {
		state.editableItem = cloneDeep(value);
	},
	[mutationTypes.RESET_EDITABLE_ITEM_BANK_ID](state) {
		state.editableItem.bankId = "";
	},
	[mutationTypes.SET_EDITABLE_ITEM_BANK_ID](state, value) {
		state.editableItem.bankId = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM_BANK_BRANCH_ID](state) {
		state.editableItem.bankBranchId = "";
	},
	[mutationTypes.SET_EDITABLE_ITEM_BANK_BRANCH_ID](state, value) {
		state.editableItem.bankBranchId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_NUMBER](state, value) {
		state.editableItem.account.accountNumber = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_CURRENCY_ID](state, value) {
		state.editableItem.account.currencyId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_OPENED_AT](state, value) {
		state.editableItem.account.openedAt = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_START_ACCEPT_DATE](state, value) {
		state.editableItem.startAcceptDate = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID](state) {
		state.editableItem.controlResponsibleEmployeeId = "";
	},
	[mutationTypes.SET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID](state, value) {
		state.editableItem.controlResponsibleEmployeeId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_CONTROL_DATE](state, value) {
		state.editableItem.controlDate = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_END_ACCEPT_DATE](state, value) {
		state.editableItem.endAcceptDate = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_STATUS](state, value) {
		state.editableItem.status = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_MONITORING_SYSTEM_CONNECTION_STATUS](state, value) {
		state.editableItem.monitoringSystemConnectionStatus = value;
	},
	[mutationTypes.DELETE_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM](state, index) {
		state.editableItem.accountAgreements.splice(index, 1);
	},
	[mutationTypes.ADD_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM](state, value) {
		state.editableItem.accountAgreements.push(value);
	},
	[mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_FETCH_PROJECT_LOADING](state, { index, value }) {
		state.editableItem.accountAgreements[index].isFetchProjectsLoading = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS](state, index) {
		state.editableItem.accountAgreements[index].suggestedProjects = [];
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS](state, { index, value }) {
		state.editableItem.accountAgreements[index].suggestedProjects = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER](state, { index, value }) {
		state.editableItem.accountAgreements[index].number = cloneDeep(value);
	},
	[mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE_CHECK_IN_PROGRESS](state, { index, value }) {
		state.editableItem.accountAgreements[index].isNumberUniqueCheckInProgress = cloneDeep(value);
	},
	[mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE](state, { index, value }) {
		state.editableItem.accountAgreements[index].isNumberUnique = cloneDeep(value);
	},
	[mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID](state, index) {
		state.editableItem.accountAgreements[index].olkCounterpartyId = "";
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID](state, { index, value }) {
		state.editableItem.accountAgreements[index].olkCounterpartyId = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_ACCOUNT_TYPE](state, { index, value }) {
		state.editableItem.accountAgreements[index].accountType = cloneDeep(value);
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const bankAccountModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default bankAccountModule;
