<template>
	<div class="hr-chart-value d-flex align-center justify-center">
		{{ value }}%
	</div>
</template>

<script>
export default {
	props: {
		value: [String, Number]
	}
};
</script>

<style scoped lang="scss">
.hr-chart-value {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: none;
	font-size: 30px;
	font-weight: 700;
	color: var(--v-blue-base);
}
</style>
