import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/employees/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AbortService from "@/services/abortService";
import EmployeesRouteQuery from "@/store/hr/modules/employees/types/employeesRouteQuery";
import EmployeesRouteService from "@/store/hr/modules/employees/services/employeesRouteService";
import EmployeesState from "@/store/hr/modules/employees/types/employeesState";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import EmployeesFilter from "@/store/hr/modules/employees/types/employeesFilter";
import { HrController } from "@/api/hr";
import routeTypes from "@/store/shared/route/types";
import EmployeesMapper from "@/store/hr/modules/employees/mapper";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import { formatFullName } from "@/utils/formatting";

const abortService = new AbortService();
const hrController = new HrController(abortService);

const defaultRouteQuery = new EmployeesRouteQuery(1, "", []);

const routeService = new EmployeesRouteService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<EmployeesState>()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeesState(
			new ListingModel<ApiHrEmployeesItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 1000
			}),
			new SearchModel({
				query: ""
			}),
			new EmployeesFilter(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<EmployeesState>;

const getters = <GetterTree<EmployeesState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let item = {
				...x,
				fullName: formatFullName(x),
				innerPhone: x.extensionPhone,
				department: x.department.name
			};
			
			return item;
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<EmployeesState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<EmployeesState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_NAME):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_IDS):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
			await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
	}
};

const actions = <ActionTree<EmployeesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		
		await dispatch(actionTypes.updateListingItems);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let { totalCount, employees } = await hrController.getEmployees(EmployeesMapper.mapToGetEmployeesParameters(state));
			
			commit(mutationTypes.SET_LISTING_ITEMS, employees);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		
		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.departmentIds.length)
			await dispatch(actionTypes.fetchDepartments);
		
		commit(mutationTypes.SET_FILTER_NAME, routeQuery.name);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, routeQuery.departmentIds);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.EMPLOYEES,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.EMPLOYEES,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
			
		try {
			const items = await hrController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<EmployeesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_NAME](state, value) {
		state.filter.name = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_IDS](state, value) {
		state.filter.departmentIds = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new EmployeesFilter();
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const employeesModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default employeesModule;
