import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/bar/modules/bankAccountsList/types";
import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import BankAccountsListState from "@/store/bar/modules/bankAccountsList/types/bankAccountsListState";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/bar/dictionaries";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import ListingMixinBuilder from "@/store/shared/listing";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import BankAccountsListRouteQueryService from "@/store/bar/modules/bankAccountsList/services/bankAccountsListRouteQueryService";
import BankAccountsListRouteQuery from "@/store/bar/modules/bankAccountsList/types/bankAccountsListRouteQuery";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import router from "@/router/bar";
import { RouteNames } from "@/router/bar/routes";
import PagingMixinBuilder from "@/store/shared/paging";
import { ApiGetBankAccountsParametersMapper } from "@/api/bar/types/apiGetBankAccountsParameters";
import { ApiBankAccountInfoPersisted } from "@/api/bar/types/bankAccountInfo/apiBankAccountInfoPersisted";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { BankInfoMapper } from "@/store/bar/types/bankInfo";
import { SuggestedCounterpartiesParameterTypeEnum } from "@/store/bar/types/SuggestedCounterpartiesParameterTypeEnum";
import { BankAccountsListItem, BankAccountsListItemMapper } from "@/store/bar/modules/bankAccountsList/types/bankAccountsListItem";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import storeManager from "@/store/manager";
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import userTypes from "@/store/bar/modules/user/types";
import { first, isNil } from "lodash";

const abortService = new AbortService();
const barController = new BarController(abortService);
const dictionariesController = new DictionariesController(abortService);

const defaultRouteQuery = new BankAccountsListRouteQuery(1, "", "", "", [], "");
const routeQueryService = new BankAccountsListRouteQueryService(defaultRouteQuery);
const updateListingBatchService = new BatchService(({ interval: 100 }));
const routeMixin = (new RouteMixinBuilder<BankAccountsListState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountsListState(
			new ListingModel<BankAccountsListItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<BankAccountsListState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<BankAccountsListState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<BankAccountsListState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_COUNTERPARTY_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_BANK_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_SIGN_STATUSES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID):
		{
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
			}
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountsListState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			// если РС не связан с заявкой (создан вручную), то отображаем x.createdAt
			// если РС связан с заявкой (создан от заявки), то отображаем x.completedAt (если даты ещё нет, то даты нет)
			const isApplicationItemsExisted = x.applications.length;
			
			let createdAtValue = isApplicationItemsExisted ? x.completedAt : x.createdAt;
			
			return {
				...x,
				createdAt: formatDate(createdAtValue, dateFormat),
				controlDate: formatDate(x.controlDate, dateFormat),
				controlDateHasPassed: !isNil(x.controlDate) && x.controlDate < Date.now(),
				application: isApplicationItemsExisted ? first(x.applications) : null
			};
		});
	}
};

const actions = <ActionTree<BankAccountsListState, any>>{
	...listingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...pagingMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.setDefaultFilterValues);
		await dispatch(actionTypes.reconstituteRoute);
		
		await Promise.all([
			dispatch(actionTypes.fetchDictionaries),
			dispatch(actionTypes.updateListingItems)
		]);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.setDefaultFilterValues]({ commit, dispatch, rootState, rootGetters, state }) {
		// задаем определенные значения для фильтра signStatuses, если роль юзера - Сотрудник УКФП
		const filterSignStatusesLength = state.filter.signStatuses.length;
		
		const isBarUserProjectFinancialControlDepartmentManager = rootGetters[resolveGetter(storeManager.bar.barUser.namespace,
			userTypes.getterTypes.isBarUserProjectFinancialControlDepartmentManager)];
		
		if(!filterSignStatusesLength && isBarUserProjectFinancialControlDepartmentManager) {
			const defaultSignStatusesValueForProjectFinancialControlDepartmentManagerRole = [
				BankAccountStatusTypeEnum.TO_CONNECT,
				BankAccountStatusTypeEnum.RESPONSIBLE_ASSIGNED,
				BankAccountStatusTypeEnum.CONNECTED,
				BankAccountStatusTypeEnum.DISCONNECTED
			];
			
			commit(mutationTypes.SET_FILTER_SIGN_STATUSES, defaultSignStatusesValueForProjectFinancialControlDepartmentManagerRole);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit, state }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const [
				banks,
				signStatuses,
				bankAccountTypes,
				currencies,
				bankResponsibleUsers
			] = await Promise.all([
				dictionariesController.getBanks(),
				dictionariesController.getSignStatuses(),
				dictionariesController.getBankAccountTypes(),
				dictionariesController.getCurrencies(),
				dictionariesController.getBankResponsibleUsers()
			]);
			
			commit(mutationTypes.SET_BANKS, banks.map((x: ApiBankInfoPersisted) => BankInfoMapper.map(x)));
			commit(mutationTypes.SET_SIGN_STATUSES, signStatuses);
			commit(mutationTypes.SET_BANK_ACCOUNT_TYPES, bankAccountTypes);
			commit(mutationTypes.SET_CURRENCIES, currencies);
			commit(mutationTypes.SET_BANK_RESPONSIBLE_USERS, bankResponsibleUsers);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const payload = ApiGetBankAccountsParametersMapper.mapToApiGetBankAccountsParameters(state);
			
			const { items, totalCount } = await barController.getBankAccountItems(payload);
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map((x: ApiBankAccountInfoPersisted) => BankAccountsListItemMapper.map(x)));
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchSuggestedCounterparties]({ commit },
		{ parameter, type }: { parameter: string, type: SuggestedCounterpartiesParameterTypeEnum })
	{
		commit(mutationTypes.SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING, true);
		
		try {
			const suggestedCounterparties = await dictionariesController.getSuggestedCounterparties(parameter, type);
			
			if(type === SuggestedCounterpartiesParameterTypeEnum.QUERY)
				commit(mutationTypes.SET_SUGGESTED_COUNTERPARTIES, suggestedCounterparties);
			
			if(type === SuggestedCounterpartiesParameterTypeEnum.ID)
				commit(mutationTypes.SET_SUGGESTED_COUNTERPARTIES, [suggestedCounterparties]);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, dispatch, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);
		
		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER, routeQuery.loanApplicationNumber);
		commit(mutationTypes.SET_FILTER_COUNTERPARTY_ID, routeQuery.counterpartyId);
		commit(mutationTypes.SET_FILTER_BANK_ID, routeQuery.bankId);
		commit(mutationTypes.SET_FILTER_SIGN_STATUSES, routeQuery.signStatuses);
		commit(mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID, routeQuery.responsibleUserId);
		
		if(routeQuery.counterpartyId)
			await dispatch(actionTypes.fetchSuggestedCounterparties,
				{ parameter: routeQuery.counterpartyId, type: SuggestedCounterpartiesParameterTypeEnum.ID });
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ rootState, state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.BANK_ACCOUNTS_LIST,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ rootState, state, commit, dispatch }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.BANK_ACCOUNTS_LIST,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<BankAccountsListState>>{
	...listingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...pagingMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_BANKS](state, value) {
		state.banks = value;
	},
	[mutationTypes.SET_SIGN_STATUSES](state, value) {
		state.signStatuses = value;
	},
	[mutationTypes.SET_BANK_ACCOUNT_TYPES](state, value) {
		state.bankAccountTypes = value;
	},
	[mutationTypes.SET_CURRENCIES](state, value) {
		state.currencies = value;
	},
	[mutationTypes.SET_BANK_RESPONSIBLE_USERS](state, value) {
		state.bankResponsibleUsers = value;
	},
	[mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER](state, value) {
		state.filter.loanApplicationNumber = value;
	},
	[mutationTypes.SET_FILTER_COUNTERPARTY_ID](state, value) {
		state.filter.counterpartyId = value;
	},
	[mutationTypes.SET_FILTER_BANK_ID](state, value) {
		state.filter.bankId = value;
	},
	[mutationTypes.SET_FILTER_SIGN_STATUSES](state, value) {
		state.filter.signStatuses = value;
	},
	[mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID](state, value) {
		state.filter.responsibleUserId = value;
	},
	[mutationTypes.SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING](state, value) {
		state.isFetchSuggestedCounterpartyLoading = value;
	},
	[mutationTypes.RESET_SUGGESTED_COUNTERPARTIES](state, value) {
		state.suggestedCounterparties = [];
	},
	[mutationTypes.SET_SUGGESTED_COUNTERPARTIES](state, value) {
		state.suggestedCounterparties = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const bankAccountsListModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default bankAccountsListModule;
