import { ApiBankBranchInfoPersisted } from "@/api/bar/types/dictionaries/apiBankBranchInfoPersisted";

export interface BankBranchInfo {
	id: string;
	name: string;
	bik: string;
	correspondentAccount: string;
}

export class BankBranchInfoMapper {
	static map(source: ApiBankBranchInfoPersisted): BankBranchInfo {
		return {
			...source.bankBranchInfo,
			id: source.id
		};
	}
}