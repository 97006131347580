import { BankAccountData, BankAccountDataHelper } from "@/store/bar/types/bankAccountData";
import { convertToZonedTimestamp } from "@/utils/dates";
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import { AccountAgreement, AccountAgreementHelper } from "@/store/bar/types/accountAgreement";
import { ApiAccountAgreement } from "@/api/bar/types/accountAgreement/apiAccountAgreement";
import { ApiBankAccountInfoPersisted } from "@/api/bar/types/bankAccountInfo/apiBankAccountInfoPersisted";
import { Application, ApplicationMapper } from "@/store/bar/modules/bankAccount/types/application";
import { ApiApplication } from "@/api/bar/types/apiApplication";

export interface BankAccountsListItem {
	id: string;
	createdAt: number;
	account: BankAccountData;
	status: BankAccountStatusTypeEnum;
	monitoringSystemConnectionStatus: BankAccountMonitoringSystemConnectionStatusTypeEnum;
	applications: Application[];
	accountAgreements: AccountAgreement[];
	bankId: string;
	bankBranchId: string;
	controlResponsibleEmployeeId: string;
	controlDate: number;
	completedAt: number;
	openedAt: number;
}

export class BankAccountsListItemMapper {
	static map(source: ApiBankAccountInfoPersisted): BankAccountsListItem {
		return {
			...source.bankAccountInfo,
			id: source.id,
			createdAt: convertToZonedTimestamp(source.createdAt) as number,
			account: BankAccountDataHelper.map(source.bankAccountInfo.account),
			accountAgreements: source.bankAccountInfo.accountAgreements.map((x: ApiAccountAgreement) => AccountAgreementHelper.map(x)),
			applications: source.bankAccountInfo.applications.map((x: ApiApplication) => ApplicationMapper.map(x)),
			controlDate: convertToZonedTimestamp(source.bankAccountInfo.controlDate) as number,
			completedAt: convertToZonedTimestamp(source.bankAccountInfo.completedAt) as number,
			openedAt: convertToZonedTimestamp(source.bankAccountInfo.controlDate) as number
		};
	}
}
