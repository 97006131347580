import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrStandard } from "@/api/hr/types/apiHrStandard";

export default class EmployeeStandardAwarenessState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public standardAwareness: ApiHrStandard = {} as ApiHrStandard
	)
	{
	}
}
