import { ApiApplication } from "@/api/bar/types/apiApplication";
import { convertToZonedTimestamp } from "@/utils/dates";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";

export interface Application {
	id: string;
	number: number;
	createdAt: number;
	sourceType: SourceTypeEnum;
}

export class ApplicationMapper {
	static map(source: ApiApplication): Application {
		return {
			...source,
			createdAt: convertToZonedTimestamp(source.createdAt) as number
		};
	}
}