import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";

export class HrDocumentController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	signDocument = async (documentId: string, signature: string) => {
		return await this.client.post<void>(urls.hr.document.sign.replace(urlTemplateParts.id, documentId), { signature });
	};
}
