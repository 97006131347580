<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.updateVacationDays.title')"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid" :readonly="isFormSaving">
				<frp-text-field class="kpi-field"
								v-model.number="vacationDays"
								type="number"
								:rules="validation.vacationDays"
								color="blue"
								required
								:label="$t('fields.hrVacationDaysCount.label')"
								:placeholder="$t('fields.hrVacationDaysCount.placeholder')">
				</frp-text-field>
				<frp-textarea class="kpi-field"
							  v-model="comment"
							  color="blue"
							  :label="$t('fields.hrVacationDaysComment.label')"
							  :placeholder="$t('fields.hrVacationDaysComment.placeholder')">
				</frp-textarea>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :loading="isFormSaving"
					 :disabled="!stateContainsUnsavedChanges || !formValid"
					 @click="handleUpdateEmployeeVacationDays">
				{{ $t("buttons.save") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import formMixin from "@/mixins/formMixin";
import { onlyPositiveNumbersRule } from "@/utils/validation";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlanEmployees";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlanEmployees/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		employee: Object
	},
	data() {
		return {
			RouteNames,
			namespace,
			validation: {
				vacationDays: [onlyPositiveNumbersRule()]
			}
		};
	},
	computed: {
		...mapState({
			updateVacationDaysRequest: state => state.updateVacationDaysRequest
		}),
		vacationDays: {
			get() {
				return this.updateVacationDaysRequest.vacationDays;
			},
			set(value) {
				this.setUpdateVacationDaysRequestVacationDays(value);
			}
		},
		comment: {
			get() {
				return this.updateVacationDaysRequest.comment;
			},
			set(value) {
				this.setUpdateVacationDaysRequestComment(value);
			}
		}
	},
	methods: {
		...mapMutations({
			resetUpdateVacationDaysRequest: mutationTypes.RESET_UPDATE_VACATION_DAYS_REQUEST,
			setUpdateVacationDaysRequestVacationDays: mutationTypes.SET_UPDATE_VACATION_DAYS_REQUEST_VACATION_DAYS,
			setUpdateVacationDaysRequestComment: mutationTypes.SET_UPDATE_VACATION_DAYS_REQUEST_COMMENT
		}),
		...mapActions({
			updateEmployeeVacationDays: actionTypes.updateEmployeeVacationDays
		}),
		async handleUpdateEmployeeVacationDays() {
			await this.updateEmployeeVacationDays(this.employee.id);
			this.resetUpdateVacationDaysRequest();
			this.$emit("update:value", false);
			this.$emit("update:employee", null);
		},
		handleClose() {
			this.$emit("update:employee", null);
			this.$emit("update:value", false);
			this.resetUpdateVacationDaysRequest();
		}
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs[this.refs.form].resetValidation();
			}
		}
	},
	components: {
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
