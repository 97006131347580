<template>
	<bar-content-layout v-if="isInitialized">
		<v-row>
			<v-col cols="6">
				<bar-bank-account-application-information-editable type="create"
																   :trigger-account-usage-processing.sync="triggerAccountUsageProcessing"/>
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<frp-btn v-if="can(Permissions.BAR_APPLICATION_CREATE)"
					 :disabled="!isBankAccountApplicationBankFormValid || !isBankAccountApplicationAccountsFormValid || isRecordUniqueCheckInProgress"
					 :loading="isBankAccountApplicationSaving"
					 class="px-12"
					 elevation="0"
					 dark
					 @click="triggerAccountUsageProcessing = true"
					 color="blue">
				{{ $t("buttons.create") }}
			</frp-btn>
		</template>
	</bar-content-layout>
	<bar-bank-account-application-create-or-update-loader v-else/>
</template>

<script>
import { AccountUsageTypeEnum } from "@/api/bar/types/AccountUsageTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes, getterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import BarBankAccountApplicationCreateOrUpdateLoader
	from "@/views/bar/bankAccountApplication/BarBankAccountApplicationCreateOrUpdateLoader.vue";
import BarBankAccountApplicationAccountTypeChangeNoticeDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationAccountTypeChangeNoticeDialog.vue";
import BarBankAccountApplicationFutureAccountUsageDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationFutureAccountUsageDialog.vue";
import BarBankAccountApplicationPrimaryAccountConflictWarningDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationPrimaryAccountConflictWarningDialog.vue";
import BarBankAccountApplicationSelectNextActionDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationSelectNextActionDialog.vue";
import BarBankAccountApplicationInformationEditable
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformationEditable.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.newBankAccountApplication")
	},
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			triggerAccountUsageProcessing: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isRecordUniqueCheckInProgress: state => state.form.isRecordUniqueCheckInProgress,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isBankAccountApplicationBankFormValid: state => state.isBankAccountApplicationBankFormValid,
			isBankAccountApplicationAccountsFormValid: state => state.isBankAccountApplicationAccountsFormValid,
		}),
		...mapGetters({
		}),
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			initializeStore: actionTypes.initialize,
		})
	},
	created() {
		this.initializeStore();
	},
	components: { BarBankAccountApplicationPrimaryAccountConflictWarningDialog, BarBankAccountApplicationFutureAccountUsageDialog, BarBankAccountApplicationAccountTypeChangeNoticeDialog, BarBankAccountApplicationSelectNextActionDialog, FrpAutocomplete, FrpDialog, FrpTextBodyTwo, FrpConfirmDialog, BarBankAccountApplicationCreateOrUpdateLoader, FrpBtn, BarBankAccountApplicationInformationEditable, BarContentLayout }
};
</script>

<style scoped>

</style>
