import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { ApiHrVacationPlansItem } from "@/api/hr/types/vacation/apiHrVacationPlansItem";
import { HrVacationPlan, HrVacationPlanService } from "@/types/hr/vacation/hrVacationPlan";

export interface HrVacationPlansItem {
	employee: ApiHrEmployeesItem;
	vacationsPlans: HrVacationPlan[];
	totalVacationDays: number;
	plannedVacationDays: number;
}

export class HrVacationPlansItemMapper {
	static map(source: ApiHrVacationPlansItem): HrVacationPlansItem {
		return {
			...source,
			vacationsPlans: source.vacationsPlans.map(x => HrVacationPlanService.map(x))
		};
	}
}
