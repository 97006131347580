import { ApiAgreement } from "@/api/bar/types/agreement/apiAgreement";

export interface ApiChangeAgreementRequest {
	number: number;
}

export class ApiChangeAgreementRequestMapper {
	static map(source: ApiAgreement): ApiChangeAgreementRequest {
		return {
			number: source.number
		};
	}
}