import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import BankAccountApplicationLegalDepartmentState
	from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types/bankAccountApplicationLegalDepartmentState";
import {
	actionTypes,
	getterTypes,
	mutationTypes,
	namespace
} from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import { ElectronicDocumentStatusTypeEnum } from "@/store/bar/types/ElectronicDocumentStatusTypeEnum";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ApiElectronicDocumentStatus from "@/api/bar/types/dictionaries/apiElectronicDocumentStatus";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import BankAccountApplicationState from "@/store/bar/modules/bankAccountApplication/types/bankAccountApplicationState";
import storeManager from "@/store/manager";
import bankAccountApplicationTypes from "@/store/bar/modules/bankAccountApplication/types";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { orderBy } from "lodash";
import { Permissions } from "@/constants/permissions";
import PermissionsService from "@/services/permissionsService";
import bankAccountApplicationMessengerModule from "@/store/bar/modules/bankAccountApplication/modules/messenger";
import bankAccountApplicationMessengerTypes from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { DocumentResponseMapper } from "@/store/bar/types/documentResponse";

const abortService = new AbortService();

const barController = new BarController(abortService);

const permissionsService = new PermissionsService();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableResponsibleStaffEmployeeId"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountApplicationLegalDepartmentState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountApplicationLegalDepartmentState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.selectableDocumentStatuses]: (state, getters, rootState) => {
		const { electronicDocumentStatuses } = resolveNestedState<BankAccountApplicationState>(rootState,
			storeManager.bar.bankAccountApplication.namespace);
		
		return electronicDocumentStatuses.filter((x: ApiElectronicDocumentStatus) => {
			return x.code !== ElectronicDocumentStatusTypeEnum.UNKNOWN
				&& x.code !== ElectronicDocumentStatusTypeEnum.IN_PROGRESS
				&& x.code !== ElectronicDocumentStatusTypeEnum.NOT_REQUIRED;
		});
	},
	[getterTypes.sortedLegalDepartmentDocumentFileInfoItems]: state => {
		const sortedItems = orderBy(state.legalDepartmentDocumentFileInfoItems, [(x) => x.createdAt], ["asc"]);
		return sortedItems.map((x, index) => ({
			...x,
			orderNumber: index + 1
		})).reverse();
	}
};

const actions = <ActionTree<BankAccountApplicationLegalDepartmentState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, rootState, state, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		const { editableItem } = resolveNestedState<BankAccountApplicationState>(rootState,
			storeManager.bar.bankAccountApplication.namespace);
		
		const isLegalDepartmentRequestEditReadPermissionExist = await permissionsService.check([Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_READ]);
		
		if(isLegalDepartmentRequestEditReadPermissionExist && editableItem.isLegalDepartmentRequestSent) {
			await dispatch(actionTypes.getLegalDepartmentInfo);
			
			if(state.legalDepartmentInfo.legalDepartmentStaffEmployeeId) {
				commit(mutationTypes.SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTED, true);
				await dispatch(actionTypes.fetchLegalDepartmentDocuments);
			}
		}
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.sendLegalDepartmentRequest]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING, true);
		
		try {
			await barController.sendBankAccountApplicationLegalDepartmentRequest(rootState.route.params.id);
			
			commit(resolveMutation(storeManager.bar.bankAccountApplication.namespace,
				bankAccountApplicationTypes.mutationTypes.SET_EDITABLE_ITEM_IS_LEGAL_DEPARTMENT_REQUEST_SENT), true, { root: true });
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SUCCESS_SENT);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING, false);
		}
	},
	async [actionTypes.getLegalDepartmentInfo]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING, true);
		
		try {
			const legalDepartmentInfo = await barController.getBankAccountApplicationLegalDepartmentInfo(rootState.route.params.id);
			
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_INFO, legalDepartmentInfo);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING, false);
		}
	},
	async [actionTypes.setResponsibleLegalDepartmentStaffEmployee]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTING, true);
		
		try {
			await barController.setBankAccountApplicationLegalDepartmentResponsiblePerson(rootState.route.params.id,
				{ ...state.legalDepartmentInfo, legalDepartmentStaffEmployeeId: state.editableResponsibleStaffEmployeeId });
			
			commit(mutationTypes.SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTED, true);
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID, state.editableResponsibleStaffEmployeeId);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTING, false);
		}
	},
	async [actionTypes.acceptLegalDepartment]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_ACCEPTING, true);
		
		try {
			const legalDepartmentInfo = await barController.acceptBankAccountApplicationLegalDepartment(rootState.route.params.id,
				state.legalDepartmentInfo);
			
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_INFO, legalDepartmentInfo);
			
			await dispatch(resolveAction(bankAccountApplicationMessengerModule.namespace, bankAccountApplicationMessengerTypes.actionTypes.fetchMessages), {}, { root: true });
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_ACCEPTED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_ACCEPTING, false);
		}
	},
	async [actionTypes.declineLegalDepartment]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DECLINING, true);
		
		try {
			const legalDepartmentInfo = await barController.declineBankAccountApplicationLegalDepartment(rootState.route.params.id,
				state.legalDepartmentInfo);
			
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_INFO, legalDepartmentInfo);
			
			await dispatch(resolveAction(bankAccountApplicationMessengerModule.namespace, bankAccountApplicationMessengerTypes.actionTypes.fetchMessages), {}, { root: true });
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DECLINED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DECLINING, false);
		}
	},
	async [actionTypes.resetStatusesForLegalDepartment]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_STATUSES_RESETTING, true);
		
		try {
			const legalDepartmentInfo = await barController.resetStatusesForBankAccountApplicationLegalDepartment(rootState.route.params.id,
				state.legalDepartmentInfo);
			
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_INFO, legalDepartmentInfo);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_STATUSES_RESETTING, false);
		}
	},
	async [actionTypes.fetchLegalDepartmentDocuments]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_FETCHING, true);
		
		try {
			const legalDepartmentDocumentFileInfoItems = await barController.getBankAccountApplicationLegalDepartmentRequestDocuments(
				rootState.route.params.id);
			
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS, legalDepartmentDocumentFileInfoItems.map(x => DocumentResponseMapper.map(x)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_FETCHING, true);
		}
	},
	async [actionTypes.uploadLegalDepartmentDocumentFileInfo]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_UPLOADING, true);
		
		try {
			const legalDepartmentDocumentFileInfo = await barController.uploadLegalDepartmentDocumentFileInfo(rootState.route.params.id,
				state.legalDepartmentDocumentFileMeta.id);
			
			commit(mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS,
				[...state.legalDepartmentDocumentFileInfoItems, DocumentResponseMapper.map(legalDepartmentDocumentFileInfo)]);
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_UPLOADED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_UPLOADING, false);
		}
	},
	async [actionTypes.deleteLegalDepartmentDocument]({ dispatch, commit, rootState, state, getters }, documentId) {
		commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM_DELETING, { documentId, value: true });
		
		try {
			await barController.deleteBankAccountApplicationDocument(rootState.route.params.id, documentId);
			
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM_DELETING, { documentId, value: false });
			commit(mutationTypes.REMOVE_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM, documentId);
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM_DELETING, { documentId, value: false });
		}
	}
};

const mutations = <MutationTree<BankAccountApplicationLegalDepartmentState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTED](state, value) {
		state.isResponsibleLegalDepartmentStaffEmployeeAppointed = value;
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING](state, value) {
		state.isLegalDepartmentRequestLoading = value;
	},
	[mutationTypes.SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTING](state, value) {
		state.isResponsibleLegalDepartmentStaffEmployeeAppointing = value;
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_ACCEPTING](state, value) {
		state.isLegalDepartmentAccepting = value;
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_DECLINING](state, value) {
		state.isLegalDepartmentDeclining = value;
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_STATUSES_RESETTING](state, value) {
		state.isLegalDepartmentStatusesResetting = value;
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_INFO](state, value) {
		state.legalDepartmentInfo = value;
	},
	[mutationTypes.RESET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID](state) {
		state.legalDepartmentInfo.legalDepartmentStaffEmployeeId = "";
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID](state, value) {
		state.legalDepartmentInfo.legalDepartmentStaffEmployeeId = value;
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_INFO_IS_ACCEPTED_VALUE](state, value) {
		state.legalDepartmentInfo.isAccepted = value;
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_INFO_IS_DECLINED_VALUE](state, value) {
		state.legalDepartmentInfo.isDeclined = value;
	},
	[mutationTypes.RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META](state) {
		state.legalDepartmentDocumentFileMeta = new FileMeta();
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META](state, value) {
		state.legalDepartmentDocumentFileMeta = value;
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META_IS_LOADING](state, value) {
		state.legalDepartmentDocumentFileMeta.isLoading = value;
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_UPLOADING](state, value) {
		state.isLegalDepartmentDocumentFileInfoUploading = value;
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_FETCHING](state, value) {
		state.isLegalDepartmentDocumentFileInfoItemsFetching = value;
	},
	[mutationTypes.RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS](state) {
		state.legalDepartmentDocumentFileInfoItems = [];
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS](state, value) {
		state.legalDepartmentDocumentFileInfoItems = value;
	},
	[mutationTypes.REMOVE_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM](state, documentId) {
		state.legalDepartmentDocumentFileInfoItems.splice(state.legalDepartmentDocumentFileInfoItems.findIndex(x => x.storedFileId ===
			documentId), 1);
	},
	[mutationTypes.SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM_DELETING](state, { documentId, value }) {
		state.legalDepartmentDocumentFileInfoItems[state.legalDepartmentDocumentFileInfoItems.findIndex(x => x.storedFileId ===
			documentId)].isDocumentDeleting = value;
	},
	[mutationTypes.RESET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID](state) {
		state.editableResponsibleStaffEmployeeId = "";
	},
	[mutationTypes.SET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID](state, value) {
		state.editableResponsibleStaffEmployeeId = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const bankAccountApplicationLegalDepartmentModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default bankAccountApplicationLegalDepartmentModule;
