<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.addVacationApplicationApprover.title')"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid" :readonly="isApproverAdding">
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  :loading="isEmployeesLoading"
								  :items="employees"
								  @firstclick="fetchEmployees"
								  v-model="employeeId"
								  color="blue"
								  required
								  :label="$t('fields.hrApprover.label')"
								  :placeholder="$t('fields.hrApprover.placeholder')">
				</bod-autocomplete>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :loading="isApproverAdding"
					 :disabled="!formValid"
					 @click="handleImportEmployee">
				{{ $t("buttons.add") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTreeAutocomplete from "@/components/fields/FrpTreeAutocomplete.vue";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/hr/modules/vacationApplication/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			employeeId: "",
			formValid: false
		};
	},
	computed: {
		...mapState({
			isEmployeesLoading: state => state.isEmployeesLoading,
			employees: state => state.employees,
			isApproverAdding: state => state.isApproverAdding
		})
	},
	methods: {
		...mapActions({
			fetchEmployees: actionTypes.fetchEmployees,
			addApprover: actionTypes.addApprover
		}),
		async handleImportEmployee() {
			await this.addApprover(this.employeeId);
			this.employeeId = "";
			this.$emit("update:value", false);
		},
		handleClose() {
			this.employeeId = "";
			this.$emit("update:value", false);
		}
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		FrpTreeAutocomplete,
		FrpDialog,
		BodAutocomplete,
		FrpBtn
	}
};
</script>
