<template>
	<v-row>
		<v-col class="px-2" style="flex-grow: 36">
			<hr-card class="kpi-card primary lighten-1 white--text" elevation="0" :centered-content="!isInitialized">
				<v-progress-circular style="min-height: 298px;" v-if="!isInitialized" indeterminate color="white"></v-progress-circular>
				<div v-else class="d-flex flex-column align-center justify-center mt-2" style="width: 100%">
					<div class="hr-photo">
						<v-img width="125" height="125" class="rounded-circle" :src="employee.photoUrl"/>
					</div>
					<div class="d-flex flex-column align-center font-weight-bold mt-4 mb-3">
						<span>{{ employee.lastName }}</span>
						<span>{{ `${employee.firstName} ${employee.middleName ? employee.middleName : ""}` }}</span>
					</div>
					<span class="mb-7 text-center">{{ employee.position }}</span>
					<div class="d-flex align-center mb-4" style="font-size: 14px">
						<frp-icon src="ico_gift-outline" :color="colors.blue.base"></frp-icon>
						<span class="mr-4 ml-7" style="font-weight: 600">{{ $t("content.birthday") }}:</span>
						<span>{{ formatDate(employee.birthday, friendlyDayMonth) }}</span>
					</div>
				</div>
			</hr-card>
		</v-col>
		<v-col class="px-2" style="flex-grow: 106">
			<hr-card :title="$t('titles.profile')" class="kpi-card" elevation="0" :centered-content="!isInitialized">
				<v-progress-circular v-if="!isInitialized" indeterminate color="primary"></v-progress-circular>
				
				<template v-else>
					<v-row no-gutters style="font-size: 14px" class="pt-3 pb-2">
						<v-col cols="5" class="d-flex flex-column py-2" style="row-gap: 16px">
							<v-row v-for="item in profileItems.slice(0, 4)" :key="item.text" no-gutters>
								<v-col cols="7">
									<div  class="d-flex align-center">
										<frp-icon :color="colors.blue.base" width="20" height="20" class="mr-3" :src="item.icon"/>
										<span class="primary--text text--darken-1 text-no-wrap" style="font-weight: 600">
											{{ item.text }}
										</span>
									</div>
								</v-col>
								<v-col>
									{{ item.value }}
								</v-col>
							</v-row>
						</v-col>
						
						<v-col class="flex-grow-0">
							<v-divider class="mx-4 mr-lg-6" vertical></v-divider>
						</v-col>
						
						<v-col class="d-flex flex-column py-2" style="row-gap: 16px">
							<v-row v-for="item in profileItems.slice(4, profileItems.length)" :key="item.text" no-gutters>
								<v-col>
									<div class="d-flex align-center">
										<frp-icon :color="colors.blue.base" width="20" height="20" class="mr-3" :src="item.icon"/>
										<span class="primary--text text--darken-1 text-no-wrap" style="font-weight: 600">
											{{ item.text }}
										</span>
									</div>
								</v-col>
								
								<v-col>
									{{ item.value }}
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</template>
				
				<template v-if="isInitialized" #append>
					<hr-expectations></hr-expectations>
				</template>
			</hr-card>
		</v-col>
		<v-col class="px-2" style="flex-grow: 34">
			<hr-card :title="$t('titles.usefulInfo')" class="kpi-card" elevation="0" content-class="flex-grow-1" no-spacer="true">
				<div class="d-flex flex-column justify-space-between fill-height">
					<frp-link-btn v-for="link in links" :key="link.text" :href="link.url"
								  class="d-flex align-center" target="_blank" :underline="false">
						<v-img width="20" height="20" class="mr-3 flex-grow-0" :src="`${baseUrl}/img/icons/${link.icon}`"></v-img>
						<span class="primary--text text--darken-1" style="font-weight: 600; font-size: 14px;">{{ link.text }}</span>
					</frp-link-btn>
				</div>
			</hr-card>
		</v-col>
	</v-row>
</template>

<script>
import router from "@/router/hr";
import { RouteNames } from "@/router/hr/routes";
import HrExpectations from "@/views/hr/employee/sections/HrExpectations.vue";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import HrCard from "Components/cards/HrCard";
import FrpIcon from "Components/icon/FrpIcon";
import { externalUrls } from "Constants/hr/externalUrls";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes } from "Store/hr/modules/employee/modules/kpi/types";
import storeManager from "Store/manager";
import { formatDate } from "Utils/dates";
import { dateFormat, friendlyDayMonth } from "Utils/formats";
import { formatFullNameWithInitials } from "Utils/formatting";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.hr.employee.profile.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			formatDate,
			friendlyDayMonth,
			baseUrl: process.env.VUE_APP_BASE_URL,
			links: [
				{
					text: this.$t("content.hrUsefulLinks.welcomebook"),
					url: externalUrls.usefulLinks.welcomebook,
					icon: "ico_bookmark-outline.png"
				},
				{
					text: this.$t("content.hrUsefulLinks.teamly"),
					url: externalUrls.usefulLinks.teamly,
					icon: "ico_link.png"
				},
				{
					text: this.$t("content.hrUsefulLinks.personnelDocumentTemplates"),
					url: router.resolve({ name: RouteNames.DOC_ROOT }).href,
					icon: "ico_stacks-outline.png"
				},
				{
					text: this.$t("content.hrUsefulLinks.informationSystems"),
					url: externalUrls.usefulLinks.informationSystems,
					icon: "ico_view-grid-outline.png"
				},
				{
					text: this.$t("content.hrUsefulLinks.organizationalStructure"),
					url: externalUrls.usefulLinks.organizationalStructure,
					icon: "ico_groups-outline.png"
				},
				{
					text: this.$t("content.hrUsefulLinks.faq"),
					url: externalUrls.usefulLinks.faq,
					icon: "ico_search.png"
				},
				{
					text: this.$t("content.hrUsefulLinks.codex"),
					url: externalUrls.usefulLinks.codex,
					icon: "ico_bookmark-outline.png"
				}
			]
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			employee: state => state.employee
		}),
		profileItems() {
			return [
				{
					text: this.$t("content.profile.email"),
					icon: "ico_email-outline",
					value: this.employee?.email
				},
				{
					text: this.$t("content.profile.innerPhone"),
					icon: "ico_phone-in-talk-outline",
					value: this.employee?.extensionPhone
				},
				{
					text: this.$t("content.profile.mobilePhone"),
					icon: "ico_cellphone-outline",
					value: this.employee?.mobilePhone
				},
				// {
				// 	text: this.$t("content.profile.status"),
				// 	icon: "ico_person-outline",
				// 	value: this.employee?.status
				// },
				{
					text: this.$t("content.profile.employmentDate"),
					icon: "ico_city-outline",
					value: this.employee?.employmentDate && formatDate(this.employee.employmentDate, dateFormat)
				},
				{
					text: this.$t("content.profile.department"),
					icon: "ico_badge-account-horizontal-outline",
					value: this.employee?.department?.name
				},
				{
					text: this.$t("content.profile.head"),
					icon: "ico_subordinate",
					value: formatFullNameWithInitials(this.employee?.head || {})
				},
				{
					text: this.$t("content.profile.vacationRemainingDays"),
					icon: "ico_weather-sunset-outline",
					value: this.employee?.vacation?.remainingDays
				}
			];
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: { HrExpectations, FrpLinkBtn, FrpIcon, HrCard }
};
</script>

<style scoped lang="scss">
</style>
