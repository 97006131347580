import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter } from "@/utils/query";
import { isEqual } from "lodash";
import EmployeesState from "@/store/hr/modules/employees/types/employeesState";
import EmployeesRouteQuery from "@/store/hr/modules/employees/types/employeesRouteQuery";

export default class EmployeesRouteService {
	defaultRouteQuery: EmployeesRouteQuery;

	constructor(defaultRouteQuery: EmployeesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: EmployeesState) {
		let query = new EmployeesRouteQuery(
			state.paging.page,
			state.filter.name,
			state.filter.departmentIds
		);

		const res = {};
		Object.keys(this.defaultRouteQuery).forEach(key => {
			//@ts-ignore
			if(!isEqual(this.defaultRouteQuery[key], query[key]))
				//@ts-ignore
				res[key] = query[key];
		});

		return res;
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(EmployeesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				departmentIds: parseArrayParameter(query.departmentIds)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
