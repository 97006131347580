import { BankAccountInfo } from "@/store/bar/types/bankAccountInfo";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import { ApiChangeAccountAgreementRequest, ApiChangeAccountAgreementRequestMapper } from "@/api/bar/types/accountAgreement/apiChangeAccountAgreementRequest";
import { ApiChangeBankAccountDataRequest, ApiChangeBankAccountDataRequestMapper } from "@/api/bar/types/bankAccountData/apiChangeBankAccountDataRequest";
import { convertToZonedIso } from "@/utils/dates";

export interface ApiChangeBankAccountInfoRequest {
	account: ApiChangeBankAccountDataRequest;
	monitoringSystemConnectionStatus: BankAccountMonitoringSystemConnectionStatusTypeEnum;
	accountAgreements: ApiChangeAccountAgreementRequest[];
	bankBranchId: string;
	controlResponsibleEmployeeId: string;
	controlDate: string;
	startAcceptDate: string;
	endAcceptDate: string;
}

export class ApiChangeBankAccountInfoRequestMapper {
	static map(source: BankAccountInfo): ApiChangeBankAccountInfoRequest {
		return {
			account: ApiChangeBankAccountDataRequestMapper.map(source.account),
			monitoringSystemConnectionStatus: source.monitoringSystemConnectionStatus,
			accountAgreements: source.accountAgreements.map(x => ApiChangeAccountAgreementRequestMapper.map(x)),
			bankBranchId: source.bankBranchId,
			controlResponsibleEmployeeId: source.controlResponsibleEmployeeId,
			controlDate: convertToZonedIso(source.controlDate),
			startAcceptDate: convertToZonedIso(source.startAcceptDate),
			endAcceptDate: convertToZonedIso(source.endAcceptDate)
		};
	}
}