import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { convertToZonedTimestamp } from "@/utils/dates";
import { ApiBankAccountData } from "@/api/bar/types/bankAccountData/apiBankAccountData";

export interface BankAccountData {
	id: string;
	accountId: string;
	accountType?: BankAccountTypeEnum;
	accountNumber: string;
	currencyId: string;
	openedAt: number;
	isAccountUniqueCheckInProgress: boolean;
	isAccountUnique: boolean;
}

export class BankAccountDataHelper {
	static map(source: ApiBankAccountData): BankAccountData {
		return {
			...source,
			accountId: "",
			openedAt: convertToZonedTimestamp(source.openedAt) as number,
			isAccountUniqueCheckInProgress: false,
			isAccountUnique: true
		};
	}
	
	static getEmpty(): BankAccountData {
		return {
			id: "",
			accountId: "",
			accountNumber: "",
			currencyId: "",
			openedAt: 0,
			isAccountUniqueCheckInProgress: false,
			isAccountUnique: true
		};
	}
}