<template>
	<hr-card v-if="isInitialized" :title="$t('titles.employeeFeedback')" centered-content style="min-width: min-content;">
<!--		<hr-chart-legend>-->
<!--			<hr-chart-legend-item v-for="(group, i) in feedback.groups" :key="i" :color="group.color">-->
<!--				{{ group.title }}-->
<!--			</hr-chart-legend-item>-->
<!--		</hr-chart-legend>-->
<!--		<hr-chart-radar :groups="feedback.groups"></hr-chart-radar>-->
		<div style="width: 100%" class="fill-height d-flex align-center justify-center">
			{{ $t("alerts.info.pendingData") }}
		</div>
	</hr-card>
</template>

<script>
import HrCard from "Components/cards/HrCard";
import HrChartLegendItem from "Components/charts/HrChartLegendItem";
import HrChartLegend from "Components/charts/HrChartLegend";
import HrChartRadar from "Components/charts/HrChartRadar";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes } from "Store/hr/modules/employee/modules/feedback/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.hr.employee.feedback.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			feedback: state => state.feedback
		})
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		HrChartRadar,
		HrCard,
		HrChartLegend,
		HrChartLegendItem,
		FrpIcon
	}
};
</script>
