<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.importVacationPlanEmployees.title')"
				max-width="470"
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid" :readonly="isFormSaving">
				<span>
					{{
						$t("dialogs.importVacationPlanEmployees.result",
							{ total: importEmployeesResponse.totalCount, imported: importEmployeesResponse.importedCount })
					}}
				</span>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import formMixin from "@/mixins/formMixin";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlanEmployees";
import { mutationTypes } from "Store/hr/modules/vacationPlanEmployees/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			importEmployeesResponse: state => state.importEmployeesResponse
		})
	},
	methods: {
		...mapMutations({
			resetImportEmployeesResponse: mutationTypes.RESET_IMPORT_EMPLOYEES_RESPONSE
		}),
		handleClose() {
			this.resetImportEmployeesResponse();
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDateField,
		FrpDialog,
		FrpBtn
	}
};
</script>
