import { ApiHrTasksTypesEnum } from "@/api/hr/types/tasks/apiHrTasksTypesEnum";
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";
import { ApiHrDocument } from "@/api/hr/types/apiHrDocument";
import { ApiHrAssignee } from "@/api/hr/types/tasks/apiHrAssignee";
import { ApiHrInitiator } from "@/api/hr/types/tasks/apiHrInitiator";
import { ApiHrTasksItem } from "@/api/hr/types/tasks/apiHrTasks";
import {
	HrVacationApplicationApproval,
	HrVacationApplicationApprovalService
} from "@/types/hr/vacationApplication/hrVacationApplicationApproval";

export interface HrTasksItem {
	id: string,
	title: string | null,
	type: ApiHrTasksTypesEnum,
	dueDate: string | null,
	state: ApiHrTasksStateEnum,
	assignee: ApiHrAssignee,
	initiator: ApiHrInitiator,
	document: ApiHrDocument
	approval?: HrVacationApplicationApproval;
}

export class HrTasksItemService {
	static map(source: ApiHrTasksItem): HrTasksItem {
		return {
			...source,
			approval: source.approval && HrVacationApplicationApprovalService.map(source.approval)
		};
	}
}
