import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import { BankAccountData, BankAccountDataHelper } from "@/store/bar/types/bankAccountData";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import { ApiBankAccountInfoPersisted } from "@/api/bar/types/bankAccountInfo/apiBankAccountInfoPersisted";
import { convertToZonedTimestamp } from "@/utils/dates";
import { AccountAgreement, AccountAgreementHelper } from "@/store/bar/types/accountAgreement";
import { ApiAccountAgreement } from "@/api/bar/types/accountAgreement/apiAccountAgreement";
import { Application, ApplicationMapper } from "@/store/bar/modules/bankAccount/types/application";
import { ApiApplication } from "@/api/bar/types/apiApplication";

export interface BankAccountInfo {
	id?: string;
	account: BankAccountData;
	status: BankAccountStatusTypeEnum;
	monitoringSystemConnectionStatus: BankAccountMonitoringSystemConnectionStatusTypeEnum;
	applications: Application[];
	accountAgreements: AccountAgreement[];
	bankId: string;
	bankBranchId: string;
	controlResponsibleEmployeeId: string;
	controlDate: number;
	completedAt: number;
	openedAt: number;
	startAcceptDate: number;
	endAcceptDate: number;
}

export class BankAccountInfoHelper {
	static map(source: ApiBankAccountInfoPersisted): BankAccountInfo {
		return {
			...source.bankAccountInfo,
			id: source.id,
			account: BankAccountDataHelper.map(source.bankAccountInfo.account),
			applications: source.bankAccountInfo.applications.map((x: ApiApplication) => ApplicationMapper.map(x)),
			accountAgreements: source.bankAccountInfo.accountAgreements.map((x: ApiAccountAgreement) => AccountAgreementHelper.map(x)),
			controlDate: convertToZonedTimestamp(source.bankAccountInfo.controlDate) as number,
			completedAt: convertToZonedTimestamp(source.bankAccountInfo.controlDate) as number,
			openedAt: convertToZonedTimestamp(source.bankAccountInfo.controlDate) as number,
			startAcceptDate: convertToZonedTimestamp(source.bankAccountInfo.startAcceptDate) as number,
			endAcceptDate: convertToZonedTimestamp(source.bankAccountInfo.endAcceptDate) as number
		};
	}
	
	static getEmpty(): BankAccountInfo {
		return {
			account: BankAccountDataHelper.getEmpty(),
			status: BankAccountStatusTypeEnum.NEW,
			monitoringSystemConnectionStatus: BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_CONNECTED,
			applications: [],
			accountAgreements: [],
			bankId: "",
			bankBranchId: "",
			controlResponsibleEmployeeId: "",
			controlDate: 0,
			completedAt: 0,
			openedAt: 0,
			startAcceptDate: 0,
			endAcceptDate: 0
		};
	}
}