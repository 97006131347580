<template>
	<div class="bar-details-item px-4">
			<v-row :style="rowStyleObject">
				<v-col>
					<frp-text-loader :width="width"></frp-text-loader>
					<slot name="additional-text"></slot>
				</v-col>
			</v-row>
			
			<v-spacer></v-spacer>
		
		<slot></slot>
	</div>
</template>

<script>

import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";

export default {
	props: {
		width: {
			type: [String, Number],
			default: '140'
		},
		rowStyleObject: Object
	},
	components: { FrpTextLoader }
};
</script>

<style scoped lang="scss">
</style>
