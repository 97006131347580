<template>
	<frp-card :title="$t('titles.organizationData')" v-if="isInitialized">
		<template #content>
			<bar-details-group>
				<bar-details-item :title="$t('details.titles.name')">
					<span class="text-subtitle-2 font-weight-medium primary--text">
						{{ editableItem.agreement.counterparty.shortName }}
					</span>
				</bar-details-item>
				<bar-details-item :title="$t('details.titles.inn')">
					<span class="text-subtitle-2 font-weight-medium primary--text">
						{{ editableItem.agreement.counterparty.inn }}
					</span>
				</bar-details-item>
				<bar-details-item :title="$t('details.titles.ogrn')">
					<span class="text-subtitle-2 font-weight-medium primary--text">
						{{ editableItem.agreement.counterparty.ogrn }}
					</span>
				</bar-details-item>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-application-information-organization-data-loader v-else/>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import BarBankAccountApplicationInformationOrganizationDataLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/organizationData/BarBankAccountApplicationInformationOrganizationDataLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountApplicationStatusTypeEnum
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({})
	},
	components: { BarBankAccountApplicationInformationOrganizationDataLoader, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
