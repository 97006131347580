<template>
	<frp-card is-loading class="pb-4">
		<template #content>
			<v-container fluid class="py-0 pb-2 px-0">
				<v-row class="px-4">
					<v-col class="mt-2">
						<frp-text-loader width="100%"/>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="auto" justify="end" class="py-0">
						<frp-btn-loader width="200" height="40"/>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: {
		FrpTextLoader,
		FrpBtnLoader,
		BarCardContentLayout,
		FrpCard
	}
};
</script>

<style scoped>
</style>
