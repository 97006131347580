import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import { DocumentResponse } from "@/store/bar/types/documentResponse";
import FileMeta from "@/store/shared/storage/types/fileMeta";

export default class BankAccountApplicationDocumentsState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDocumentsLoading: boolean = false,
		public documentFileInfoItems: DocumentResponse[] = [],
		public documentFileMeta: FileMeta = new FileMeta(),
		public isSignedDocumentFileInfoUploading: boolean = false,
		public isSigned: boolean = false
	)
	{
	}
}
