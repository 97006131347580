import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";

export default class BankAccountsListFilter {
	constructor(
		public loanApplicationNumber: string = "",
		public counterpartyId: string = "",
		public bankId: string = "",
		public signStatuses: BankAccountStatusTypeEnum[] = [],
		public responsibleUserId: string = ""
	)
	{
	}
}
