import { HrNewVacation } from "@/types/hr/vacation/hrNewVacation";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrNewVacation {
	previousVacationId?: string;
	startDate: string;
	endDate: string;
}

export class ApiHrNewVacationMapper {
	static map(source: HrNewVacation): ApiHrNewVacation {
		return {
			previousVacationId: source.previousVacationId,
			startDate: formatDate(source.startDate!, isoDateFormat) as string,
			endDate: formatDate(source.endDate!, isoDateFormat) as string
		};
	}
}
