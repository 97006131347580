import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/tasks/types";
import BaseMixinBuilder from "@/store/shared/base";
import FormMixinBuilder from "@/store/shared/form";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, createNamespacedHelpers, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import routeTypes from "@/store/shared/route/types";
import userTypes from "@/store/hr/modules/user/types";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import { formatFullName, formatFullNameWithInitials } from "@/utils/formatting";
import TasksState from "@/store/hr/modules/tasks/types/tasksState";
import TasksFilter from "@/store/hr/modules/tasks/types/tasksFilter";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import TasksRouteService from "@/store/hr/modules/tasks/services/tasksRouteService";
import TasksRouteQuery from "@/store/hr/modules/tasks/types/tasksRouteQuery";
import TasksMapper from "@/store/hr/modules/tasks/mapper";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { saveAs } from "file-saver";
import { HrStorageController } from "@/api/hr/storage";
import storeManager from "@/store/manager";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import UserState from "@/store/hr/modules/user/types/userState";
import { ApiHrRoleEnum } from "@/api/hr/types/ApiHrRoleEnum";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import contentDispositionParser from "content-disposition-parser";
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";
import { HrDocumentController } from "@/api/hr/document";
import { ApiHrDownloadArchiveRequestMapper } from "@/api/hr/types/apiHrDownloadArchiveParameters";
import { HrDownloadArchiveRequest } from "@/api/hr/types/hrDownloadArchiveRequest";
import { ApiHrDocumentTypesEnum } from "@/api/hr/types/ApiHrDocumentTypesEnum";
import ChangeRequest from "@/store/hr/modules/tasks/types/changeRequest";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { cloneDeep } from "lodash";
import { HrTasksItem, HrTasksItemService } from "@/types/hr/task/hrTasksItem";
import { HrVacationApplicationApprovalService } from "@/types/hr/vacationApplication/hrVacationApplicationApproval";

const hrUserModuleHelpers = createNamespacedHelpers(storeManager.hr.user.namespace);

const abortService = new AbortService();
const hrController = new HrController(abortService);
const hrDocumentController = new HrDocumentController(abortService);
const hrStorageController = new HrStorageController(abortService);

const defaultRouteQuery = new TasksRouteQuery();

const routeService = new TasksRouteService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<TasksState>()).build();

const formMixin = (new FormMixinBuilder()).build();

const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["request"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new TasksState(
			formMixin.state(),
			snapshotMixin.state(),
			new ListingModel<HrTasksItem>({
				items: [],
				isLoadingState: false
			}),
			new TasksFilter(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<TasksState>;

const getters = <GetterTree<TasksState, any>>{
	...formMixin.getters,
	...listingMixin.getters,
	...snapshotMixin.getters,
	...hrUserModuleHelpers.mapGetters({}),
	[getterTypes.currentUser](state, getters, rootState) {
		return resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).user;
	},
	[getterTypes.isAdmin]: (state, getters, rootState) => {
		const roles = resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).roles;
		
		return roles.includes(ApiHrRoleEnum.Administrator);
	},
	[getterTypes.formattedItems](state, getters) {
		return state.listing.items.map(x => {
			return {
				...x,
				dueDate: x.dueDate && formatDate(new Date(x.dueDate), dateFormat),
				assignee: formatFullNameWithInitials(x.assignee),
				initiator: getters[getterTypes.isAdmin] ? formatFullNameWithInitials(x.initiator) : formatFullName(x.initiator),
				certificatesStamp: x.document,
				isCurrentUserAssignee: getters[getterTypes.currentUser].id === x.assignee.id,
				isToDo: x.state === ApiHrTasksStateEnum.ToDo
			};
		});
	},
	[getterTypes.taskAssignees]: state => {
		return state.listing.items.map(x => {
			return {
				id: x.assignee.id,
				fullName: formatFullName(x.assignee)
			};
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<TasksState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<TasksState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TASK_ID):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TASK_TYPES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TASK_STATES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TASK_ASSIGNEE_IDS):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			if(!state.isInitialized)
				return;
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
		case resolveMutation(userTypes.namespace, userTypes.mutationTypes.SET_USER_ROLES):
		{
			const roles = mutation.payload as ApiHrRoleEnum[];
			
			if(roles.includes(ApiHrRoleEnum.Administrator))
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.setAdminDefaultFilter));
			
			break;
		}
	}
};

const actions = <ActionTree<TasksState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		await dispatch(actionTypes.fetchEmployees);
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		
		await dispatch(actionTypes.setAdminDefaultFilter);
		await dispatch(actionTypes.updateListingItems);
		
		if(state.filter.taskId && !state.listing.items.find(x => x.id === state.filter.taskId)) {
			commit(mutationTypes.SET_FILTER_TASK_ID, "");
			alertService.addError(AlertKeys.TASK_NOT_FOUND);
		}
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch, rootState }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const tasks = await hrController.getTasks(TasksMapper.mapToGetTasksParameters(state));
			
			commit(mutationTypes.SET_LISTING_ITEMS, tasks.map(x => HrTasksItemService.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchApproval]({ commit, state, dispatch }, { itemId, approvalId }) {
		commit(mutationTypes.ADD_LOADING_APPROVAL_ITEM_IDS, itemId);
		
		try {
			const approval = await hrController.getApproval(approvalId);
			
			commit(mutationTypes.SET_CURRENT_APPROVAL, HrVacationApplicationApprovalService.map(approval));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.REMOVE_LOADING_APPROVAL_ITEM_IDS, itemId);
		}
	},
	async [actionTypes.fetchEmployees]({ commit, state, dispatch }) {
		if(state.employees.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, true);
		
		try {
			const { employees } = await hrController.getEmployees({} as ApiHrGetEmployeesParameters);
			
			commit(mutationTypes.SET_EMPLOYEES, employees.map(x => ({ ...x, fullName: formatFullName(x) })));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.generatePdfUrl]({ commit, state, dispatch }, { fileId, title }: { fileId: string, title: string }) {
		commit(mutationTypes.SET_IS_FILE_LOADING, true);
		
		try {
			const file = await hrStorageController.getFile(fileId);
			
			commit(mutationTypes.SET_FILE, file);
			commit(mutationTypes.SET_PDF_URL, URL.createObjectURL(file));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FILE_LOADING, false);
		}
	},
	async [actionTypes.getFileMeta]({ commit, state, dispatch }, id: string) {
		try {
			const fileMeta = await hrStorageController.getFileMeta(id);
			
			return fileMeta;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	},
	async [actionTypes.downloadArchive]({ commit, state, dispatch }, parameters: HrDownloadArchiveRequest) {
		commit(mutationTypes.SET_IS_DOWNLOADING, true);
		try {
			let downloadArchive;
			
			switch (parameters.documentType) {
				case ApiHrDocumentTypesEnum.VacationApplication:
					downloadArchive = hrController.downloadArchive;
					break;
			}
			
			const { data, responseHeaders } = await downloadArchive(ApiHrDownloadArchiveRequestMapper.map(parameters));
			const { filename } = contentDispositionParser(responseHeaders["content-disposition"]);
			
			saveAs(data, filename);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_DOWNLOADING, false);
		}
	},
	async [actionTypes.downloadFile]({ commit, state, dispatch }, id: string) {
		commit(mutationTypes.SET_IS_DOWNLOADING, true);
		
		try {
			const file = await hrStorageController.getFile(id);
			
			saveAs(file, state.selectedTask.document.title);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_DOWNLOADING, false);
		}
	},
	async [actionTypes.declineTask]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_DECLINING, true);
		
		try {
			await hrController.declineTask({ taskId: state.selectedTask.id }, { reason: state.declineReason });
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.SUCCESS_DECLINED);

			return true;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}

			return false;
		} finally {
			commit(mutationTypes.SET_IS_DECLINING, false);
		}
	},
	async [actionTypes.approveTask]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_APPROVING, true);
		
		try {
			await hrController.approveTask(state.selectedTask.id);
			
			await dispatch(actionTypes.updateListingItems);

			alertService.addInfo(AlertKeys.SUCCESS_APPROVED);

			return true;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}

			return false;
		} finally {
			commit(mutationTypes.SET_IS_APPROVING, false);
		}
	},
	async [actionTypes.signFile]({ commit, state, dispatch }, signature: string) {
		commit(mutationTypes.SET_IS_SIGNING, true);
		
		try {
			await hrDocumentController.signDocument(state.selectedTask.document.id, signature);
			
			await dispatch(actionTypes.updateListingItems);

			alertService.addInfo(AlertKeys.SUCCESS_SIGNED);
			
			return true;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
			
			return false;
		} finally {
			commit(mutationTypes.SET_IS_SIGNING, false);
		}
	},
	async [actionTypes.markTaskInformationAsDone]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_MARKING_TASK_INFORMATION_AS_DONE, true);
		
		try {
			await hrController.markTaskInformationAsDone({ taskId: state.selectedTask.id });
			
			await dispatch(actionTypes.updateListingItems);

			return true;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}

			return false;
		} finally {
			commit(mutationTypes.SET_IS_MARKING_TASK_INFORMATION_AS_DONE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		
		commit(mutationTypes.SET_FILTER_TASK_TYPES, routeQuery.taskTypes);
		commit(mutationTypes.SET_FILTER_TASK_STATES, routeQuery.taskStates);
		commit(mutationTypes.SET_FILTER_TASK_ASSIGNEE_IDS, routeQuery.taskAssigneeIds);
		commit(mutationTypes.SET_FILTER_TASK_ID, routeQuery.taskId);
		commit(mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES, routeQuery.isAllEmployees);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.TASKS,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.TASKS,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.setAdminDefaultFilter]({ commit, state, getters }) {
		if(!getters.isAdmin)
			return;
		
		const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;
		
		if(!state.isInitialized || !currentUser?.id)
			return;
		
		if(!state.filter.isAllEmployees && getters[getterTypes.isAdmin]) {
			const defaultRouteQuery = new TasksRouteQuery([], [], [currentUser.id]);
			
			if(!state.filter.taskAssigneeIds.length && !state.filter.taskId)
				commit(mutationTypes.SET_FILTER_TASK_ASSIGNEE_IDS, [currentUser.id]);
			else if(!state.filter.taskAssigneeIds.length && state.filter.taskId)
				commit(mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES, true);
			
			routeService.setDefaultRoute(defaultRouteQuery);
		} else {
			commit(mutationTypes.SET_FILTER_TASK_ASSIGNEE_IDS, []);
		}
	}
};

const mutations = <MutationTree<TasksState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_TASK_TYPES](state, value) {
		state.filter.taskTypes = value;
	},
	[mutationTypes.SET_FILTER_TASK_STATES](state, value) {
		state.filter.taskStates = value;
	},
	[mutationTypes.SET_FILTER_TASK_ASSIGNEE_IDS](state, value) {
		state.filter.taskAssigneeIds = value;
	},
	[mutationTypes.SET_FILTER_TASK_ID](state, value) {
		state.filter.taskId = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new TasksFilter();
	},
	[mutationTypes.SET_SELECTED_TASK](state, value) {
		state.selectedTask = value;
	},
	[mutationTypes.RESET_SELECTED_TASK](state) {
		state.selectedTask = {} as HrTasksItem;
	},
	[mutationTypes.SET_IS_DECLINING](state, value) {
		state.isDeclining = value;
	},
	[mutationTypes.SET_DECLINE_REASON](state, value) {
		state.declineReason = value;
	},
	[mutationTypes.RESET_DECLINE_REASON](state) {
		state.declineReason = "";
	},
	[mutationTypes.SET_PDF_URL](state, value) {
		state.pdfUrl = value;
	},
	[mutationTypes.RESET_PDF_URL](state) {
		state.pdfUrl = "";
	},
	[mutationTypes.SET_EMPLOYEES](state, value) {
		state.employees = value;
	},
	[mutationTypes.SET_IS_EMPLOYEES_LOADING](state, value) {
		state.isEmployeesLoading = value;
	},
	[mutationTypes.SET_FILTER](state, value) {
		state.filter = value;
	},
	[mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES](state, value) {
		state.filter.isAllEmployees = value;
	},
	[mutationTypes.SET_IS_APPROVING](state, value) {
		state.isApproving = value;
	},
	[mutationTypes.SET_IS_SIGNING](state, value) {
		state.isSigning = value;
	},
	[mutationTypes.SET_IS_MARKING_TASK_INFORMATION_AS_DONE](state, value) {
		state.isMarkingTaskInformationAsDone = value;
	},
	[mutationTypes.SET_IS_DOWNLOADING](state, value) {
		state.isDownloading = value;
	},
	[mutationTypes.SET_IS_FILE_LOADING](state, value) {
		state.isFileLoading = value;
	},
	[mutationTypes.SET_FILE](state, value) {
		state.file = value;
	},
	[mutationTypes.RESET_REQUEST](state) {
		state.request = new ChangeRequest();
	},
	[mutationTypes.SET_REQUEST_DOCUMENT_TYPE](state, value) {
		state.request.documentType = value;
	},
	[mutationTypes.SET_REQUEST_DOCUMENT_KIND](state, value) {
		state.request.documentKind = value;
	},
	[mutationTypes.SET_REQUEST_EMPLOYEE_IDS](state, value) {
		state.request.employeeIds = value;
	},
	[mutationTypes.SET_REQUEST_EXPORT_ALL_TIME](state, value) {
		state.request.exportAllTime = value;
	},
	[mutationTypes.SET_REQUEST_PERIOD_RANGE](state, value) {
		state.request.periodRange = value;
	},
	[mutationTypes.SET_CURRENT_APPROVAL](state, value) {
		state.currentApproval = cloneDeep(value);
	},
	[mutationTypes.ADD_LOADING_APPROVAL_ITEM_IDS](state, value) {
		state.loadingApprovalItemIds.push(value);
	},
	[mutationTypes.REMOVE_LOADING_APPROVAL_ITEM_IDS](state, value) {
		state.loadingApprovalItemIds = state.loadingApprovalItemIds.filter(x => x !== value);
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const tasksModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default tasksModule;
