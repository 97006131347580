import { HrAcceptPlanRequest } from "@/types/hr/vacation/hrAcceptPlanRequest";

export interface ApiHrAcceptPlanRequest {
	employeeId: string;
	planId: string;
	reason?: string;
}

export class ApiHrAcceptPlanRequestMapper {
	static map(source: HrAcceptPlanRequest): ApiHrAcceptPlanRequest {
		return {
			...source
		};
	}
}
