import { DocumentResponse } from "@/store/bar/types/documentResponse";

export default class ApiUpdateDocumentCommentRequest {
	constructor(
		public explanation: string
	)
	{
	}
}

export class ApiUpdateDocumentCommentRequestMapper {
	static map(source: DocumentResponse): ApiUpdateDocumentCommentRequest {
		return {
			explanation: source.explanation
		};
	}
}