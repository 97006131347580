import { findDescendants, flatSubsWithLevels } from "@/utils/array";
import { ApiHrGetAdministrationEmployeesParameters } from "@/api/hr/types/vacation/apiHrGetAdministrationEmployeesParameters";
import VacationPlanEmployeesState from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesState";

export default class VacationPlanEmployeesMapper {
	static mapToGetAdministrationEmployeesParameters(source: VacationPlanEmployeesState): ApiHrGetAdministrationEmployeesParameters {
		// Для передачи всех дочерних подразделений в фильтр
		const departmentIds: string[] = [...source.filter.departmentIds];
		
		source.filter.departmentIds.forEach(x => {
			departmentIds.push(...findDescendants(flatSubsWithLevels("departments", source.departments), x).map(x => x.id));
		});
		
		return {
			departmentIds: departmentIds,
			employeeIds: source.filter.employeeIds
		};
	}
}
