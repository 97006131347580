import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter, parseBooleanParameter } from "@/utils/query";
import { isNil } from "lodash";
import VacationPlansRouteParams from "@/store/hr/modules/vacationPlans/types/vacationPlansRouteParams";
import VacationPlansRouteQuery from "@/store/hr/modules/vacationPlans/types/vacationPlansRouteQuery";
import VacationPlansState from "@/store/hr/modules/vacationPlans/types/vacationPlansState";

export default class VacationPlansRouteService {
	initialRouteQuery: VacationPlansRouteQuery;
	initialRouteParams: VacationPlansRouteParams;
	defaultRouteQuery: VacationPlansRouteQuery;
	defaultRouteParams: VacationPlansRouteParams;

	constructor(defaultRouteQuery: VacationPlansRouteQuery, defaultRouteParams: VacationPlansRouteParams) {
		this.initialRouteQuery = defaultRouteQuery;
		this.initialRouteParams = defaultRouteParams;
		this.setDefaultRoute(defaultRouteQuery, defaultRouteParams);
	}

	setDefaultRoute(defaultRouteQuery: VacationPlansRouteQuery, defaultRouteParams: VacationPlansRouteParams) {
		this.defaultRouteQuery = defaultRouteQuery;
		this.defaultRouteParams = defaultRouteParams;
	}

	resolveRouteQueryDictionary(state: VacationPlansState) {
		let query = new VacationPlansRouteQuery(
			state.filter.departmentIds,
			state.filter.employeeIds,
			state.filter.versionId,
			state.filter.month,
			state.filter.view,
			state.filter.isAllEmployees
		);

		return difference(this.initialRouteQuery, query);
	}

	resolveRouteParamsDictionary(state: VacationPlansState): Dictionary<string> {
		let params = new VacationPlansRouteParams(
			state.filter.year
		);

		return difference(this.initialRouteParams, params);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			const isAllEmployees = parseBooleanParameter(query.isAllEmployees, this.defaultRouteQuery.isAllEmployees);
			
			let result = plainToInstance(VacationPlansRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				month: query.month ? +query.month : null,
				departmentIds: parseArrayParameter(query.departmentIds, this.defaultRouteQuery.departmentIds),
				employeeIds: isAllEmployees ? [] : parseArrayParameter(query.employeeIds, this.defaultRouteQuery.employeeIds),
				isAllEmployees,
				versionId: query.versionId ?? null
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}

	async resolveRouteParams(params: Dictionary<any>) {
		try {
			let result = plainToInstance(VacationPlansRouteParams, {
				...this.defaultRouteParams,
				...params,
				year: isNil(params.year) ? this.defaultRouteParams.year : +params.year
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteParams;
		}
	}
}
