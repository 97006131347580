import { PaperDocumentStatusTypeEnum } from "@/store/bar/types/PaperDocumentStatusTypeEnum";
import { ElectronicDocumentStatusTypeEnum } from "@/store/bar/types/ElectronicDocumentStatusTypeEnum";
import { DocumentResponse } from "@/store/bar/types/documentResponse";

export default class ApiUpdateDocumentStatusRequest {
	constructor(
		public electronicDocumentStatus?: ElectronicDocumentStatusTypeEnum,
		public paperDocumentStatus?: PaperDocumentStatusTypeEnum,
		public explanation?: string
	)
	{
	}
}


export class ApiUpdateDocumentStatusRequestMapper {
	static map(source: DocumentResponse): ApiUpdateDocumentStatusRequest {
		return {
			electronicDocumentStatus: source.electronicStatus || ElectronicDocumentStatusTypeEnum.IN_PROGRESS,
			paperDocumentStatus: source.paperStatus || PaperDocumentStatusTypeEnum.NOT_SUBMITTED,
			explanation: source.explanation
		};
	}
}