export default class VacationPlanHolidaysFilter {
	year?: number;
	
	constructor(
		year?: number
	)
	{
		this.year = year;
	}
}
