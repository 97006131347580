export default class ApiDictionaryItemInfo {
	title: string;
	code: string;

	constructor(
		title: string = "",
		code: string = ""
	)
	{
		this.title = title;
		this.code = code;
	}
}
