import { IsArray, IsEnum, IsIn, IsInt, IsString, Max, Min } from "class-validator";
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";

const AVAILABLE_FILTER_STATUS_TYPE = [
	BankAccountStatusTypeEnum.UNKNOWN,
	BankAccountStatusTypeEnum.NEW,
	BankAccountStatusTypeEnum.TO_CONNECT,
	BankAccountStatusTypeEnum.RESPONSIBLE_ASSIGNED,
	BankAccountStatusTypeEnum.CONNECTED,
	BankAccountStatusTypeEnum.DISCONNECTED,
	""
];

export default class BankAccountsListRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: Number;

	@IsString()
	loanApplicationNumber: String;

	@IsString()
	counterpartyId: String;

	@IsString()
	bankId: String;
	
	@IsArray()
	@IsIn(AVAILABLE_FILTER_STATUS_TYPE, {
		each: true
	})
	signStatuses: String[];

	@IsString()
	responsibleUserId: String;

	constructor(
		page: Number,
		loanApplicationNumber: String,
		counterpartyId: String,
		bankId: String,
		signStatuses: String[],
		responsibleUserId: String
	)
	{
		this.page = page;
		this.loanApplicationNumber = loanApplicationNumber;
		this.counterpartyId = counterpartyId;
		this.bankId = bankId;
		this.signStatuses = signStatuses;
		this.responsibleUserId = responsibleUserId;
	}
}
