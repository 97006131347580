<template>
	<frp-dialog max-width="568"
				:value="value"
				:title="$t('dialogs.bankAccountAgreementTypeHistory.title')" 
				@update:value="$emit('update:value', $event)">
		<template #subtitle>
			<p class="text-caption primary--text text--lighten-1 mt-0">
				{{ `${bankAccountNumber} - ${counterpartyShortName} ${loanApplicationNumber}` }}
			</p>
		</template>
		
		<template #content="{ onIntersect }">
			<v-container v-intersect="onIntersect" class="px-0 pt-0" fluid v-if="agreementTypeHistoryItems.length">
				<v-row v-for="(historyItem, i) in agreementTypeHistoryItems" :key="`${historyItem.createdAt}-${i}`">
					<v-col cols="auto">
							<span class="text-subtitle-1 font-weight-medium blue--text text--darken-4">
								{{ formatDate(historyItem.createdAt, dateFormat) }}</span>
					</v-col>
					<v-col>
						<div class="d-flex flex-column" style="gap: 10px">
								<span>
									{{ $t(`aliases.bankAccountType.${historyItem.accountType}`) }}
								</span>
							<span class="text-caption primary--text text--lighten-1">
									{{ historyItem.creatorFullName }}
								</span>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</template>
		
		<template #footer>
			<frp-btn class="elevation-0"
					 outlined
					 @click="$emit('update:value', false)"
					 :color="colors.primary.base">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { namespace } from "@/store/bar/modules/bankAccount";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		agreementTypeHistoryItems: {
			type: Array,
			default: []
		},
		counterpartyShortName: {
			type: String,
			default: ""
		},
		loanApplicationNumber: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			RouteNames,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...mapState({
			id: state => state.editableItem.id,
			isBankAccountAgreementTypesHistoryLoading: state => state.isBankAccountAgreementTypesHistoryLoading,
			bankAccountNumber: state => state.editableItem.account.accountNumber
		})
	},
	components: {
		FrpDialog,
		FrpIcon,
		FrpBtn
	}
};
</script>

<style scoped>
</style>
