export interface HrImportEmployeesRequest {
	hireDateFrom?: number;
	hireDateTo?: number;
}

export class HrImportEmployeesRequestService {
	static getEmpty(): HrImportEmployeesRequest {
		return {
			hireDateFrom: undefined,
			hireDateTo: undefined
		};
	}
}
