export interface HrTableError {
	title: string;
	message: string;
}

export class HrTableErrorMapper {
	static map(title: string, message: string): HrTableError {
		return {
			title: title || "",
			message: message || ""
		}
	}
}
