export interface HrAcceptPlanRequest {
	employeeId: string;
	planId: string;
	reason?: string;
}

export class HrAcceptPlanRequestService {
	static getEmpty(): HrAcceptPlanRequest {
		return {
			employeeId: "",
			planId: "",
			reason: undefined
		};
	}
}
