import IPageState from "@/store/shared/base/types/pageState";
import ApiApplicationStatus from "@/api/bar/types/dictionaries/apiApplicationStatus";
import ApiBankAccountType from "@/api/bar/types/dictionaries/apiBankAccountType";
import BankAccountsListFilter from "@/store/bar/modules/bankAccountsList/types/bankAccountsListFilter";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { ApiCurrency } from "@/api/bar/types/dictionaries/apiCurrency";
import { ApiBankResponsibleUser } from "@/api/bar/types/dictionaries/apiBankResponsibleUser";
import { ApiSuggestedCounterparty } from "@/api/bar/types/apiSuggestedCounterparty";
import { BankAccountsListItem } from "@/store/bar/modules/bankAccountsList/types/bankAccountsListItem";

export default class BankAccountsListState implements IPageState {
	constructor(
		public listing: ListingModel<BankAccountsListItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public banks: ApiBankInfoPersisted[] = [],
		public signStatuses: ApiApplicationStatus[] = [],
		public bankAccountTypes: ApiBankAccountType[] = [],
		public currencies: ApiCurrency[] = [],
		public bankResponsibleUsers: ApiBankResponsibleUser[] = [],
		public filter: BankAccountsListFilter = new BankAccountsListFilter(),
		public isFetchSuggestedCounterpartyLoading: boolean = false,
		public suggestedCounterparties: ApiSuggestedCounterparty[] = []
	)
	{
	}
}
