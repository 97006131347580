<template>
	<div class="d-flex flex-column justify-center align-center frp-doc" style="gap: 60px">
		<div class="d-flex flex-column justify-center align-center" style="gap: 16px">
			<div class="d-flex flex-column justify-center align-center" style="gap: 16px">
				<h1 class="text-uppercase" :style="{ color: colors.primary.base }">Кадровые документы</h1>
				<span style="width: 82px; height: 2px; background-color: #E55B53"></span>
			</div>
			<v-btn v-if="isHrUserAdministrator" style="height: 34px;" outlined :color="colors.primary.lighten1"
				   @click="handleDialog(true, 'partition')">
				{{ $t("buttons.addSection") }}
			</v-btn>
		</div>
		
		<v-progress-circular v-if="isLoading" indeterminate></v-progress-circular>
		
		<v-expansion-panels v-else style="max-width: 831px" v-model="panel">
			<v-expansion-panel v-for="(item,i) in personnelPartitions"
							   :key="i">
				<v-expansion-panel-header
					style="gap: 11px; padding-left: 7px; height: 63px; border-bottom: 1px solid #5D5D5D; border-radius: 0;">
					<div style="order: 1; font-size: 22px">{{ item.title }}</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content style="position: relative">
					<div v-for="document in item.documents" class="d-flex align-center"
						 style="margin-bottom: 17px; overflow-wrap: anywhere; max-width: 630px;">
						<frp-file-with-extension style="margin-right: 24px;"
												 downloadable
												 font-size="20px !important"
												 src-icon="ico_file-document"
												 underline
												 :id="document.fileId"
												 :name="document.title">
						</frp-file-with-extension>
						
						<v-btn v-if="isHrUserAdministrator"
							   icon
							   style="margin-right: 12px"
							   @click="handleDialog(false, 'document', item, document)">
							<frp-icon src="ico_pencil"></frp-icon>
						</v-btn>
					</div>
					<frp-btn v-if="isHrUserAdministrator"
							 elevation="0"
							 class="bar-btn"
							 style="height: 34px; font-size: 16px; margin-left: 7px; text-transform: none;"
							 outlined
							 @click="handleDialog(true, 'document', item)"
							 color="#5D5D5D">
						<v-icon>mdi-plus</v-icon>
						{{ $t("buttons.addDocument") }}
					</frp-btn>
					<v-btn v-if="isHrUserAdministrator"
						   @click="handleDialog(false, 'partition', item)"
						   style="height: 34px; position: absolute; right: 0; top: 19px;" outlined :color="colors.primary.lighten1">
						{{ $t("buttons.changeSection") }}
					</v-btn>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		
		<hr-create-partition-dialog v-if="openCreatePartitionDialog"
									v-model="openCreatePartitionDialog">
		</hr-create-partition-dialog>
		<hr-create-document-dialog v-if="openCreateDocumentDialog"
								   v-model="openCreateDocumentDialog">
		</hr-create-document-dialog>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpFileWithExtension from "@/components/common/FrpFileWithExtension.vue";
import FrpHeadingOne from "@/components/typography/FrpHeadingOne.vue";
import colorsMixin from "@/mixins/colorsMixin";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/hr/routes";
import { namespace } from "@/store/hr/modules/personnelDocuments";
import { getterTypes, mutationTypes, actionTypes } from "@/store/hr/modules/personnelDocuments/types";
import storeManager from "@/store/manager";
import HrCreateDocumentDialog from "@/views/hr/personnelDocuments/dialogs/HrCreateDocumentDialog.vue";
import HrCreatePartitionDialog from "@/views/hr/personnelDocuments/dialogs/HrCreatePartitionDialog.vue";
import { createNamespacedHelpers } from "vuex";
import userTypes from "@/store/hr/modules/user/types";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const userModuleHelpers = createNamespacedHelpers(storeManager.user.namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	metaInfo() {
		return {
			title: this.$t("metaTitles.personnelDocuments")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			openCreatePartitionDialog: false,
			openCreateDocumentDialog: false,
			panel: null
		};
	},
	computed: {
		...mapState({
			initialized: state => state.initialized,
			personnelPartitions: state => state.personnelPartitions,
			isLoading: state => state.isLoading
		}),
		...userModuleHelpers.mapGetters({
			isHrUserAdministrator: userTypes.getterTypes.isHrUserAdministrator
		}),
		isNotData() {
			return !this.personnelPartitions.length;
		}
	},
	methods: {
		...mapMutations({
			setIsCreateMode: mutationTypes.SET_IS_CREATE_MODE,
			setSelectedPersonnelPartition: mutationTypes.SET_SELECTED_PERSONNEL_PARTITION,
			setSelectedPersonnelDocument: mutationTypes.SET_SELECTED_PERSONNEL_DOCUMENT
		}),
		...mapActions({
			initializeStore: actionTypes.initialize
		}),
		handleDialog(isCreateMode, type = null, partitionItem = {}, documentItem = {}) {
			this.setSelectedPersonnelPartition(partitionItem);
			this.setSelectedPersonnelDocument(documentItem);
			this.setIsCreateMode(isCreateMode);
			
			if(type === "document") {
				this.openCreateDocumentDialog = true;
			} else if(type === "partition") {
				this.openCreatePartitionDialog = true;
			}
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpFileWithExtension,
		HrCreateDocumentDialog,
		HrCreatePartitionDialog,
		FrpBtn,
		FrpHeadingOne,
		FrpIcon
	}
};
</script>

<style lang="scss">
.frp-doc {
  .v-expansion-panel::before {
	content: none !important;
  }

  .v-expansion-panel-content__wrap {
	padding: 19px 24px 0 13px !important;
  }
}
</style>