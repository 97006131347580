import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrPromise } from "@/api/hr/types/apiHrPromise";
import { ApiHrTaskStats } from "@/api/hr/types/tasks/apiHrTaskStats";

export default class EmployeePromisesState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public bitrixPromises: ApiHrPromise = {} as ApiHrPromise,
		public hrTaskStats: ApiHrTaskStats[] = {} as ApiHrTaskStats[]
	)
	{
	}
}
