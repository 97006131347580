import storeManager from "@/store/manager";
import { RouteNames } from "@/router/bar/routes";

export const routeToStoreMap = new Map<string, string[]>([
		[RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST, [storeManager.bar.bankAccountApplicationsList.namespace]],
		[RouteNames.BANK_ACCOUNT_APPLICATION, [storeManager.bar.bankAccountApplication.namespace]],
		[RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE, [storeManager.bar.bankAccountApplication.namespace]],
		[RouteNames.BANK_ACCOUNT_APPLICATION_CREATE, [storeManager.bar.bankAccountApplication.namespace]],
		[RouteNames.BANK_ACCOUNTS_LIST, [storeManager.bar.bankAccountsList.namespace]],
		[RouteNames.BANK_ACCOUNT, [storeManager.bar.barAccount.namespace]],
		[RouteNames.BANK_ACCOUNT_CREATE, [storeManager.bar.barAccount.namespace]],
	]
);
