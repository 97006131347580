import personnelDocumentsTypes, { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/personnelDocuments/types";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { HrPersonnelDocumentController } from "@/api/hr/personnelDocument";
import PersonnelDocumentsState from "@/store/hr/modules/personnelDocuments/types/personnelDocumentsState";
import { ApiHrCreatePartitionRequest } from "@/api/hr/types/personnelDocument/apiHrCreatePartitionRequest";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { cloneDeep } from "lodash";
import { ApiHrCreateDocumentRequest } from "@/api/hr/types/personnelDocument/apiHrCreateDocumentRequest";
import { HrStorageController } from "@/api/hr/storage";
import { saveAs } from "file-saver";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService from "@/store/modules/alerts/services/alertService";

const abortService = new AbortService();
const hrPersonnelDocumentController = new HrPersonnelDocumentController(abortService);
const hrStorageController = new HrStorageController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();

const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["payloadPartition", "payloadDocument"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new PersonnelDocumentsState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<PersonnelDocumentsState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<PersonnelDocumentsState, any>>{
	...baseMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetchPersonnelPartitions);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchPersonnelPartitions]({ commit, state, dispatch, rootState }) {
		commit(mutationTypes.SET_IS_LOADING, true);
		
		try {
			const partitions = await hrPersonnelDocumentController.getPartitions();
			
			commit(mutationTypes.SET_PERSONNEL_PARTITIONS, partitions);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.processingPartition]({ state, commit, dispatch },
		{ id, payload }: { id: string, payload: ApiHrCreatePartitionRequest })
	{
		commit(mutationTypes.SET_IS_PROCESSING_LOADING, true);
		
		try {
			if(state.isCreateMode) {
				await hrPersonnelDocumentController.createPartition(payload);
			} else {
				await hrPersonnelDocumentController.updatePartition(id, payload);
			}
			
			await dispatch(actionTypes.fetchPersonnelPartitions);
		} catch (error) {
			if(error.response?.data?.title) {
				alertService.addCustomError(error.response.data.title);
			} else {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_PROCESSING_LOADING, false);
		}
	},
	async [actionTypes.deletePartition]({ state, commit, dispatch },
		{ id }: { id: string })
	{
		commit(mutationTypes.SET_IS_PROCESSING_LOADING, true);
		
		try {
			await hrPersonnelDocumentController.deletePartition(id);
			
			await dispatch(actionTypes.fetchPersonnelPartitions);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_PROCESSING_LOADING, false);
		}
	},
	async [actionTypes.processingDocument]({ state, commit, dispatch },
		{ id, payload }: { id?: string, payload: ApiHrCreateDocumentRequest })
	{
		commit(mutationTypes.SET_IS_PROCESSING_LOADING, true);
		
		try {
			if(state.isCreateMode) {
				await hrPersonnelDocumentController.createDocument(payload);
			} else {
				const { partitionId, ...data } = payload;
				await hrPersonnelDocumentController.updateDocument(id!, data);
			}
			
			await dispatch(actionTypes.fetchPersonnelPartitions);
		} catch (error) {
			if(error.response?.data?.title) {
				alertService.addCustomError(error.response.data.title);
			} else {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_PROCESSING_LOADING, false);
		}
	},
	async [actionTypes.deleteDocument]({ state, commit, dispatch },
		{ id }: { id: string })
	{
		commit(mutationTypes.SET_IS_PROCESSING_LOADING, true);
		
		try {
			await hrPersonnelDocumentController.deleteDocument(id);
			
			await dispatch(actionTypes.fetchPersonnelPartitions);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_PROCESSING_LOADING, false);
		}
	},
	async [actionTypes.getFileMeta]({ state, commit, dispatch }, id: string)
	{
		commit(mutationTypes.SET_IS_FILE_META_LOADING, true);
		
		try {
			return await hrStorageController.getFileMeta(id);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_FILE_META_LOADING, false);
		}
	}
};

const mutations = <MutationTree<PersonnelDocumentsState>>{
	...baseMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_PERSONNEL_PARTITIONS](state, value) {
		state.personnelPartitions = value;
	},
	[mutationTypes.SET_IS_PROCESSING_LOADING](state, value) {
		state.isProcessingLoading = value;
	},
	[mutationTypes.SET_IS_CREATE_MODE](state, value) {
		state.isCreateMode = value;
	},
	[mutationTypes.SET_SELECTED_PERSONNEL_PARTITION](state, value) {
		state.selectedPersonnelPartition = cloneDeep(value);
	},
	[mutationTypes.RESET_PAYLOAD_PARTITION](state) {
		state.payloadPartition = {
			title: ""
		};
	},
	[mutationTypes.SET_PAYLOAD_PARTITION](state, value) {
		state.payloadPartition = cloneDeep(value);
	},
	[mutationTypes.SET_PAYLOAD_PARTITION_TITLE](state, value) {
		state.payloadPartition.title = value;
	},
	[mutationTypes.RESET_PAYLOAD_DOCUMENT](state) {
		state.payloadDocument = {
			title: "",
			tempFileId: "",
			partitionId: ""
		};
	},
	[mutationTypes.SET_PAYLOAD_DOCUMENT](state, value) {
		state.payloadDocument = cloneDeep(value);
	},
	[mutationTypes.SET_PAYLOAD_DOCUMENT_TITLE](state, value) {
		state.payloadDocument.title = value;
	},
	[mutationTypes.SET_PAYLOAD_DOCUMENT_TEMP_FILE_ID](state, value) {
		state.payloadDocument.tempFileId = value;
	},
	[mutationTypes.SET_PAYLOAD_DOCUMENT_PARTITION_ID](state, value) {
		state.payloadDocument.partitionId = value;
	},
	[mutationTypes.SET_SELECTED_PERSONNEL_DOCUMENT](state, value) {
		state.selectedPersonnelDocument = cloneDeep(value);
	},
	[mutationTypes.SET_IS_FILE_META_LOADING](state, value) {
		state.isFileMetaLoading = value;
	}
};

export {
	namespace, state, actions, mutations, getters
};

const personnelDocumentsModule = {
	namespace, state, actions, mutations, getters, namespaced: true
};

export default personnelDocumentsModule;
