import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrDepartmentsItem } from "@/api/hr/types/apiHrDepartmentsItem";
import { HrAvailableVacation } from "@/types/hr/vacationApplication/hrAvailableVacation";

export default class EmployeeState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public permissions: string[] = [],
		public isPermissionsLoading: boolean = false,
		public departments: ApiHrDepartmentsItem[] = [],
		public closestVacation: HrAvailableVacation = {} as HrAvailableVacation,
	)
	{
	}
}
