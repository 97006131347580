<template>
	<div>
		<span :style="{ color }">{{ text }}</span>
	</div>
</template>

<script>
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		statuses: {
			type: Array
		},
		status: {
			type: [String, BankAccountStatusTypeEnum]
		}
	},
	computed: {
		text() {
			return this.statuses.find(x => x.code === this.status)?.title || "";
		},
		color() {
			switch (this.status) {
				case BankAccountStatusTypeEnum.NEW:
					return this.colors.blue.base
				case BankAccountStatusTypeEnum.TO_CONNECT:
					return this.colors.warning.base;
				case BankAccountStatusTypeEnum.CONNECTED:
					return this.colors.blue.base
				case BankAccountStatusTypeEnum.RESPONSIBLE_ASSIGNED:
					return this.colors.green.base;
				case BankAccountStatusTypeEnum.DISCONNECTED:
					return this.colors.secondary.base
			}
		}
	}
};
</script>
