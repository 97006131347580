import { IsArray, IsIn, IsString } from "class-validator";
import { AVAILABLE_SORTS } from "@/store/hr/modules/vacationPlanEmployees/constants";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";

export default class VacationPlanEmployeesRouteQuery {
	@IsArray()
	departmentIds: string[];
	
	@IsArray()
	employeeIds: string[];
	
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([sortingOrderType.descending, sortingOrderType.ascending])
	sortDirection: string;
	
	constructor(
		departmentIds: string[] = [],
		employeeIds: string[] = [],
		sort: string = "",
		sortDirection: string = sortingOrderType.ascending
	)
	{
		this.departmentIds = departmentIds;
		this.employeeIds = employeeIds;
		this.sort = sort;
		this.sortDirection = sortDirection;
	}
}
