<template>
	<div style="position: relative">
		<pie style="width: 145px; height: 145px" :chart-data="chartData" :chart-options="chartOptions"/>
		<hr-chart-pie-value :value="value"/>
	</div>
</template>

<script>
import HrChartPieValue from "Components/charts/HrChartPieValue";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import colorsMixin from "Mixins/colorsMixin";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: Number,
			required: true
		},
		label: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			chartOptions: {
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						filter: (context) => {
							return context.dataIndex === 0;
						},
						displayColors: false
					}
				}
			}
		};
	},
	computed: {
		chartData() {
			return {
				labels: [this.label],
				datasets: [
					{
						backgroundColor: [this.colors.blue.lighten5, "transparent"],
						data: [this.value, 100 - (this.value > 100 ? 100 : this.value)],
						borderColor: ["transparent"]
					}
				]
			};
		}
	},
	components: { Pie, HrChartPieValue }
};
</script>
