<template>
	<frp-card v-if="isInitialized" :title="$t('titles.informationLetter')" class="pb-4">
		<template #content>
			<bar-card-content-layout>
				<v-row>
					<v-col style="padding-top: 10px">
						<span class="text-body-2 font-weight-medium primary--text">
							{{ $t("common.informationLetterInfo") }}
						</span>
					</v-col>
				</v-row>
				<v-row class="mt-0 d-flex justify-end align-center">
					<v-col cols="6">
						<span class="text-body-2 font-weight-medium grey--text text-right d-flex">
							{{
								isSourceLK ?
									$t("common.informationLetterGenerateInfoPersonalAccount") :
									$t("common.informationLetterGenerateInfoLoan")
							}}
						</span>
					</v-col>
					<v-col cols="auto">
						<frp-btn class="ml-6"
								 elevation="0"
								 :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.GENERATE_LETTER_BUTTON) || !can([Permissions.BAR_APPLICATION_GENERATE_UPDATE, Permissions.BAR_APPLICATION_GENERATE_CREATE])"
								 dark
								 :loading="isInformationLetterGenerating"
								 @click="handleGenerateInformationLetterFile"
								 :color="colors.blue.base">
							{{ $t("buttons.generate") }}
						</frp-btn>
					</v-col>
				</v-row>
			</bar-card-content-layout>
			
			<frp-dialog v-model="isInformationLetterDialogOpened" :title="$t('dialogs.informationLetterGenerating.title')" max-width="568">
				<template #content="{ onIntersect }">
					<v-container v-intersect="onIntersect" class="pt-0">
						<v-row>
							<v-col class="px-0">
								<span class="text-body-2 font-weight-medium grey--text">
									{{
										isSourceLK ?
											$t("common.informationLetterGenerateInfoPersonalAccount") :
											$t("common.informationLetterGenerateInfoLoan")
									}}
								</span>
							</v-col>
						</v-row>
						<v-row class="d-flex justify-space-between">
							<v-col cols="9" class="px-0">
								<div class="d-flex align-center">
									<frp-icon src="ico_file_bar" :color="colors.primary.darken1" class="mr-3"></frp-icon>
									<span class="text-body-2 py-2" style="color: var(--v-primary-darken1)">
										{{ informationLetterFile?.name }}
									</span>
								</div>
							</v-col>
							<v-col cols="auto" justify="end" class="px-0">
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 :disabled="!informationLetterFile && !informationLetterFile?.name"
										 @click="saveGeneratedInformationLetterFile">
									{{ $t("buttons.download") }}
								</frp-btn>
							</v-col>
						</v-row>
					</v-container>
				</template>
				
				<template #footer>
					<frp-btn v-if="isSourceLK"
							 :href="getLkProjectLink"
							 target="_blank"
							 outlined
							 color="blue">
						{{ $t("buttons.goToPersonalAccountShort") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 @click="isInformationLetterDialogOpened = false">
						{{ $t("buttons.close") }}
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
		
		<template #actions>
			<frp-text-btn v-if="isSourceLK"
						  :href="getLkProjectLink"
						  target="_blank"
						  class="pa-0 bar-text-btn"
						  :text="$t('buttons.goToPersonalAccountShort')"
						  color="primary">
			</frp-text-btn>
		</template>
	</frp-card>
	<bar-bank-account-application-information-letter-loader v-else/>
</template>

<script>
import { BarController } from "@/api/bar";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import { externalUrls } from "@/constants/bar/externalUrls";
import { FileTypes } from "@/constants/fileTypes";
import NotDefinedException from "@/exceptions/notDefinedException";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import AbortService from "@/services/abortService";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import bankAccountApplicationTypes from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import BarBankAccountApplicationInformationLetterLoader
	from "@/views/bar/bankAccountApplication/sections/modules/informationLetter/BarBankAccountApplicationInformationLetterLoader.vue";
import { createNamespacedHelpers } from "vuex";
import { saveAs } from "file-saver";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const barController = new BarController(abortService);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			SourceTypeEnum,
			FileTypes,
			informationLetterFile: null,
			isInformationLetterGenerating: false,
			isInformationLetterDialogOpened: false
		};
	},
	computed: {
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum;
		},
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem
		}),
		...mapGetters({
			buttonStatus: bankAccountApplicationTypes.getterTypes.buttonUnlocked
		}),
		isSourceLK() {
			return this.editableItem.source === SourceTypeEnum.LK && this.$route.query.sourceType === SourceTypeEnum.LK;
		},
		getLkProjectLink() {
			return `${externalUrls.lkProject}/${this.editableItem.agreement.entryId}`;
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({}),
		async handleGenerateInformationLetterFile() {
			this.isInformationLetterGenerating = true;
			
			try {
				const { data } = await barController.generateInformationalLetter(this.editableItem.id);
				
				if(!data || !data.size || data?.type !== FileTypes.DOCX)
					throw new NotDefinedException(data);
				
				let informationLetterFileName = `${this.$t("common.informationLetter")}.docx`;
				
				this.informationLetterFile = new File([data], informationLetterFileName, { type: data.type });
				
				this.isInformationLetterDialogOpened = true;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isInformationLetterGenerating = false;
			}
		},
		saveGeneratedInformationLetterFile() {
			saveAs(this.informationLetterFile, this.informationLetterFile?.name);
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		isInformationLetterDialogOpened(value) {
			if(!value) {
				this.informationLetterFile = null;
			}
		}
	},
	components: {
		BarBankAccountApplicationInformationLetterLoader,
		BarCardContentLayout,
		FrpIcon,
		FrpDialog,
		FrpTextBtn,
		FrpBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
