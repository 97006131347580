import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiHrPersonnelPartition } from "@/api/hr/types/personnelDocument/apiHrPersonnelPartition";
import { ApiHrCreatePartitionRequest } from "@/api/hr/types/personnelDocument/apiHrCreatePartitionRequest";
import { ApiHrUpdatePartitionRequest } from "@/api/hr/types/personnelDocument/apiHrUpdatePartitionRequest";
import { ApiHrCreateDocumentRequest } from "@/api/hr/types/personnelDocument/apiHrCreateDocumentRequest";
import { ApiHrUpdateDocumentRequest } from "@/api/hr/types/personnelDocument/apiHrUpdateDocumentRequest";

export class HrPersonnelDocumentController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPartitions = async () => {
		return this.client.get<ApiHrPersonnelPartition[]>(urls.hr.personnelDocument.getPartitions);
	};
	
	createPartition = async (payload: ApiHrCreatePartitionRequest) => {
		return this.client.post(urls.hr.personnelDocument.createPartition, payload);
	};
	
	updatePartition = async (id: string, payload: ApiHrUpdatePartitionRequest) => {
		return this.client.put(prepareUrl(urls.hr.personnelDocument.updatePartition.replace(urlTemplateParts.id, id)), payload);
	};
	
	deletePartition = async (id: string) => {
		return this.client.delete(prepareUrl(urls.hr.personnelDocument.deletePartition.replace(urlTemplateParts.id, id)));
	};
	
	createDocument = async (payload: ApiHrCreateDocumentRequest) => {
		return this.client.post(urls.hr.personnelDocument.createDocument, payload);
	};
	
	updateDocument = async (id: string, payload: Omit<ApiHrCreateDocumentRequest, "partitionId">) => {
		return this.client.put(prepareUrl(urls.hr.personnelDocument.updateDocument.replace(urlTemplateParts.id, id)), payload);
	};
	
	deleteDocument = async (id: string) => {
		return this.client.delete(prepareUrl(urls.hr.personnelDocument.deleteDocument.replace(urlTemplateParts.id, id)));
	};
}
