<template>
	<kpi-content-layout>
		<v-row>
			<v-col cols="12" md="6">
				<v-card class="kpi-card pb-2">
					<frp-form-card>
						<frp-form-card-blocks>
							<frp-form-card-block>
								<template #title>
									<frp-form-card-block-row>
										<frp-form-card-block-col cols="12">
											<frp-form-item>
												<frp-form-block-title-loader class="mt-3"/>
												<v-divider class="mt-6 mb-2"></v-divider>
											</frp-form-item>
										</frp-form-card-block-col>
									</frp-form-card-block-row>
								</template>
								
								<frp-form-card-block-row>
									<frp-form-card-block-col cols="12">
										<frp-form-item v-for="i in 4" :key="i">
											<frp-input-loader/>
										</frp-form-item>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
							</frp-form-card-block>
						</frp-form-card-blocks>
					</frp-form-card>
				</v-card>
			</v-col>
		</v-row>
	</kpi-content-layout>
</template>

<script>
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpFormActions from "@/components/form/FrpFormActions.vue";
import FrpNestedContentLayout from "@/components/layouts/FrpNestedContentLayout.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import FrpCheckboxLoader from "@/components/loaders/common/FrpCheckboxLoader.vue";
import FrpFormBlockTitleLoader from "@/components/loaders/form/FrpFormBlockTitleLoader.vue";
import FrpInputLoader from "@/components/loaders/form/FrpInputLoader.vue";
import FrpSwitchLoader from "@/components/loaders/form/FrpSwitchLoader.vue";
import FrpTextareaLoader from "@/components/loaders/form/FrpTextareaLoader.vue";
import FrpFormCard from "@/components/markup/FrpFormCard.vue";
import FrpFormCardBlock from "@/components/markup/FrpFormCardBlock.vue";
import FrpFormCardBlockCol from "@/components/markup/FrpFormCardBlockCol.vue";
import FrpFormCardBlockRow from "@/components/markup/FrpFormCardBlockRow.vue";
import FrpFormCardBlocks from "@/components/markup/FrpFormCardBlocks.vue";
import FrpFormItem from "@/components/markup/FrpFormItem.vue";

export default {
	components: {
		FrpInputLoader, FrpFormBlockTitleLoader, FrpCheckboxLoader, FrpTextareaLoader, FrpSwitchLoader,
		KpiContentLayout,
		FrpFormActions,
		FrpFormCardBlockCol,
		FrpFormCardBlocks,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormItem,
		FrpFormCard,
		FrpTextarea,
		FrpNestedContentLayout
	}
};
</script>
