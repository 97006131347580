import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import { ElectronicDocumentStatusTypeEnum } from "@/store/bar/types/ElectronicDocumentStatusTypeEnum";
import { ApiDocumentResponse } from "@/api/bar/types/apiDocumentResponse";
import { convertToTimestamp } from "@/utils/dates";
import { PaperDocumentStatusTypeEnum } from "@/store/bar/types/PaperDocumentStatusTypeEnum";

export interface DocumentResponse {
	bankAccountApplicationId: string;
	accountId: string;
	storedFileId: string;
	type: BankAccountApplicationDocumentTypeEnum;
	electronicStatus: ElectronicDocumentStatusTypeEnum;
	paperStatus: PaperDocumentStatusTypeEnum;
	explanation: string;
	createdAt: number;
	createdBy: string;
	documentName: string;
	isStatusChanging: boolean;
	isCommentChanging: boolean;
	isDocumentDeleting: boolean;
	acceptorId: string;
	acceptedAt: string;
}

export class DocumentResponseMapper {
	static map(source: ApiDocumentResponse): DocumentResponse {
		return {
			...source,
			createdAt: convertToTimestamp(source.createdAt) as number,
			isStatusChanging: false,
			isCommentChanging: false,
			isDocumentDeleting: false,
			explanation: source.explanation?.trim?.()
		};
	}
}