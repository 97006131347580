import { OptionsItem } from "@/types/options/optionsItem";
import { i18n } from "@/plugins/index";

export function useOptions(value: object, key: string, { i18nKey }: { i18nKey?: string } = {}) {
	const items = Object.values(value);
	
	return {
		items: items.map(x => {
			i18nKey = i18nKey ? `${i18nKey}` : `aliases.${key}`;
			
			return {
				title: i18n.t(`${i18nKey}.${x}`),
				value: x
			} as OptionsItem;
		})
	};
}
