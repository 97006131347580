<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.sendVacationApplicationToReview.title')"
				max-width="536"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<span>{{ $t("dialogs.sendVacationApplicationToReview.text") }}</span>
		</template>
		
		<template #footer>
			<frp-btn outlined color="primary"
					 @click="$emit('update:value', false)">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="isFormSaving"
					 @click="handleSave">
				{{ $t("buttons.send") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, namespace } from "Store/hr/modules/vacationApplication/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			isFormSaving: state => state.form.isSaving
		})
	},
	methods: {
		...mapActions({
			save: actionTypes.save
		}),
		async handleSave() {
			await this.save();
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
