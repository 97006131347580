import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import BankAccountApplicationsListRouteQuery
	from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListRouteQuery";
import BankAccountApplicationsListState from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListState";

export default class BankAccountApplicationsListRouteQueryService {
	defaultRouteQuery: BankAccountApplicationsListRouteQuery;

	constructor(defaultRouteQuery: BankAccountApplicationsListRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: BankAccountApplicationsListState) {
		let query = new BankAccountApplicationsListRouteQuery(
			state.paging.page,
			state.filter.loanApplicationNumber,
			state.filter.counterpartyId,
			state.filter.status
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(BankAccountApplicationsListRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
