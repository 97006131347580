import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";

export default class BankAccountApplicationsListFilter {
	loanApplicationNumber: string;
	counterpartyId: string;
	status: BankAccountApplicationStatusTypeEnum;

	constructor(
		loanApplicationNumber: string = "",
		counterpartyId: string = "",
		status: BankAccountApplicationStatusTypeEnum = BankAccountApplicationStatusTypeEnum.IN_PROGRESS
	)
	{
		this.loanApplicationNumber = loanApplicationNumber;
		this.counterpartyId = counterpartyId;
		this.status = status;
	}
}
