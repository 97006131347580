import { IMiddleware } from "@/types/core/middleware";
import { Route } from "vue-router/types/router";
import SecurityService from "@/services/auth/securityService";
import { RouteNames } from "@/router/routes";

const securityService = new SecurityService();

export default class AuthenticationMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		try {
			let access_token = await securityService.getToken();

			if(!access_token) {
				try {
					await securityService.signIn({}, { url: to.fullPath });
				} catch (e) {
					return { name: RouteNames.ERROR_INTERNAL_SERVER_ERROR };
				}
			}
		} catch (e) {
			console.error(e);
		}
	}
}
