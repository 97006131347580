<template>
	<frp-card is-loading>
		<template #content>
			<bar-card-content-layout style="padding-bottom: 15px !important;">
				<v-skeleton-loader width="100%" type="image" class="bar-messenger-loader rounded-lg"/>
			</bar-card-content-layout>
		</template>
		<template #actions>
			<frp-btn-loader width="20" height="20"/>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: {
		FrpBtnLoader,
		BarCardContentLayout,
		FrpCard
	}
};
</script>

<style lang="scss">
.bar-messenger-loader {
	height: 318px;

	.v-skeleton-loader__image.v-skeleton-loader__bone {
		height: 318px !important;
	}
}
</style>
