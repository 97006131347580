import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { ApiBankAccountData, ApiBankAccountDataMapper } from "@/api/bar/types/bankAccountData/apiBankAccountData";
import { ApiContractInfo, ApiContractInfoMapper } from "@/api/bar/types/apiContractInfo";
import { ApiChangeAgreementRequest, ApiChangeAgreementRequestMapper } from "@/api/bar/types/agreement/apiChangeAgreementRequest";
import { BankAccountApplicationInfo } from "@/store/bar/types/bankAccountApplicationInfo";
import { ApiAccountUsageItem } from "@/api/bar/types/apiAccountUsageItem";

export interface ApiChangeBankAccountApplicationInfoRequest {
	agreement: ApiChangeAgreementRequest;
	bankBranchId: string;
	contract: ApiContractInfo;
	accounts: ApiBankAccountData[];
	source?: SourceTypeEnum;
	accountUsages?: ApiAccountUsageItem[] | null;
}

export class ApiChangeBankAccountApplicationInfoRequestMapper {
	static map(source: BankAccountApplicationInfo): ApiChangeBankAccountApplicationInfoRequest {
		return {
			agreement: ApiChangeAgreementRequestMapper.map(source.agreement!),
			bankBranchId: source.bankBranchId,
			contract: ApiContractInfoMapper.map(source.contract),
			accounts: source.accounts.map(x => ApiBankAccountDataMapper.map(x)),
			source: source.source,
			accountUsages: source.accountUsages?.length ? source.accountUsages : null
		};
	}
}