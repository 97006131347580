<template>
	<v-row>
		<v-col class="px-4">
			<v-row>
				<v-col cols="auto" align-self="center">
					<span class="text-body-2 primary--text">{{ $t("content.atypicalContractCoordination") }}</span>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="auto" class="d-flex align-center justify-end">
					<div class="d-flex align-baseline">
						<frp-text-body-two class="grey--text mr-2">{{ $t("details.titles.responsible") }}:</frp-text-body-two>
						<frp-text-body-two class="primary--text mr-1">
							{{ lawyerStaffEmployees.find(x => x.id === legalDepartmentInfo.legalDepartmentStaffEmployeeId)?.fullName || "" }}
						</frp-text-body-two>
					</div>
					<frp-btn small
							 height="30"
							 :disabled="!isEditInfoAccessAllowed"
							 style="margin-left: -4px !important; margin-right: -6px !important"
							 :color="colors.blue.base"
							 @click="$emit('responsible-user-dialog:open')"
							 icon>
						<frp-icon src="ico_settings-account" :color="isEditInfoAccessAllowed ? colors.blue.base : colors.grey.base"></frp-icon>
					</frp-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="6" align-self="center">
					<template v-if="legalDepartmentInfo.isAccepted">
						<div class="d-flex align-center"
							 :style="{ cursor: isCurrentUserLegalDepartmentResponsiblePerson ? 'pointer' : 'default' }"
							 @click="handleResetStatusesForLegalDepartment">
							<frp-icon src="ico_accept-circle"
									  :color="colors.green.base"
									  class="mr-3">
							</frp-icon>
							<frp-text-body-two class="green--text mr-1">{{ $t("common.isAccepted") }}</frp-text-body-two>
						</div>
					</template>
					
					<template v-if="legalDepartmentInfo.isDeclined">
						<div class="d-flex align-center"
							 :style="{ cursor: isCurrentUserLegalDepartmentResponsiblePerson ? 'pointer' : 'default' }"
							 @click="handleResetStatusesForLegalDepartment">
							<frp-icon src="ico_decline-circle"
									  :color="colors.secondary.base"
									  class="mr-3">
							</frp-icon>
							<frp-text-body-two class="secondary--text mr-1">{{ $t("common.isDeclined") }}</frp-text-body-two>
						</div>
					</template>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="6" class="d-flex justify-end">
					<frp-btn elevation="0"
							 :color="colors.secondary.base"
							 :loading="isLegalDepartmentDeclining || isLegalDepartmentStatusesResetting"
							 :disabled="isLegalDepartmentAccepting || isLegalDepartmentActionsDisabled"
							 @click="declineLegalDepartment"
							 dark>
						{{ $t("buttons.decline") }}
					</frp-btn>
					<frp-btn elevation="0"
							 :color="colors.green.base"
							 :loading="isLegalDepartmentAccepting || isLegalDepartmentStatusesResetting"
							 :disabled="isLegalDepartmentDeclining || isLegalDepartmentActionsDisabled"
							 @click="acceptLegalDepartment"
							 dark>
						{{ $t("buttons.setAccept") }}
					</frp-btn>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import { mutationTypes, actionTypes } from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import {
	actionTypes as bankAccountApplicationMessengerActionTypes
} from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { getterTypes as bankAccountApplicationGetterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { getterTypes as barUserGetterTypes } from "@/store/bar/modules/user/types";
import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import storeManager from "@/store/manager";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);
const bankAccountApplicationDocumentsHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplicationDocuments.namespace);
const bankAccountApplicationMessengerHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplicationMessenger.namespace);

const barUserHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		isEditInfoAccessAllowed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountApplicationDocumentTypeEnum
		};
	},
	computed: {
		...barUserHelpers.mapState({
			user: state => state.user
		}),
		...barUserHelpers.mapGetters({
			isBarUserAdministrator: barUserGetterTypes.isBarUserAdministrator
		}),
		...bankAccountApplicationHelpers.mapState({
			editableItem: state => state.editableItem
		}),
		...bankAccountApplicationHelpers.mapGetters({
			lawyerStaffEmployees: bankAccountApplicationGetterTypes.lawyerStaffEmployees
		}),
		...bankAccountApplicationDocumentsHelpers.mapState({
			bankAccountApplicationDocuments: state => state.documentFileInfoItems
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			isLegalDepartmentRequestLoading: state => state.isLegalDepartmentRequestLoading,
			legalDepartmentDocumentFileMeta: state => state.legalDepartmentDocumentFileMeta,
			isLegalDepartmentDeclining: state => state.isLegalDepartmentDeclining,
			isLegalDepartmentAccepting: state => state.isLegalDepartmentAccepting,
			isLegalDepartmentStatusesResetting: state => state.isLegalDepartmentStatusesResetting,
			legalDepartmentInfo: state => state.legalDepartmentInfo,
			legalDepartmentDocumentFileInfoItems: state => state.legalDepartmentDocumentFileInfoItems
		}),
		isLegalDepartmentDocumentFileMetaInfoExist() {
			return !!this.legalDepartmentDocumentFileMeta.id || this.legalDepartmentDocumentFileMeta.isLoading;
		},
		isLegalDepartmentStatusActive() {
			return this.legalDepartmentInfo.isDeclined || this.legalDepartmentInfo.isAccepted;
		},
		isCurrentUserLegalDepartmentResponsiblePerson() {
			return this.isBarUserAdministrator || this.isCurrentUserLegalDepartmentResponsibleEmployee;
		},
		isCurrentUserLegalDepartmentResponsibleEmployee() {
			return this.user.employeeId === this.legalDepartmentInfo.legalDepartmentStaffEmployeeId;
		},
		isLegalDepartmentActionsDisabled() {
			const directWithdrawalAgreement = this.bankAccountApplicationDocuments.find(x => x.type === BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT);
			const isDirectWithdrawalAgreementStoredFileIdNonExists = !directWithdrawalAgreement?.storedFileId;
			
			return isDirectWithdrawalAgreementStoredFileIdNonExists
				   || this.isLegalDepartmentStatusActive
				   || this.isLegalDepartmentDocumentFileMetaInfoExist
				   || !this.isCurrentUserLegalDepartmentResponsiblePerson;
		}
	},
	methods: {
		...mapMutations({
			setLegalDepartmentInfoIsAcceptedValue: mutationTypes.SET_LEGAL_DEPARTMENT_INFO_IS_ACCEPTED_VALUE,
			setLegalDepartmentInfoIsDeclinedValue: mutationTypes.SET_LEGAL_DEPARTMENT_INFO_IS_DECLINED_VALUE
		}),
		...mapActions({
			sendLegalDepartmentRequest: actionTypes.sendLegalDepartmentRequest,
			acceptLegalDepartment: actionTypes.acceptLegalDepartment,
			declineLegalDepartment: actionTypes.declineLegalDepartment,
			resetStatusesForLegalDepartment: actionTypes.resetStatusesForLegalDepartment
		}),
		...bankAccountApplicationMessengerHelpers.mapActions({
			fetchMessages: bankAccountApplicationMessengerActionTypes.fetchMessages
		}),
		async handleResetStatusesForLegalDepartment() {
			if(!this.isCurrentUserLegalDepartmentResponsiblePerson || this.isLegalDepartmentStatusesResetting) return;
			
			await this.resetStatusesForLegalDepartment();
			await this.fetchMessages();
		}
	},
	components: { FrpIcon, FrpTextBodyTwo, BarCardContentLayout, FrpBtn, FrpCard }
};
</script>

<style scoped lang="scss">
</style>
