import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import EmployeesState from "@/store/hr/modules/employees/types/employeesState";
import { findDescendants, flatSubsWithLevels } from "@/utils/array";

export default class EmployeesMapper {
	static mapToGetEmployeesParameters(source: EmployeesState): ApiHrGetEmployeesParameters {
		// Для передачи всех дочерних подразделений в фильтр
		const res: string[] = [...source.filter.departmentIds];
		
		source.filter.departmentIds.forEach(x => {
			res.push(...findDescendants(flatSubsWithLevels("departments", source.departments), x).map(x => x.id));
		});
		
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.filter.name,
			departmentIds: res
		};
	}
}
