<template>
	<v-progress-circular v-if="loading" style="margin-left: 18px" size="16" width="2" class="my-3 ml-0" color="primary" indeterminate/>
	<div v-else-if="name || meta.namespace">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<div class="d-flex align-center">
			<div @click="download" :style="{ cursor: id && downloadable ? 'pointer' : 'default' }" class="d-flex align-center">
				<frp-icon v-if="icon"
						  :src="srcIcon"
						  :color="colors.primary.darken1"
						  class="mr-3">
				</frp-icon>
				<span class="text-body-2 py-2"
					  style="color: var(--v-primary-darken1)"
					  :class="boldText ? 'font-weight-bold' : ''"
					  :style="{'text-decoration': underline ? 'underline' : '', fontSize, width}">
					{{ name || $t(`aliases.documents.${meta.namespace}`) }}
				</span>
			</div>
			<v-btn v-if="deletable && !uploading" x-small icon color="error" class="ml-3" @click="$emit('delete', name || id)">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-progress-circular v-if="uploading" style="margin-left: 18px" size="16" width="2" color="primary" indeterminate/>
			<div v-if="$slots['append-outer']" :class="$vuetify.breakpoint.lgAndUp ? 'ml-11' : 'ml-6'">
				<slot name="append-outer"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { BarStorageController } from "@/api/barStorage";
import { HrStorageController } from "@/api/hr/storage";
import { LoanStorageController } from "@/api/loanStorage";
import { ApplicationModeType } from "@/types/ApplicationModeType";
import { getApplicationMode } from "@/utils/configuration";
import { getFileExtension } from "@/utils/file";
import { StorageController } from "Api/storage";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { saveAs } from "file-saver";
import AbortService from "Services/abortService";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";

const abortService = new AbortService();

// По сути тот же FrpFile, но всегда (если downloadable)) запрашивает мета-данные,
// чтобы указать верное расширение при скачивании (для application/octet-stream).
// Если передан name, то к нему подставляется расширение из meta.name
export default {
	mixins: [colorsMixin],
	props: {
		deletable: Boolean,
		id: String,
		name: String,
		label: String,
		uploading: Boolean,
		required: Boolean,
		boldText: {
			type: Boolean,
			default: false
		},
		icon: {
			type: Boolean,
			default: true
		},
		downloadable: {
			type: Boolean,
			default: false
		},
		underline: {
			type: Boolean,
			default: false
		},
		fontSize: {
			type: String,
			default: ""
		},
		srcIcon: {
			type: String,
			default: "ico_file"
		},
		width: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			meta: {},
			loading: false,
			storageController: null
		};
	},
	methods: {
		async download() {
			if(!this.id || !this.downloadable) return;
			
			try {
				this.loading = true;
				
				const file = await this.storageController.getFile(this.id);
				
				const extension = getFileExtension(this.meta.name);
				
				saveAs(file, (this.name || this.meta.name) + extension);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.loading = false;
			}
		},
		async getMeta() {
			this.loading = true;
			
			try {
				this.meta = await this.storageController.getFileMeta(this.id);
				
				this.loading = false;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		initializeController() {
			const mode = getApplicationMode();
			
			switch (mode) {
				case ApplicationModeType.OLK:
					this.storageController = new StorageController(abortService);
					break;
				case ApplicationModeType.HR:
					this.storageController = new HrStorageController(abortService);
					break;
				case ApplicationModeType.BAR:
					this.storageController = new BarStorageController(abortService);
					break;
				case ApplicationModeType.LOAN:
					this.storageController = new LoanStorageController(abortService);
					break;
				default:
					throw Error("FrpFile не работает в этом модуле");
			}
		}
	},
	created() {
		this.initializeController();
		
		abortService.initialize();
		
		if(this.id && this.downloadable) this.getMeta();
	},
	components: {
		FrpIcon
	}
};
</script>

<style scoped>

</style>
