<template>
	<frp-dialog :value="value"
				:title="dialogName ? $t(`dialogs.${dialogName}.title`) : ''"
				max-width="1280"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<bar-bank-account-application-messenger @message:sent="handleMessageSent"/>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="$emit('update:value')"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { MessageType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/MessageType";
import storeManager from "@/store/manager";
import { namespace } from "Store/hr/modules/vacationPlans";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import BarBankAccountApplicationMessenger
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessenger.vue";
import { createNamespacedHelpers } from "vuex";

const bankAccountApplicationMessengerHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplicationMessenger.namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		dialogName: String,
		value: Boolean,
		messageType: {
			type: MessageType,
			default: MessageType.Unknown
		}
	},
	data() {
		return {
			namespace
		};
	},
	methods: {
		async handleMessageSent() {
			await this.$emit("message:sent");
			this.$emit("update:value", false);
		},
		...bankAccountApplicationMessengerHelpers.mapMutations({
			setEditableMessageItemMessageType: mutationTypes.SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TYPE
		})
	},
	created() {
		this.setEditableMessageItemMessageType(this.messageType);
	},
	components: {
		BarBankAccountApplicationMessenger,
		FrpDialog,
		FrpBtn
	}
};
</script>
