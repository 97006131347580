import { ApiHrPersonnelPartition } from "@/api/hr/types/personnelDocument/apiHrPersonnelPartition";
import FormState from "@/store/shared/form/models/formState";
import { ApiHrCreateDocumentRequest } from "@/api/hr/types/personnelDocument/apiHrCreateDocumentRequest";
import { ApiHrCreatePartitionRequest } from "@/api/hr/types/personnelDocument/apiHrCreatePartitionRequest";
import { ApiHrPersonnelDocument } from "@/api/hr/types/personnelDocument/apiHrPersonnelDocument";
import IPageState from "@/store/shared/base/types/pageState";

export default class PersonnelDocumentsState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public personnelPartitions: ApiHrPersonnelPartition[] = [],
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public isFileMetaLoading: boolean = false,
		public isProcessingLoading: boolean = false,
		public isCreateMode: boolean = false,
		public selectedPersonnelPartition: ApiHrPersonnelPartition = {} as ApiHrPersonnelPartition,
		public payloadPartition: ApiHrCreatePartitionRequest = { title: "" },
		public payloadDocument: ApiHrCreateDocumentRequest = { title: "", partitionId: "", tempFileId: "" },
		public selectedPersonnelDocument: ApiHrPersonnelDocument = {} as ApiHrPersonnelDocument
	)
	{
	}
}
