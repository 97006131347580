import { PageModeType } from "@/store/bar/types/pageModeType";

export default class RootState {
	pageMode: PageModeType;
	isAppLoading: boolean;

	constructor(pageMode: PageModeType, isAppLoading: boolean = true) {
		this.pageMode = pageMode;
		this.isAppLoading = isAppLoading;
	}
}
