import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { ApiHrRoleEnum } from "@/api/hr/types/ApiHrRoleEnum";
import IPageState from "@/store/shared/base/types/pageState";

export default class UserState implements IPageState {
	constructor(
		public user: ApiHrEmployee = {} as ApiHrEmployee,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isUserLoading: boolean = false,
		public roles: ApiHrRoleEnum[] = []
	)
	{
	}
}
