import { RouteNames } from "@/router/bar/routes";
import { Permissions } from "@/constants/permissions";

const routeToPermissionsMap = new Map<RouteNames, Permissions[]>([
		[RouteNames.ROOT, []],
		[RouteNames.CALLBACK, []],
		[RouteNames.SILENT_RENEW, []],
		[RouteNames.ERROR_PAGE_NOT_FOUND, []],
		[RouteNames.ERROR_ACCESS_DENIED, []],
		[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],
		
		[RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST, [Permissions.BAR_APPLICATION_REGISTRY_READ]],
		
		[RouteNames.BANK_ACCOUNT_APPLICATION, [Permissions.BAR_APPLICATION_READ]],
		[RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE, [Permissions.BAR_APPLICATION_UPDATE]],
		[
			RouteNames.BANK_ACCOUNT_APPLICATION_CREATE,
			[Permissions.BAR_APPLICATION_CREATE, Permissions.BAR_APPLICATION_CREATE_READ, Permissions.BAR_APPLICATION_CREATE_CREATE ]
		],
		
		[RouteNames.BANK_ACCOUNTS_LIST, [Permissions.BAR_ACCOUNT_REGISTRY_READ]],
		
		[RouteNames.BANK_ACCOUNT, [Permissions.BAR_ACCOUNT_READ]],
		[RouteNames.BANK_ACCOUNT_CREATE, [Permissions.BAR_ACCOUNT_CREATE]]
	]
);

export default routeToPermissionsMap;
