<template>
	<div v-if="isInitialized">
		<frp-btn v-if="permissions.includes(Permissions.HR_EXPECTATION_CREATE)"
				 @click="isDialogOpened = true" dense color="blue" dark elevation="0">
			{{ $t("buttons.createExpectation") }}
		</frp-btn>
		
		<frp-dialog content-class="hr-dialog" max-width="695" v-model="isDialogOpened" :title="$t('dialogs.expectations.title')">
			<template #content="{ onIntersect }">
				<frp-text-field autocomplete="off"
								v-model="query"
								:placeholder="$t('placeholders.search')"
								class="white hr-search-field">
					<template #append>
						<frp-icon class="mt-3" width="18" height="18" :color="colors.blue.base" src="ico_search"></frp-icon>
					</template>
				</frp-text-field>
				
				<div class="d-flex flex-column mt-3 primary--text text--darken-1">
					<div class="d-flex flex-column mb-9" v-for="(item, i) in filteredItems" :key="i">
						<span class="mb-6" style="font-weight: 600; font-size: 20px">{{ item.title }}</span>
						<div class="d-flex flex-column" style="gap: 10px; font-size: 18px">
							<a v-for="link in item.links" :key="link.text" target="_blank" :href="link.href" class="text-decoration-none">
								<v-card class="kpi-card px-7 py-3 primary--text text--darken-1">
									<div class="colored-side-line" :class="item.color" style="width: 7px"></div>
									{{ link.text }}
								</v-card>
							</a>
						</div>
					</div>
				</div>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="isDialogOpened = false"
						 color="primary">
					{{ $t("buttons.close") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</div>
	<div v-else class="fill-height d-flex align-center justify-center">
		<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</div>
</template>

<script>
import router from "@/router/hr";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import HrCard from "Components/cards/HrCard";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import { externalUrls } from "Constants/hr/externalUrls";
import { Permissions } from "Constants/permissions";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/hr/routes";
import { actionTypes } from "Store/hr/modules/employee/modules/expectations/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.hr.employee.expectations.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);
const { mapState: employeeMapState } = createNamespacedHelpers(storeManager.hr.employee.namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			RouteNames,
			Permissions,
			namespace,
			isDialogOpened: false,
			query: "",
			items: [
				{
					title: this.$t("content.expectations.titles.drp"),
					color: "accent",
					links: [
						{
							text: this.$t("content.expectations.links.vacationRequest"),
							href: router.resolve({ name: RouteNames.VACATION_APPLICATIONS }).href
						},
						{
							text: this.$t("content.expectations.links.internshipRequest"),
							href: externalUrls.expectations.internshipRequest
						},
						{
							text: this.$t("content.expectations.links.businessTripRequest"),
							href: externalUrls.expectations.businessTripRequest
						}
					]
				}
				// {
				// 	title: this.$t("content.expectations.titles.it"),
				// 	color: "blue",
				// 	links: [
				// 		{
				// 			text: this.$t("content.expectations.links.itTechnicalSupport"),
				// 			href: externalUrls.expectations.itTechnicalSupport
				// 		}
				// 	]
				// },
				// {
				// 	title: this.$t("content.expectations.titles.legalDepartment"),
				// 	color: "green lighten-4",
				// 	links: [
				// 		{
				// 			text: this.$t("content.expectations.links.lawyersRequest"),
				// 			href: externalUrls.expectations.lawyersRequest
				// 		}
				// 	]
				// },
				// {
				// 	title: this.$t("content.expectations.titles.def"),
				// 	color: "blue lighten-5",
				// 	links: [
				// 		{
				// 			text: this.$t("content.expectations.links.vendorContractPaymentRequest"),
				// 			href: externalUrls.expectations.vendorContractPaymentRequest
				// 		}
				// 	]
				// },
				// {
				// 	title: this.$t("content.expectations.titles.dmkp"),
				// 	color: "orange darken-1",
				// 	links: [
				// 		{
				// 			text: this.$t("content.expectations.links.borrowerSettlementsRequest"),
				// 			href: externalUrls.expectations.borrowerSettlementsRequest
				// 		}
				// 	]
				// }
			]
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized
		}),
		...employeeMapState({
			permissions: state => state.permissions
		}),
		filteredItems() {
			if(!this.query)
				return this.items;
			
			const checkIsMatched = (value) => value.toLowerCase().includes(this.query.toLowerCase());
			
			const filteredGroups = this.items.filter(x => checkIsMatched(x.title) || x.links.some(y => checkIsMatched(y.text)));
			
			return filteredGroups.map(x => ({
				...x,
				links: checkIsMatched(x.title) ? x.links : x.links.filter(y => checkIsMatched(y.text))
			}));
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: { FrpIcon, FrpBottomSpace, FrpTextField, FrpDialog, FrpLinkBtn, FrpBtn, FrpRouterLinkBtn, HrCard }
};
</script>
