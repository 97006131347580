<template>
	<div>
		<frp-dialog :value="value"
					:title="$t(`dialogs.${mode.title}.title`)"
					max-width="490"
					persistent
					v-if="!openConfirmDelete"
					@update:value="$emit('update:value', $event)">
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-model="formValid" v-intersect="onIntersect">
					<frp-text-field v-model="title"
									required
									:color="colors.grey.base"
									:label="$t('fields.createPartition.label')"
									:placeholder="$t('fields.createPartition.placeholder')">
					</frp-text-field>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 color="primary"
						 @click="handleClose">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn v-if="!isCreateMode"
						 color="secondary"
						 @click="openConfirmDelete = true">
					{{ $t("buttons.delete") }}
				</frp-btn>
				<frp-btn elevation="0"
						 dark
						 color="blue"
						 :disabled="isProcessingLoading || !formValid"
						 :loading="isProcessingLoading"
						 @click="handlePartition">
					{{ $t(`buttons.${mode.action}`) }}
				</frp-btn>
			</template>
		
		</frp-dialog>
		<hr-confirm-delete-dialog v-model="openConfirmDelete"
								  :title="dialogs.title"
								  :text="dialogs.text"
								  :loading="isProcessingLoading"
								  @submit="handleDelete"
								  @cancel="openConfirmDelete = false">
		</hr-confirm-delete-dialog>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { getterTypes } from "@/store/bar/modules/bankAccount/types";
import { namespace } from "@/store/hr/modules/personnelDocuments";
import { actionTypes, mutationTypes } from "@/store/hr/modules/personnelDocuments/types";
import HrConfirmDeleteDialog from "@/views/hr/personnelDocuments/dialogs/HrConfirmDeleteDialog.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, formMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			namespace,
			openConfirmDelete: false
		};
	},
	computed: {
		...mapState({
			isProcessingLoading: state => state.isProcessingLoading,
			isCreateMode: state => state.isCreateMode,
			selectedPersonnelPartition: state => state.selectedPersonnelPartition,
			payload: state => state.payloadPartition,
			state: state => state
		}),
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges
		}),
		dialogs() {
			return {
				title: this.$t(`dialogs.deletePartition.title`),
				text: this.$t(`dialogs.deletePartition.text`).replace("{{namePartition}}", this.selectedPersonnelPartition?.title)
			};
		},
		title: {
			get() {
				return this.payload.title;
			},
			set(value) {
				this.setPayloadTitle(value);
			}
		},
		mode() {
			if(this.isCreateMode) {
				return {
					title: "createPartition",
					action: "add"
				};
			} else {
				return {
					title: "updatePartition",
					action: "save"
				};
			}
		}
	},
	methods: {
		...mapMutations({
			setSelectedPersonnelPartition: mutationTypes.SET_SELECTED_PERSONNEL_PARTITION,
			setPayload: mutationTypes.SET_PAYLOAD_PARTITION,
			setPayloadTitle: mutationTypes.SET_PAYLOAD_PARTITION_TITLE,
			resetPayload: mutationTypes.RESET_PAYLOAD_PARTITION
		}),
		...mapActions({
			processingPartition: actionTypes.processingPartition,
			deletePartition: actionTypes.deletePartition
		}),
		async handleDelete() {
			await this.deletePartition({ id: this.selectedPersonnelPartition?.id });
			
			this.handleClose();
		},
		async handlePartition() {
			await this.processingPartition({
				id: this.selectedPersonnelPartition?.id, payload: {
					...this.payload,
					order: this.selectedPersonnelPartition?.order
				}
			});
			
			this.handleClose();
		},
		handleClose() {
			this.setSelectedPersonnelPartition({});
			this.resetPayload();
			this.$emit("update:value", false);
		}
	},
	created() {
		this.setPayloadTitle(this.selectedPersonnelPartition?.title);
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		HrConfirmDeleteDialog,
		FrpConfirmDialog,
		FrpTextField,
		FrpTextarea,
		FrpDialog,
		FrpBtn
	}
};
</script>
