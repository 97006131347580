<template>
	<tr>
		<td>
			<frp-autocomplete v-model="accountType"
							  :hint="bankAccountTypesHint"
							  :items="bankAccountTypes"
							  item-text="title"
							  item-value="code"
							  color="blue"
							  class="bar-field"
							  hide-details
							  top
							  required
							  :disabled="isBankAccountApplicationAccountsFormDisabled"
							  :placeholder="$t('fields.bankAccountType.label')">
			</frp-autocomplete>
		</td>
		<td>
			<frp-text-field v-model="accountNumber"
							hide-details
							:mask="masks.bankAccountNumber"
							:rules="validation.bankAccountNumber"
							:errors="bankAccountNumberErrors"
							:loading="account.isAccountUniqueCheckInProgress"
							:disabled="!bik || isBankAccountApplicationAccountsFormDisabled"
							required
							class="bar-field"
							:placeholder="$t('fields.bankAccountNumber.label')">
			</frp-text-field>
		</td>
		<td>
			<frp-autocomplete v-model="currencyId"
							  :items="filteredCurrencies"
							  item-text="russianName"
							  item-value="id"
							  color="blue"
							  class="bar-field"
							  hide-details
							  top
							  required
							  :disabled="isBankAccountApplicationAccountsFormDisabled"
							  :placeholder="$t('fields.currency.label')">
			</frp-autocomplete>
		</td>
		<td>
			<frp-date-field v-model="openedAt"
							hide-details
							class="bar-field"
							bar-date-field
							required
							:disabled="isBankAccountApplicationAccountsFormDisabled"
							:placeholder="$t('fields.date.label')">
			</frp-date-field>
		</td>
		<td>
			<frp-btn
				without-padding
				small
				icon
				height="28"
				color="blue"
				dark
				:disabled="editableItem.accounts.length <= 1 || isBankAccountApplicationAccountsFormDisabled"
				@click="removeEditableItemAccountsItem(account)">
				<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
			</frp-btn>
		</td>
	</tr>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { masks } from "@/constants/masks";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import BatchService from "@/services/batchService";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { CurrencyTypeEnum } from "@/store/bar/types/CurrencyTypeEnum";
import { prepareBankAccountNumberValidationRule, prepareBankAccountRule, requiredRule } from "@/utils/validation";
import { validateBankAccountNumber } from "@/utils/validator";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const requestBatchService = new BatchService(({ interval: 500 }));
export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		account: Object
	},
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountApplicationStatusTypeEnum,
			BankAccountTypeEnum,
			CurrencyTypeEnum,
			masks,
			validateBankAccountNumber,
			validation: {
				bankAccountNumber: [requiredRule(), prepareBankAccountNumberValidationRule(() => this.bik)]
			}
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			bik: state => state.bik,
			bankAccountTypes: state => state.bankAccountTypes,
			currencies: state => state.currencies,
			editableItem: state => state.editableItem,
			isBankAccountApplicationAccountsFormDisabled: state => state.isBankAccountApplicationAccountsFormDisabled
		}),
		bankAccountTypesHint() {
			return { key: "code", text: this.$t("tooltips.bankAccount") }
		},
		filteredCurrencies() {
			if(this.account.accountType === BankAccountTypeEnum.PRIMARY)
				return this.currencies.filter(x => x.code === CurrencyTypeEnum.RUB);
			
			return this.currencies;
		},
		accountType: {
			get() {
				return this.account.accountType;
			},
			set(value) {
				this.setAccountType(value);
			}
		},
		accountNumber: {
			get() {
				return this.account.accountNumber;
			},
			set(value) {
				this.setAccountNumber(value);
			}
		},
		currencyId: {
			get() {
				return this.account.currencyId;
			},
			set(value) {
				this.setEditableItemAccountsItemAccountCurrencyId({ id: this.account.id, value });
			}
		},
		openedAt: {
			get() {
				return this.account.openedAt;
			},
			set(value) {
				this.setEditableItemAccountsItemAccountOpenedAt({ id: this.account.id, value });
			}
		},
		bankAccountNumberErrors() {
			return this.account.isAccountUnique ? [] : [`${this.$t("alerts.errors.bankAccountNumberAlreadyExists")}`];
		}
	},
	methods: {
		...mapMutations({
			setEditableItemAccountsItemAccountType: mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_TYPE,
			setEditableItemAccountsItemAccountNumber: mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_NUMBER,
			setEditableItemAccountsItemAccountCurrencyId: mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_CURRENCY_ID,
			setEditableItemAccountsItemAccountOpenedAt: mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_OPENED_AT,
			removeEditableItemAccountsItem: mutationTypes.REMOVE_EDITABLE_ITEM_ACCOUNTS_ITEM
		}),
		...mapActions({
			checkIsAccountValid: actionTypes.checkIsAccountValid
		}),
		setAccountType(value) {
			if(value === BankAccountTypeEnum.PRIMARY) {
				const rubCurrency = this.currencies.find(x => x.code === CurrencyTypeEnum.RUB);
				this.setEditableItemAccountsItemAccountCurrencyId({ id: this.account.id, value: rubCurrency?.id || "" });
			}
			
			this.setEditableItemAccountsItemAccountType({ id: this.account.id, value });
		},
		setAccountNumber(value) {
			this.setEditableItemAccountsItemAccountNumber({ id: this.account.id, value });
			
			if(validateBankAccountNumber(value, this.bik))
				requestBatchService.push(async () => await this.checkIsAccountValid(this.account.id));
		}
	},
	components: { FrpIcon, FrpDateField, FrpTextField, FrpAutocomplete, FrpBtn, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
