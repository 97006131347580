import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";
import VacationPlansFilter from "@/store/hr/modules/vacationPlans/types/vacationPlansFilter";
import { HrNewVacation, HrNewVacationService } from "@/types/hr/vacation/hrNewVacation";
import { HrAcceptPlanRequest, HrAcceptPlanRequestService } from "@/types/hr/vacation/hrAcceptPlanRequest";
import { HrVacationPlansPageModeEnum } from "@/store/hr/modules/vacationPlans/types/HrVacationPlansPageModeEnum";
import { HrVacationPlansItem } from "@/types/hr/vacation/hrVacationPlansItem";
import { HrVacationPlan } from "@/types/hr/vacation/hrVacationPlan";
import { HrVacation } from "@/types/hr/vacation/hrVacation";

export default class VacationPlansState implements IPageState {
	constructor(
		public listing: ListingModel<HrVacationPlansItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: VacationPlansFilter,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isSavingAndApproving: boolean = false,
		public isLoading: boolean = false,
		public isYearsLoading: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public isEmployeesLoading: boolean = false,
		public years: ApiHrVacationYear[] = [],
		public departments: ApiHrDepartment[] = [],
		public employees: ApiHrEmployeesItem[] = [],
		public mode: HrVacationPlansPageModeEnum = HrVacationPlansPageModeEnum.READ,
		public isWithdrawing: boolean = false,
		public isDraftDeleting: boolean = false,
		public isSendingToReview: boolean = false,
		public isApproving: boolean = false,
		public isExporting: boolean = false,
		public isDeclining: boolean = false,
		public isNewVacationCreating: boolean = false,
		public editableVersionId: string = "",
		public editableVacation: HrNewVacation = HrNewVacationService.getEmpty(),
		public editableVacationIndex: number | null = null,
		public newVacations: HrNewVacation[] = [],
		public parentVacations: HrVacation[] = [],
		public acceptPlanRequest: HrAcceptPlanRequest = HrAcceptPlanRequestService.getEmpty(),
		public currentUserPlans: HrVacationPlan[] = []
	)
	{
	}
}
