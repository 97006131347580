import { convertToTimestamp } from "@/utils/dates";
import { ApiHrAvailableVacation } from "@/api/hr/types/vacationApplication/apiHrAvailableVacation";

export interface HrAvailableVacation {
	id: string;
	startDate: number;
	endDate: number;
	days: number;
	vacationsPlanId: string;
}

export class HrAvailableVacationService {
	static map(source: ApiHrAvailableVacation): HrAvailableVacation {
		return {
			...source,
			startDate: convertToTimestamp(source.startDate) as number,
			endDate: convertToTimestamp(source.endDate) as number
		};
	}
}
