import { render, staticRenderFns } from "./BarContentLayout.vue?vue&type=template&id=e2211a48&scoped=true"
import script from "./BarContentLayout.vue?vue&type=script&lang=js"
export * from "./BarContentLayout.vue?vue&type=script&lang=js"
import style0 from "./BarContentLayout.vue?vue&type=style&index=0&id=e2211a48&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2211a48",
  null
  
)

export default component.exports