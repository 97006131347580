<template>
	<v-container fluid>
		<v-row class="bar-breadcrumbs-wrapper px-0">
			<v-col class="pt-3 pb-3 px-8 d-flex justify-space-between">
				<v-responsive style="min-height: 14px">
						<template v-if="isLoading">
							<div class="d-flex align-center">
							<frp-text-loader width="100" height="14" class="mr-5"/>
							<frp-text-loader width="200" height="14"/>
							</div>
						</template>
						<template v-else>
							<v-breadcrumbs :items="items" class="pa-0 bar-breadcrumbs text-subtitle-2"></v-breadcrumbs>
						</template>
				</v-responsive>
				
				<v-spacer></v-spacer>
				
				<template v-if="isBankAccountApplicationPage && bankAccountApplicationsListLink">
					<div v-if="isLoading" class="d-flex align-center">
						<frp-text-loader width="150" height="14"/>
					</div>
					<frp-text-btn v-else
								  class="pa-0 bar-breadcrumbs-link"
								  :to="bankAccountApplicationsListLink"
								  color="blue">
						<span>{{ $t("buttons.bankAccountApplicationsListByProjectNumber") }}</span>
					</frp-text-btn>
				</template>
			</v-col>
		</v-row>
	</v-container>

</template>

<script>
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import { RouteNames } from "@/router/bar/routes";
import FrpBreadcrumbsLoader from "Components/loaders/common/FrpBreadcrumbsLoader";
import FrpTitleLoader from "Components/loaders/common/FrpTitleLoader";
import authorizationMixin from "Mixins/authorizationMixin";
import breadcrumbsTypes from "Store/bar/modules/breadcrumbs/types";
import { mapInstanceActions, mapInstanceState } from "Utils/vuexMapInstanse";

export default {
	mixins: [authorizationMixin],
	props: {
		namespace: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		...mapInstanceState({
			isLoading: state => state.isLoading,
			items: state => state.items.map(x => ({
				...x,
				exact: true
			}))
		}),
		isShown() {
			return this.isLoading || this.items.length;
		},
		isBankAccountApplicationPage() {
			return this.$route.name === RouteNames.BANK_ACCOUNT_APPLICATION 
			|| this.$route.name === RouteNames.BANK_ACCOUNT_APPLICATION_CREATE 
		    || this.$route.name === RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE;
		},
		bankAccountApplicationsListLink() {
			switch (this.$route.name) {
				case RouteNames.BANK_ACCOUNT_APPLICATION:
				case RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE:
				case RouteNames.BANK_ACCOUNT_APPLICATION_CREATE:
					return { name: RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST, query: { loanApplicationNumber: this.$route.query.loanApplicationNumber } }
			}
		}
	},
	methods: {
		...mapInstanceActions({
			processRoute: breadcrumbsTypes.actionTypes.processRoute
		})
	},
	components: { FrpTextBtn, FrpTextLoader, FrpBreadcrumbsLoader, FrpTitleLoader }
};
</script>

<style lang="scss">
.bar-breadcrumbs-wrapper {
	background: var(--v-white-base);
}

.bar-breadcrumbs {
	font-size: 10px !important;

	.v-breadcrumbs__item {
		color: var(--v-blue-base) !important;

		&--disabled {
			color: var(--v-primary-base) !important;
		}
	}

	.v-breadcrumbs__divider {
		color: var(--v-primary-base) !important;
		padding: 0 8px !important;
	}
}

.bar-breadcrumbs-link {
  .v-btn__content {
	font-size: 0.875rem !important;
	font-family: "Ubuntu", sans-serif !important;
	letter-spacing: 0.0015em !important;
	line-height: 100% !important;
	color: var(--v-blue-base) !important;
	font-weight: 500;
  }
}
</style>
