<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.plannedVacation.title')"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-mutate="onIntersect" v-intersect="onIntersect" v-model="formValid" :readonly="isFormSaving">
				<hr-range-date-field v-model="datesRange"
									 @select:firstDate="isNewPeriodEditing = $event"
									 :holidays="holidays"
									 :max-days="allowedNewVacationDaysCount"
									 :disabled-periods="disabledPeriods"
									 :year="filter.year"
									 required
									 :label="$t('fields.vacationNewPeriod.label')"
									 :placeholder="$t('fields.vacationNewPeriod.placeholder')"
									 type="number">
				</hr-range-date-field>
				
				<bod-autocomplete item-text="title"
								  item-value="id"
								  v-model="editableVacationPreviousVacationId"
								  class="kpi-field"
								  v-if="parentVacations.length"
								  :items="parentVacationItems"
								  color="blue"
								  required
								  :label="$t('fields.vacationParentPeriod.label')"
								  :placeholder="$t('fields.vacationParentPeriod.placeholder')">
				</bod-autocomplete>
				
				<frp-text-field class="kpi-field"
								:value="daysCount"
								type="number"
								color="blue"
								disabled
								:label="$t('fields.vacationDays.label')">
				</frp-text-field>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="handleCancel"
					 class="mr-2"
					 no-margin
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn dark
					 elevation="0"
					 class="mr-2"
					 no-margin
					 @click="handleDelete"
					 color="secondary">
				{{ $t("buttons.delete") }}
			</frp-btn>
			<frp-btn elevation="0"
					 @click="save"
					 color="blue"
					 :disabled="!formValid || isNewPeriodEditing"
					 no-margin
					 dark>
				{{ $t("buttons.save") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import HrRangeDateField from "@/components/fields/HrRangeDateField.vue";
import formMixin from "@/mixins/formMixin";
import { getVacationsWithDays } from "@/store/hr/helpers/calculateVacationDays";
import { namespace } from "@/store/hr/modules/vacationPlans";
import { convertToTimestamp } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { differenceInDays, format, isWithinInterval } from "date-fns";
import { cloneDeep, isEqual } from "lodash";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		employee: Object
	},
	data() {
		return {
			RouteNames,
			namespace,
			isNewPeriodEditing: false,
			internalDatesRange: [0, 0]
		};
	},
	computed: {
		...mapState({
			filter: state => state.filter,
			newVacations: state => state.newVacations,
			editableVacation: state => state.editableVacation,
			editableVacationIndex: state => state.editableVacationIndex,
			parentVacations: state => state.parentVacations,
			isNewVacationCreating: state => state.isNewVacationCreating
		}),
		...mapGetters({
			allowedNewVacationDaysCount: getterTypes.allowedNewVacationDaysCount,
			holidays: getterTypes.holidays
		}),
		parentVacationItems() {
			const filteredWithMaxDays = this.parentVacations.filter(x => {
                if(x.id === this.editableVacationPreviousVacationId)
                    return true;
                
				const children = this.newVacations.filter(y => y.previousVacationId === x.id);
				
				return children.reduce((acc, v) => acc + v.days, 0) < x.days;
			});
			
			return filteredWithMaxDays.map(x => ({
				...x,
				title: `${format(x.startDate, dateFormat)} - ${format(x.endDate, dateFormat)}`
			}));
		},
		disabledPeriods() {
			return this.newVacations.filter((_, i) => i !== this.editableVacationIndex).map(x => [x.startDate, x.endDate]);
		},
		editableVacationPreviousVacationId: {
			get() {
				return this.editableVacation.previousVacationId;
			},
			set(value) {
				this.setEditableVacationPreviousVacationId(value);
			}
		},
		datesRange: {
			get() {
				return this.internalDatesRange;
			},
			set(value) {
				this.internalDatesRange = cloneDeep(value);
			}
		},
		daysCount() {
			const [start, end] = this.datesRange;
			
			if(!start || !end)
				return 0;
			
			const holidaysCount = this.holidays.filter(x => isWithinInterval(convertToTimestamp(x), { start, end })).length;
			
			return differenceInDays(end, start) + 1 - holidaysCount;
		}
	},
	methods: {
		...mapMutations({
			removeNewVacationItem: mutationTypes.REMOVE_NEW_VACATIONS_ITEM,
			setEditableVacationPreviousVacationId: mutationTypes.SET_EDITABLE_VACATION_PREVIOUS_VACATION_ID,
			setEditableVacationEndDate: mutationTypes.SET_EDITABLE_VACATION_END_DATE,
			setEditableVacationStartDate: mutationTypes.SET_EDITABLE_VACATION_START_DATE,
			resetEditableVacation: mutationTypes.RESET_EDITABLE_VACATION,
			setEditableVacationIndex: mutationTypes.SET_EDITABLE_VACATION_INDEX,
			addNewVacationsItem: mutationTypes.ADD_NEW_VACATIONS_ITEM,
			removeNewVacationsItem: mutationTypes.REMOVE_NEW_VACATIONS_ITEM,
			setIsNewVacationCreating: mutationTypes.SET_IS_NEW_VACATION_CREATING
		}),
		handleClose() {
			this.resetEditableVacation();
			this.setEditableVacationIndex(null);
			this.setIsNewVacationCreating(false);
			this.$emit("update:value", false);
		},
		handleDelete() {
			this.removeNewVacationItem(this.editableVacationIndex);
			this.handleClose();
		},
		handleCancel() {
			if(this.isNewVacationCreating)
				this.removeNewVacationItem(this.editableVacationIndex);
			
			this.handleClose();
		},
		async save() {
			const [startDate, endDate] = this.datesRange;
			
			this.setEditableVacationStartDate(startDate);
			this.setEditableVacationEndDate(endDate);
			
			if(this.newVacations.some(x => isEqual(x, this.editableVacation))) {
				this.handleClose();
				
				return;
			} else {
				const vacation = getVacationsWithDays([{
                    ...this.editableVacation,
                    id: ""
                }], this.holidays)[0];

				await this.removeNewVacationsItem(this.editableVacationIndex);
				await this.addNewVacationsItem(vacation);
			}
			
			const parentIdx = this.newVacations.findIndex(x => x.id === this.editableVacation.previousVacationId);
			
			if(this.parentVacations.length && parentIdx !== -1)
				await this.removeNewVacationsItem(parentIdx);
			
			this.handleClose();
		}
	},
	async mounted() {
		if(this.isNewVacationCreating)
			this.datesRange = [this.editableVacation.startDate, this.editableVacation.endDate];
		
		await this.$nextTick();
		this.$refs[this.refs.form].resetValidation();
	},
	watch: {
		async editableVacationPreviousVacationId(value) {
			if(value) {
				await this.$nextTick();
				
				this.$refs.form.validate();
			} else {
				await this.$nextTick();
				
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		HrRangeDateField,
		BodAutocomplete,
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
