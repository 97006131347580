<template>
	<div class="d-flex flex-column text-caption mr-2" style="font-weight: 600 !important; row-gap: 8px">
		<slot></slot>
	</div>
</template>

<script>
export default {
};
</script>
