export interface ApiBankAccountApplicationLegalDepartmentInfo {
	legalDepartmentStaffEmployeeId: string;
	isAccepted: boolean;
	isDeclined: boolean;
}

export class ApiBankAccountApplicationLegalDepartmentInfoHelper {
	static getEmpty(): ApiBankAccountApplicationLegalDepartmentInfo {
		return {
			legalDepartmentStaffEmployeeId: "",
			isAccepted: false,
			isDeclined: false
		};
	}
}