import { IsArray, IsString } from "class-validator";

export default class EmployeesFilter {
	@IsString()
	name: string;

	@IsArray()
	departmentIds: string[];

	constructor(
		name: string = "",
		departmentIds: string[] = []
	)
	{
		this.name = name;
		this.departmentIds = departmentIds;
	}
}
