<template>
	<frp-document-dialog :value="value"
						 persistent
						 :maxWidth="848"
						 @update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }">
			<div v-mutate="onIntersect" v-intersect="onIntersect">
				<vue-pdf-embed v-if="!isFileLoading" style="max-width: 750px" :source="pdfUrl"/>
				<v-progress-circular v-else class="my-4 mx-auto d-block" indeterminate></v-progress-circular>
			</div>
		</template>
		
		<template #footer>
			<v-spacer></v-spacer>
			
			<frp-btn elevation="0"
					 outlined
					 color="primary"
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
			
			<frp-digital-signature-dialog v-if="selectedTask.isCurrentUserAssignee && selectedTask.isToDo"
										  :blob="file"
										  @signed="handleSigned"
										  pdf
										  color="blue"
										  v-model="isSignDocumentDialogOpened">
				<template #activator="{ on, attrs }">
					<frp-btn elevation="0" color="blue" dark class="ml-4"
							 :disabled="isFileLoading"
							 :loading="isSigning"
							 v-on="on"
							 v-bind="attrs">
						{{ $t("buttons.sign") }}
					</frp-btn>
				</template>
			</frp-digital-signature-dialog>
			
			<frp-btn @click="handleDownload"
					 elevation="0"
					 :color="colors.green.lighten2"
					 dark
					 :disabled="isFileLoading"
					 :loading="isDownloading"
					 download>
				{{ $t("buttons.pdfDownload") }}
			</frp-btn>
		</template>
	</frp-document-dialog>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDocumentDialog from "@/components/dialogs/FrpDocumentDialog.vue";
import FrpDigitalSignatureDialog from "@/components/digitalSignature/FrpDigitalSignatureDialog.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { namespace } from "@/store/hr/modules/tasks";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/tasks/types";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			isSignDocumentDialogOpened: false
		};
	},
	async beforeMount() {
		await this.generatePdfUrl(this.selectedTask.document);
	},
	beforeDestroy() {
		URL.revokeObjectURL(this.pdfUrl);
		this.resetPdfUrl();
		this.setFile();
	},
	computed: {
		...mapState({
			selectedTask: state => state.selectedTask,
			pdfUrl: state => state.pdfUrl,
			isDownloading: state => state.isDownloading,
			isSigning: state => state.isSigning,
			isFileLoading: state => state.isFileLoading,
			file: state => state.file
		})
	},
	methods: {
		...mapMutations({
			setFilterTaskId: mutationTypes.SET_FILTER_TASK_ID,
			resetSelectedTask: mutationTypes.RESET_SELECTED_TASK,
			resetPdfUrl: mutationTypes.RESET_PDF_URL,
			setFile: mutationTypes.SET_FILE
		}),
		...mapActions({
			approveTask: actionTypes.approveTask,
			generatePdfUrl: actionTypes.generatePdfUrl,
			downloadFile: actionTypes.downloadFile,
			signFile: actionTypes.signFile
		}),
		async handleDownload() {
			await this.downloadFile(this.selectedTask.document.fileId);
		},
		async handleSigned(signature) {
			const res = await this.signFile(signature);
			
			if(res)
				this.handleClose();
		},
		handleClose() {
			this.setFilterTaskId("");
			this.resetSelectedTask();
			this.$emit("update:value", false);
		},
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		}
	},
	components: { FrpDocumentDialog, FrpDigitalSignatureDialog, FrpFile, FrpBtn, FrpDialog, VuePdfEmbed }
};
</script>

<style scoped>

</style>