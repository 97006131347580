<template>
	<bar-content-layout>
		<v-row>
			<v-col cols="6">
				<bar-bank-account-information-loader/>
			</v-col>
			<v-col cols="6">
				<v-row class="d-flex flex-column">
					<v-col class="pb-1">
						<bar-bank-account-applications-loader/>
					</v-col>
					<v-col>
						<bar-bank-account-control-loader/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<frp-btn-loader height="40" width="160"/>
		</template>
	</bar-content-layout>
</template>

<script>
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";
import BarBankAccountApplicationsLoader from "@/views/bar/bankAccount/sections/applications/BarBankAccountApplicationsLoader.vue";
import BarBankAccountControlLoader from "@/views/bar/bankAccount/sections/control/BarBankAccountControlLoader.vue";
import BarBankAccountInformationLoader from "@/views/bar/bankAccount/sections/information/BarBankAccountInformationLoader.vue";

export default {
	components: {
		FrpBtnLoader,
		BarBankAccountControlLoader,
		BarBankAccountApplicationsLoader,
		BarBankAccountInformationLoader,
		BarContentLayout
	}
};
</script>

<style scoped>

</style>
