<template>
</template>

<script>
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/user/types";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers(namespace);

export default {
	computed: {
		...mapState({
			user: state => state.user
		})
	},
	watch: {
		async user() {
			if(this.user.id)
				await this.$router.replace({ name: RouteNames.EMPLOYEE, params: { id: this.user.id } });
		}
	}
};
</script>
