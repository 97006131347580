<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.declineReason.title')"
				max-width="490"
				persistent
				@update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="isFormValid">
				<frp-textarea v-model="reason"
							  color="blue"
							  :label="$t('fields.reason.label')"
							  :placeholder="$t('fields.reason.placeholder')">
				</frp-textarea>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 color="primary"
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="secondary"
					 :loading="isDeclining"
					 @click="handleDecline">
				{{ $t("buttons.decline") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { namespace } from "@/store/hr/modules/tasks";
import { actionTypes, mutationTypes } from "@/store/hr/modules/tasks/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			namespace,
			isFormValid: false
		};
	},
	computed: {
		...mapState({
			isDeclining: state => state.isDeclining,
			declineReason: state => state.declineReason
		}),
		reason: {
			get() {
				return this.declineReason;
			},
			set(value) {
				this.setDeclineReason(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setFilterTaskId: mutationTypes.SET_FILTER_TASK_ID,
			resetSelectedTask: mutationTypes.RESET_SELECTED_TASK,
			setDeclineReason: mutationTypes.SET_DECLINE_REASON,
			resetDeclineReason: mutationTypes.RESET_DECLINE_REASON
		}),
		...mapActions({
			declineTask: actionTypes.declineTask
		}),
		async handleDecline() {
			const res = await this.declineTask();
			
			if(res)
				this.handleClose();
		},
		handleClose() {
			this.setFilterTaskId("");
			this.resetDeclineReason();
			this.resetSelectedTask();
			this.$emit("update:value", false);
		},
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		}
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		FrpTextarea,
		FrpDialog,
		FrpBtn
	}
};
</script>
