import { IsArray, IsBoolean, IsString } from "class-validator";
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";
import { ApiHrTasksTypesEnum } from "@/api/hr/types/tasks/apiHrTasksTypesEnum";

export default class TasksFilter {
	@IsArray()
	taskTypes: ApiHrTasksTypesEnum[];
	
	@IsArray()
	taskStates: ApiHrTasksStateEnum[];
	
	@IsArray()
	@IsString({ each: true })
	taskAssigneeIds: string[];
	
	@IsBoolean()
	isAllEmployees: boolean;

	@IsString()
	taskId: string;
	
	constructor(
		taskTypes: ApiHrTasksTypesEnum[] = [],
		taskStates: ApiHrTasksStateEnum[] = [],
		taskAssigneeIds: string[] = [],
		isAllEmployees: boolean = false,
		taskId: string = ""
	)
	{
		this.taskTypes = taskTypes;
		this.taskStates = taskStates;
		this.taskAssigneeIds = taskAssigneeIds;
		this.isAllEmployees = isAllEmployees;
		this.taskId = taskId;
	}
}
