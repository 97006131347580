import IPageState from "@/store/shared/base/types/pageState";
import { HrKpi } from "@/types/hr/hrKpi";

export default class EmployeeKpiState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public kpi: HrKpi = {} as HrKpi
	)
	{
	}
}
