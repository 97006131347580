import { eachDayOfInterval, isSameDay } from "date-fns";
import { HrNewVacation } from "@/types/hr/vacation/hrNewVacation";
import { convertToTimestamp } from "@/utils/dates";

export const calculateVacationDays = (vacation: HrNewVacation, holidays: string[]): number => {
	const preparedHolidays = holidays.map(x => convertToTimestamp(x)) as number[];
	const total = eachDayOfInterval({ start: new Date(vacation.startDate!), end: new Date(vacation.endDate!) });

	return total.filter(date => !preparedHolidays.some(holiday => isSameDay(date, holiday))).length;
};

export const getVacationsWithDays = (vacations: HrNewVacation[], holidays: string[]): HrNewVacation[] => {
	return vacations.map(vacation => ({
		...vacation,
		days: calculateVacationDays(vacation, holidays)
	}));
};
