<template>
	<hr-card :title="$t('titles.employeeDiscipline')" :subtitle="subtitle" style="min-width: min-content;"
			 :centered-content="true || !isInitialized || isNil(discipline?.discipline)">
<!--		<v-progress-circular v-if="!isInitialized" indeterminate color="primary"></v-progress-circular>-->
<!--		<div style="width: 100%" class="fill-height d-flex align-center justify-center mb-4" v-else-if="isNil(discipline?.discipline)">-->
<!--			{{ $t("alerts.info.noData") }}-->
<!--		</div>-->
<!--		<template v-else>-->
<!--			<hr-chart-legend>-->
<!--				<hr-chart-legend-item>{{ `${discipline.totalCount} ${$t("chart.legend.tasksTotalCount")}` }}</hr-chart-legend-item>-->
<!--				<hr-chart-legend-item>-->
<!--					{{ `${discipline.overdueCount} ${$t("chart.legend.tasksOverdueCount")}` }}-->
<!--				</hr-chart-legend-item>-->
<!--			</hr-chart-legend>-->
<!--			<hr-chart-pie :label="$t('chart.legend.tasksCompletedOnTimeCount')" :value="chartValue"></hr-chart-pie>-->
<!--		</template>-->
		<div style="width: 100%" class="fill-height d-flex align-center justify-center mb-4">
			{{ $t("alerts.info.pendingData") }}
		</div>
	</hr-card>
</template>

<script>
import { ApiKpiQuarterEnum } from "Api/kpi/types/ApiKpiQuarterEnum";
import HrCard from "Components/cards/HrCard";
import HrChartLegendItem from "Components/charts/HrChartLegendItem";
import HrChartLegend from "Components/charts/HrChartLegend";
import HrChartPie from "Components/charts/HrChartPie";
import FrpIcon from "Components/icon/FrpIcon";
import { getQuarter, subQuarters } from "date-fns";
import { isNil } from "lodash";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes } from "Store/hr/modules/employee/modules/discipline/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.hr.employee.discipline.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			isNil
		}
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			discipline: state => state.discipline
		}),
		subtitle() {
			return this.$t("subheaders.forQuarter", { quarter: ApiKpiQuarterEnum[getQuarter(subQuarters(new Date(), 1))] });
		},
		chartValue() {
			return this.discipline.discipline;
		},
		chartLabel() {
			return `${this.discipline.totalCount} ${this.$t("chart.legend.tasksTotalCount")}`;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		HrChartPie,
		HrCard,
		HrChartLegend,
		HrChartLegendItem,
		FrpIcon
	}
};
</script>
