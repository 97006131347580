<template>
	<span :style="{ color }">{{ $t(`aliases.vacationPlanState.${value}`) }}</span>
</template>

<script>
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: [String]
		}
	},
	computed: {
		color() {
			switch (this.value) {
				case HrVacationPlanStateEnum.DRAFT:
					return this.colors.blue.base;
				case HrVacationPlanStateEnum.IN_REVIEW:
					return this.colors.orange.darken3;
				case HrVacationPlanStateEnum.APPROVED:
					return this.colors.green.lighten3;
				case HrVacationPlanStateEnum.REJECTED:
					return this.colors.secondary.lighten2;
			}
		}
	}
};
</script>
