export interface ApiBarUser {
	id: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	roles: string[];
	employeeId: string;
}

export class ApiBarUserHelper {
	static getEmpty(): ApiBarUser {
		return {
			id: "",
			firstName: "",
			lastName: "",
			middleName: "",
			email: "",
			roles: [],
			employeeId: ""
		};
	}
}