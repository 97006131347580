<template>
	<frp-card :title="$t('titles.applicationInformation')" v-if="isInitialized">
		<template #content>
			<bar-details-group>
				<template v-if="$route.params?.id">
					<bar-details-item :title="$t('details.titles.applicationStatus')">
						<v-progress-circular v-if="isFetchBankAccountApplicationLoading" width="2" size="24" color="blue" indeterminate />
						<div v-else>
							<span class="text-subtitle-2 font-weight-medium warning--text">
								{{ $t(`aliases.bankAccountApplicationStatusType.${editableItem.status}`) }}
							</span>
							<frp-btn small
									 height="30"
									 style="margin-left: 4px !important; margin-right: -10px !important;"
									 :color="colors.blue.base"
									 :loading="isFetchStatusHistoryLoading"
									 @click="handleFetchStatusHistoryItems"
									 icon>
								<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
							</frp-btn>
						</div>
					</bar-details-item>
				</template>
				
				<bar-details-item :title="$t('details.titles.project')">
					<frp-text-btn v-if="projectLink && editableItem.loanApplicationNumber"
								  :href="projectLink"
								  class="pa-0 bar-text-btn bar-project-link font-weight-bold"
								  style="min-width: fit-content;"
								  :text="editableItem.loanApplicationNumber"
								  color="primary"
								  target="_blank">
					</frp-text-btn>
					<span v-else class="text-subtitle-2 primary--text">{{ editableItem.loanApplicationNumber || "" }}</span>
				</bar-details-item>
				
				<bar-details-item v-if="editableItem?.agreement?.supervisorManagerInfo"
								  :title="$t('details.titles.supervisorProjectManager')">
					<div class="text-subtitle-2 primary--text d-flex align-center">
						<span>{{ editableItem.agreement.supervisorManagerInfo.fullName }}</span>
						<frp-text-btn :href="`mailto:${editableItem.agreement.supervisorManagerInfo.email}`"
									  class="ml-1 pa-0 bar-text-btn bar-supervisor-email-link"
									  style="min-width: fit-content;"
									  :text="editableItem.agreement.supervisorManagerInfo.email"
									  color="primary">
						</frp-text-btn>
					</div>
				</bar-details-item>
			</bar-details-group>
			
			<frp-dialog max-width="568"
						v-model="isStatusHistoryDialogOpened"
						:title="$t('dialogs.bankAccountApplicationStatusHistory.title')">
				<template #content="{ onIntersect }">
					<div class="d-flex justify-center flex-column" style="gap: 14px" v-intersect="onIntersect">
						<frp-text-body-two v-if="!statusHistoryItems.length || !applicationStatuses.length"
										   class="grey--text align-self-center mt-6 mb-3">
							{{ $t("dialogs.debtorStatusHistory.noDataText") }}
						</frp-text-body-two>
						
						<div class="d-flex justify-space-between align-center" v-else v-for="(item, i) in statusHistoryItems" :key="i">
							<div class="d-flex flex-column justify-center">
								<span class="text-subtitle-2 primary--text">
									{{ applicationStatuses.find(x => x.code === item?.status)?.title || "" }}
								</span>
								
								<span class="text-caption grey--text mt-1">
									{{ getStatusHistoryDialogItemText(item) }}
								</span>
							</div>
						</div>
					</div>
				</template>
				
				<template #footer>
					<frp-btn class="elevation-0"
							 @click="isStatusHistoryDialogOpened = false"
							 :color="colors.blue.base">
						<span class="white--text">{{ $t("buttons.close") }}</span>
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</frp-card>
	<bar-bank-account-application-information-base-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import { externalUrls } from "@/constants/bar/externalUrls";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { actionTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import BarBankAccountApplicationInformationBaseLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/baseInformation/BarBankAccountApplicationInformationBaseLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			SourceTypeEnum,
			BankAccountApplicationStatusTypeEnum,
			externalUrls,
			isStatusHistoryDialogOpened: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem,
			isFetchStatusHistoryLoading: state => state.isFetchStatusHistoryLoading,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading,
			applicationStatuses: state => state.applicationStatuses,
			staffEmployees: state => state.staffEmployees,
			statusHistoryItems: state => state.statusHistoryItems
		}),
		projectLink() {
			const agreement = this.editableItem?.agreement;
			return agreement ? `${externalUrls.lkProject}/${agreement.entryId}` : "";
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			fetchStatusHistoryItems: actionTypes.fetchStatusHistoryItems
		}),
		getStatusHistoryDialogItemText(statusItem) {
			const createdAtDate = formatDate(statusItem?.createdAt, dateFormat);
			
			return `${statusItem.creatorShortName}, ${createdAtDate}`;
		},
		async handleFetchStatusHistoryItems() {
			await this.fetchStatusHistoryItems();
			this.isStatusHistoryDialogOpened = true;
		}
	},
	components: {
		FrpTextBtn,
		BarBankAccountApplicationInformationBaseLoader,
		FrpTextBodyTwo,
		FrpDialog,
		FrpIcon,
		FrpBtn,
		FrpCard,
		BarDetailsItem,
		BarDetailsGroup
	}
};
</script>

<style lang="scss">
.bar-project-link {
  .v-btn__content {
	justify-content: center !important;
  }
}

.bar-supervisor-email-link {
  .v-btn__content {
	font-size: 0.875rem !important;
	font-family: "Ubuntu", sans-serif !important;
	letter-spacing: 0.0015em !important;
	line-height: 100% !important;
	font-weight: 500;
	color: var(--v-primary-base) !important;
  }
}
</style>
