import { ApiHrDocumentTypesEnum } from "@/api/hr/types/ApiHrDocumentTypesEnum";
import { ApiHrDocumentKindsEnum } from "@/api/hr/types/ApiHrDocumentKindsEnum";

export default class ChangeRequest {
	constructor(
		public documentType: ApiHrDocumentTypesEnum | null = null,
		public documentKind: ApiHrDocumentKindsEnum | null = null,
		public employeeIds: string[] = [],
		public exportAllTime: boolean = false,
		public periodRange: [string, string] = ["", ""]
	)
	{
	}
}
