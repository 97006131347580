import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { BankAccountData } from "@/store/bar/types/bankAccountData";
import { convertToZonedIso } from "@/utils/dates";

export interface ApiBankAccountData {
	id: string;
	accountType?: BankAccountTypeEnum;
	accountNumber: string;
	currencyId: string;
	openedAt: string;
}

export class ApiBankAccountDataMapper {
	static map(source: BankAccountData): ApiBankAccountData {
		return {
			id: source.id,
			accountType: source.accountType,
			accountNumber: source.accountNumber,
			currencyId: source.currencyId,
			openedAt: convertToZonedIso(source.openedAt)
		};
	}
}