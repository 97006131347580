import { IsArray, IsInt, IsString, Max, Min } from "class-validator";

export default class EmployeesRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	name: string;
	
	@IsArray()
	departmentIds: string[];
	
	constructor(
		page: number,
		name: string = "",
		departmentIds: string[] = []
	)
	{
		this.page = page;
		this.name = name;
		this.departmentIds = departmentIds;
	}
}
