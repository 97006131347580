<template>
	<frp-card is-loading class="pb-2">
		<template #content>
			<bar-card-content-layout class="pb-4">
				<v-row>
					<v-col class="d-flex align-center pt-0 pl-2">
						<frp-btn-loader height="20" width="20" class="ml-1 mr-2"/>
						<frp-text-loader width="60%"/>
					</v-col>
				</v-row>
			</bar-card-content-layout>
			
			<bar-details-group>
				<bar-details-item-loader v-for="(item, i) in 4"
										 :key="i"
										 class="py-3"
										 width="100%"
										 :row-style-object="{width: '100%'}">
					<template #additional-text>
						<frp-text-loader width="100%" class="mt-2"></frp-text-loader>
					</template>
					<v-row>
						<v-col class="d-flex align-center pl-12">
							<frp-btn-loader height="40" width="116" class="mr-4"/>
							<frp-btn-loader height="40" width="135" class="mr-4"/>
							<frp-btn-loader height="40" width="135"/>
							<frp-btn-loader height="28" width="28" class="ml-2"/>
						</v-col>
					</v-row>
				</bar-details-item-loader>
			</bar-details-group>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import BarDetailsItemLoader from "@/components/loaders/details/BarDetailsItemLoader.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: {
		FrpTextLoader,
		FrpBtnLoader,
		BarDetailsItemLoader,
		BarDetailsGroup,
		BarCardContentLayout,
		FrpCard
	}
};
</script>

<style scoped lang="scss">
.bar-details-item--application-document {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
