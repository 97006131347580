<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<v-card elevation="0"
				class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex flex-wrap" style="gap: 16px">
				<bod-autocomplete item-text="title"
								  item-value="value"
								  @keydown.enter="applyFilter"
								  multiple
								  style="width: 288px"
								  :items="taskTypes"
								  v-model="internalFilterValues.taskTypes"
								  color="blue"
								  :placeholder="$t('fields.taskTypes.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<bod-autocomplete item-text="title"
								  item-value="value"
								  @keydown.enter="applyFilter"
								  multiple
								  style="width: 237px"
								  :items="taskStates"
								  v-model="internalFilterValues.taskStates"
								  color="blue"
								  :placeholder="$t('fields.taskStates.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<bod-autocomplete v-if="isAdmin"
								  item-text="fullName"
								  item-value="id"
								  @keydown.enter="applyFilter"
								  multiple
								  style="width: 313px"
								  :loading="isEmployeesLoading"
								  :items="employees"
								  v-model="internalFilterValues.taskAssigneeIds"
								  color="blue"
								  :placeholder="$t('fields.taskAssignees.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
				
				<v-spacer></v-spacer>
				
				<frp-btn @click="isDocumentsExportDialogOpened = true"
						 v-if="isAdmin"
						 outlined
						 color="blue"
						 elevation="0">
					{{ $t("buttons.documents") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-card elevation="0"
				class="kpi-card pa-0">
			<v-row>
				<v-col class="py-0">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading || isUserLoading"
								  hide-default-footer
								  item-key="id"
								  :items="formattedItems"
								  :mobile-breakpoint="mobileBreakpoint"
								  :items-per-page="-1"
								  class="loan-table bod-table hr-table d-flex flex-column">
						<template #item.title="{ item: { approval, title, id } }">
							<span v-if="!approval">
								{{ title }}
							</span>
							
							<template v-else-if="approval.type === ApiHrVacationApplicationApprovalTypeEnum.VacationsPlan">
								<v-progress-circular v-if="loadingApprovalItemIds.includes(id)" width="2" size="20" indeterminate />
								<span v-else @click="handleOpenApprovalDialog(id, approval.id)" class="text-decoration-underline pointer">
									{{ title }}
								</span>
							</template>
							
							<frp-router-link-btn v-else
												 underline
												 target="_blank"
												 :to="{ name: RouteNames.VACATION_APPLICATION, params: { id: approval.entityId } }">
								{{ title }}
							</frp-router-link-btn>
						</template>
						
						<template #item.certificatesStamp="{ value }">
							<frp-file v-if="value.certificatesStampFileId"
									  class="text-break"
									  :id="value.certificatesStampFileId"
									  :name="value.title"
									  underline
									  :icon="false">
							</frp-file>
							<span v-else>-</span>
						</template>
						
						<template #item.document="{ item, value }">
							<frp-link-btn @click="setFilterTaskId(item.id)">
								{{ value.title }}
							</frp-link-btn>
						</template>
						
						<template #item.state="{ value }">
							<hr-tasks-state-label :value="value"/>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		
		<frp-bottom-space height="24"></frp-bottom-space>
		
		<hr-tasks-document-sign-dialog v-if="isSigningDialogOpened"
									   v-model="isSigningDialogOpened">
		</hr-tasks-document-sign-dialog>
		<hr-tasks-document-approval-dialog v-if="isApprovalDialogOpened"
										   v-model="isApprovalDialogOpened">
		</hr-tasks-document-approval-dialog>
		<hr-tasks-document-info-dialog v-if="isInfoDialogOpened"
									   v-model="isInfoDialogOpened">
		</hr-tasks-document-info-dialog>
		<hr-tasks-documents-export-dialog v-if="isDocumentsExportDialogOpened"
										  v-model="isDocumentsExportDialogOpened">
		</hr-tasks-documents-export-dialog>
		<hr-tasks-approval-status-dialog v-model="isApprovalStatusDialogOpened" />
	</hr-content-layout>
</template>

<script>
import { HrStorageController } from "@/api/hr/storage";
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";
import { ApiHrTasksTypesEnum } from "@/api/hr/types/tasks/apiHrTasksTypesEnum";
import { ApiHrVacationApplicationApprovalTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationApprovalTypeEnum";
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import HrTasksStateLabel from "@/components/labels/HrTasksStateLabel.vue";
import { useOptions } from "@/store/composables/options/useOptions";
import HrTasksApprovalStatusDialog from "@/views/hr/tasks/dialogs/HrTasksApprovalStatusDialog.vue";
import HrTasksDocumentApprovalDialog from "@/views/hr/tasks/dialogs/HrTasksDocumentApprovalDialog.vue";
import HrTasksDocumentsExportDialog from "@/views/hr/tasks/dialogs/HrTasksDocumentsExportDialog.vue";
import HrTasksDocumentInfoDialog from "@/views/hr/tasks/dialogs/HrTasksDocumentInfoDialog.vue";
import HrTasksDocumentSignDialog from "@/views/hr/tasks/dialogs/HrTasksDocumentSignDialog.vue";
import HrDeclineReasonDialog from "@/views/hr/vacationPlans/dialogs/HrDeclineReasonDialog.vue";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpTreeAutocomplete from "Components/fields/FrpTreeAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import BodContentLayout from "Components/layouts/BodContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import HrContentLayout from "Components/layouts/HrContentLayout";
import { assign, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/tasks";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/tasks/types";
import TasksFilter from "Store/hr/modules/tasks/types/tasksFilter";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import storeManager from "Store/manager";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const hrUserModuleHelpers = createNamespacedHelpers(storeManager.hr.user.namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return {
			title: this.$t("metaTitles.tasks")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			ApiHrVacationApplicationApprovalTypeEnum,
			HrStorageController,
			internalFilterValues: {
				taskTypes: [],
				taskStates: [],
				taskAssigneeIds: [],
				isAllEmployees: false,
				taskId: ""
			},
			taskTypes: useOptions(ApiHrTasksTypesEnum, "tasksTypes").items,
			taskStates: useOptions(ApiHrTasksStateEnum, "tasksState").items,
			isSigningDialogOpened: false,
			isApprovalDialogOpened: false,
			isInfoDialogOpened: false,
			isDocumentsExportDialogOpened: false,
			isApprovalStatusDialogOpened: false
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			selectedTask: state => state.selectedTask,
			employees: state => state.employees,
			isEmployeesLoading: state => state.isEmployeesLoading,
			loadingApprovalItemIds: state => state.loadingApprovalItemIds
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty,
			taskAssignees: getterTypes.taskAssignees,
			isAdmin: getterTypes.isAdmin,
			currentUser: getterTypes.currentUser
		}),
		...hrUserModuleHelpers.mapState({
			isUserLoading: state => state.isUserLoading
		}),
		filter() {
			return {
				taskTypes: this.internalFilterValues.taskTypes,
				taskStates: this.internalFilterValues.taskStates,
				taskAssigneeIds: this.internalFilterValues.taskAssigneeIds
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new TasksFilter(this.isAdmin ? [this.currentUser.id] : []), this.filterValues);
		},
		headers() {
			return [
				{
					text: this.$t("tables.task"),
					value: "title",
					class: "text-caption",
					sortable: false
				},
				{
					text: this.$t("tables.document"),
					value: "document",
					class: "text-caption",
					sortable: false
				},
				{
					text: this.$t("tables.initiator"),
					value: "initiator",
					class: "text-caption",
					sortable: false
				},
				this.isAdmin && {
					text: this.$t("tables.assignee"),
					value: "assignee",
					class: "text-caption",
					sortable: false
				},
				{
					text: this.$t("tables.dueDate"),
					value: "dueDate",
					class: "text-caption",
					sortable: false
				},
				{
					text: this.$t("tables.status"),
					value: "state",
					class: "text-caption",
					sortable: false
				},
				{
					text: this.$t("tables.certificatesStamp"),
					value: "certificatesStamp",
					class: "text-caption",
					sortable: false
				}
			].filter(Boolean);
		}
	},
	methods: {
		...mapActions({
			fetchApproval: actionTypes.fetchApproval,
		}),
		...mapMutations({
			setFilterTaskTypes: mutationTypes.SET_FILTER_TASK_TYPES,
			setFilterTaskStates: mutationTypes.SET_FILTER_TASK_STATES,
			setFilterTaskAssigneeIds: mutationTypes.SET_FILTER_TASK_ASSIGNEE_IDS,
			setFilterTaskId: mutationTypes.SET_FILTER_TASK_ID,
			setFilter: mutationTypes.SET_FILTER,
			setFilterIsAllEmployees: mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES,
			setSelectedTask: mutationTypes.SET_SELECTED_TASK,
		}),
		resetFilter() {
			const defaults = new TasksFilter([], [], this.isAdmin ? [this.currentUser.id] : []);
			this.setFilter(defaults);
		},
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterTaskTypes(this.internalFilterValues.taskTypes || []);
			this.setFilterTaskStates(this.internalFilterValues.taskStates || []);
			this.setFilterTaskAssigneeIds(this.internalFilterValues.taskAssigneeIds || []);
		},
		handleOpenDialog(id) {
			const task = this.formattedItems.find(x => x.id === id);
			
			if(!task)
				return;
			
			this.setSelectedTask(task);
			
			if(task.type === ApiHrTasksTypesEnum.Signing) {
				this.isSigningDialogOpened = true;
			} else if(task.type === ApiHrTasksTypesEnum.Approval) {
				this.isApprovalDialogOpened = true;
			} else if(task.type === ApiHrTasksTypesEnum.Information) {
				this.isInfoDialogOpened = true;
			}
		},
		async handleOpenApprovalDialog(itemId, approvalId) {
			await this.fetchApproval({ itemId, approvalId });
			this.isApprovalStatusDialogOpened = true;
		}
	},
	created() {
		this.initializeStore();
	},
	watch: {
		"filterValues.taskAssigneeIds"(value, prev) {
			if(!value.length && !!prev.length) {
				this.setFilterIsAllEmployees(true);
			} else if(!!value.length && !prev.length) {
				this.setFilterIsAllEmployees(false);
			}
		},
		"filterValues.taskId"(value) {
			if(value)
				this.handleOpenDialog(value);
		},
		formattedItems(value) {
			if(value.length && this.filterValues.taskId)
				this.handleOpenDialog(this.filterValues.taskId);
		}
	},
	components: {
		HrTasksApprovalStatusDialog,
		HrTasksDocumentsExportDialog,
		HrTasksDocumentInfoDialog,
		HrDeclineReasonDialog,
		HrTasksDocumentApprovalDialog,
		HrTasksDocumentSignDialog,
		VuePdfEmbed,
		FrpDialog,
		FrpFile,
		HrTasksStateLabel,
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout,
		HrContentLayout
	}
};
</script>
