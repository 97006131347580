import { ApiBarUser, ApiBarUserHelper } from "@/api/bar/types/apiBarUser";
import IPageState from "@/store/shared/base/types/pageState";

export default class UserState implements IPageState {
	constructor(
		public user: ApiBarUser = ApiBarUserHelper.getEmpty(),
		public isUserLoading: boolean = false,
		public isPermissionsLoading: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public permissions: string[] = []
	)
	{
	}
}
