<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on">
				<frp-icon src="ico_error" :color="colors.error"></frp-icon>
			</div>
		</template>
		
		<span v-if="title" class="font-weight-bold mr-1">{{ title }}:</span>
		<span>{{ message }}</span>
	</v-tooltip>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";


export default {
	mixins: [colorsMixin],
	props: {
		title: String,
		message: String
	},
	components: { FrpIcon }
};
</script>
