export interface HrUpdateEmployeeVacationDaysRequest {
	vacationDays?: number;
	comment?: string;
}

export class HrUpdateEmployeeVacationDaysRequestService {
	static getEmpty(): HrUpdateEmployeeVacationDaysRequest {
		return {
			vacationDays: undefined,
			comment: undefined
		};
	}
}
