<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.importVacationPlanEmployees.title')"
				max-width="407"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-mutate.child="onIntersect" v-intersect="onIntersect" v-model="formValid" :readonly="isFormSaving">
				<div class="primary--text text--darken-1 mb-4" style="font-size: 14px; font-weight: 600; line-height: 19px">
					{{ $t("dialogs.importVacationPlanEmployees.text") }}
				</div>
				<div class="d-flex" style="column-gap: 15px">
					<frp-date-field :max="maxDate" class="kpi-field" v-model="hireDateFrom" label="Начало"></frp-date-field>
					<frp-date-field :max="maxDate" class="kpi-field" v-model="hireDateTo" label="Окончание"></frp-date-field>
				</div>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :loading="isFormSaving"
					 :disabled="!formValid"
					 @click="handleImportEmployees">
				{{ $t("buttons.import") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import formMixin from "@/mixins/formMixin";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlanEmployees";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlanEmployees/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		employeeId: String
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			importEmployeesRequest: state => state.importEmployeesRequest,
			importEmployeesResponse: state => state.importEmployeesResponse
		}),
		hireDateFrom: {
			get() {
				return this.importEmployeesRequest.hireDateFrom;
			},
			set(value) {
				this.setImportEmployeesRequestHireDateFrom(value);
			}
		},
		hireDateTo: {
			get() {
				return this.importEmployeesRequest.hireDateTo;
			},
			set(value) {
				this.setImportEmployeesRequestHireDateTo(value);
			}
		},
		maxDate() {
			return new Date();
		}
	},
	methods: {
		...mapMutations({
			resetImportEmployeesRequest: mutationTypes.RESET_IMPORT_EMPLOYEES_REQUEST,
			setImportEmployeesRequestHireDateFrom: mutationTypes.SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_FROM,
			setImportEmployeesRequestHireDateTo: mutationTypes.SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_TO
		}),
		...mapActions({
			importEmployees: actionTypes.importEmployees
		}),
		async handleImportEmployees() {
			try {
				await this.importEmployees();

				if(this.importEmployeesResponse.importedCount)
					this.$emit("success", false);
			} catch (error) {
				// Обработка в сторе
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.resetImportEmployeesRequest();
			this.$emit("update:value", false);
		}
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs[this.refs.form].resetValidation();
			}
		}
	},
	components: {
		FrpDateField,
		FrpDialog,
		FrpBtn
	}
};
</script>
