<template>
	<frp-card is-loading>
		<template #content>
				<bar-details-group>
					<bar-details-item-loader>
						<v-skeleton-loader height="36" width="270" type="image" class="rounded-md"/>
					</bar-details-item-loader>
					<bar-details-item-loader>
						<v-skeleton-loader height="36" width="270" type="image" class="rounded-md"/>
					</bar-details-item-loader>
				</bar-details-group>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItemLoader from "@/components/loaders/details/BarDetailsItemLoader.vue";

export default {
	components: { BarDetailsItemLoader, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
