import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import { DictionariesController } from "@/api/bar/dictionaries";
import FormMixinBuilder from "@/store/shared/form";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import BankAccountApplicationMessengerState
	from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/bankAccountApplicationMessengerState";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { namespace, actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { cloneDeep } from "lodash";
import { BankAccountApplicationMessageHelper } from "@/store/bar/types/bankAccountApplicationMessage";
import UserState from "@/store/bar/modules/user/types/userState";
import { Permissions } from "@/constants/permissions";
import PermissionsService from "@/services/permissionsService";
import { ApiBankAccountApplicationMessageMapper } from "@/api/bar/types/apiBankAccountApplicationMessage";
import { ApiBankAccountApplicationMessagePersisted } from "@/api/bar/types/apiBankAccountApplicationMessagePersisted";
import { MessageType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/MessageType";

const abortService = new AbortService();

const barController = new BarController(abortService);
const dictionariesController = new DictionariesController(abortService);

const permissionsService = new PermissionsService();

const formMixin = (new FormMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountApplicationMessengerState(
			formMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountApplicationMessengerState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<BankAccountApplicationMessengerState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.initialize]({ dispatch, rootState, state, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		const isMessengerReadPermissionExist = await permissionsService.check([Permissions.BAR_APPLICATION_MESSAGES_READ]);
		
		if(isMessengerReadPermissionExist) {
			const { user } = resolveNestedState<UserState>(rootState, storeManager.bar.barUser.namespace);
			commit(mutationTypes.SET_EDITABLE_MESSAGE_ITEM_EMPLOYEE_ID, user.id);
			
			await dispatch(actionTypes.fetchMessages);
		}
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchMessages]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_MESSAGES_FETCHING, true);
		
		try {
			const messages = await barController.getMessages(rootState.route.params.id);
			
			commit(mutationTypes.SET_MESSAGES, messages.map((x: ApiBankAccountApplicationMessagePersisted) => BankAccountApplicationMessageHelper.map(x)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_MESSAGES_FETCHING, false);
		}
	},
	async [actionTypes.sendMessage]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const preparedMessage = ApiBankAccountApplicationMessageMapper.map(state.editableMessageItem);

			await barController.sendMessage(rootState.route.params.id, preparedMessage);
			
			await dispatch(actionTypes.fetchMessages);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteMessage]({ dispatch, commit, rootState, state }, id) {
		commit(mutationTypes.SET_IS_MESSAGE_ITEM_DELETING, { id, value: true });
		commit(mutationTypes.SET_IS_MESSAGE_DELETING, true);
		
		try {
			await barController.deleteMessage(rootState.route.params.id, id);
			
			commit(mutationTypes.SET_IS_MESSAGE_ITEM_DELETING, { id, value: false });
			commit(mutationTypes.DELETE_MESSAGE_ITEM, id);
			
			await dispatch(actionTypes.fetchMessages);
		} catch (error) {
			commit(mutationTypes.SET_IS_MESSAGE_ITEM_DELETING, { id, value: false });
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_MESSAGE_DELETING, false);
		}
	}
};


const mutations = <MutationTree<BankAccountApplicationMessengerState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[mutationTypes.SET_IS_MESSAGES_FETCHING](state, value) {
		state.isMessagesFetching = value;
	},
	[mutationTypes.SET_MESSAGES](state, value) {
		state.messages = value;
	},
	[mutationTypes.SET_IS_MESSAGE_ITEM_DELETING](state, { id, value }) {
		state.messages[state.messages.findIndex(x => x.id === id)].isMessageDeleting = cloneDeep(value);
	},
	[mutationTypes.DELETE_MESSAGE_ITEM](state, value) {
		state.messages.splice(state.messages.findIndex(x => x.id === value), 1);
	},
	[mutationTypes.SET_IS_MESSAGE_DELETING](state, value) {
		state.isMessageDeleting = value;
	},
	[mutationTypes.SET_IS_MESSENGER_FULLSCREEN](state, value) {
		state.isMessengerFullscreen = value;
	},
	[mutationTypes.RESET_EDITABLE_MESSAGE_ITEM](state) {
		state.editableMessageItem = BankAccountApplicationMessageHelper.getEmpty();
	},
	[mutationTypes.SET_EDITABLE_MESSAGE_ITEM](state, value) {
		state.editableMessageItem = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_MESSAGE_ITEM_EMPLOYEE_ID](state, value) {
		state.editableMessageItem.employeeId = value;
	},
	[mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_TO_EMPLOYEES](state) {
		state.editableMessageItem.toEmployees = [];
	},
	[mutationTypes.REMOVE_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID](state, value) {
		state.editableMessageItem.toEmployees.splice(state.editableMessageItem.toEmployees.findIndex(x => x === value), 1);
	},
	[mutationTypes.SET_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID](state, value) {
		state.editableMessageItem.toEmployees.push(value);
	},
	[mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID](state) {
		state.editableMessageItem.relatedMessageId = "";
	},
	[mutationTypes.SET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID](state, value) {
		state.editableMessageItem.relatedMessageId = value;
	},
	[mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT](state) {
		state.editableMessageItem.messageText = "";
	},
	[mutationTypes.SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT](state, value) {
		state.editableMessageItem.messageText = value;
	},
	[mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TYPE](state) {
		state.editableMessageItem.type = MessageType.Unknown;
	},
	[mutationTypes.SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TYPE](state, value) {
		state.editableMessageItem.type = value;
	},
	[mutationTypes.SET_MESSENGER_MODE](state, value) {
		state.messengerMode = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const bankAccountApplicationMessengerModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default bankAccountApplicationMessengerModule;
