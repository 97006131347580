<template>
	<frp-card is-loading>
		<template #content>
			<bar-details-group>
				<bar-details-item-loader>
					<frp-text-loader width="200"></frp-text-loader>
				</bar-details-item-loader>
				<bar-details-item-loader>
					<frp-text-loader width="80"></frp-text-loader>
				</bar-details-item-loader>
				<bar-details-item-loader>
					<frp-text-loader width="105"></frp-text-loader>
				</bar-details-item-loader>
			</bar-details-group>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import BarDetailsItemLoader from "@/components/loaders/details/BarDetailsItemLoader.vue";

export default {
	components: { FrpTextLoader, BarDetailsItemLoader, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
