import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/employee/modules/expectations/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import EmployeeExpectationsState from "@/store/hr/modules/employee/modules/expectations/types/employeeExpectationsState";

const abortService = new AbortService();
const hrController = new HrController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeExpectationsState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeExpectationsState, any>>{
};

const actions = <ActionTree<EmployeeExpectationsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	}
};

const mutations = <MutationTree<EmployeeExpectationsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations
};

export {
	namespace, state, getters, actions, mutations
};

const employeeExpectationsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeExpectationsModule;
