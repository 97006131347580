<template>
	<span :style="{ color }">{{ text }}</span>
</template>

<script>
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: [String, ApiHrTasksStateEnum]
		}
	},
	computed: {
		text() {
			return this.$t(`aliases.tasksState.${this.value}`);
		},
		color() {
			switch (this.value) {
				case ApiHrTasksStateEnum.Done:
					return this.colors.green.lighten3;
				case ApiHrTasksStateEnum.ToDo:
					return this.colors.orange.darken3;
				case ApiHrTasksStateEnum.Withdrawn:
					return this.colors.grey.base;
				case ApiHrTasksStateEnum.Declined:
					return this.colors.secondary.lighten2;
			}
		}
	}
};
</script>
