<template>
	<div>
		<frp-document-dialog :value="value"
							 persistent
							 :maxWidth="848"
							 v-if="!isDialogOpened"
							 @update:value="onDialogValueUpdated">
			<template #content="{ onIntersect }">
				<div v-mutate="onIntersect" v-intersect="onIntersect">
					<vue-pdf-embed v-if="!isFileLoading" style="max-width: 750px" :source="pdfUrl"/>
					<v-progress-circular v-else class="my-4 mx-auto d-block" indeterminate></v-progress-circular>
				</div>
			</template>
			
			<template #footer>
				<v-spacer></v-spacer>
				
				<frp-btn elevation="0"
						 outlined
						 color="primary"
						 @click="handleClose">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn v-if="selectedTask.isCurrentUserAssignee && selectedTask.isToDo"
						 elevation="0"
						 color="blue"
						 dark
						 :disabled="isFileLoading"
						 :loading="isMarkingTaskInformationAsDone"
						 @click="handleMarkTaskInformationAsDone">
					{{ $t("buttons.done") }}
				</frp-btn>
			</template>
		</frp-document-dialog>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDocumentDialog from "@/components/dialogs/FrpDocumentDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { namespace } from "@/store/hr/modules/tasks";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/tasks/types";
import HrTasksDeclineReasonDialog from "@/views/hr/tasks/dialogs/HrTasksDeclineReasonDialog.vue";
import HrDeclineReasonDialog from "@/views/hr/vacationPlans/dialogs/HrDeclineReasonDialog.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			isDialogOpened: false
		};
	},
	async beforeMount() {
		await this.generatePdfUrl(this.selectedTask.document);
	},
	computed: {
		...mapState({
			selectedTask: state => state.selectedTask,
			pdfUrl: state => state.pdfUrl,
			isMarkingTaskInformationAsDone: state => state.isMarkingTaskInformationAsDone,
			isFileLoading: state => state.isFileLoading
		})
	},
	beforeDestroy() {
		URL.revokeObjectURL(this.pdfUrl);
		this.resetPdfUrl();
		this.setFile();
	},
	methods: {
		...mapMutations({
			setFilterTaskId: mutationTypes.SET_FILTER_TASK_ID,
			resetSelectedTask: mutationTypes.RESET_SELECTED_TASK,
			resetPdfUrl: mutationTypes.RESET_PDF_URL,
			setFile: mutationTypes.SET_FILE
		}),
		...mapActions({
			markTaskInformationAsDone: actionTypes.markTaskInformationAsDone,
			generatePdfUrl: actionTypes.generatePdfUrl
		}),
		handleClose() {
			this.setFilterTaskId("");
			this.resetSelectedTask();
			this.$emit("update:value", false);
		},
		async handleMarkTaskInformationAsDone() {
			const res = await this.markTaskInformationAsDone();
			
			if(res)
				this.handleClose();
		},
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		}
	},
	components: {
		FrpDocumentDialog,
		HrTasksDeclineReasonDialog,
		HrDeclineReasonDialog,
		FrpTextarea,
		FrpFile,
		FrpBtn,
		FrpDialog,
		VuePdfEmbed
	}
};
</script>

<style scoped>

</style>