<template>
	<frp-card :title="$t('titles.bank')" v-if="isInitialized">
		<template #content>
			<v-form v-if="isInitialized"
					ref="bankAccountApplicationBankForm"
					v-model="bankAccountApplicationBankFormValid">
				<bar-details-group>
					<bar-details-item :title="$t('details.titles.bank')">
						<span class="text-subtitle-2 font-weight-regular primary--text d-inline-block pl-3" style="min-width: 270px">
							{{ bank.name }}
						</span>
					</bar-details-item>
					<bar-details-item :title="$t('details.titles.bankBranch')">
						<frp-autocomplete v-model="bankBranch"
										  :items="bankBranches"
										  item-text="name"
										  item-value="id"
										  color="blue"
										  return-object
										  class="bar-field bar-field--width"
										  required
										  hide-details
										  :error="branchFieldError"
										  :disabled="isFormDisabled || !editableItem.bankId || (!bankBranches.length && !!bik && !!correspondentAccount) || bankBranches.length === 1"
										  :placeholder="$t('fields.bankBranch.placeholder')">
						</frp-autocomplete>
					</bar-details-item>
					<bar-details-item :title="$t('details.titles.bik')">
						<frp-text-field v-model="bankBranchBik"
										type="number"
										hide-details
										:mask="masks.bik"
										:rules="validation.bik"
										:loading="isFetchSuggestedBankAndBankBranchByBikLoading"
										required
										:error="bikFieldError"
										class="bar-field bar-field--width"
										:placeholder="$t('fields.bik.placeholder')">
						</frp-text-field>
					</bar-details-item>
					<bar-details-item :title="$t('details.titles.correspondentAccount')">
						<span class="text-subtitle-2 font-weight-medium primary--text">{{ correspondentAccount }}</span>
					</bar-details-item>
				</bar-details-group>
			</v-form>
		</template>
	</frp-card>
	<bar-bank-account-application-information-bank-editable-loader v-else/>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import { masks } from "@/constants/masks";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import {
	maxLengths,
	onlyIntegerNumbersRule,
	onlyNumbersRule,
	prepareBankIdentificationCodeValidationRule, prepareMaxLengthRule
} from "@/utils/validation";
import BarBankAccountApplicationInformationBankEditableLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/bank/BarBankAccountApplicationInformationBankEditableLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountApplicationStatusTypeEnum,
			bikFieldError: false,
			validation: {
				bik: [
					prepareBankIdentificationCodeValidationRule(),
					onlyIntegerNumbersRule(),
					prepareMaxLengthRule({ maxLength: maxLengths.bik })
				]
			}
		};
	},
	computed: {
		masks() {
			return masks
		},
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem,
			banks: state => state.banks,
			bankBranches: state => state.bankBranches,
			bik: state => state.bik,
			correspondentAccount: state => state.correspondentAccount,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isBankAccountApplicationBankFormValid: state => state.isBankAccountApplicationBankFormValid,
			isBankAccountApplicationBankFormDisabled: state => state.isBankAccountApplicationBankFormDisabled,
			isFetchSuggestedBankAndBankBranchByBikLoading: state => state.isFetchSuggestedBankAndBankBranchByBikLoading
		}),
		isFormDisabled() {
			return this.isBankAccountApplicationBankFormDisabled || this.isFetchBankAccountApplicationLoading ||
				this.isBankAccountApplicationSaving;
		},
		bankAccountApplicationBankFormValid: {
			get() {
				return this.isBankAccountApplicationBankFormValid;
			},
			set(value) {
				this.setIsBankAccountApplicationBankFormValid(value);
			}
		},
		bank: {
			get() {
				return this.banks.find(x => x.id === this.editableItem.bankId) || "";
			},
			set(value) {
				this.setSelectedBank(value);
			}
		},
		bankBranch: {
			get() {
				return this.bankBranches.find(x => x.id === this.editableItem.bankBranchId) || "";
			},
			set(value) {
				this.setSelectedBankBranch(value);
			}
		},
		bankBranchBik: {
			get() {
				return this.bik;
			},
			set(value) {
				this.setBik(value);
			}
		},
		contractNumber: {
			get() {
				return this.editableItem.contract.number;
			},
			set(value) {
				this.setEditableItemContractNumber(value);
			}
		},
		contractOpeningDate: {
			get() {
				return this.editableItem.contract.openingDate;
			},
			set(value) {
				this.setEditableItemContractOpeningDate(value);
			}
		},
		branchFieldError() {
			return !!this.bankBranches?.length && this.bankBranches.length > 1 && !this.bankBranch;
		}
	},
	methods: {
		...mapMutations({
			setIsBankAccountApplicationBankFormValid: mutationTypes.SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_VALID,
			setCorrespondentAccount: mutationTypes.SET_CORRESPONDENT_ACCOUNT,
			setEditableItemContractNumber: mutationTypes.SET_EDITABLE_ITEM_CONTRACT_NUMBER,
			setEditableItemContractOpeningDate: mutationTypes.SET_EDITABLE_ITEM_CONTRACT_OPENING_DATE
		}),
		...mapActions({
			setSelectedBank: actionTypes.setSelectedBank,
			setSelectedBankBranch: actionTypes.setSelectedBankBranch,
			setBik: actionTypes.setBik
		})
	},
	watch: {
		bank(value) {
			this.bikFieldError = !value;
		}
	},
	components: {
		BarBankAccountApplicationInformationBankEditableLoader,
		FrpDateField,
		FrpTextField,
		FrpAutocomplete,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
