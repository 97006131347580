import { stringifyQuery } from "@/utils/query";
import FrpInternalServerError from "@/views/errors/FrpInternalServerError.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpSilentRenew from "@/views/FrpSilentRenew.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import HrLayout from "@/views/layouts/HrLayout.vue";
import FrpEmptyLayout from "@/views/layouts/FrpEmptyLayout.vue";
import FrpCallback from "@/views/FrpCallback.vue";
import { RouteNames } from "@/router/hr/routes";
import { configureMiddleware } from "@/router/hr/middleware";
import HrEmployees from "@/views/hr/employees/HrEmployees.vue";
import HrEmployee from "@/views/hr/employee/HrEmployee.vue";
import HrMe from "@/views/hr/me/HrMe.vue";
import HrVacationPlanHolidays from "@/views/hr/vacationPlanHolidays/HrVacationPlanHolidays.vue";
import HrVacationPlanEmployees from "@/views/hr/vacationPlanEmployees/HrVacationPlanEmployees.vue";
import HrVacationPlans from "@/views/hr/vacationPlans/HrVacationPlans.vue";
import HrTasks from "@/views/hr/tasks/HrTasks.vue";
import HrVacationApplications from "@/views/hr/vacationApplications/HrVacationApplications.vue";
import HrVacationApplication from "@/views/hr/vacationApplication/HrVacationApplication.vue";
import HrDocuments from "@/views/hr/personnelDocuments/HrPersonnelDocuments.vue";
import HrDocumentsLayout from "@/views/hr/personnelDocuments/HrPersonnelDocumentsLayout.vue";
import HrSupport from "@/views/hr/support/HrSupport.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: FrpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: FrpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: HrLayout,
		name: RouteNames.ROOT,
		children: [
			{
				path: "/employees",
				component: HrEmployees,
				name: RouteNames.EMPLOYEES
			},
			{
				path: "/employee/:id",
				component: HrEmployee,
				name: RouteNames.EMPLOYEE
			},
			{
				path: "/me",
				component: HrMe,
				name: RouteNames.ME
			},
			{
				path: "/vacation-plans/:year?",
				component: HrVacationPlans,
				name: RouteNames.VACATION_PLANS
			},
			{
				path: "/vacation-plans/administration/employees/:year?",
				component: HrVacationPlanEmployees,
				name: RouteNames.VACATION_PLANS_ADMINISTRATION_EMPLOYEES
			},
			{
				path: "/vacation-plans/administration/holidays/:year?",
				component: HrVacationPlanHolidays,
				name: RouteNames.VACATION_PLANS_ADMINISTRATION_HOLIDAYS
			},
			{
				path: "/tasks",
				component: HrTasks,
				name: RouteNames.TASKS
			},
			{
				path: "/vacation-applications",
				component: HrVacationApplications,
				name: RouteNames.VACATION_APPLICATIONS,
			},
			{
				path: "application",
				component: HrVacationApplication,
				name: RouteNames.VACATION_APPLICATION_CREATE
			},
			{
				path: "application/:id",
				component: HrVacationApplication,
				name: RouteNames.VACATION_APPLICATION
			},
			{
				path: "/support",
				component: HrSupport,
				name: RouteNames.SUPPORT
			}
		]
	},
	{
		path: "/frp/doc",
		component: HrDocumentsLayout,
		name: RouteNames.DOC_ROOT,
		children: [
			{
				path: "/",
				component: HrDocuments,
				name: RouteNames.DOC
			}
		]
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: FrpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: FrpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: FrpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
