<template>
	<div class="mb-6">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: 16px">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<div v-if="loading" class="rounded-lg d-flex justify-center align-center" style="border: 2px dashed gray; height: 60px">
			<v-progress-circular indeterminate></v-progress-circular>
		</div>
		<div v-else>
			<div v-show="!files.length">
				<vue-dropzone :name="type"
							  ref="vueDropzone"
							  id="dropzone"
							  :options="dropzoneOptions"
							  :useCustomSlot="true"
							  @vdropzone-file-added="handleFileAdded"
							  class="white rounded-lg pa-2 d-flex align-center flex-wrap justify-center frp-dropzone">
					<div class="d-flex align-center">
						<span class="grey--text">{{ placeholder }}</span>
					</div>
				</vue-dropzone>
			</div>
			
			<div v-if="files.length">
				<v-row class="mt-1">
					<v-col class="d-flex align-start flex-column py-0">
						<div v-for="{ name } in files" :key="name" class="d-flex align-center justify-space-between rounded-lg pa-2"
							 style="width: 100%; border: 2px dashed gray;">
							<div class="d-flex align-center justify-center">
								<frp-icon src="ico_bod-file"></frp-icon>
								<span style="width: 280px">{{ name }}</span>
							</div>
							<frp-btn outlined
									 color="primary"
									 @click="handleClick">
								{{ $t("buttons.replace") }}
							</frp-btn>
						</div>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>

<script>
import { HrStorageController } from "@/api/hr/storage";
import ApiFile from "@/api/types/storage/apiFile";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import { i18n } from "@/plugins";
import { getMimeType } from "@/utils/getMimeType";
import BodFile from "@/components/common/BodFile.vue";
import AbortService from "@/services/abortService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import VueDropzone from "vue2-dropzone";
import colorsMixin from "@/mixins/colorsMixin";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import { FileTypes } from "@/constants/fileTypes";

const DEFAULT_URL = "http://localhost";

const abortService = new AbortService();
const hrStorageController = new HrStorageController(abortService);

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		maxSize: [Number, String],
		formats: Array,
		file: [File, Object],
		label: {
			type: String,
			default: i18n.t("fields.uploadDocument.label")
		},
		placeholder: {
			type: String,
			default: i18n.t("fields.uploadDocument.placeholder")
		},
		loading: Boolean,
		required: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dropzoneOptions: {
				autoProcessQueue: false,
				url: DEFAULT_URL,
				maxFilesize: this.maxSize,
				acceptedFiles: "",
				maxFiles: 1
			},
			files: [],
			isLoading: false
		};
	},
	computed: {
		filename() {
			return this.file.name;
		}
	},
	methods: {
		async handleFileAdded(file) {
			this.clear();

			this.isLoading = true;
			this.$emit("file:loading", true);

			try {
				const preparedFile = new File([file], file.name, {
				  lastModified: file.lastModified,
				  type: file.type || FileTypes.DEFAULT
				});

				const { name, type } = preparedFile;

				this.files.push({ name });

				const res = await hrStorageController.createTemperFile(new ApiFile(preparedFile, name, "", type, ""));

				this.$emit("file:add", res);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isLoading = false;
				this.$emit("file:loading", false);
			}
		},
		onDelete(name) {
			this.files = this.files.filter(file => file.name !== name);
			this.$emit("file:delete", name);
		},
		setAcceptedFiles() {
			if(!this.formats) return;
			
			const res = [];
			
			this.formats.forEach(format => res.push(getMimeType(format)));
			this.dropzoneOptions.acceptedFiles = res.join(",");
		},
		clear() {
			this.$refs.vueDropzone.removeAllFiles(true);
			this.$emit("file:delete");
			this.files = [];
		},
		handleClick() {
			this.$refs.vueDropzone.$el.click();
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		formats: {
			handler(formats) {
				this.setAcceptedFiles(formats);
			},
			immediate: true
		},
		file: {
			handler(value) {
				if(value) {
					this.files.push(value);
				}
			},
			immediate: true
		}
	},
	components: {
		FrpBtn,
		BodFile,
		VueDropzone,
		FrpIcon,
		FrpTextField
	}
};
</script>
