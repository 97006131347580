<template>
	<frp-dialog :value="value"
				:title="$t(`dialogs.selectSigner.title`)"
				max-width="500"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<frp-autocomplete v-model="signer"
							  :items="signers"
							  item-text="title"
							  item-value="code"
							  color="blue"
							  class="bar-field"
							  hide-details
							  top
							  :placeholder="$t('fields.selectSigner.placeholder')">
			</frp-autocomplete>
		</template>
		
		<template #footer>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :disabled="!signer"
					 @click="handleClick">
				{{ $t("buttons.ok") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="red"
					 dark
					 @click="$emit('update:value', false)">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { ApiSignerEnum } from "@/api/bar/types/ApiSignerEnum";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			signer: null
		};
	},
	computed: {
		...mapState({
			signers: state => state.signers
		})
	},
	methods: {
		handleClick() {
			this.$emit(`click:ok`, this.signer);
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpAutocomplete,
		FrpDialog,
		FrpBtn
	}
};
</script>
