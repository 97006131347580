import { HrUpdateEmployeeVacationDaysRequest } from "@/types/hr/vacation/hrUpdateEmployeeVacationDays";

export interface ApiHrUpdateEmployeeVacationDaysRequest {
	vacationDays: number;
	comment?: string;
}

export class ApiHrUpdateEmployeeVacationDaysMapper {
	static map(source: HrUpdateEmployeeVacationDaysRequest): ApiHrUpdateEmployeeVacationDaysRequest {
		return {
			...source,
			vacationDays: source.vacationDays!,
			comment: source.comment || ""
		};
	}
}
