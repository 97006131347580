import { ApiHrVacationApplicationSubstitute } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationSubstitute";
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import { convertToTimestamp } from "@/utils/dates";
import { ApiHrVacationApplicationApprover } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationApprover";

export interface HrVacationApplicationApprover {
	employee: ApiHrVacationApplicationSubstitute;
	state: ApiHrVacationApplicationStateEnum;
	stateUpdatedAt: number;
}

export class HrVacationApplicationApproverService {
	static map(source: ApiHrVacationApplicationApprover): HrVacationApplicationApprover {
		return {
			...source,
			stateUpdatedAt: convertToTimestamp(source.stateUpdatedAt) as number
		};
	}
}
