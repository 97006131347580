import { stringifyQuery } from "@/utils/query";
import FrpInternalServerError from "@/views/errors/FrpInternalServerError.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpSilentRenew from "@/views/FrpSilentRenew.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import BarLayout from "@/views/layouts/BarLayout.vue";
import FrpEmptyLayout from "@/views/layouts/FrpEmptyLayout.vue";
import FrpCallback from "@/views/FrpCallback.vue";
import { RouteNames } from "@/router/bar/routes";
import { configureMiddleware } from "@/router/bar/middleware";
import BarBankAccountsList from "@/views/bar/bankAccountsList/BarBankAccountsList.vue";
import BarBankAccountDetails from "@/views/bar/bankAccount/BarBankAccountDetails.vue";
import BarBankAccountUpdate from "@/views/bar/bankAccount/BarBankAccountUpdate.vue";
import BarBankAccountCreate from "@/views/bar/bankAccount/BarBankAccountCreate.vue";
import BarBankAccountApplicationsList from "@/views/bar/bankAccountApplicationsList/BarBankAccountApplicationsList.vue";
import BarBankAccountApplicationCreate from "@/views/bar/bankAccountApplication/BarBankAccountApplicationCreate.vue";
import BarBankAccountApplicationDetails from "@/views/bar/bankAccountApplication/BarBankAccountApplicationDetails.vue";
import BarBankAccountApplicationUpdate from "@/views/bar/bankAccountApplication/BarBankAccountApplicationUpdate.vue";
import FrpRouterView from "@/components/common/FrpRouterView.vue";
import FrpCounterparty from "@/views/counterparty/FrpCounterparty.vue";
import FrpCounterpartyGeneral from "@/views/counterparty/sections/FrpCounterpartyGeneral.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: FrpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: FrpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: BarLayout,
		name: RouteNames.ROOT,
		children: [
			{
				path: "bank-account-applications",
				component: BarBankAccountApplicationsList,
				name: RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST
			},
			{
				path: "bank-account-applications/bank-account-application",
				component: FrpRouterView,
				children: [
					{
						path: ":id",
						component: BarBankAccountApplicationDetails,
						query: ["loanApplicationNumber", "sourceType"],
						name: RouteNames.BANK_ACCOUNT_APPLICATION
					},
					{
						path: ":id/update",
						component: BarBankAccountApplicationUpdate,
						query: ["loanApplicationNumber", "sourceType"],
						name: RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE
					},
					{
						path: "",
						component: BarBankAccountApplicationCreate,
						query: ["loanApplicationNumber", "sourceType"],
						name: RouteNames.BANK_ACCOUNT_APPLICATION_CREATE
					},
				]
			},
			{
				path: "bank-accounts",
				component: BarBankAccountsList,
				name: RouteNames.BANK_ACCOUNTS_LIST,
			},
			{
				path: "bank-accounts/bank-account",
				component: FrpRouterView,
				children: [
					{
						path: ":id",
						component: BarBankAccountDetails,
						name: RouteNames.BANK_ACCOUNT,
					},
					{
						path: "",
						query: ["loanApplicationNumber"],
						component: BarBankAccountCreate,
						name: RouteNames.BANK_ACCOUNT_CREATE,
					},
				]
			},
		]
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: FrpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: FrpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: FrpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
