import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import EmployeesFilter from "@/store/hr/modules/employees/types/employeesFilter";
import { HrDepartment } from "@/types/hr/hrDepartment";

export default class EmployeesState implements IPageState {
	constructor(
		public listing: ListingModel<ApiHrEmployeesItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: EmployeesFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public departments: HrDepartment[] = []
	)
	{
	}
}
