<template>
	<v-app v-if="isAppLoading && $route.name !== RouteNames.CALLBACK && $route.name !== RouteNames.SILENT_RENEW">
		<v-main>
			<frp-center-content-layout>
				<frp-spinner-loader color="blue"/>
			</frp-center-content-layout>
		</v-main>
	</v-app>
	<router-view v-else/>
</template>

<script>
import FrpCenterContentLayout from "@/components/layouts/FrpCenterContentLayout.vue";
import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";
import { RouteNames } from "@/router/bar/routes";
import { mapState } from "vuex";

export default {
	name: "BarApp",
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		...mapState({
			isAppLoading: state => state.isAppLoading
		})
	},
	components: { FrpCenterContentLayout, FrpSpinnerLoader }
};
</script>
