<template>
	<div style="position: relative">
		<radar :width="340" :height="170" :chart-data="chartData" :chart-options="chartOptions"/>
	</div>
</template>

<script>
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, RadialLinearScale, Filler } from "chart.js";
import colorsMixin from "Mixins/colorsMixin";
import { Radar } from "vue-chartjs/legacy";

ChartJS.register(Title, Tooltip, Legend, PointElement, RadialLinearScale, LineElement, Filler);


export default {
	mixins: [colorsMixin],
	props: {
		groups: {
			type: Array, // HrFeedbackGroup[]
			required: true
		}
	},
	data() {
		return {
			chartOptions: {
				interaction: {
					intersect: false,
					mode: "nearest",
					axis: "xy"
				},
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					}
				},
				elements: {
					point: {
						radius: 0
					}
				},
				scales: {
					r: {
						ticks: {
							display: false,
							stepSize: 17
						},
						grid: {
							circular: true
						},
						suggestedMin: 0,
						suggestedMax: 100
					}
				}
			}
		};
	},
	computed: {
		chartData() {
			return {
				labels: this.groups[0].values.map(x => x.label),
				datasets: this.groups.map(x => ({
					label: x.title,
					data: x.values.map(x => x.value || 0),
					fill: true,
					backgroundColor: x.color,
					borderColor: "transparent"
				}))
			};
		}
	},
	components: { Radar }
};
</script>
