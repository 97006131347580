import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { isNil } from "lodash";
import VacationPlanHolidaysRouteParams from "@/store/hr/modules/vacationPlanHolidays/types/vacationPlanHolidaysRouteParams";
import VacationPlanHolidaysState from "@/store/hr/modules/vacationPlanHolidays/types/vacationPlanHolidaysState";

export default class VacationPlanHolidaysRouteService {
	defaultRouteParams: VacationPlanHolidaysRouteParams;

	constructor(defaultRouteParams: VacationPlanHolidaysRouteParams) {
		this.defaultRouteParams = defaultRouteParams;
	}

	resolveRouteParamsDictionary(state: VacationPlanHolidaysState): Dictionary<string> {
		let params = new VacationPlanHolidaysRouteParams(
			state.filter.year
		);
		return difference(this.defaultRouteParams, params);
	}

	async resolveRouteParams(params: Dictionary<any>) {
		try {
			let result = plainToInstance(VacationPlanHolidaysRouteParams, {
				...this.defaultRouteParams,
				...params,
				year: isNil(params.year) ? undefined : +params.year
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteParams;
		}
	}
}
