<template>
	<div class="d-flex align-center">
		<div :style="`width: 21px; height: 8px; background-color: ${color}`" class="mr-2"></div>
		<span :style="`color: ${textColor}`">
			<slot>
				{{ text }}
			</slot>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: "var(--v-grey-lighten5)"
		},
		textColor: {
			type: String,
			default: "var(--v-primary-darken1)"
		},
		text: String
	}
};
</script>
