<template>
	<v-row>
		<v-col class="px-2">
			<hr-card :title="$t('titles.subordinates')" dense :centered-content="!isInitialized || !items.length">
				<v-progress-circular class="mb-4" style="min-height: 188px"
									 v-if="!isInitialized" indeterminate color="primary">
				</v-progress-circular>
				<div style="min-height: 60px" class="fill-height d-flex align-center justify-center mb-4" v-else-if="!items.length">
					{{ $t("alerts.info.noData") }}
				</div>
				<v-data-table v-else
							  :headers="headers"
							  :loading-text="$t('tables.loading')"
							  :loading="isItemsLoading"
							  hide-default-footer
							  item-key="id"
							  :items="formattedItems"
							  :mobile-breakpoint="mobileBreakpoint"
							  :items-per-page="-1"
							  ref="table"
							  :options="options"
							  class="loan-table bod-table hr-inner-table d-flex flex-column flex-grow-1">					
					<template #item.fullName="{ item }">
						<div class="d-flex align-center">
							<frp-icon class="mr-4" :color="colors.orange.darken1" src="ico_subordinate"></frp-icon>
							<frp-router-link-btn :to="{ name: RouteNames.EMPLOYEE, params: { id: item.id } }">
								<span :style="`font-size: 12px; font-weight: 600; color: ${colors.blue.base}`">
									{{ `${item.lastName} ${item.firstName}` }}
								</span>
							</frp-router-link-btn>
						</div>
					</template>
					
					<template #item.kpi="{ item }">
						<v-progress-circular v-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.KPI).loading"
											 width="2" size="20" indeterminate>
						</v-progress-circular>
						<hr-subordinates-error-value v-else-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.KPI).error"
													 v-bind="getValueState(item.id, HrSubordinatesTableValueTypeEnum.KPI).error">
						</hr-subordinates-error-value>
						<span :style="`color: ${getValueColor(item.kpi.kpi)}`" v-else-if="!isNil(item.kpi?.kpi)">
							{{ `${item.kpi.kpi}%` }}
						</span>
					</template>
					
					<template #item.standardAwareness="{ item }">
						<v-progress-circular v-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.STANDARD).loading"
											 width="2" size="20" indeterminate>
						</v-progress-circular>
						<hr-subordinates-error-value v-else-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.STANDARD).error"
													 v-bind="getValueState(item.id, HrSubordinatesTableValueTypeEnum.STANDARD).error">
						</hr-subordinates-error-value>
						<span :style="`color: ${getValueColor(item.standardAwareness)}`" v-else-if="!isNil(item.standardAwareness)">
							{{ `${item.standardAwareness}%` }}
						</span>
					</template>
					
					<template #item.discipline="{ item }">
						<v-progress-circular v-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.DISCIPLINE).loading"
											 width="2" size="20" indeterminate>
						</v-progress-circular>
						<hr-subordinates-error-value v-else-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.DISCIPLINE).error"
													 v-bind="getValueState(item.id, HrSubordinatesTableValueTypeEnum.DISCIPLINE).error">
						</hr-subordinates-error-value>
						<span :style="`color: ${getValueColor(item.discipline)}`" v-else-if="!isNil(item.discipline)">
							{{ `${item.discipline}%` }}
						</span>
					</template>
					
					<template #item.vacationRemainingDays="{ item }">
						<v-progress-circular v-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.VACATION).loading"
											 width="2" size="20" indeterminate>
						</v-progress-circular>
						<hr-subordinates-error-value v-else-if="getValueState(item.id, HrSubordinatesTableValueTypeEnum.VACATION).error"
													 v-bind="getValueState(item.id, HrSubordinatesTableValueTypeEnum.VACATION).error">
						</hr-subordinates-error-value>
						<span v-else>{{ item.vacationRemainingDays }}</span>
					</template>
				</v-data-table>
			</hr-card>
		</v-col>
	</v-row>
</template>

<script>
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import HrCard from "Components/cards/HrCard";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { listMixin } from "Mixins/listMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/hr/routes";
import { getterTypes } from "Store/hr/modules/employee/modules/subordinates/types";
import { HrSubordinatesTableValueTypeEnum } from "Store/hr/modules/employee/modules/subordinates/types/hrSubordinatesTableValueTypeEnum";
import { mutationTypes, actionTypes } from "Store/hr/modules/employees/types";
import storeManager from "Store/manager";
import HrSubordinatesErrorValue from "Views/hr/employee/sections/HrSubordinatesErrorValue";
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";

const namespace = storeManager.hr.employee.subordinates.namespace;
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, authorizationMixin, storeModuleBasedPage],
	data() {
		return {
			isNil,
			RouteNames,
			HrSubordinatesTableValueTypeEnum,
			namespace,
			headers: [
				{
					text: "",
					value: "fullName",
					class: "text-caption",
					sortable: false,
					width: "13%"
				},
				{
					text: this.$t("tables.department"),
					value: "department.name",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.position"),
					value: "position",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.phone"),
					value: "extensionPhone",
					class: "text-caption",
					sortable: false,
					width: "8%"
				},
				{
					text: this.$t("tables.vacationRemainingDays"),
					value: "vacationRemainingDays",
					class: "text-caption",
					sortable: false,
					width: "12%"
				},
				{
					text: this.$t("tables.hrKpi"),
					value: "kpi",
					class: "text-caption",
					sortable: false,
					width: "7%"
				},
				{
					text: this.$t("tables.hrDiscipline"),
					value: "discipline",
					class: "text-caption",
					sortable: false,
					width: "10%"
				},
				{
					text: this.$t("tables.hrStandardAwareness"),
					value: "standardAwareness",
					class: "text-caption",
					sortable: false,
					width: "14%"
				}
			]
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			valuesState: state => state.valuesState
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		})
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		}),
		getValueColor(value) {
			if(value <= 50)
				return this.colors.red.base;
			if(value > 50 && value < 85)
				return this.colors.orange.darken2;
			if(value >= 85)
				return this.colors.green.lighten3;
		},
		getValueState(id, type) {
			return this.valuesState.find(x => x.employeeId === id && x.type === type);
		},
		// Установка высоты для отображения только четырёх первых записей, остальное под скроллом
		updateTableHeight() {
			if(!this.$refs.table)
				return;
			
			const wrapper = this.$refs.table.$el.querySelector(".v-data-table__wrapper");
			const table = this.$refs.table.$children[0].$children[0].$el;
			const rows = [...table.querySelectorAll("tr")];
			const heightValues = rows.slice(0, 5).map(x => x.offsetHeight)
			const height = heightValues.reduce((acc, val) => acc + val, 0);
			
			wrapper.style = `max-height: ${height}px`;
		}
	},
	created() {
		this.initializeStore();
	},
	watch: {
		async isInitialized() {
			await this.$nextTick();
			this.updateTableHeight();
		}
	},
	components: { HrSubordinatesErrorValue, FrpIcon, FrpRouterLinkBtn, HrCard }
};
</script>
