import { BankAccountData } from "@/store/bar/types/bankAccountData";
import { convertToZonedIso } from "@/utils/dates";

export interface ApiChangeBankAccountDataRequest {
	accountNumber: string;
	currencyId: string;
	openedAt: string;
}

export class ApiChangeBankAccountDataRequestMapper {
	static map(source: BankAccountData): ApiChangeBankAccountDataRequest {
		return {
			accountNumber: source.accountNumber,
			currencyId: source.currencyId,
			openedAt: convertToZonedIso(source.openedAt)
		};
	}
}