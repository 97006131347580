<template>
	<frp-card v-if="isInitialized" :title="$t('titles.bankAccounts')" class="pb-1">
		<template #actions>
			<frp-btn v-if="foundBankTemplate"
					 @click="showDialogOrDownloadTemplate"
					 :loading="isBankApplicationTemplateDownloading"
					 :disabled="isBankApplicationTemplateDownloading"
					 elevation="0"
					 dark
					 color="blue">
				{{ $t("buttons.downloadApplication") }}
			</frp-btn>
			<span v-else style="font-size: 14px">
				{{ $t("common.noTemplateForBank") }}
			</span>
		</template>
		
		<template #content>
			<bar-details-group>
				<template v-for="account in editableItem.accounts">
					<bar-details-item v-if="bankAccountTypes.length && account.accountType && account.accountNumber"
									  :title="bankAccountTypes.find(x => x.code === account.accountType)?.title">
						<frp-text-btn v-if="account.accountId"
									  :to="{ name: RouteNames.BANK_ACCOUNT, params: { id: account.accountId }}"
									  class="pa-0 bar-text-btn bar-project-link font-weight-bold"
									  style="min-width: fit-content;"
									  :text="account.accountNumber"
									  color="primary">
						</frp-text-btn>
						<span v-else class="text-subtitle-2 font-weight-medium primary--text">{{ account.accountNumber }}</span>
					</bar-details-item>
				</template>
			</bar-details-group>
			
			<select-signer-dialog v-if="isSelectSignerDialogOpen"
								  v-model="isSelectSignerDialogOpen"
								  @click:ok="handleDownloadBankApplicationTemplate($event)"
			></select-signer-dialog>
		</template>
	</frp-card>
	<bar-bank-account-application-information-accounts-loader v-else/>
</template>

<script>
import { ApiSignerEnum } from "@/api/bar/types/ApiSignerEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import storeManager from "@/store/manager";
import BarBankAccountApplicationInformationAccountsLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsLoader.vue";
import SelectSignerDialog
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/dialogs/SelectSignerDialog.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			isSelectSignerDialogOpen: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			bankAccountTypes: state => state.bankAccountTypes,
			currencies: state => state.currencies,
			editableItem: state => state.editableItem,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading,
			isBankApplicationTemplateDownloading: state => state.isBankApplicationTemplateDownloading,
			bankSettings: state => state.bankSettings
		}),
		...mapGetters({
			getBankName: getterTypes.getBankName
		}),
		...barUserModuleHelpers.mapGetters({
			isBarUserAdministrator: barUserModuleGetterTypes.isBarUserAdministrator,
			isBarUserProjectFinancialControlDepartmentManager: barUserModuleGetterTypes.isBarUserProjectFinancialControlDepartmentManager
		}),
		headers() {
			return [
				{
					text: this.$t("tables.accountType"),
					value: "accountType",
					class: "text-caption primary--text text--lighten-1",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.accountNumber"),
					value: "accountNumber",
					class: "text-caption primary--text text--lighten-1",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.accountCurrency"),
					value: "accountCurrency",
					class: "text-caption primary--text text--lighten-1",
					width: "18%",
					sortable: false
				},
				{
					text: this.$t("tables.openingDate"),
					value: "openingDate",
					class: "text-caption primary--text text--lighten-1",
					width: "18%",
					sortable: false
				},
				{
					text: "",
					value: "delete",
					class: "text-caption primary--text text--lighten-1",
					width: "1%",
					sortable: false
				}
			];
		},
		foundBankTemplate() {
			if(this.isBarUserAdministrator || this.isBarUserProjectFinancialControlDepartmentManager) {
				const bankName = this.getBankName(this.editableItem.bankId);
				return this.bankSettings[bankName];
			}
			
			return false;
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			downloadBankApplicationTemplate: actionTypes.downloadBankApplicationTemplate
		}),
		showDialogOrDownloadTemplate() {
			if(this.foundBankTemplate?.requiresSigner) {
				this.isSelectSignerDialogOpen = true;
			} else {
				this.handleDownloadBankApplicationTemplate(null);
			}
		},
		handleDownloadBankApplicationTemplate(signer) {
			this.downloadBankApplicationTemplate({
				projectId: this.$route.params.id,
				signer
			});
		}
	},
	components: { SelectSignerDialog, FrpDialog, FrpTextBtn, BarBankAccountApplicationInformationAccountsLoader, FrpIcon, FrpDateField, FrpTextField, FrpAutocomplete, FrpBtn, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
