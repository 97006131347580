<template>
	<frp-card v-if="isInitialized" :title="$t('titles.documents')" class="pb-2">
		<template #content>
			<bar-card-content-layout class="pb-4">
				<v-row  class="pr-16">
					<v-col class="pt-0 pl-2">
						<span class="d-flex align-center">
							<frp-icon class="mr-2"
									  :color="colors.warning.base"
									  src="ico_notification">
							</frp-icon>
							<span class="mr-5 text-body-2 font-weight-medium warning--text">
								{{ $t("alerts.info.reminderForProvideOriginalDocuments") }}
							</span>
							<v-spacer></v-spacer>
							<span class="mr-4 d-inline-block flex-shrink-0" style="font-size: 12px; width: 135px">
								{{ $t("tables.electronicDocumentType") }}
							</span>
							<span class="mr-3 d-inline-block flex-shrink-0" style="font-size: 12px; width: 135px">
								{{ $t("tables.paperDocumentType") }}
							</span>
							<span class="mr-1 d-inline-block flex-shrink-0" style="font-size: 12px; width: 135px">
								{{ $t("tables.comment") }}
							</span>
						</span>
					</v-col>
				</v-row>
			</bar-card-content-layout>
			
			<v-divider class="mx-4"></v-divider>
			
			<bar-details-group>
				<template v-for="(item, i) in documentFileInfoItems">
					<bar-details-item class="bar-details-item--application-document"
									  :key="`${i}-${item.type}`"
									  :title="getBankAccountApplicationDocumentName(item)"
									  :link="!!item.storedFileId"
									  :tooltip="formatTooltip(item.createdAt)"
									  @click="handleDownloadFile(item)"
									  wrap-link>
						<template #prepend>
							<template v-if="[BankAccountApplicationDocumentTypeEnum.INFORMATIONAL_LETTER, BankAccountApplicationDocumentTypeEnum.ACCOUNT_CORRECTION_AGREEMENT].includes(item.type)">
								<span style="margin-right: 72px;"></span>
							</template>
							<template v-if="item.type === BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT">
								<frp-btn-info-tooltip :text="$t(`aliases.bankAccountApplicationDocumentType.${item.type}.primary`)"/>
								<frp-btn-info-tooltip :text="$t(`aliases.bankAccountApplicationDocumentType.${item.type}.secondary`)"/>
							</template>
							<template v-else-if="item.type === BankAccountApplicationDocumentTypeEnum.PAYMENT_ACCEPT_AGREEMENT">
								<span style="margin-right: 36px;"></span>
								<frp-btn-info-tooltip :text="$t(`aliases.bankAccountApplicationDocumentType.${item.type}.primary`)"/>
							</template>
						</template>
						<bar-bank-account-application-documents-document-item-actions :document-item="item" :document-index="i"/>
					</bar-details-item>
				</template>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-application-documents-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpBtnInfoTooltip from "@/components/buttons/FrpBtnInfoTooltip.vue";
import FrpAlert from "@/components/buttons/FrpBtnInfoTooltip.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { Permissions } from "@/constants/permissions";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/documents";
import { actionTypes, mutationTypes, getterTypes } from "@/store/bar/modules/bankAccountApplication/modules/documents/types";
import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import storeManager from "@/store/manager";
import { formatDate } from "@/utils/dates";
import { dateFormat, dateTimeFormat } from "@/utils/formats";
import BarBankAccountApplicationDocumentsDocumentItemActions
	from "@/views/bar/bankAccountApplication/sections/modules/documents/BarBankAccountApplicationDocumentsDocumentItemActions.vue";
import BarBankAccountApplicationDocumentsLoader
	from "@/views/bar/bankAccountApplication/sections/modules/documents/BarBankAccountApplicationDocumentsLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			isDialogOpened: false
		};
	},
	computed: {
		BankAccountApplicationDocumentTypeEnum() {
			return BankAccountApplicationDocumentTypeEnum;
		},
		...bankAccountApplicationHelpers.mapState({
			editableItem: state => state.editableItem
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			documentFileInfoItems: state => state.documentFileInfoItems
		}),
		...mapGetters({
			hasAccounts: getterTypes.hasAccounts
		})
		
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			downloadFile: actionTypes.downloadFile
		}),
		async handleDownloadFile(item) {
			const isDocumentsDownloadAccessAllowed = this.can(Permissions.BAR_APPLICATION_DOWNLOAD_DOCUMENTS_READ);
			if(!isDocumentsDownloadAccessAllowed) return;
			
			const { storedFileId } = item;
			
			if(storedFileId) {
				const docName = this.getBankAccountApplicationDocumentName(item);
				await this.downloadFile({ storedFileId, docName });
			}
		},
		getBankAccountApplicationDocumentName(item) {
			return this.$t(`aliases.bankAccountApplicationDocumentName.${item.type}`);
		},
		formatTooltip(value) {
			return formatDate(value, dateTimeFormat) || "";
		}
	},
	components: {
		FrpBtnInfoTooltip,
		BarBankAccountApplicationDocumentsLoader,
		BarBankAccountApplicationDocumentsDocumentItemActions,
		BarDetailsItem,
		BarDetailsGroup,
		FrpIcon,
		FrpTextField,
		FrpBtn,
		BarCardContentLayout,
		FrpCard,
		FrpAlert
	}
};
</script>

<style scoped lang="scss">
.bar-details-item--application-document {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
