<template>
	<frp-card is-loading>
		<template #content>
			<bar-details-group>
				<bar-details-item-loader>
					<frp-text-loader width="100"></frp-text-loader>
				</bar-details-item-loader>
				<bar-details-item-loader v-for="(item, i) in 3" :key="`${i}-first`">
					<v-skeleton-loader height="36" width="270" type="image" class="rounded-md"/>
				</bar-details-item-loader>
				<bar-details-item-loader>
					<frp-text-loader width="75"></frp-text-loader>
				</bar-details-item-loader>
				<bar-details-item-loader>
					<frp-text-loader width="160"></frp-text-loader>
				</bar-details-item-loader>
				<bar-details-item-loader v-for="(item, j) in 6" :key="`${j}-second`">
					<v-skeleton-loader height="36" width="270" type="image" class="rounded-md"/>
				</bar-details-item-loader>
			</bar-details-group>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import BarDetailsItemLoader from "@/components/loaders/details/BarDetailsItemLoader.vue";

export default {
	components: {
		FrpTextLoader,
		BarDetailsItemLoader,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
