<template>
	<bar-content-layout>
		<v-row>
			<v-col cols="5">
				<bar-bank-account-application-information-loader/>
			</v-col>
			<v-col cols="7">
				<v-row class="d-flex flex-column">
					<v-col class="pb-1">
						<bar-bank-account-application-information-letter-loader/>
					</v-col>
					
					<v-col class="pb-1">
						<bar-bank-account-application-documents-loader/>
					</v-col>
					
					<v-col class="pb-1">
						<bar-bank-account-application-legal-department-loader/>
					</v-col>
					
					<v-col class="pb-6">
						<bar-bank-account-application-messenger-loader/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<frp-btn-loader width="165" height="40"></frp-btn-loader>
		</template>
	</bar-content-layout>
</template>

<script>
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";
import BarBankAccountApplicationInformation
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformation.vue";
import BarBankAccountApplicationInformationLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformationLoader.vue";
import BarBankAccountApplicationDocuments
	from "@/views/bar/bankAccountApplication/sections/modules/documents/BarBankAccountApplicationDocuments.vue";
import BarBankAccountApplicationDocumentsLoader
	from "@/views/bar/bankAccountApplication/sections/modules/documents/BarBankAccountApplicationDocumentsLoader.vue";
import BarBankAccountApplicationInformationLetter
	from "@/views/bar/bankAccountApplication/sections/modules/informationLetter/BarBankAccountApplicationInformationLetter.vue";
import BarBankAccountApplicationInformationLetterLoader
	from "@/views/bar/bankAccountApplication/sections/modules/informationLetter/BarBankAccountApplicationInformationLetterLoader.vue";
import BarBankAccountApplicationLegalDepartment
	from "@/views/bar/bankAccountApplication/sections/modules/legalDepartment/BarBankAccountApplicationLegalDepartment.vue";
import BarBankAccountApplicationLegalDepartmentLoader
	from "@/views/bar/bankAccountApplication/sections/modules/legalDepartment/BarBankAccountApplicationLegalDepartmentLoader.vue";
import BarBankAccountApplicationMessenger
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessenger.vue";
import BarBankAccountApplicationMessengerLoader
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessengerLoader.vue";

export default {
	components: {
		BarBankAccountApplicationMessengerLoader,
		BarBankAccountApplicationLegalDepartmentLoader,
		BarBankAccountApplicationDocumentsLoader,
		BarBankAccountApplicationInformationLetterLoader,
		BarBankAccountApplicationInformationLoader,
		FrpBtnLoader,
		BarBankAccountApplicationMessenger,
		BarBankAccountApplicationLegalDepartment,
		BarBankAccountApplicationDocuments,
		BarBankAccountApplicationInformationLetter,
		BarBankAccountApplicationInformation,
		BarContentLayout
	}
};
</script>

<style scoped>

</style>
