import { actionTypes, mutationTypes, namespace } from "@/store/bar/modules/breadcrumbs/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";

import BreadcrumbsState from "@/store/bar/modules/breadcrumbs/types/breadcrumbsState";
import Breadcrumb from "@/store/bar/modules/breadcrumbs/types/breadcrumb";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import NotDefinedException from "@/exceptions/notDefinedException";
import baseMixinTypes from "@/store/shared/base/types";
import { isArray, isString } from "lodash";
import { findRoute, RouteNames, routesThreeRoot } from "@/router/bar/routes";
import { routeToStoreMap } from "@/router/bar/routeToStoreMap";
import { i18n } from "@/plugins";
import PermissionsService from "@/services/permissionsService";
import routeToPermissionsMap from "@/router/bar/routeToPermissionsMap";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import storeManager from "@/store/manager";
import BankAccountApplicationState from "@/store/bar/modules/bankAccountApplication/types/bankAccountApplicationState";

const permissionsService = new PermissionsService();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BreadcrumbsState();
	}
}

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BreadcrumbsState, any>>{};

const prepareBreadcrumb = async (routeName: RouteNames,
	{ params, query, text }: { params?: any, query?: any, text?: null | string } = { params: {}, query: {}, text: null }) => {
	const permissions = routeToPermissionsMap.get(routeName);
	if(!permissions) throw new NotDefinedException("permissions");
	
	return new Breadcrumb(
		text ?? i18n.t(`navigation.barBreadcrumbs.${routeName}`).toString(),
		{ name: routeName, params, query },
		!(await permissionsService.check(permissions))
	);
};

const actions = <ActionTree<BreadcrumbsState, any>>{
	async [actionTypes.resolveBreadcrumb]({ rootState, rootGetters, commit }, { segment, route }) {
		
		const routeName = segment as RouteNames;
		
		switch (routeName) {
			case RouteNames.BANK_ACCOUNT_APPLICATION:
			case RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE:
				const { editableItem } = resolveNestedState<BankAccountApplicationState>(rootState,
					storeManager.bar.bankAccountApplication.namespace);
				const { applicationNumber, createdAt } = editableItem;
				const formattedCreatedAt = formatDate(createdAt || Date.now(), dateFormat);
				
				return await prepareBreadcrumb(routeName, {
					text: i18n.t(`navigation.barBreadcrumbs.${routeName}`, { applicationNumber, createdAt: formattedCreatedAt }).toString(),
					params: { ...rootState.route.params },
					query: { ...rootState.route.query }
				});
			case RouteNames.BANK_ACCOUNT_APPLICATION_CREATE:
			case RouteNames.BANK_ACCOUNT_CREATE:
				return await prepareBreadcrumb(routeName, { query: { ...rootState.route.query } });
			case RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST:
			case RouteNames.BANK_ACCOUNTS_LIST:
			case RouteNames.BANK_ACCOUNT:
				return await prepareBreadcrumb(routeName);
			
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch }, routeName: string) {
		const result = [];
		
		if(routeName) {
			let route = routesThreeRoot.first(x => x.model.name === routeName);
			
			if(!route) throw new NotDefinedException("route");
			
			switch (route.model.name) {
				case RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST:
				case RouteNames.BANK_ACCOUNTS_LIST:
				{
					commit(mutationTypes.SET_ITEMS, []);
					commit(mutationTypes.SET_IS_LOADING, false);
					return;
				}
			}
			
			for (const segment of route.getPath()) {
				let breadcrumb = await dispatch(actionTypes.resolveBreadcrumb, { segment: segment.model.name, route: routeName });
				if(breadcrumb && breadcrumb.text)
					result.push(breadcrumb);
			}
		}
		
		commit(mutationTypes.SET_ITEMS, result);
		commit(mutationTypes.SET_IS_LOADING, false);
	}
};

const mutations = <MutationTree<BreadcrumbsState>>{
	[mutationTypes.SET_ITEMS](state, value) {
		state.items = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	}
};

const subscribe = (store: any) => {
	const initializedMap = new Map<string, string | string[]>();
	const beforeInitializedMap = new Map<string, string | string[]>();
	
	for (const [route, namespace] of routeToStoreMap) {
		initializedMap.set(route, namespace.map(x => resolveMutation(x, baseMixinTypes.mutationTypes.SET_IS_INITIALIZED)));
		beforeInitializedMap.set(route, namespace.map(x => resolveMutation(x, baseMixinTypes.mutationTypes.BEFORE_INITIALIZED)));
	}
	
	store.subscribe(async ({ type, payload }: any, state: any) => {
		const beforeInitializedEventValue = beforeInitializedMap.get(state.route.name);
		if(beforeInitializedEventValue) {
			if(isString(beforeInitializedEventValue)) {
				if(type === beforeInitializedEventValue) {
					store.commit(resolveMutation(namespace, mutationTypes.SET_IS_LOADING), true);
				}
			} else if(isArray(beforeInitializedEventValue)) {
				let result = beforeInitializedEventValue.find(x => type === x);
				if(result) {
					store.commit(resolveMutation(namespace, mutationTypes.SET_IS_LOADING), true);
				}
			}
		}
		
		
		const initializedEventValue = initializedMap.get(state.route.name);
		if(initializedEventValue) {
			if(isString(initializedEventValue)) {
				if(type === initializedEventValue) {
					store.dispatch(resolveAction(namespace, actionTypes.processRoute), state.route.name);
				}
			} else if(isArray(initializedEventValue)) {
				let result = initializedEventValue.find(x => type === x);
				if(result) {
					store.dispatch(resolveAction(namespace, actionTypes.processRoute), state.route.name);
				}
			}
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const breadcrumbsModule = {
	namespace, state, getters, actions, mutations, namespaced: true, subscribe
};

export default breadcrumbsModule;
