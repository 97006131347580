<template>
	<div class="bar-message-item flex-column mt-3">
		<div class="d-flex flex-column">
			<v-subheader class="pa-0 mb-1 d-flex justify-space-between"
						 style="height: fit-content; width: 100%">
				<v-row class="text-body-2">
					<v-col v-if="message.relatedMessageId" class="primary--text font-weight-bold" cols="10">
						<v-row>
							<v-col cols="5">
								<span>
									{{ getEmployeeFullName }}
								</span>
							</v-col>
							<v-col cols="2">
								<frp-icon :color="colors.primary.base"
										  src="ico_double-arrow-right">
								</frp-icon>
							</v-col>
							<v-col cols="5">
								<span>
									{{ getRelatedAnswerMessageEmployeeFullName }}
								</span>
							</v-col>
						</v-row>
					</v-col>
					<v-col v-else-if="!message.relatedMessageId && message.toEmployees.length"
						   class="primary--text font-weight-bold" cols="10">
						<v-row>
							<v-col cols="5">
								<span>
									{{ getEmployeeFullName }}
								</span>
							</v-col>
							<v-col cols="2">
								<frp-icon :color="colors.primary.base"
										  src="ico_double-arrow-right">
								</frp-icon>
							</v-col>
							<v-col cols="5">
								<span>
									{{ getRelatedMessageEmployeeFullName }}
								</span>
							</v-col>
						</v-row>

					</v-col>
					<v-col v-else class="primary--text font-weight-bold" cols="10">
						{{ getEmployeeFullName }}
					</v-col>
					
					<v-col class="bar-message-item--date grey--text lighten-3" cols="2">
						{{ formatDate(message.createdAt, timeFormat) }}
					</v-col>
				</v-row>
				
				<v-menu offset-y
						nudge-top="0"
						content-class="bar-message-item-menu"
						ref="bar-message-item-menu"
						transition="slide-y-transition">
					<template v-slot:activator="{ on, attrs }">
						<frp-btn small
								 v-bind="attrs"
								 v-on="on"
								 height="30"
								 :disabled="(isMessageDeleting && !message.isMessageDeleting) || !can([Permissions.BAR_APPLICATION_MESSAGES_CREATE, Permissions.BAR_APPLICATION_MESSAGES_UPDATE])"
								 :loading="message.isMessageDeleting"
								 style="margin: -14px -6px -12px -4px !important;"
								 :color="colors.grey.darken2"
								 icon>
							<frp-icon src="ico_more" :color="colors.primary.base"></frp-icon>
						</frp-btn>
					</template>
					<v-list class="pa-0">
						<v-list-item
							v-for="(item, i) in menuItems"
							:key="item.type"
							@click="handleSelectMenuAction(item.type)"
							:value="item.type">
							<v-list-item-title class="text-body-2 primary--text">
								{{ item.text }}
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-subheader>
			
			<div v-if="message.relatedMessageId" class="bar-message-item__related-message ml-4 mb-1">
				<div class="bar-message-item--message-text text-body-2 primary--text d-flex align-center justify-space-between">
					<span>{{ getRelatedMessageText }}</span>
				</div>
			</div>
		</div>
		<div class="bar-message-item--message-text text-body-2 primary--text d-flex align-center justify-space-between">
			<span>{{ message.messageText }}</span>
		</div>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/messenger";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { MessengerModeType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/messengerModeType";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import { BankAccountApplicationMessage } from "@/store/bar/types/bankAccountApplicationMessage";
import { Permissions } from "@/constants/permissions";
import storeManager from "@/store/manager";
import { formatDate } from "Utils/dates";
import { timeFormat } from "Utils/formats";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

const MessageMenuActionType = {
	ANSWER: "ANSWER",
	DELETE: "DELETE"
};

export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		message: {
			type: BankAccountApplicationMessage,
			required: true
		}
	},
	data() {
		return {
			formatDate,
			timeFormat,
			namespace,
			MessageMenuActionType,
			MessengerModeType
		};
	},
	computed: {
		...mapState({
			isMessageDeleting: state => state.isMessageDeleting,
			messages: state => state.messages
		}),
		...barUserModuleHelpers.mapGetters({
			isBarUserAdministrator: barUserModuleGetterTypes.isBarUserAdministrator,
			userId: barUserModuleGetterTypes.userId
		}),
		...bankAccountApplicationHelpers.mapState({
			staffEmployees: state => state.staffEmployees
		}),
		getEmployeeFullName() {
			return this.staffEmployees.find(x => x.id === this.message.employeeId)?.fullName || "";
		},
		getRelatedAnswerMessageEmployeeFullName() {
			const message = this.messages.find(x => x.id === this.message.relatedMessageId);
			return this.staffEmployees.find(x => x.id === message.employeeId)?.fullName || "";
		},
		getRelatedMessageEmployeeFullName() {
			return this.staffEmployees.find(x => x.id === this.message.toEmployees[0])?.fullName || "";
		},
		getRelatedMessageText() {
			return this.messages.find(x => x.id === this.message.relatedMessageId)?.messageText || "";
		},
		menuItems() {
			const defaultMenuItems = [
				{ type: MessageMenuActionType.ANSWER, text: this.$t("buttons.answer") }
			];
			
			const fullMenuItems = [
				...defaultMenuItems,
				{ type: MessageMenuActionType.DELETE, text: this.$t("buttons.remove") }
			];
			
			return this.can(Permissions.BAR_APPLICATION_MESSAGES_DELETE) &&
			(this.isBarUserAdministrator || this.userId === this.message.employeeId)
				? fullMenuItems
				: defaultMenuItems;
		}
	},
	methods: {
		...mapMutations({
			setMessengerMode: mutationTypes.SET_MESSENGER_MODE,
			setEditableMessageItemRelatedMessageId: mutationTypes.SET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID,
			resetEditableMessageItemToEmployees: mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_TO_EMPLOYEES,
			setEditableMessageItemSelectedEmployeeId: mutationTypes.SET_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID
		}),
		...mapActions({
			deleteMessage: actionTypes.deleteMessage
		}),
		handleSelectMenuAction(messageActionType) {
			switch (messageActionType) {
				case MessageMenuActionType.ANSWER:
					this.resetEditableMessageItemToEmployees();
					this.setMessengerMode(MessengerModeType.ANSWER);
					this.setEditableMessageItemRelatedMessageId(this.message.id);
					this.setEditableMessageItemSelectedEmployeeId(this.message.employeeId);
					break;
				case MessageMenuActionType.DELETE:
					this.deleteMessage(this.message.id);
					break;
			}
		}
	},
	components: { FrpIcon, FrpBtn }
};
</script>

<style lang="scss">
.bar-message-item {
	max-width: 570px !important;

	&__related-message {
		border-left: 2px solid var(--v-blue-base);
	}

	&--date {
		font-style: italic;
	}

	&--message-text {
		background: var(--v-white-lighten1);
		border-radius: 6px;
		padding: 5px 16px 4px 14px !important;
		min-height: 45px;
	}
}

.bar-message-item-menu {
	background: var(--v-white-base) !important;
	box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	z-index: 2 !important;
}
</style>
