import { ApiBankAccountApplicationMessagePersisted } from "@/api/bar/types/apiBankAccountApplicationMessagePersisted";
import { convertToTimestamp, formatDate } from "@/utils/dates";
import { friendlyDateFormat } from "@/utils/formats";
import { MessageType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/MessageType";

export interface BankAccountApplicationMessage {
	id: string;
	createdAt: number;
	relatedMessageId: string;
	messageText: string;
	employeeId: string;
	toEmployees: string[];
	isMessageDeleting: boolean;
	groupedDate: string;
	type: MessageType;
}

export class BankAccountApplicationMessageHelper {
	static map(source: ApiBankAccountApplicationMessagePersisted): BankAccountApplicationMessage {
		return {
			...source,
			...source.bankAccountApplicationMessage,
			createdAt: convertToTimestamp(source.createdAt) as number,
			groupedDate: formatDate(convertToTimestamp(source.createdAt) as number, friendlyDateFormat) as string,
			isMessageDeleting: false
		}
	}
	
	static getEmpty(): BankAccountApplicationMessage {
		return {
			id: "",
			createdAt: 0,
			relatedMessageId: "",
			messageText: "",
			employeeId: "",
			toEmployees: [],
			isMessageDeleting: false,
			groupedDate: "",
			type: MessageType.Unknown
		};
	}
}