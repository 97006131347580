import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import { BankAccountApplicationMessage, BankAccountApplicationMessageHelper } from "@/store/bar/types/bankAccountApplicationMessage";
import { MessengerModeType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/messengerModeType";

export default class BankAccountApplicationMessengerState implements IPageState {
	constructor(
		public form: FormState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isMessagesFetching: boolean = false,
		public messages: BankAccountApplicationMessage[] = [],
		public isMessageDeleting: boolean = false,
		public isMessengerFullscreen: boolean = false,
		public editableMessageItem: BankAccountApplicationMessage = BankAccountApplicationMessageHelper.getEmpty(),
		public messengerMode: MessengerModeType = MessengerModeType.DEFAULT
	)
	{
	}
}
