<template>
	<div>
		<v-tooltip bottom
				   :open-on-focus="false"
				   v-model="show"
				   :max-width="600"
				   :color="colors.blue.base">
			<template v-slot:activator="{ on, attrs }">
				<frp-btn icon
						 v-on="on"
						 v-bind="attrs"
						 :color="colors.grey.base"
						 @click="show = !show">
					<frp-icon src="ico_info2" :color="colors.blue.base"></frp-icon>
				</frp-btn>
			</template>
			<span>{{ text }}</span>
		</v-tooltip>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	components: { FrpTextBtn, FrpIcon, FrpBtn, FrpDialog },
	props: {
		text: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			show: false
		};
	}
};
</script>

<style scoped>

</style>