<template>
	<frp-card is-loading>
		<template #content>
			<v-container class="px-0 mt-5 pb-5">
				<v-row class="mx-1 pb-3">
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="70"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="90"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="95"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="100"/>
					</v-col>
					<div style="width: 40px; visibility: hidden" class="d-flex flex-column px-3 mr-1">
						<v-skeleton-loader type="text" max-width="16"/>
					</div>
				</v-row>
				<v-divider class="mt-3 mb-4"></v-divider>
				<v-row class="mx-1">
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader height="36" max-width="213" type="image" class="rounded-md"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader height="36" max-width="205" type="image" class="rounded-md"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader height="36" max-width="140" type="image" class="rounded-md"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader height="36" max-width="140" type="image" class="rounded-md"/>
					</v-col>
					<div style="width: 40px;" class="d-flex flex-column justify-center px-3 mr-1">
						<v-skeleton-loader height="16" max-width="16" type="image" class="rounded-md"/>
					</div>
				</v-row>
			</v-container>
		</template>
		
		<template #actions>
			<frp-btn-loader height="32" width="152"></frp-btn-loader>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	
	components: {
		FrpBtnLoader,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
