<template>
	<hr-card :title="$t('titles.employeeKpi')" :subtitle="subtitle" style="min-width: min-content;"
			 :centered-content="!isInitialized || isNil(kpi?.completionRate)"
			 :href="kpiUrl">
		<v-progress-circular v-if="!isInitialized" indeterminate color="primary"></v-progress-circular>
		<div style="width: 100%" class="fill-height d-flex align-center justify-center mb-4" v-else-if="isNil(kpi?.completionRate)">
			{{ $t("alerts.info.noData") }}
		</div>
		<template v-else>
			<hr-chart-legend>
				<hr-chart-legend-item :color="colors.blue.lighten5">{{ chartLabel }}</hr-chart-legend-item>
				<hr-chart-legend-item v-if="kpi.laborRate">
					{{ `${$t("chart.legend.laborRate")} - ${kpi.laborRate} %` }}
				</hr-chart-legend-item>
			</hr-chart-legend>
			<hr-chart-pie :label="chartLabel" :value="kpi.completionRate"></hr-chart-pie>
		</template>
	</hr-card>
</template>

<script>
import { HR_KPI_AMOUNT_OF_SUB_QUARTERS, HR_KPI_QUARTER_START_DAY } from "@/constants/hr/date";
import { setQuarterStartDate } from "@/utils/dates";
import { ApiKpiQuarterEnum } from "Api/kpi/types/ApiKpiQuarterEnum";
import HrCard from "Components/cards/HrCard";
import HrChartLegendItem from "Components/charts/HrChartLegendItem";
import HrChartLegend from "Components/charts/HrChartLegend";
import HrChartPie from "Components/charts/HrChartPie";
import FrpIcon from "Components/icon/FrpIcon";
import { externalUrls } from "Constants/hr/externalUrls";
import { getQuarter, getYear, subQuarters } from "date-fns";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes } from "Store/hr/modules/employee/modules/kpi/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";

const namespace = storeManager.hr.employee.kpi.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			isNil,
			externalUrls
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			kpi: state => state.kpi
		}),
		subtitle() {
			return this.$t("subheaders.forQuarter", { quarter: ApiKpiQuarterEnum[this.period.quarter] });
		},
		chartLabel() {
			return this.$t("chart.legend.completionRate");
		},
		period() {
			const date = subQuarters(setQuarterStartDate(new Date(), HR_KPI_QUARTER_START_DAY), HR_KPI_AMOUNT_OF_SUB_QUARTERS);
			
			return { quarter: getQuarter(date), year: getYear(date) };
		},
		kpiUrl() {
			return externalUrls.kpi ?
				`${externalUrls.kpi}/${getYear(new Date())}/${getQuarter(new Date())}?staffIds=${this.$route.params.id}` :
				undefined;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		HrChartPie,
		HrCard,
		HrChartLegend,
		HrChartLegendItem,
		FrpIcon
	}
};
</script>
