<template>
	<div v-if="closestVacation.id">
		<frp-dialog :value="internalValue"
					title=""
					max-width="776"
					@update:value="$emit('update:value', $event)">
			<template #content="{ onIntersect }">
				<div style="font-size: 20px; line-height: 27px" class="text-justify">
				<span class="font-weight-bold d-block">
					{{ $t("dialogs.vacationApplicationRequired.part1") }}: {{ startDate }} – {{ endDate }}.
				</span>
					<span>{{ $t("dialogs.vacationApplicationRequired.part2") }}.</span>
					<div class="mt-6">
						<span class="mr-1">{{ $t("dialogs.vacationApplicationRequired.part3") }} {{ deadline }}.</span>
						<span class="font-weight-bold">
					{{ $t("dialogs.vacationApplicationRequired.part4") }}.
				</span>
					</div>
				</div>
				
				<frp-bottom-space height="10"></frp-bottom-space>
			</template>
			
			<template #footer>
				<div class="d-flex" style="width: 100%">
					<frp-btn elevation="0"
							 color="blue"
							 style="flex-basis: 50%"
							 :to="vacationPlanLink"
							 dark>
						{{ $t("buttons.wantToEditVacationPlan") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="green lighten-4"
							 style="flex-basis: 50%"
							 @click="goToCreateApplicationPage"
							 dark>
						{{ $t("buttons.wantToCreateVacationApplication") }}
					</frp-btn>
				</div>
			</template>
		</frp-dialog>
	</div>
</template>

<script>
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import HrVacationPlanStateLabel from "@/components/common/HrVacationPlanStateLabel.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpBottomSpace from "@/components/layouts/FrpBottomSpace.vue";
import { namespace } from "@/store/hr/modules/employee/types";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { getYear, subDays } from "date-fns";
import { RouteNames } from "Router/hr/routes";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			dateFormat,
			internalValue: false
		};
	},
	computed: {
		...mapState({
			closestVacation: state => state.closestVacation
		}),
		startDate() {
			return formatDate(this.closestVacation.startDate, dateFormat);
		},
		endDate() {
			return formatDate(this.closestVacation.endDate, dateFormat);
		},
		deadline() {
			return formatDate(subDays(this.closestVacation.startDate, 14), dateFormat);
		},
		vacationPlanLink() {
			return {
				name: RouteNames.VACATION_PLANS,
				params: { year: getYear(this.closestVacation.startDate) },
				query: { versionId: this.closestVacation.vacationsPlanId }
			};
		}
	},
	methods: {
		formatDate,
		getYear,
		goToCreateApplicationPage() {
			this.$router.push({ name: RouteNames.VACATION_APPLICATION_CREATE, query: { id: this.closestVacation.id } });
			
			this.$emit("update:value", false);
		}
	},
	watch: {
		value: {
			async handler(value) {
				await this.$nextTick();
				this.internalValue = value;
			},
			immediate: true
		}
	},
	components: {
		FrpBottomSpace,
		HrVacationPlanStateLabel,
		FrpRouterLinkBtn,
		FrpDateField,
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
