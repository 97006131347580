<template>
	<span v-on="$listeners" :style="{ color }">{{ text }}</span>
</template>

<script>
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: [String, ApiHrVacationApplicationStateEnum]
		}
	},
	computed: {
		text() {
			return this.$t(`aliases.vacationApplicationState.${this.value}`);
		},
		color() {
			switch (this.value) {
				case ApiHrVacationApplicationStateEnum.New:
					return this.colors.blue.base;
				case ApiHrVacationApplicationStateEnum.InReview:
					return this.colors.orange.darken3;
				case ApiHrVacationApplicationStateEnum.Approved:
					return this.colors.green.lighten3;
				case ApiHrVacationApplicationStateEnum.Withdrawn:
					return this.colors.grey.base;
				case ApiHrVacationApplicationStateEnum.Declined:
					return this.colors.secondary.lighten2;
			}
		}
	}
};
</script>
