import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter } from "@/utils/query";
import VacationPlanEmployeesRouteParams from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesRouteParams";
import VacationPlanEmployeesRouteQuery from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesRouteQuery";
import VacationPlanEmployeesState from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesState";
import { isNil } from "lodash";

export default class VacationPlanEmployeesRouteService {
	defaultRouteQuery: VacationPlanEmployeesRouteQuery;
	defaultRouteParams: VacationPlanEmployeesRouteParams;
	
	constructor(defaultRouteQuery: VacationPlanEmployeesRouteQuery, defaultRouteParams: VacationPlanEmployeesRouteParams) {
		this.defaultRouteQuery = defaultRouteQuery;
		this.defaultRouteParams = defaultRouteParams;
	}
	
	resolveRouteQueryDictionary(state: VacationPlanEmployeesState) {
		let query = new VacationPlanEmployeesRouteQuery(
			state.filter.departmentIds,
			state.filter.employeeIds,
			state.sorting.type,
			state.sorting.order
		);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	resolveRouteParamsDictionary(state: VacationPlanEmployeesState): Dictionary<string> {
		let params = new VacationPlanEmployeesRouteParams(
			state.filter.year
		);
		return difference(this.defaultRouteParams, params);
	}
	
	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(VacationPlanEmployeesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				departmentIds: parseArrayParameter(query.departmentIds),
				employeeIds: parseArrayParameter(query.employeeIds)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
	
	async resolveRouteParams(params: Dictionary<any>) {
		try {
			let result = plainToInstance(VacationPlanEmployeesRouteParams, {
				...this.defaultRouteParams,
				...params,
				year: isNil(params.year) ? undefined : +params.year
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteParams;
		}
	}
}
