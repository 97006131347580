export enum BankAccountApplicationButtonTypeEnum {
	DSP_BUTTON = "DSPButton",
	UFKP_BUTTON = "UFKPButton",
	ON_CONNECT_BUTTON = "OnConnectButton",
	EDIT_BUTTON = "EditButton",
	COMMENT_BUTTON = "CommentButton",
	ELECTRONIC_STATUS_BUTTON = "ElectronicStatusButton",
	PAPER_STATUS_BUTTON = "PaperStatusButton",
	SEND_MESSAGE_BUTTON = "SendMessageButton",
	SEND_REQUEST_TO_LD_BUTTON = "SendRequestToLdButton",
	GENERATE_LETTER_BUTTON = "GenerateLetterButton",
	UPLOAD_DOCUMENT_BUTTON = "UploadDocumentButton",
}
