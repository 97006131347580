import { IsNumber, ValidateIf } from "class-validator";

export default class VacationPlansRouteParams {
	@IsNumber()
	@ValidateIf((_, value) => value !== undefined)
	year?: number;

	constructor(
		year?: number
	)
	{
		this.year = year;
	}
}
