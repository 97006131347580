import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "tasks";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	taskAssignees: "taskAssignees",
	currentUser: "currentUser",
	isAdmin: "isAdmin"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...listingActionTypes,
	...routeTypes.actionTypes,
	approveTask: "approveTask",
	declineTask: "declineTask",
	signFile: "signFile",
	declineSigningTask: "declineSigningTask",
	markTaskInformationAsDone: "markTaskInformationAsDone",
	downloadFile: "downloadFile",
	generatePdfUrl: "generatePdfUrl",
	getFileMeta: "getFileMeta",
	setAdminDefaultFilter: "setAdminDefaultFilter",
	fetchEmployees: "fetchEmployees",
	downloadArchive: "downloadArchive",
	fetchApproval: "fetchApproval",
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...listingMutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_TASK_TYPES: "SET_FILTER_TASK_TYPES",
	SET_FILTER_TASK_STATES: "SET_FILTER_TASK_STATES",
	SET_FILTER_TASK_ASSIGNEE_IDS: "SET_FILTER_TASK_ASSIGNEE_IDS",
	SET_FILTER_TASK_ID: "SET_FILTER_TASK_ID",
	RESET_FILTER: "RESET_FILTER",
	SET_SELECTED_TASK: "SET_SELECTED_TASK",
	RESET_SELECTED_TASK: "RESET_SELECTED_TASK",
	SET_IS_DECLINING: "SET_IS_DECLINING",
	SET_DECLINE_REASON: "SET_DECLINE_REASON",
	RESET_DECLINE_REASON: "RESET_DECLINE_REASON",
	SET_PDF_URL: "SET_PDF_URL",
	RESET_PDF_URL: "RESET_PDF_URL",
	SET_EMPLOYEES: "SET_EMPLOYEES",
	SET_IS_EMPLOYEES_LOADING: "SET_IS_EMPLOYEES_LOADING",
	SET_FILTER: "SET_FILTER",
	SET_FILTER_IS_ALL_EMPLOYEES: "SET_FILTER_IS_ALL_EMPLOYEES",
	SET_IS_APPROVING: "IS_APPROVING_TASK",
	SET_IS_SIGNING: "IS_SIGNING_TASK",
	SET_IS_MARKING_TASK_INFORMATION_AS_DONE: "IS_MARKING_TASK_INFORMATION_AS_DONE",
	SET_IS_DOWNLOADING: "SET_IS_DOWNLOADING",
	SET_REQUEST: "SET_REQUEST",
	SET_REQUEST_DOCUMENT_TYPE: "SET_REQUEST_DOCUMENT_TYPE",
	SET_REQUEST_DOCUMENT_KIND: "SET_REQUEST_DOCUMENT_KIND",
	SET_REQUEST_EMPLOYEE_IDS: "SET_REQUEST_EMPLOYEE_IDS",
	SET_REQUEST_EXPORT_ALL_TIME: "SET_REQUEST_EXPORT_ALL_TIME",
	SET_REQUEST_PERIOD_RANGE: "SET_REQUEST_PERIOD_RANGE",
	RESET_REQUEST: "RESET_REQUEST",
	SET_IS_FILE_LOADING: "SET_IS_FILE_LOADING",
	SET_FILE: "SET_FILE",
	SET_CURRENT_APPROVAL: "SET_CURRENT_APPROVAL",
	ADD_LOADING_APPROVAL_ITEM_IDS: "ADD_LOADING_APPROVAL_ITEM_IDS",
	REMOVE_LOADING_APPROVAL_ITEM_IDS: "REMOVE_LOADING_APPROVAL_ITEM_IDS",
};

const tasksTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default tasksTypes;
