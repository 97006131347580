import _mime, { Mime } from "mime";
import { FileTypes } from "@/constants/fileTypes";

const mime = new Mime();

mime.define({
	[FileTypes.RAR]: ["rar"],
	[FileTypes["7Z"]]: ["7z"]
});

export const getMimeType = (path: string) => {
	return mime.getType(path) ?? _mime.getType(path);
};
