import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/hr/modules/employee/modules/kpi/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import router from "@/router/hr";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import EmployeeKpiState from "@/store/hr/modules/employee/modules/kpi/types/employeeKpiState";
import { KpiController } from "@/api/kpi";
import { getQuarter, getYear, subQuarters } from "date-fns";
import EmployeeKpiMapper from "@/store/hr/modules/employee/modules/kpi/mapper";
import { setQuarterStartDate } from "@/utils/dates";
import { HR_KPI_AMOUNT_OF_SUB_QUARTERS, HR_KPI_QUARTER_START_DAY } from "@/constants/hr/date";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeKpiState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeKpiState, any>>{};

const actions = <ActionTree<EmployeeKpiState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit }) {
		commit(mutationTypes.SET_IS_LOADING, true);
		
		try {
			const date = subQuarters(setQuarterStartDate(new Date(), HR_KPI_QUARTER_START_DAY), HR_KPI_AMOUNT_OF_SUB_QUARTERS);
			
			const data = await kpiController.getEmployeeReportingByQuarter(getYear(date), getQuarter(date), router.currentRoute.params.id);
			
			commit(mutationTypes.SET_KPI, EmployeeKpiMapper.map(data));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<EmployeeKpiState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_KPI](state, value) {
		state.kpi = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeeKpiModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeKpiModule;
