import { ApiKpiReport } from "@/api/kpi/types/apiKpiReport";
import { HrKpi } from "@/types/hr/hrKpi";

export default class EmployeeKpiMapper {
	static map(source: ApiKpiReport): HrKpi {
		return {
			period: { year: source.year, quarter: source.quarter },
			completionRate: source.staffs[0]?.kpi,
			laborRate: source.staffs[0]?.laborRate?.value
		};
	}
}
