<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.vacationPlansMassApprove.title', { year: filter.year })"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<span>{{ $t("dialogs.vacationPlansMassApprove.text") }}</span>
		</template>
		
		<template #footer>
			<frp-btn outlined color="primary"
					 @click="handleClose">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="isApproving"
					 @click="handleMassApprove">
				{{ $t("buttons.vacationPlansApprove") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			isApproving: state => state.isApproving,
			filter: state => state.filter
		})
	},
	methods: {
		...mapActions({
			approvePlans: actionTypes.approvePlans
		}),
		async handleMassApprove() {
			await this.approvePlans();
			this.handleClose();
		},
		handleClose() {
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDateField,
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
