<template>
	<kpi-content-layout v-if="initialized">
		<v-row>
			<v-col cols="12" md="6">
				<hr-vacation-application-general></hr-vacation-application-general>
			</v-col>
			<v-col cols="12" md="6" v-if="!isCreateMode">
				<hr-vacation-application-approval></hr-vacation-application-approval>
			</v-col>
		</v-row>
	</kpi-content-layout>
	<hr-vacation-application-loader v-else></hr-vacation-application-loader>
</template>

<script>
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpFormActions from "@/components/form/FrpFormActions.vue";
import FrpNestedContentLayout from "@/components/layouts/FrpNestedContentLayout.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import FrpFormCard from "@/components/markup/FrpFormCard.vue";
import FrpFormCardBlock from "@/components/markup/FrpFormCardBlock.vue";
import FrpFormCardBlockCol from "@/components/markup/FrpFormCardBlockCol.vue";
import FrpFormCardBlockRow from "@/components/markup/FrpFormCardBlockRow.vue";
import FrpFormCardBlocks from "@/components/markup/FrpFormCardBlocks.vue";
import FrpFormItem from "@/components/markup/FrpFormItem.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import formMixin from "@/mixins/formMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/hr/routes";
import { namespace } from "Store/hr/modules/vacationApplication/types";
import HrVacationApplicationLoader from "@/views/hr/vacationApplication/HrVacationApplicationLoader.vue";
import HrVacationApplicationApproval from "@/views/hr/vacationApplication/sections/HrVacationApplicationApproval.vue";
import HrVacationApplicationGeneral from "@/views/hr/vacationApplication/sections/HrVacationApplicationGeneral.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, formMixin, authorizationMixin],
	metaInfo() {
		return {
			title: this.$t("titles.vacationApplication")
		};
	},
	data() {
		return {
			namespace
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			application: state => state.application
		}),
		isCreateMode() {
			return !this.application?.id;
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.id, vacationId: this.$route.query.id });
	},
	async beforeRouteLeave(to, from, next) {
		next();
		
		// todo: временное решение для переинициализации стора при переходе на create/update страницу
		if(to.name !== from.name &&[RouteNames.VACATION_APPLICATION, RouteNames.VACATION_APPLICATION_CREATE].includes(to.name))
			this.initializeStore({ id: to.params.id, vacationId: to.query.id });
	},
	components: {
		HrVacationApplicationLoader,
		HrVacationApplicationApproval,
		HrVacationApplicationGeneral,
		BodAutocomplete,
		FrpAutocomplete,
		KpiContentLayout,
		FrpTextField,
		FrpFormActions,
		FrpFormCardBlockCol,
		FrpFormCardBlocks,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormItem,
		FrpFormCard,
		FrpTextarea,
		FrpNestedContentLayout
	}
};
</script>
