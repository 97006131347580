<template>
	<div class="frp-gantt-month">
		<div class="d-flex justify-center align-center">
			<div class="d-flex justify-center align-center" :style="setVisibilityForDecemberJanuary('prev')">
				<div class="frp-gantt__text text-right">{{ monthText.prev }}</div>
				<frp-btn icon
						 @click="goToPrevPeriod">
					<frp-icon src="ico_menu-left" :color="colors.grey.darken2"></frp-icon>
				</frp-btn>
			</div>
			<div class="frp-gantt__text frp-gantt__text--active text-center">{{ monthText.current }}</div>
			<div class="d-flex justify-center align-center" :style="setVisibilityForDecemberJanuary('next')">
				<frp-btn icon
						 @click="goToNextPeriod">
					<frp-icon src="ico_menu-right" :color="colors.grey.darken2"></frp-icon>
				</frp-btn>
				<div class="frp-gantt__text text-left">{{ monthText.next }}</div>
			</div>
		</div>
		<v-data-table class="frp-gantt"
					  ref="table"
					  :headers="headers"
					  :items="items"
					  hide-default-footer>
			<template #item.fullName="{ item }">
				<span @click="$emit('click', item.id)" class="pointer">
					{{ item.fullName }}
				</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";
import BatchService from "@/services/batchService";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { isRangesOverlapping } from "@/utils/number";
import {
	addDays,
	addMonths,
	areIntervalsOverlapping,
	differenceInDays,
	eachDayOfInterval,
	format, getDaysInMonth, isSameDay,
	isWeekend,
	lastDayOfMonth,
	startOfMonth,
	subDays,
	subMonths
} from "date-fns";
import { capitalize, sortBy } from "lodash";

const batchService = new BatchService({ interval: 100 });

const HALF_MONTH_START_DAY = 15;
const TASK_OFFSET_X = 4;
const TASK_OFFSET_TOP = 5;
const TASK_HEIGHT = 23;
const TASK_GAP = 4;
const ROW_HEIGHT = 34;

export default {
	mixins: [colorsMixin],
	components: { FrpIcon, FrpBtn },
	props: {
		items: Array,
		year: Number,
		month: {
			type: Number,
			default: 0
		},
		holidays: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			ganttHandlers: [],
			internalMonth: this.month
		};
	},
	computed: {
		monthText() {
			const start = this.getMonthName(this.startDate);
			const startPrev = this.getMonthName(subMonths(this.startDate, 1));
			const end = this.getMonthName(addDays(this.endDate, 1));
			
			return {
				prev: this.internalMonth % 1 === 0 ? `${startPrev} \\ ${start}` : start,
				current: this.internalMonth % 1 === 0 ? start : `${start} \\ ${end}`,
				next: this.internalMonth % 1 === 0 ? `${start} \\ ${end}` : end
			};
		},
		headers() {
			const dates = eachDayOfInterval({
				start: this.startDate,
				end: this.endDate
			});
			
			const headers = [];
			
			headers.push({
				cellClass: ["frp-gantt__grid-row"],
				width: "300px",
				divider: true,
				text: "",
				value: "fullName",
				sortable: false
			});
			
			dates.forEach((date) => {
				const isHolidays = this.holidays.includes(
					formatDate(date, "yyyy-MM-dd")
				);
				
				const weekendClass = isWeekend(date) || isHolidays ? "weekend" : "";
				
				headers.push({
					cellClass: ["frp-gantt__cell", `${format(date, "MMM-dd-yyyy")}`, weekendClass],
					class: ["frp-gantt__scale-cell", weekendClass],
					width: "47px",
					align: "center",
					divider: true,
					text: date.getDate(),
					value: format(date, "MMM-dd-yyyy"),
					sortable: false
				});
			});
			
			return headers;
		},
		tasks() {
			let tasks = [];
			
			this.items.forEach(({ id, periods }, i) => {
				for (const { startDate, endDate, isApproved } of periods) {
					const start = new Date(startDate);
					const end = new Date(endDate);
					
					if(start.getFullYear() !== this.year || end.getFullYear() !== this.year)
						continue;
					
					if(startDate > this.endDate.getTime() || endDate < this.startDate.getTime())
						continue;
					
					const isCurrentPeriodIncludesStartDay = isSameDay(startDate, this.startDate) || startDate > this.startDate.getTime();
					const isCurrentPeriodIncludesEndDay = isSameDay(endDate, this.endDate) || endDate < this.endDate.getTime();
					const startIdx = isCurrentPeriodIncludesStartDay ? this.days.findIndex(x => isSameDay(start, x)) : 0;
					const endIdx = isCurrentPeriodIncludesEndDay ? this.days.findIndex(x => isSameDay(end, x)) : this.days.length - 1;
					
					tasks.push({
						employeeIdx: i,
						isApproved,
						startDate: format(start, "MMM-dd-yyyy"),
						endDate: format(end, "MMM-dd-yyyy"),
						start,
						end,
						startIdx,
						endIdx,
						duration: endIdx - startIdx,
						position: 0,
						text: `${format(start, dateFormat)}-${format(end, dateFormat)}`
					});
				}
			});
			
			return tasks;
		},
		startDate() {
			if(this.internalMonth % 1 === 0) {
				return new Date(this.year, this.internalMonth);
			} else {
				const firstMonth = new Date(this.year, Math.floor(this.internalMonth));
				const start = HALF_MONTH_START_DAY - 1;
				
				return addDays(firstMonth, start);
			}
		},
		endDate() {
			if(this.internalMonth % 1 === 0) {
				return lastDayOfMonth(new Date(this.year, this.internalMonth));
			} else {
				const secondMonth = new Date(this.year, Math.ceil(this.internalMonth));
				const end = Math.ceil(getDaysInMonth(secondMonth) / 2) - 1;
				
				return addDays(secondMonth, end);
			}
		},
		days() {
			return eachDayOfInterval({ start: this.startDate, end: this.endDate });
		}
	},
	methods: {
		setVisibilityForDecemberJanuary(type) {
			return { visibility: this.monthText[type] === "Декабрь \\ Январь" ? "hidden" : "visible" };
		},
		goToPrevPeriod() {
			if(this.internalMonth === 0) return;
			
			this.internalMonth -= 0.5;
			
			this.rerenderTasks();
		},
		goToNextPeriod() {
			if(this.internalMonth === 11) return;
			
			this.internalMonth += 0.5;
			
			this.rerenderTasks();
		},
		getMonthName(date) {
			return capitalize(formatDate(date, "LLLL"));
		},
		renderTasks() {
			const rows = this.$refs.table.$el.querySelector("tbody").children;
			
			this.items.forEach((x, i) => {
				const row = rows[i];
				const cols = Array.prototype.slice.call(row.children, 1);
				let tasks = sortBy(this.tasks.filter(y => i === y.employeeIdx), [(x) => !x.isApproved, (x) => x.start.getDate()]);
				
				cols.forEach((firstCol, i) => {
					let taskOffset = 0;
					
					tasks.forEach((task, j) => {
						if(task.startIdx === i) {
							tasks.forEach(x => {
								if(x.startIdx < i && x.position === taskOffset && (task.startIdx - x.startIdx) <= x.duration)
									taskOffset++;
							});
							
							tasks[j] = { ...tasks[j], position: taskOffset };
							
							taskOffset++;
						}
					})
				});
				
				tasks.forEach(task => {
					const taskCols = cols.filter((_, i) => i >= task.startIdx && i <= task.endIdx);
					const firstCol = taskCols[0];
					const offsetTop = task.position * (TASK_HEIGHT + TASK_GAP) + TASK_OFFSET_TOP + "px";
					const width = taskCols.reduce((acc, v) => acc + v.getBoundingClientRect().width, 0) - TASK_OFFSET_X * 2 - 1;
						
					const taskEl = document.createElement("div");
					
					firstCol.style = `height: ${ROW_HEIGHT + task.position * (TASK_HEIGHT + TASK_GAP)}px !important`;
					taskEl.style.position = "absolute";
					taskEl.style.width = `${width}px`;
					taskEl.style.top = offsetTop;
					taskEl.style.left = `${TASK_OFFSET_X}px`;
					taskEl.classList.add("frp-gantt__task");
					
					if(task.isApproved)
						taskEl.classList.add("frp-gantt__task--approved");
					
					firstCol.appendChild(taskEl);
				});
			});
		},
		rerenderTasks() {
			batchService.push(() => {
				const tasks = this.$refs.table.$el.querySelectorAll(".frp-gantt__task");
				tasks.forEach(x => x.remove());
				
				this.renderTasks();
			});
		}
	},
	mounted() {
		this.renderTasks();
		
		window.addEventListener("resize", this.rerenderTasks);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.rerenderTasks);
	},
	watch: {
		items() {
			this.rerenderTasks();
		},
		year() {
			this.rerenderTasks();
		},
		month(value) {
			this.internalMonth = value;
			this.rerenderTasks();
		}
	}
};
</script>

<style lang="scss">
.frp-gantt-month {
  .weekend {
	background-color: var(--v-grey-lighten1);
  }

  .frp-gantt__header {
	min-width: 500px;
	height: 45px;
	gap: 5px;
  }

  .frp-gantt__text {
	font-size: 14px;
	color: var(--v-grey-base);
	min-width: 140px;

	&--active {
	  font-weight: 600;
	  color: var(--v-primary-base);
	}
  }

  .frp-gantt__cell {
	height: 34px !important;
  }
}
</style>
