import { ApiHrVacation } from "@/api/hr/types/vacation/apiHrVacation";
import { convertToTimestamp } from "@/utils/dates";
import { ApiHrVacationPlanApplication } from "@/api/hr/types/vacation/apiHrVacationPlanApplication";

export interface HrVacation {
	id: string;
	startDate: number | null;
	endDate: number | null;
	days: number;
	factDate: number | null;
	previousVacationId?: string;
	vacationApplication?: ApiHrVacationPlanApplication;
}

export class HrVacationService {
	static map(source: ApiHrVacation): HrVacation {
		return {
			...source,
			previousVacationId: source.previousVacationId || source.id,
			startDate: convertToTimestamp(source.startDate) as number,
			endDate: convertToTimestamp(source.endDate) as number,
			factDate: convertToTimestamp(source.factDate) as number
		};
	}
}
