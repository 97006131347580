import { convertToZonedIso } from "@/utils/dates";
import { ContractInfo } from "@/store/bar/types/contractInfo";

export interface ApiContractInfo {
	number: string;
	openingDate: string;
}

export class ApiContractInfoMapper {
	static map(source: ContractInfo): ApiContractInfo {
		return {
			...source,
			openingDate: convertToZonedIso(source.openingDate)
		};
	}
}