<template>
	<bar-content-layout v-if="isInitialized">
		<v-form ref="bankAccountForm"
				v-model="bankAccountFormValid">
			<v-row>
				<v-col cols="6">
					<bar-bank-account-information/>
				</v-col>
				<v-col cols="6">
					<bar-bank-account-agreements/>
				</v-col>
			</v-row>
		</v-form>
		
		<template #footer-actions>
			<frp-btn elevation="0"
					 :to="{ name: RouteNames.BANK_ACCOUNTS_LIST }"
					 dark
					 color="blue">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			
			<frp-btn :disabled="isCreateBankAccountDisabled"
					 class="ml-6"
					 elevation="0"
					 @click="createBankAccount"
					 :loading="isBankAccountSaving"
					 dark
					 color="blue">
				{{ $t("buttons.addBankAccount") }}
			</frp-btn>
		</template>
	</bar-content-layout>
	<bar-bank-account-create-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccount";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import BarBankAccountCreateLoader from "@/views/bar/bankAccount/BarBankAccountCreateLoader.vue";
import BarBankAccountAgreements from "@/views/bar/bankAccount/sections/agreements/BarBankAccountAgreements.vue";
import BarBankAccountInformation from "@/views/bar/bankAccount/sections/information/BarBankAccountInformation.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.newBankAccount")
	},
	mixins: [storeModuleBasedPage, formMixin, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isBankAccountNumberUniqueCheckInProgress: state => state.isBankAccountNumberUniqueCheckInProgress,
			isBankAccountNumberUnique: state => state.isBankAccountNumberUnique,
			isBankAccountFormValid: state => state.isBankAccountFormValid,
			isBankAccountSaving: state => state.isBankAccountSaving,
			accountAgreements: state => state.editableItem.accountAgreements
		}),
		isCreateBankAccountDisabled() {
			const isSomeAccountAgreementNumberNotUnique = this.accountAgreements.some(x => !x.isNumberUnique);
			const isSomeAccountAgreementNumberUniqueCheckedInProgress = this.accountAgreements.some(x => x.isNumberUniqueCheckInProgress);
			
			return !this.isBankAccountFormValid
				|| this.isBankAccountNumberUniqueCheckInProgress
				|| !this.isBankAccountNumberUnique
				|| isSomeAccountAgreementNumberUniqueCheckedInProgress
				|| isSomeAccountAgreementNumberNotUnique
				|| !this.can(Permissions.BAR_ACCOUNT_CREATE);
		},
		bankAccountFormValid: {
			get() {
				return this.isBankAccountFormValid;
			},
			set(value) {
				this.setIsBankAccountFormValid(value);
			}
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			createBankAccount: actionTypes.createBankAccount
		}),
		...mapMutations({
			setIsBankAccountFormValid: mutationTypes.SET_IS_BANK_ACCOUNT_FORM_VALID
		})
	},
	created() {
		this.initializeStore();
	},
	components: { BarBankAccountAgreements, BarBankAccountCreateLoader, FrpBtn, BarBankAccountInformation, BarContentLayout }
};
</script>

<style scoped>

</style>
