<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$t('dialogs.hrApplicationDocument.title')"
				max-width="600"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<div class="white darken-1 pa-4" style="border-right: 4px">
				<frp-file :name="currentOpenedDocument.title"></frp-file>
			</div>
		</template>

		<template #footer>
			<frp-btn outlined color="primary"
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :disabled="!currentOpenedDocument.certificatesStampFileId"
					 :loading="isCertificatesStampDocumentDownloading"
					 @click="downloadCertificatesStampDocument">
				{{ $t("buttons.downloadCertificatesStamp") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="isPdfDocumentDownloading"
					 @click="downloadPdfDocument">
				{{ $t("buttons.hrDownloadPdf") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import storeManager from "@/store/manager";
import { RouteNames } from "@/router/hr/routes";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/vacationApplications/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "@/mixins/colorsMixin";
import FrpBtn from "@/components/buttons/FrpBtn.vue";

const namespace = storeManager.hr.vacationApplications.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			currentOpenedDocument: state => state.currentOpenedDocument,
			isCertificatesStampDocumentDownloading: state => state.isCertificatesStampDocumentDownloading,
			isPdfDocumentDownloading: state => state.isPdfDocumentDownloading
		})
	},
	methods: {
		...mapMutations({
			setCurrentOpenedDocument: mutationTypes.SET_CURRENT_OPENED_DOCUMENT
		}),
		...mapActions({
			downloadPdfDocument: actionTypes.downloadPdfDocument,
			downloadCertificatesStampDocument: actionTypes.downloadCertificatesStampDocument
		}),
		handleClose() {
			this.setCurrentOpenedDocument(undefined);
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpFile,
		FrpDialog,
		FrpBtn
	}
};
</script>
