<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$tc('dialogs.hrVacationChangesApprovalTask.title')"
				max-width="626"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<span class="font-weight-bold primary--text mt-3" slot="font-size: 18px">{{ $t("dialogs.hrApplicationState.subtitle") }}</span>
			
			<v-divider class="mt-3"></v-divider>
			
			<hr-approval v-bind="currentApproval" no-created-at />
		</template>
		
		<template #footer>
			<frp-btn elevation="0" color="blue" dark
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import HrApproval from "@/components/common/HrApproval.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { RouteNames } from "@/router/hr/routes";
import { namespace, actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/tasks/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "@/mixins/colorsMixin";
import FrpBtn from "@/components/buttons/FrpBtn.vue";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			currentApproval: state => state.currentApproval
		})
	},
	methods: {
		...mapMutations({
			setCurrentApproval: mutationTypes.SET_CURRENT_APPROVAL
		}),
		handleClose() {
			this.setCurrentApproval(undefined);
			this.$emit("update:value", false);
		}
	},
	components: {
		HrApproval,
		FrpDialog,
		FrpBtn
	}
};
</script>
