import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/employee/modules/colleagues/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import router from "@/router/hr";
import EmployeeColleaguesState from "@/store/hr/modules/employee/modules/colleagues/types/employeeColleaguesState";

const abortService = new AbortService();
const hrController = new HrController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeColleaguesState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeColleaguesState, any>>{
};

const actions = <ActionTree<EmployeeColleaguesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ commit, dispatch }) {
		try {
			let apiColleagues = await hrController.getColleagues(router.currentRoute.params.id);

			commit(mutationTypes.SET_COLLEAGUES, apiColleagues);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		}
	}
};

const mutations = <MutationTree<EmployeeColleaguesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_COLLEAGUES](state, value) {
		state.colleagues = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeeColleaguesModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeColleaguesModule;
