import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { HrVacation, HrVacationService } from "@/types/hr/vacation/hrVacation";
import { ApiHrVacationPlan } from "@/api/hr/types/vacation/apiHrVacationPlan";

export interface HrVacationPlan {
	id: string;
	employee: ApiHrEmployeesItem;
	version: string;
	state: HrVacationPlanStateEnum;
	plannedVacations: HrVacation[];
}

export class HrVacationPlanService {
	static map(source: ApiHrVacationPlan): HrVacationPlan {
		return {
			...source,
			plannedVacations: source.plannedVacations && source.plannedVacations.map(x => HrVacationService.map(x))
		};
	}
}
