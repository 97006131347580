import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter, parseBooleanParameter } from "@/utils/query";
import { isEqual } from "lodash";
import TasksRouteQuery from "@/store/hr/modules/tasks/types/tasksRouteQuery";
import TasksState from "@/store/hr/modules/tasks/types/tasksState";
import { difference } from "@/utils/difference";

export default class TasksRouteService {
	initialRouteQuery: TasksRouteQuery;
	defaultRouteQuery: TasksRouteQuery;
	
	constructor(defaultRouteQuery: TasksRouteQuery) {
		this.initialRouteQuery = defaultRouteQuery;
		this.setDefaultRoute(defaultRouteQuery);
	}
	
	setDefaultRoute(defaultRouteQuery: TasksRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	resolveRouteQueryDictionary(state: TasksState) {
		let query = new TasksRouteQuery(
			state.filter.taskTypes,
			state.filter.taskStates,
			state.filter.taskAssigneeIds,
			state.filter.isAllEmployees,
			state.filter.taskId
		);
		
		return difference(this.initialRouteQuery, query);
	}
	
	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(TasksRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				taskTypes: parseArrayParameter(query.taskTypes),
				taskStates: parseArrayParameter(query.taskStates),
				taskAssigneeIds: parseArrayParameter(query.taskAssigneeIds),
				isAllEmployees: parseBooleanParameter(query.isAllEmployees, this.defaultRouteQuery.isAllEmployees)
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
