import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import { ElectronicDocumentStatusTypeEnum } from "@/store/bar/types/ElectronicDocumentStatusTypeEnum";
import { PaperDocumentStatusTypeEnum } from "@/store/bar/types/PaperDocumentStatusTypeEnum";

export interface ApiDocumentResponse {
	bankAccountApplicationId: string;
	accountId: string;
	storedFileId: string;
	type: BankAccountApplicationDocumentTypeEnum;
	electronicStatus: ElectronicDocumentStatusTypeEnum;
	paperStatus: PaperDocumentStatusTypeEnum;
	explanation: string;
	createdAt: string;
	createdBy: string;
	documentName: string;
	acceptorId: string;
	acceptedAt: string;
}

export class ApiDocumentResponseHelper {
	static getEmpty(): ApiDocumentResponse {
		return {
			bankAccountApplicationId: "",
			accountId: "",
			storedFileId: "",
			type: BankAccountApplicationDocumentTypeEnum.UNKNOWN,
			electronicStatus: ElectronicDocumentStatusTypeEnum.UNKNOWN,
			paperStatus: PaperDocumentStatusTypeEnum.UNKNOWN,
			explanation: "",
			createdAt: "",
			createdBy: "",
			documentName: "",
			acceptorId: "",
			acceptedAt: ""
		};
	}
}