<template>
	<v-row class="d-flex flex-column">
		<v-col class="pb-1">
			<bar-bank-account-application-information-base-loader/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-organization-data-loader/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-bank-editable-loader/>
		</v-col>
		<v-col>
			<bar-bank-account-application-information-accounts-editable-loader/>
		</v-col>
	</v-row>
</template>

<script>
import BarBankAccountApplicationInformationAccountsEditableLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsEditableLoader.vue";
import BarBankAccountApplicationInformationBankEditableLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/bank/BarBankAccountApplicationInformationBankEditableLoader.vue";
import BarBankAccountApplicationInformationBaseLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/baseInformation/BarBankAccountApplicationInformationBaseLoader.vue";
import BarBankAccountApplicationInformationOrganizationDataLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/organizationData/BarBankAccountApplicationInformationOrganizationDataLoader.vue";

export default {
	components: {
		BarBankAccountApplicationInformationAccountsEditableLoader,
		BarBankAccountApplicationInformationBankEditableLoader,
		BarBankAccountApplicationInformationOrganizationDataLoader,
		BarBankAccountApplicationInformationBaseLoader,
	}
};
</script>

<style scoped>

</style>
