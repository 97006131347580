<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.deleteVacationPlanEmployee.title')"
				max-width="430"
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid" :readonly="isFormSaving">
				<span>
					{{ $t("dialogs.deleteVacationPlanEmployee.text", { name: formatFullNameWithInitials(employee || {}) }) }}
				</span>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="secondary"
					 dark
					 :loading="isFormSaving"
					 @click="handleDeleteEmployee">
				{{ $t("buttons.delete") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import formMixin from "@/mixins/formMixin";
import { formatFullNameWithInitials } from "@/utils/formatting";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlanEmployees";
import { actionTypes } from "Store/hr/modules/vacationPlanEmployees/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		employee: Object
	},
	data() {
		return {
			RouteNames,
			namespace,
			formatFullNameWithInitials
		};
	},
	methods: {
		...mapActions({
			deleteEmployee: actionTypes.deleteEmployee
		}),
		async handleDeleteEmployee() {
			await this.deleteEmployee(this.employee.id);
			this.$emit("update:value", false);
			this.$emit("update:employee", null);
		},
		handleClose() {
			this.$emit("update:employee", null);
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
