import { IsArray, IsBoolean, IsEnum, IsNumber, IsString, ValidateIf } from "class-validator";
import { HrVacationPlanViewEnum } from "@/types/hr/vacation/HrVacationPlanViewEnum";

export default class VacationPlansRouteQuery {
	@IsArray()
	@IsString({ each: true })
	departmentIds: string[];

	@IsArray()
	@IsString({ each: true })
	employeeIds: string[];

	@IsString()
	@ValidateIf((_, value) => value !== null)
	versionId: string | null;

	@IsNumber()
	@ValidateIf((_, value) => value !== null)
	month: number | null;

	@IsEnum(HrVacationPlanViewEnum)
	view: HrVacationPlanViewEnum;

	@IsBoolean()
	isAllEmployees: boolean;

	constructor(
		departmentIds: string[] = [],
		employeeIds: string[] = [],
		versionId: string | null = null,
		month: number | null = null,
		view: HrVacationPlanViewEnum = HrVacationPlanViewEnum.UNKNOWN,
		isAllEmployees: boolean = false
	)
	{
		this.departmentIds = departmentIds;
		this.employeeIds = employeeIds;
		this.versionId = versionId;
		this.month = month;
		this.view = view;
		this.isAllEmployees = isAllEmployees;
	}
}
