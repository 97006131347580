import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";

export default class EmployeeColleaguesState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public colleagues: ApiHrEmployee[] = []
	)
	{
	}
}
