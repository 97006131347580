<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.documentsExport.title')"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form :ref="refs.form" v-intersect="onIntersect" v-model="formValid" class="d-flex flex-column" style="gap: 8px">
				<frp-autocomplete item-text="title"
								  item-value="value"
								  :items="documentTypes"
								  v-model="documentType"
								  required
								  :rules="validation.documentType"
								  color="blue"
								  :label="$t('fields.documentType.label')"
								  :placeholder="$t('fields.documentType.placeholder')"
								  hide-details>
				</frp-autocomplete>
				
				<frp-autocomplete item-text="title"
								  item-value="value"
								  :items="documentKinds"
								  v-model="documentKind"
								  required
								  :rules="validation.documentKind"
								  color="blue"
								  :label="$t('fields.documentKinds.label')"
								  :placeholder="$t('fields.documentKinds.placeholder')"
								  hide-details>
				</frp-autocomplete>
				
				<frp-autocomplete item-text="fullName"
								  item-value="id"
								  :items="employees"
								  v-model="employeeIds"
								  :loading="isEmployeesLoading"
								  multiple
								  color="blue"
								  :label="$t('fields.employee.label')"
								  :placeholder="$t('fields.employee.placeholder')"
								  hide-details>
				</frp-autocomplete>
				
				<frp-range-date-field v-model="periodRange"
									  :label="$t('fields.period.label')"
									  type="string"
									  dense
									  :required="!exportAllTime"
									  hide-details
									  hide-title
									  :disabled="exportAllTime"
									  :placeholder="$t('fields.period.placeholder')">
				</frp-range-date-field>
				
				<frp-checkbox class="mb-4 mt-0"
							  color="blue"
							  v-model="exportAllTime"
							  label-class="grey--text pl-1"
							  :text="$t('fields.exportAllTime.label')">
				</frp-checkbox>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 color="primary"
					 @click="handleClose">
				{{ $t("buttons.cancellation") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :disabled="!formValid"
					 :loading="isDownloading"
					 @click="handleDownload">
				{{ $t("buttons.download") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { ApiHrDocumentKindsEnum } from "@/api/hr/types/ApiHrDocumentKindsEnum";
import { ApiHrDocumentTypesEnum } from "@/api/hr/types/ApiHrDocumentTypesEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpCheckbox from "@/components/fields/FrpCheckbox.vue";
import FrpRangeDateField from "@/components/fields/FrpRangeDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import HrRangeDateField from "@/components/fields/HrRangeDateField.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { useOptions } from "@/store/composables/options/useOptions";
import { namespace } from "@/store/hr/modules/tasks";
import { actionTypes, mutationTypes, getterTypes } from "@/store/hr/modules/tasks/types";
import { requiredRule } from "@/utils/validation";
import { createNamespacedHelpers } from "vuex";
import formMixin from "Mixins/formMixin";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, formMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			namespace,
			documentTypes: useOptions(ApiHrDocumentTypesEnum, "documentTypes").items,
			documentKinds: useOptions(ApiHrDocumentKindsEnum, "documentKinds").items,
			validation: {
				documentType: [requiredRule()],
				documentKind: [requiredRule()]
			}
		};
	},
	computed: {
		...mapState({
			employees: state => state.employees,
			isEmployeesLoading: state => state.isEmployeesLoading,
			isDownloading: state => state.isDownloading,
			formData: state => state.formData,
			request: state => state.request
		}),
		documentType: {
			get() {
				return this.request.documentType;
			},
			set(value) {
				this.setRequestDocumentType(value);
			}
		},
		documentKind: {
			get() {
				return this.request.documentKind;
			},
			set(value) {
				this.setRequestDocumentKind(value);
			}
		},
		employeeIds: {
			get() {
				return this.request.employeeIds;
			},
			set(value) {
				this.setRequestEmployeeIds(value);
			}
		},
		periodRange: {
			get() {
				return this.request.periodRange;
			},
			set(value) {
				this.setRequestPeriodRange(value);
			}
		},
		exportAllTime: {
			get() {
				return this.request.exportAllTime;
			},
			set(value) {
				this.setRequestExportAllTime(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setRequestDocumentType: mutationTypes.SET_REQUEST_DOCUMENT_TYPE,
			setRequestDocumentKind: mutationTypes.SET_REQUEST_DOCUMENT_KIND,
			setRequestEmployeeIds: mutationTypes.SET_REQUEST_EMPLOYEE_IDS,
			setRequestExportAllTime: mutationTypes.SET_REQUEST_EXPORT_ALL_TIME,
			setRequestPeriodRange: mutationTypes.SET_REQUEST_PERIOD_RANGE,
			resetRequest: mutationTypes.RESET_REQUEST
		}),
		...mapActions({
			download: actionTypes.downloadArchive
		}),
		async handleDownload() {
			await this.download(this.request);
			
			this.handleClose();
		},
		handleClose() {
			this.resetRequest();
			this.$emit("update:value", false);
		}
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		FrpRangeDateField,
		HrRangeDateField,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		FrpTextarea,
		FrpDialog,
		FrpBtn
	}
};
</script>
