import { BankAccountApplicationMessage } from "@/store/bar/types/bankAccountApplicationMessage";
import { MessageType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/MessageType";

export interface ApiBankAccountApplicationMessage {
	relatedMessageId: string;
	messageText: string;
	employeeId: string;
	toEmployees: string[];
	type: MessageType;
}

export class ApiBankAccountApplicationMessageMapper {
	static map(source: BankAccountApplicationMessage): ApiBankAccountApplicationMessage {
		return {
			...source
		};
	}
}