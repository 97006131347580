<template>
	<div>
		<div class="d-flex align-center ml-2">
			<frp-icon v-if="(value === 1 && !hasLongVacation) || value > 1" src="ico_hr-information"
					  :color="colors.secondary.lighten2"></frp-icon>
			<frp-icon v-else src="ico_hr-accept-circle" :color="value === 1 ? colors.green.lighten3 : colors.black.lighten5"></frp-icon>
			<div class="d-flex flex-column justify-center ml-1">
				<span class="ml-1 mb-1 primary--text" style="font-size: 12px">{{ days }}/{{ $tc("pluralization.day", maxDays) }}</span>
				<v-progress-linear :value="value * 100"
								   height="9" style="width: 240px" rounded background-color="black lighten-5"
								   :color="value > 1 ? 'secondary lighten-2' : 'green lighten-3'">
				</v-progress-linear>
			</div>
		</div>
		<span style="font-size: 10px" class="primary--text" :class="{ 'secondary--text text--lighten-2': value === 1 && !hasLongVacation }">
			{{ $t("common.longVacationRequired", { count: 14 }) }}
		</span>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		maxDays: {
			type: Number,
			required: true
		},
		days: {
			type: Number,
			required: true
		},
		hasLongVacation: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		value() {
			return this.days / this.maxDays;
		}
	},
	components: {
		FrpIcon
	}
};
</script>
