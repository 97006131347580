import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import BankAccountsListState from "@/store/bar/modules/bankAccountsList/types/bankAccountsListState";

export interface ApiGetBankAccountsParameters {
	take?: number;
	skip?: number;
	loanApplicationNumber?: string;
	counterpartyId?: string;
	bankId?: string;
	signStatuses?: BankAccountStatusTypeEnum[];
	responsibleUserId?: string;
	accountNumber?: string;
}

export class ApiGetBankAccountsParametersMapper {
	static mapToApiGetBankAccountsParameters(source: BankAccountsListState): ApiGetBankAccountsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			loanApplicationNumber: source.filter.loanApplicationNumber,
			counterpartyId: source.filter.counterpartyId,
			bankId: source.filter.bankId,
			signStatuses: source.filter.signStatuses,
			responsibleUserId: source.filter.responsibleUserId
		};
	}
}