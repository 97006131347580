<template>
	<v-row class="d-flex flex-column" v-if="isInitialized">
		<v-col class="pb-1">
			<bar-bank-account-application-information-base/>
		</v-col>
		<v-col class="pb-1" v-if="editableItem?.agreement?.counterparty">
			<bar-bank-account-application-information-organization-data/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-bank/>
		</v-col>
		<v-col>
			<bar-bank-account-application-information-accounts/>
		</v-col>
		<v-col v-if="accountUsages.length">
			<bar-bank-account-application-account-usages/>
		</v-col>
	</v-row>
	<bar-bank-account-application-information-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import BarBankAccountApplicationInformationLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformationLoader.vue";
import BarBankAccountApplicationAccountUsages
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accountUsages/BarBankAccountApplicationAccountUsages.vue";
import BarBankAccountApplicationInformationAccounts
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccounts.vue";
import BarBankAccountApplicationInformationBank
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/bank/BarBankAccountApplicationInformationBank.vue";
import BarBankAccountApplicationInformationBase
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/baseInformation/BarBankAccountApplicationInformationBase.vue";
import BarBankAccountApplicationInformationOrganizationData
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/organizationData/BarBankAccountApplicationInformationOrganizationData.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountApplicationStatusTypeEnum
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem,
			accountUsages: state => state.accountUsages
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({})
	},
	components: {
		BarBankAccountApplicationAccountUsages,
		BarBankAccountApplicationInformationLoader,
		BarBankAccountApplicationInformationAccounts,
		BarBankAccountApplicationInformationBank,
		BarBankAccountApplicationInformationBase,
		BarBankAccountApplicationInformationOrganizationData,
		FrpIcon,
		FrpBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
