import { getMonth } from "date-fns";
import { HR_FILTER_NEXT_YEAR_START_MONTH } from "@/constants/hr/date";
import { first } from "lodash";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";

export const getHrDefaultYear = (items: ApiHrVacationYear[]): ApiHrVacationYear | undefined => {
	const currentYear = new Date().getFullYear();
	const defaultYear = getMonth(new Date()) >= HR_FILTER_NEXT_YEAR_START_MONTH ? currentYear + 1 : currentYear;
	return items.find(x => x.value === defaultYear) || first(items);
}
