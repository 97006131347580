import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import VacationPlanHolidaysFilter from "@/store/hr/modules/vacationPlanHolidays/types/vacationPlanHolidaysFilter";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";

export default class VacationPlanHolidaysState implements IPageState {
	constructor(
		public filter: VacationPlanHolidaysFilter,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isYearsLoading: boolean = false,
		public isYearCreating: boolean = false,
		public years: ApiHrVacationYear[] = [],
		public holidays: string[] = []
	)
	{
	}
}
