import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "personnelDocuments";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	fetchPersonnelPartitions: "fetchPersonnelPartitions",
	processingPartition: "processingPartition",
	deletePartition: "deletePartition",
	processingDocument: "processingDocument",
	deleteDocument: "deleteDocument",
	getFileMeta: "getFileMeta"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_PERSONNEL_PARTITIONS: "SET_PERSONNEL_PARTITIONS",
	SET_IS_PROCESSING_LOADING: "SET_IS_PROCESSING_LOADING",
	SET_SELECTED_PERSONNEL_PARTITION: "SET_SELECTED_PERSONNEL_PARTITION",
	SET_IS_CREATE_MODE: "SET_IS_CREATE_MODE",
	SET_PAYLOAD_PARTITION: "SET_PAYLOAD_PARTITION",
	SET_PAYLOAD_PARTITION_TITLE: "SET_PAYLOAD_PARTITION_TITLE",
	RESET_PAYLOAD_PARTITION: "RESET_PAYLOAD_PARTITION",
	SET_PAYLOAD_DOCUMENT: "SET_PAYLOAD_DOCUMENT",
	SET_PAYLOAD_DOCUMENT_TITLE: "SET_PAYLOAD_DOCUMENT_TITLE",
	SET_PAYLOAD_DOCUMENT_TEMP_FILE_ID: "SET_PAYLOAD_DOCUMENT_TEMP_FILE_ID",
	SET_PAYLOAD_DOCUMENT_PARTITION_ID: "SET_PAYLOAD_DOCUMENT_PARTITION_ID",
	RESET_PAYLOAD_DOCUMENT: "RESET_PAYLOAD_DOCUMENT",
	SET_SELECTED_PERSONNEL_DOCUMENT: "SET_SELECTED_PERSONNEL_DOCUMENT",
	SET_IS_FILE_META_LOADING: "SET_IS_FILE_META_LOADING"
};

const personnelDocumentsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default personnelDocumentsTypes;
