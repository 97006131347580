<template>
	<v-dialog v-model="isDialogOpened" :width="dialogWidth">
		<template v-slot:activator="{ on }">
			<div>
				<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
					<span>{{ label }}</span>
					<span v-if="required">*</span>
				</v-subheader>
				<v-text-field :rules="readonly ? [] : [...rules, ...defaultRules]"
							  :readonly="readonly"
							  :disabled="disabled"
							  :clearable="clearable"
							  :style="{ width }"
							  dense
							  ref="input"
							  :hide-details="hideDetails"
							  :hint="hint"
							  autocomplete="off"
							  v-mask="masks.dateRange"
							  :placeholder="placeholder"
							  @change="onChange"
							  class="frp-date-field"
							  :class="{ 'frp-dense-field': dense }"
							  v-model="internalFormattedRowValue"
							  outlined>
					<template v-slot:append>
						<frp-icon src="ico_calendar"
								  :color="colors.grey.base"
								  @click="!readonly && !disabled && openDialog()">
						</frp-icon>
					</template>
				</v-text-field>
			</div>
		</template>
		<div class="frp-date-picker">
			<v-date-picker
				class="ma-0 pa-0"
				:class="hideTitle && 'hidden-title'"
				v-model="internalVuetifyFormattedRowDate"
				@input="closeDialog"
				full-width
				:selected-items-text="selectedItemsText"
				range>
			</v-date-picker>
		</div>
	</v-dialog>
</template>

<script>
import { findFirstAndLastDates, formatDate } from "@/utils/dates";
import FrpIcon from "Components/icon/FrpIcon";
import { masks } from "Constants/masks";
import { format, isSameDay, parse } from "date-fns";
import validator from "is-my-date-valid";
import colorsMixin from "Mixins/colorsMixin";
import { parseDate } from "Utils/dates";
import { dateFormat, isMyDateValidDateFormat, isoDateFormat } from "Utils/formats";
import { requiredRule } from "Utils/validation";

const validate = validator({ format: `${isMyDateValidDateFormat}-${isMyDateValidDateFormat}` });

export default {
	mixins: [colorsMixin],
	components: { FrpIcon },
	props: {
		hideTitle: {
			type: Boolean,
			default: false
		},
		selectedItemsText: String,
		disabled: Boolean,
		placeholder: {
			type: String,
			default: "Введите дату"
		},
		rules: {
			type: Array,
			default: () => []
		},
		label: String,
		hint: String,
		readonly: Boolean,
		clearable: Boolean,
		required: Boolean,
		hideDetails: Boolean,
		dense: Boolean,
		value: {
			type: Array
		},
		dialogWidth: {
			type: Number,
			default: 300
		},
		width: {
			default: "100%"
		},
		type: {
			default: "date"
		}
	},
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isDialogOpened: false,
			internalFormattedRowValue: "",
			internalVuetifyFormattedRowDate: [],
			masks
		};
	},
	computed: {
		defaultRules() {
			const rules = [];
			if(this.required) rules.push(requiredRule());
			return rules;
		},
		dialogSelectedInternalValue() {
			if(this.internalVuetifyFormattedRowDate && this.internalVuetifyFormattedRowDate.length === 2) {
				return findFirstAndLastDates(this.internalVuetifyFormattedRowDate.map(d => parse(d, isoDateFormat, new Date())));
			} else {
				return [];
			}
		}
	},
	methods: {
		async onChange(value) {
			if(!value || !validate(value)) {
				this.internalFormattedRowValue = "";
				this.internalVuetifyFormattedRowDate = [];
			}
		},
		async updateValue(value) {
			const isNewValue = !value || value.some(x => !x) || this.value.some(x => !x);
			const isSameDays = value?.every((x, i) => isSameDay(x,
				this.type === "string" ? parseDate(this.value[i]) : this.value[i]));
			
			if(isNewValue || !isSameDays) {
				if(this.type === "date")
					this.$emit("update:value", value || [null, null]);
				if(this.type === "number")
					this.$emit("update:value", value ? value.map(x => x.getTime()) : [0, 0]);
				if(this.type === "string")
					this.$emit("update:value", value ? value.map(x => formatDate(x, isoDateFormat)) : ["", ""]);
				
				if(value) {
					this.internalVuetifyFormattedRowDate = findFirstAndLastDates(value).map(d => format(d, isoDateFormat));
				} else {
					// временное решение для бага с v-mask
					await this.$nextTick();
					this.internalFormattedRowValue = "";
				}
			}
		},
		openDialog() {
			if(!this.$refs.input.isDisabled)
				this.isDialogOpened = true;
		},
		closeDialog() {
			if(this.dialogSelectedInternalValue && this.dialogSelectedInternalValue.length === 2) {
				this.internalFormattedRowValue = this.dialogSelectedInternalValue.map(d => format(d, dateFormat)).join("-");
				this.updateValue(this.dialogSelectedInternalValue);
				this.isDialogOpened = false;
			}
		}
	},
	mounted() {
		this.onChange(this.internalFormattedRowValue);
	},
	watch: {
		internalFormattedRowValue(value) {
			if(!value || validate(value)) {
				this.updateValue(value ? findFirstAndLastDates(value.split("-").map(d => parse(d, dateFormat, new Date()))) : null);
			}
		},
		value: {
			handler(newValue) {
				if(!newValue)
					return;
				
				if(newValue.some(x => !x)) {
					this.internalFormattedRowValue = "";
					this.internalVuetifyFormattedRowDate = [];
				} else {
					const value = this.type === "string" ? newValue.map(x => parseDate(x)) : newValue;
					
					const sortedDates = findFirstAndLastDates(value).map(d => format(d, dateFormat));
					const sortedRowDates = findFirstAndLastDates(value).map(d => format(d, isoDateFormat));
					
					this.internalFormattedRowValue = sortedDates.join("-");
					this.internalVuetifyFormattedRowDate = sortedRowDates;
				}
			},
			immediate: true
		}
	}
};
</script>
<style>
.frp-date-picker {
	.hidden-title {
		.v-picker__title {
			display: none !important;
		}
	}
}
</style>