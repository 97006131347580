import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import BankAccountsListRouteQuery from "@/store/bar/modules/bankAccountsList/types/bankAccountsListRouteQuery";
import BankAccountsListState from "@/store/bar/modules/bankAccountsList/types/bankAccountsListState";
import { parseArrayParameter } from "@/utils/query";

export default class BankAccountsListRouteQueryService {
	defaultRouteQuery: BankAccountsListRouteQuery;

	constructor(defaultRouteQuery: BankAccountsListRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: BankAccountsListState) {
		let query = new BankAccountsListRouteQuery(
			state.paging.page,
			state.filter.loanApplicationNumber,
			state.filter.counterpartyId,
			state.filter.bankId,
			state.filter.signStatuses,
			state.filter.responsibleUserId
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(BankAccountsListRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				signStatuses: parseArrayParameter(query.signStatuses)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
