import { IMiddleware } from "@/types/core/middleware";
import { Route } from "vue-router/types/router";
import { RouteNames } from "@/router/hr/routes";

export default class RedirectMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		try {
			if(to.name === RouteNames.ROOT) {
				return { name: RouteNames.EMPLOYEES };
			}
		} catch (e) {
			console.error(e);
		}
	}
}
