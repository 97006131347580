import { HrImportEmployeesRequest } from "@/types/hr/vacation/hrImportEmployeesRequest";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrImportEmployeesRequest {
	hireDateFrom?: string;
	hireDateTo?: string;
}

export class ApiHrImportEmployeesRequestMapper {
	static map(source: HrImportEmployeesRequest): ApiHrImportEmployeesRequest {
		return {
			hireDateFrom: source.hireDateFrom ? formatDate(source.hireDateFrom, isoDateFormat) as string : undefined,
			hireDateTo: source.hireDateTo ? formatDate(source.hireDateTo, isoDateFormat) as string : undefined
		}
	}
}
