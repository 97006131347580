<template>
	<frp-card :title="$t('titles.bankAccountInformation')" class="pb-1" v-if="isInitialized">
		<template #content>
			<bar-details-group>
				<bar-details-item :title="$t('details.titles.status')">
					<span class="text-subtitle-2 font-weight-medium primary--text">{{ signStatus }}</span>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.bank')">
					<frp-autocomplete v-model="bank"
									  :items="banks"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  return-object
									  class="bar-field bar-field--width"
									  hide-details
									  :disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving || isBankAccountHasPayments"
									  required
									  :placeholder="$t('fields.bank.placeholder')">
					</frp-autocomplete>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.bankBranch')">
					<frp-autocomplete v-model="bankBranch"
									  :items="bankBranches"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  return-object
									  class="bar-field bar-field--width"
									  required
									  hide-details
									  :disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving || isBankAccountHasPayments || !editableItem.bankId || (!bankBranches.length && !!bik && !!correspondentAccount)"
									  :placeholder="$t('fields.bankBranch.placeholder')">
					</frp-autocomplete>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.bik')">
					<frp-text-field v-model="bankBranchBik"
									type="number"
									:mask="masks.bik"
									hide-details
									:disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving || isBankAccountHasPayments || bankSelectionMode === BankSelectionModeTypeEnum.BANK"
									:rules="validation.bik"
									:loading="isFetchSuggestedBankAndBankBranchByBikLoading"
									required
									class="bar-field bar-field--width"
									:placeholder="$t('fields.bik.placeholder')">
					</frp-text-field>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.correspondentAccount')">
					<span class="text-subtitle-2 font-weight-medium primary--text">{{ correspondentAccount }}</span>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.accountNumber')">
					<frp-text-field v-model="bankAccountNumber"
									hide-details
									:errors="bankAccountNumberErrors"
									:disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving || isBankAccountHasPayments || !bik"
									:mask="masks.bankAccountNumber"
									:rules="validation.bankAccountNumber"
									:loading="isBankAccountNumberUniqueCheckInProgress"
									required
									class="bar-field bar-field--width"
									:placeholder="$t('fields.bankAccountNumber.placeholder')">
					</frp-text-field>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.accountCurrency')">
					<frp-autocomplete v-model="accountCurrency"
									  :items="filteredCurrencies"
									  :disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving || isBankAccountHasPayments || accountAgreements.some(x => x.accountType === BankAccountTypeEnum.PRIMARY)"
									  item-text="russianName"
									  item-value="id"
									  color="blue"
									  class="bar-field bar-field--width"
									  hide-details
									  required
									  :placeholder="$t('fields.currency.placeholder')">
					</frp-autocomplete>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.openedAtDate')">
					<frp-date-field v-model="openedAtDate"
									hide-details
									class="bar-field"
									width="270px"
									bar-date-field
									:disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving"
									:placeholder="$t('fields.openedAtDate.placeholder')">
					</frp-date-field>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.startAcceptDate')">
					<frp-date-field v-model="startAcceptDate"
									hide-details
									class="bar-field"
									width="270px"
									required
									bar-date-field
									:disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving"
									:placeholder="$t('fields.startAcceptDate.placeholder')">
					</frp-date-field>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.endAcceptDate')">
					<frp-date-field v-model="endAcceptDate"
									hide-details
									class="bar-field"
									width="270px"
									bar-date-field
									:disabled="isCardEditingDisabledWithRequests || !can(Permissions.BAR_ACCOUNT_UPDATE) || isBankAccountSaving"
									:placeholder="$t('fields.endAcceptDate.placeholder')">
					</frp-date-field>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.monitoringSystemConnection')">
					<frp-autocomplete v-model="monitoringSystemConnection"
									  :items="monitoringSystemConnectionTypes"
									  item-text="title"
									  item-value="code"
									  color="blue"
									  class="bar-field bar-field--width"
									  hide-details
									  :disabled="isCardEditingDisabledWithRequests || isMonitoringSystemConnectionFieldDisabled"
									  :placeholder="$t('fields.status.placeholder')">
					</frp-autocomplete>
				</bar-details-item>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-information-loader v-else/>
</template>

<script>
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import { masks } from "@/constants/masks";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import BatchService from "@/services/batchService";
import { namespace } from "@/store/bar/modules/bankAccount";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { CurrencyTypeEnum } from "@/store/bar/types/CurrencyTypeEnum";
import { BankSelectionModeTypeEnum } from "@/store/bar/types/BankSelectionModeTypeEnum";
import storeManager from "@/store/manager";
import {
	maxLengths,
	onlyIntegerNumbersRule,
	prepareBankAccountNumberValidationRule,
	prepareBankIdentificationCodeValidationRule, prepareMaxLengthRule,
	requiredRule
} from "@/utils/validation";
import { validateBankAccountNumber } from "@/utils/validator";
import BarBankAccountInformationLoader from "@/views/bar/bankAccount/sections/information/BarBankAccountInformationLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

const requestBatchService = new BatchService(({ interval: 500 }));

export default {
	mixins: [storeModuleBasedPage, formMixin, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountTypeEnum,
			BankAccountStatusTypeEnum,
			BankSelectionModeTypeEnum,
			BankAccountMonitoringSystemConnectionStatusTypeEnum,
			masks,
			validateBankAccountNumber,
			validation: {
				bankAccountNumber: [requiredRule(), prepareBankAccountNumberValidationRule(() => this.bik)],
				bik: [
					prepareBankIdentificationCodeValidationRule(),
					onlyIntegerNumbersRule(),
					prepareMaxLengthRule({ maxLength: maxLengths.bik })
				]
			}
		};
	},
	computed: {
		...barUserModuleHelpers.mapGetters({
			isBarUserAdministrator: barUserModuleGetterTypes.isBarUserAdministrator,
			isBarUserProjectFinancialControlDepartmentManager: barUserModuleGetterTypes.isBarUserProjectFinancialControlDepartmentManager
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			bik: state => state.bik,
			correspondentAccount: state => state.correspondentAccount,
			isFetchSuggestedBankAndBankBranchByBikLoading: state => state.isFetchSuggestedBankAndBankBranchByBikLoading,
			isBankAccountSaving: state => state.isBankAccountSaving,
			isBankAccountHasPayments: state => state.isBankAccountHasPayments,
			isBankAccountNumberUnique: state => state.isBankAccountNumberUnique,
			isBankAccountNumberUniqueCheckInProgress: state => state.isBankAccountNumberUniqueCheckInProgress,
			editableItem: state => state.editableItem,
			accountAgreements: state => state.editableItem.accountAgreements,
			bankSelectionMode: state => state.bankSelectionMode,
			activeSuggestedProject: state => state.activeSuggestedProject,
			banks: state => state.banks,
			bankBranches: state => state.bankBranches,
			bankAccountTypes: state => state.bankAccountTypes,
			currencies: state => state.currencies,
			signStatuses: state => state.signStatuses,
			monitoringSystemConnectionTypes: state => state.monitoringSystemConnectionTypes
		}),
		...mapGetters({
			isCardEditingDisabledWithRequests: getterTypes.isCardEditingDisabledWithRequests
		}),
		filteredCurrencies() {
			if(this.editableItem.account.accountType === BankAccountTypeEnum.PRIMARY)
				return this.currencies.filter(x => x.code === CurrencyTypeEnum.RUB);
			
			return this.currencies;
		},
		bank: {
			get() {
				return this.banks.find(x => x.id === this.editableItem.bankId) || "";
			},
			set(value) {
				this.setSelectedBank(value);
				this.signStatus = this.renderSignStatus();
			}
		},
		bankBranch: {
			get() {
				return this.bankBranches.find(x => x.id === this.editableItem.bankBranchId) || "";
			},
			set(value) {
				this.setSelectedBankBranch(value);
			}
		},
		bankBranchBik: {
			get() {
				return this.bik;
			},
			set(value) {
				this.setBik(value);
			}
		},
		bankAccountNumber: {
			get() {
				return this.editableItem.account.accountNumber;
			},
			set(value) {
				this.setBankAccountNumber(value);
			}
		},
		accountCurrency: {
			get() {
				return this.editableItem.account.currencyId;
			},
			set(value) {
				this.setEditableItemCurrencyId(value);
			}
		},
		openedAtDate: {
			get() {
				return this.editableItem.account.openedAt;
			},
			set(value) {
				this.setEditableItemOpenedAt(value);
			}
		},
		startAcceptDate: {
			get() {
				return this.editableItem.startAcceptDate;
			},
			set(value) {
				this.setEditableItemStartAcceptDate(value);
			}
		},
		endAcceptDate: {
			get() {
				return this.editableItem.endAcceptDate;
			},
			set(value) {
				this.setEditableItemEndAcceptDate(value);
				this.signStatus = this.renderSignStatus();
			}
		},
		monitoringSystemConnection: {
			get() {
				return this.editableItem.monitoringSystemConnectionStatus;
			},
			set(value) {
				this.setEditableItemMonitoringSystemConnectionStatus(value);
				this.signStatus = this.renderSignStatus();
			}
		},
		signStatus: {
			get() {
				return this.signStatuses.find(x => x.code === this.editableItem.status)?.title || "";
			},
			set(value) {
				this.setEditableItemStatus(value);
			}
		},
		isMonitoringSystemConnectionFieldDisabled() {
			const changeMonitoringSystemConnectionAccessAllowed = this.isBarUserAdministrator ||
				this.isBarUserProjectFinancialControlDepartmentManager;
			
			return !this.can(this.Permissions.BAR_ACCOUNT_UPDATE)
				|| this.isBankAccountSaving
				|| (this.isBankAccountHasPayments && !changeMonitoringSystemConnectionAccessAllowed);
		},
		bankAccountNumberErrors() {
			return this.isBankAccountNumberUnique ? [] : [`${this.$t("alerts.errors.bankAccountNumberAlreadyExists")}`];
		},
		isActiveMonitoringSystemConnectionStatusExist() {
			return this.editableItem.monitoringSystemConnectionStatus === BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_REQUIRED
				|| this.editableItem.monitoringSystemConnectionStatus === BankAccountMonitoringSystemConnectionStatusTypeEnum.CONNECTED;
		}
	},
	methods: {
		...mapMutations({
			setEditableItemAccountNumber: mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_NUMBER,
			setEditableItemCurrencyId: mutationTypes.SET_EDITABLE_ITEM_CURRENCY_ID,
			setEditableItemOpenedAt: mutationTypes.SET_EDITABLE_ITEM_OPENED_AT,
			setEditableItemStartAcceptDate: mutationTypes.SET_EDITABLE_ITEM_START_ACCEPT_DATE,
			setEditableItemEndAcceptDate: mutationTypes.SET_EDITABLE_ITEM_END_ACCEPT_DATE,
			setEditableItemMonitoringSystemConnectionStatus: mutationTypes.SET_EDITABLE_ITEM_MONITORING_SYSTEM_CONNECTION_STATUS,
			setEditableItemStatus: mutationTypes.SET_EDITABLE_ITEM_STATUS,
			setCorrespondentAccount: mutationTypes.SET_CORRESPONDENT_ACCOUNT
		}),
		...mapActions({
			initializeStore: actionTypes.initialize,
			setSelectedBank: actionTypes.setSelectedBank,
			setSelectedBankBranch: actionTypes.setSelectedBankBranch,
			setBik: actionTypes.setBik,
			checkIsBankAccountNumberUnique: actionTypes.checkIsBankAccountNumberUnique
		}),
		setBankAccountNumber(value) {
			this.setEditableItemAccountNumber(value);
			
			if(validateBankAccountNumber(value, this.bik))
				requestBatchService.push(async () => await this.checkIsBankAccountNumberUnique());
		},
		renderSignStatus() {
			if(this.editableItem.endAcceptDate) return BankAccountStatusTypeEnum.DISCONNECTED;
			
			if(this.editableItem.monitoringSystemConnectionStatus ===
				BankAccountMonitoringSystemConnectionStatusTypeEnum.TO_DISCONNECT) return BankAccountStatusTypeEnum.TO_DISCONNECT;
			
			if([BankAccountMonitoringSystemConnectionStatusTypeEnum.CONNECTED,
				BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_REQUIRED].includes(
				this.editableItem.monitoringSystemConnectionStatus)) return BankAccountStatusTypeEnum.CONNECTED;
			
			return BankAccountStatusTypeEnum.TO_CONNECT;
		}
	},
	components: {
		FrpConfirmDialog,
		FrpTextBtn,
		BarBankAccountInformationLoader,
		FrpDateField,
		FrpTextField,
		FrpAutocomplete,
		BarDetailsItem,
		FrpCard,
		BarDetailsGroup
	}
};
</script>

<style scoped>

</style>
