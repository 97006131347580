<template>
	<frp-card is-loading style="padding-bottom: 14px !important;">
		<template #content>
			<bar-card-content-layout>
				<v-row>
					<v-col class="pt-4">
						<frp-text-loader width="100%"/>
						<frp-text-loader class="mt-2" width="10%"/>
					</v-col>
				</v-row>
				
				<v-row class="mt-1 d-flex justify-end align-center">
					<v-col cols="6" class="d-flex flex-column align-end">
						<frp-text-loader height="14" width="100%"/>
						<frp-text-loader class="mt-1" height="14" width="50%"/>
					</v-col>
					<v-col cols="auto" class="ml-4">
						<frp-btn-loader width="155" height="40"/>
					</v-col>
				</v-row>
			</bar-card-content-layout>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: { FrpBtnLoader, FrpTextLoader, BarCardContentLayout, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
