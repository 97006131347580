import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/employee/modules/promises/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import router from "@/router/hr";
import EmployeePromisesState from "@/store/hr/modules/employee/modules/promises/types/employeePromisesState";
import { ApiHrGetTasksParameters } from "@/api/hr/types/tasks/apiHrGetTasksParameters";
import { ApiHrTasksTypesEnum } from "@/api/hr/types/tasks/apiHrTasksTypesEnum";
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";

const abortService = new AbortService();
const hrController = new HrController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeePromisesState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeePromisesState, any>>{};

const actions = <ActionTree<EmployeePromisesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		
	},
	async [actionTypes.fetch]({ commit, dispatch }) {
		try {
			const parameters: ApiHrGetTasksParameters = {
				taskTypes: [ApiHrTasksTypesEnum.Signing, ApiHrTasksTypesEnum.Approval],
				taskStates: [ApiHrTasksStateEnum.ToDo],
				taskAssigneeIds: [router.currentRoute.params.id]
			};
			
			let [bitrixPromises, hrTaskStats] = await Promise.all([
					hrController.getBitrixPromises(router.currentRoute.params.id),
					hrController.getTasksPromises(parameters)
				]
			);
			
			commit(mutationTypes.SET_BITRIX_PROMISES, bitrixPromises);
			commit(mutationTypes.SET_HR_TASK_STATS, hrTaskStats);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		}
	}
};

const mutations = <MutationTree<EmployeePromisesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_BITRIX_PROMISES](state, value) {
		state.bitrixPromises = value;
	},
	[mutationTypes.SET_HR_TASK_STATS](state, value) {
		state.hrTaskStats = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeePromisesModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeePromisesModule;
