import { BankBranchInfo, BankBranchInfoMapper } from "@/store/bar/types/bankBranchInfo";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { ApiBankBranchInfoPersisted } from "@/api/bar/types/dictionaries/apiBankBranchInfoPersisted";

export interface BankInfo {
	id: string;
	name: string;
	responsibleEmployeeId: string;
	branches: BankBranchInfo[];
	directWithdrawalAgreementTemplateUrl: string | null
}

export class BankInfoMapper {
	static map(source: ApiBankInfoPersisted): BankInfo {
		return {
			...source.bankInfo,
			id: source.id,
			branches: source.bankInfo.branches.map((x: ApiBankBranchInfoPersisted) => BankBranchInfoMapper.map(x))
		};
	}
}