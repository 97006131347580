<template>
	<bar-content-layout>
		<template #title-append>
			<frp-text-btn class="pa-0"
						  :to="{ name: RouteNames.BANK_ACCOUNTS_LIST }"
						  color="blue">
				<span>{{ $t("buttons.bankAccounts") }}</span>
			</frp-text-btn>
		</template>
		
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="frp-card pa-0 pt-9">
					<v-row class="px-6 pb-3">
						<v-col class="d-flex justify-center align-end" style="gap: 24px">
							<frp-text-field v-model="internalFilterValues.loanApplicationNumber"
											hide-details
											@keydown.enter="applyFilter"
											class="bar-field bar-field--filter"
											:placeholder="$t('fields.projectNumber.placeholder')">
							</frp-text-field>
							
							<frp-autocomplete v-model="internalFilterValues.counterpartyId"
											  :min-query-length="MIN_COUNTERPARTIES_QUERY_LENGTH"
											  :items="suggestedCounterparties"
											  @update:search-input="getSuggestedCounterparties"
											  item-text="shortName"
											  item-value="id"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  :loading="isFetchSuggestedCounterpartyLoading"
											  class="bar-field bar-field--filter"
											  :placeholder="$t('fields.organization.label')">
							</frp-autocomplete>
							
							<frp-autocomplete v-model="internalFilterValues.status"
											  :items="applicationStatuses"
											  item-text="title"
											  item-value="code"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  class="bar-field bar-field--filter"
											  :placeholder="$t('fields.applicationStatus.placeholder')">
							</frp-autocomplete>
							
							<v-spacer></v-spacer>
							
							<div class="align-self-end d-flex flex-nowrap">
								<frp-btn elevation="0"
										 color="primary"
										 outlined
										 dark
										 class="px-14"
										 :disabled="isFilterEmpty && !isFilterChanged"
										 @click="resetFilter">
									{{ $t("buttons.reset") }}
								</frp-btn>
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 class="px-14"
										 :disabled="!isFilterChanged"
										 @click="applyFilter">
									{{ $t("buttons.search") }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  :items="items"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="bar-table d-flex flex-column">
								<template #item.application="{item}">
									<span v-if="!item.applicationNumber && !item.createdAt">{{ "" }}</span>
									<frp-text-btn v-else
												  :to="{ name: RouteNames.BANK_ACCOUNT_APPLICATION, query: { loanApplicationNumber: item.loanApplicationNumber, sourceType: item.source }, params: { id: item.id }}"
												  class="pa-0 bar-text-btn"
												  :text="`${item.applicationNumber} ${$t('common.from')} ${formatDate(item.createdAt, dateFormat)}`"
												  color="primary">
									</frp-text-btn>
								</template>
								
								<template #item.organizationName="{item}">
									<span>{{ item?.agreement.counterparty.shortName }}</span>
								</template>
								
								<template #item.loanApplicationNumber="{item}">
									<frp-text-btn v-if="item?.agreement?.entryId && item.loanApplicationNumber"
												  :href="getProjectLink(item.agreement.entryId)"
												  class="pa-0 bar-text-btn bar-project-link"
												  style="min-width: fit-content;"
												  :text="item.loanApplicationNumber"
												  color="primary"
												  target="_blank">
									</frp-text-btn>
									<span v-else class="text-subtitle-2 primary--text">{{ item.loanApplicationNumber || "" }}</span>
								</template>
								
								<template #item.bankId="{ item }">
									<span>{{ banks.find(x => x.id === item.bankId)?.name || "" }}</span>
								</template>
								
								<template #item.type="{ item }">
									<div class="d-flex flex-column">
										<template v-for="({accountType}, i) in item.accounts">
											<span :key="`${i}-${item?.id}`">
												<span>{{ bankAccountTypes.find(x => x.code === accountType)?.title }}</span>
												<span v-if="i !== item.accounts.length - 1">,</span>
											</span>
										</template>
									</div>
								</template>
								
								<template #item.applicationStatus="{ item }">
									<span v-if="!applicationStatuses.length || !item.status">{{ "" }}</span>
									<bar-application-status-label v-else
																  :statuses="applicationStatuses"
																  :status="item.status">
									</bar-application-status-label>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		
		<template #footer-pagination>
			<frp-pagination v-if="!isItemsLoading"
							v-model="page"
							active-color="blue"
							:length="Math.ceil(paging.total / paging.pageSize)">
			</frp-pagination>
		</template>
		
		<template #footer-actions>
			<frp-btn v-if="$route.query.loanApplicationNumber && can(Permissions.BAR_APPLICATION_CREATE_CREATE)" class="ml-6 px-12"
					 elevation="0"
					 dark
					 color="blue"
					 :to="{ name: RouteNames.BANK_ACCOUNT_APPLICATION_CREATE, query: {loanApplicationNumber: $route.query.loanApplicationNumber, sourceType: SourceTypeEnum.KZ} }">
				{{ $t("buttons.create") }}
			</frp-btn>
		</template>
	</bar-content-layout>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import BarApplicationStatusLabel from "@/components/common/BarApplicationStatusLabel.vue";
import FrpPagination from "@/components/common/FrpPagination.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import BarFooter from "@/components/layouts/BarFooter.vue";
import LoanContentLayout from "@/components/layouts/LoanContentLayout.vue";
import { externalUrls } from "@/constants/bar/externalUrls";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/bar/routes";
import BatchService from "@/services/batchService";
import { MIN_COUNTERPARTIES_QUERY_LENGTH } from "@/store/bar/constants";
import { namespace } from "@/store/bar/modules/bankAccountApplicationsList";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplicationsList/types";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { SuggestedCounterpartiesParameterTypeEnum } from "@/store/bar/types/SuggestedCounterpartiesParameterTypeEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const requestBatchService = new BatchService(({ interval: 500 }));

export default {
	metaInfo: {
		title: i18n.t("metaTitles.bankAccountApplications")
	},
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			formatDate,
			dateFormat,
			RouteNames,
			SourceTypeEnum,
			BankAccountTypeEnum,
			BankAccountApplicationStatusTypeEnum,
			SuggestedCounterpartiesParameterTypeEnum,
			externalUrls,
			MIN_COUNTERPARTIES_QUERY_LENGTH,
			internalFilterValues: {
				loanApplicationNumber: "",
				counterpartyId: "",
				status: ""
			}
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			banks: state => state.banks,
			bankAccountTypes: state => state.bankAccountTypes,
			applicationStatuses: state => state.applicationStatuses,
			suggestedCounterparties: state => state.suggestedCounterparties,
			isFetchSuggestedCounterpartyLoading: state => state.isFetchSuggestedCounterpartyLoading
		}),
		headers() {
			return [
				{
					text: this.$t("tables.application"),
					value: "application",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.organizationName"),
					value: "organizationName",
					class: "text-caption",
					width: "28%",
					sortable: false
				},
				{
					text: this.$t("tables.projectNumber"),
					value: "loanApplicationNumber",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.bank"),
					value: "bankId",
					class: "text-caption",
					width: "22%",
					sortable: false
				},
				{
					text: this.$t("tables.type"),
					value: "type",
					class: "text-caption",
					width: "14%",
					sortable: false
				},
				{
					text: this.$t("tables.applicationStatus"),
					value: "applicationStatus",
					class: "text-caption",
					width: "14%",
					sortable: false
				}
			];
		},
		filter() {
			return {
				loanApplicationNumber: this.internalFilterValues.loanApplicationNumber,
				counterpartyId: this.internalFilterValues.counterpartyId,
				status: this.internalFilterValues.status
			};
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			fetchSuggestedCounterparties: actionTypes.fetchSuggestedCounterparties
		}),
		...mapMutations({
			setFilterLoanApplicationNumber: mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER,
			setFilterCounterpartyId: mutationTypes.SET_FILTER_COUNTERPARTY_ID,
			setFilterStatus: mutationTypes.SET_FILTER_STATUS,
			resetSuggestedCounterparties: mutationTypes.RESET_SUGGESTED_COUNTERPARTIES
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] = this.filterValues[key]);
		},
		resetFilter() {
			this.internalFilterValues.loanApplicationNumber = "";
			this.internalFilterValues.counterpartyId = "";
			this.internalFilterValues.status = "";
			this.applyFilter();
		},
		applyFilter() {
			this.setFilterLoanApplicationNumber(this.internalFilterValues.loanApplicationNumber || "");
			this.setFilterCounterpartyId(this.internalFilterValues.counterpartyId || "");
			this.setFilterStatus(this.internalFilterValues.status || "");
		},
		async getSuggestedCounterparties(query) {
			const counterparty = this.suggestedCounterparties.find(x => x.shortName === query);
			if(counterparty && counterparty.id === this.internalFilterValues.counterpartyId) return;
			
			if(query?.length && query.length >= MIN_COUNTERPARTIES_QUERY_LENGTH)
				requestBatchService.push(async () => await this.fetchSuggestedCounterparties({
					parameter: query,
					type: SuggestedCounterpartiesParameterTypeEnum.QUERY
				}));
		},
		getProjectLink(entryId) {
			return `${externalUrls.lkProject}/${entryId}`;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		BarApplicationStatusLabel,
		FrpPagination,
		BarFooter,
		FrpAutocomplete,
		FrpTextField,
		FrpBtn,
		FrpTextBtn,
		BarContentLayout,
		LoanContentLayout
	}
};
</script>
<style lang="scss">
</style>
