<template>
	<frp-card v-if="isInitialized" :title="$t('titles.futureAccountUsage')" class="pb-1">
		<template #content>
			<bar-details-group>
				<template v-for="accountUsage in accountUsages">
					<bar-details-item :title="getBankName(accountUsage.bankId)"></bar-details-item>
					<template v-for="item in accountUsage.items">
						<bar-details-item :link="Boolean(item.accountId)"
										  :to="{ name: RouteNames.BANK_ACCOUNT, params: { id: item.accountId }}"
										  :title="item.accountNumber">
							<span>
								{{ applicationBankAccountUsageTypes.find(x => x.code === item.usageType)?.title }}
							</span>
						</bar-details-item>
					</template>
				</template>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-application-information-accounts-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import BarBankAccountApplicationInformationAccountsLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			bankAccountTypes: state => state.bankAccountTypes,
			currencies: state => state.currencies,
			accountUsages: state => state.accountUsages,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading,
			applicationBankAccountUsageTypes: state => state.applicationBankAccountUsageTypes
		}),
		...mapGetters({
			getBankName: getterTypes.getBankName
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({})
	},
	components: { FrpTextBtn, BarBankAccountApplicationInformationAccountsLoader, FrpIcon, FrpDateField, FrpTextField, FrpAutocomplete, FrpBtn, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
