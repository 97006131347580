import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { ApiBankAccountTypeHistory } from "@/api/bar/types/apiBankAccountTypeHistory";
import { convertToZonedTimestamp } from "@/utils/dates";

export interface BankAccountTypeHistory {
	creatorFullName: string,
	accountType: BankAccountTypeEnum,
	createdAt: number
}

export class BankAccountTypeHistoryMapper {
	static map(source: ApiBankAccountTypeHistory): BankAccountTypeHistory {
		return {
			...source,
			createdAt: convertToZonedTimestamp(source.createdAt) as number
		};
	}
}