import IPageState from "@/store/shared/base/types/pageState";
import ApiApplicationStatus from "@/api/bar/types/dictionaries/apiApplicationStatus";
import BankAccountApplicationsListFilter from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListFilter";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import { ApiSuggestedCounterparty } from "@/api/bar/types/apiSuggestedCounterparty";
import { BankInfo } from "@/store/bar/types/bankInfo";
import { BankAccountApplicationsListItem } from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListItem";
import ApiBankAccountType from "@/api/bar/types/dictionaries/apiBankAccountType";

export default class BankAccountApplicationsListState implements IPageState {
	constructor(
		public listing: ListingModel<BankAccountApplicationsListItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public banks: BankInfo[] = [],
		public applicationStatuses: ApiApplicationStatus[] = [],
		public bankAccountTypes: ApiBankAccountType[] = [],
		public filter: BankAccountApplicationsListFilter = new BankAccountApplicationsListFilter(),
		public isFetchSuggestedCounterpartyLoading: boolean = false,
		public suggestedCounterparties: ApiSuggestedCounterparty[] = []
	)
	{
	}
}
