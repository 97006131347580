import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { ContractInfo, ContractInfoHelper } from "@/store/bar/types/contractInfo";
import { BankAccountData, BankAccountDataHelper } from "@/store/bar/types/bankAccountData";
import { ApiBankAccountApplicationInfoPersisted } from "@/api/bar/types/bankAccountApplicationInfo/apiBankAccountApplicationInfoPersisted";
import { convertToZonedTimestamp } from "@/utils/dates";
import { ApiAgreement } from "@/api/bar/types/agreement/apiAgreement";
import { ApiAccountUsageItem } from "@/api/bar/types/apiAccountUsageItem";

export interface BankAccountApplicationInfo {
	id?: string;
	createdAt: number;
	source: SourceTypeEnum;
	applicationNumber: number;
	loanApplicationNumber: number;
	status: BankAccountApplicationStatusTypeEnum;
	bankId: string;
	bankBranchId: string;
	isLegalDepartmentRequestSent: boolean;
	contract: ContractInfo;
	accounts: BankAccountData[];
	agreement?: ApiAgreement;
	accountUsages?: ApiAccountUsageItem[];
}

export class BankAccountApplicationInfoHelper {
	static map(source: ApiBankAccountApplicationInfoPersisted): BankAccountApplicationInfo {
		return {
			...source.bankAccountApplicationInfo,
			id: source.id,
			loanApplicationNumber: source.bankAccountApplicationInfo.agreement.number || 0,
			createdAt: convertToZonedTimestamp(source.createdAt) as number,
			contract: ContractInfoHelper.map(source.bankAccountApplicationInfo.contract),
			accounts: source.bankAccountApplicationInfo.accounts.map(x => BankAccountDataHelper.map(x))
		};
	}
	
	static getEmpty(): BankAccountApplicationInfo {
		return {
			createdAt: 0,
			source: SourceTypeEnum.UNKNOWN,
			applicationNumber: 0,
			loanApplicationNumber: 0,
			status: BankAccountApplicationStatusTypeEnum.IN_PROGRESS,
			bankId: "",
			bankBranchId: "",
			isLegalDepartmentRequestSent: false,
			contract: ContractInfoHelper.getEmpty(),
			accounts: []
		};
	}
}