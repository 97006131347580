import { registerAlertService } from "@/store/modules/alerts/services/alertService";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree } from "vuex";
import alertsModule from "@/store/modules/alerts";
import RootState from "@/store/bar/types/rootState";
import { PageModeType } from "@/store/bar/types/pageModeType";
import { mutationTypes } from "@/store/bar/types";
import bankAccountsListModule from "@/store/bar/modules/bankAccountsList";
import userModule from "@/store/bar/modules/user";
import breadcrumbsModule from "@/store/bar/modules/breadcrumbs";
import bankAccountModule from "@/store/bar/modules/bankAccount";
import bankAccountApplicationsListModule from "@/store/bar/modules/bankAccountApplicationsList";
import bankAccountApplicationModule from "@/store/bar/modules/bankAccountApplication";
import bankAccountApplicationDocumentsModule from "@/store/bar/modules/bankAccountApplication/modules/documents";
import bankAccountApplicationLegalDepartmentModule from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment";
import bankAccountApplicationMessengerModule from "@/store/bar/modules/bankAccountApplication/modules/messenger";

Vue.use(Vuex);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{};

const mutations = <MutationTree<RootState>>{
	[mutationTypes.SET_IS_APP_LOADING](state, value) {
		state.isAppLoading = value;
	},
	[mutationTypes.SET_PAGE_MODE](state, value) {
		state.pageMode = value;
	},
	[mutationTypes.RESET_PAGE_MODE](state) {
		state.pageMode = PageModeType.OK;
	},
	[mutationTypes.SET_PAGE_MODE_NOT_FOUND](state) {
		state.pageMode = PageModeType.PAGE_NOT_FOUND;
	},
	[mutationTypes.SET_PAGE_MODE_ACCESS_FORBIDDEN](state) {
		state.pageMode = PageModeType.ACCESS_DENIED;
	}
};

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	modules: {
		[bankAccountApplicationsListModule.namespace]: {
			...bankAccountApplicationsListModule
		},
		[bankAccountApplicationModule.namespace]: {
			...bankAccountApplicationModule
		},
		[bankAccountApplicationDocumentsModule.namespace]: {
			...bankAccountApplicationDocumentsModule
		},
		[bankAccountApplicationLegalDepartmentModule.namespace]: {
			...bankAccountApplicationLegalDepartmentModule
		},
		[bankAccountApplicationMessengerModule.namespace]: {
			...bankAccountApplicationMessengerModule
		},
		[bankAccountsListModule.namespace]: {
			...bankAccountsListModule
		},
		[bankAccountModule.namespace]: {
			...bankAccountModule
		},
		[userModule.namespace]: {
			...userModule
		},
		[breadcrumbsModule.namespace]: {
			...breadcrumbsModule
		},
		[alertsModule.namespace]: {
			...alertsModule
		}
	}
});

const registerSubscribers = (store: any) => {
	breadcrumbsModule.subscribe(store);
	bankAccountApplicationModule.subscribe(store);
	
	bankAccountApplicationsListModule.initializeSubscribersManager(store);
	bankAccountsListModule.initializeSubscribersManager(store);
	
	bankAccountModule.initializeSubscribersManager(store);
};
registerAlertService(store);
registerSubscribers(store);

export default store;
