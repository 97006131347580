<template>
	<div>
		<hr-card :title="$t('titles.promises')" dense style="min-height: 64px" class="pb-4 pt-6"></hr-card>
		
		<v-card class="kpi-card d-flex flex-column mt-4 frp-scrollbar" style="max-height: 348px"
				:style="isInitialized ? 'overflow-y: auto': ''">
			<v-progress-circular style="min-height: 116px" class="align-self-center"
								 v-if="!isInitialized"
								 indeterminate color="primary">
			</v-progress-circular>
			<div style="min-height: 116px" class="fill-height d-flex align-center justify-center" v-else-if="isEmpty">
				{{ $t("alerts.info.noData") }}
			</div>
			<v-expansion-panels class="hr-extension-panels" flat tile
								v-else
								:value="items.map((_, i) => i)" multiple
								style="font-size: 14px; position: relative">
				<v-expansion-panel v-for="(item,i) in items"
								   :key="i" class="mt-0 pr-7 pl-5">
					<div class="colored-side-line" :class="item.color"></div>
					<v-expansion-panel-header class="pa-0" style="min-height: 50px">
						<template v-slot:actions>
							<v-icon class="icon">$expand</v-icon>
						</template>
						<div class="header ml-1">
							<frp-link-btn style="width: fit-content" target="_blank" :href="item.href"
										  :color="colors.blue.base" :underline="false">
								<span style="font-weight: 600">{{ item.title }}</span>
							</frp-link-btn>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content class="pl-1 pb-4">
						<div class="d-flex align-center justify-space-between" v-for="(field, idx) in item.fields"
							 :class="idx !== item.fields.length - 1 ? 'mb-2' : ''">
							<span>{{ $t(`content.${field}`) }}</span>
							<span>{{ item[field] }}</span>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
	</div>
</template>

<script>
import { ApiHrTasksTypesEnum } from "@/api/hr/types/tasks/apiHrTasksTypesEnum";
import router from "@/router/hr";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import HrCard from "Components/cards/HrCard";
import FrpIcon from "Components/icon/FrpIcon";
import { externalUrls } from "Constants/hr/externalUrls";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/hr/routes";
import { actionTypes } from "Store/hr/modules/employee/modules/promises/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.hr.employee.promises.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin, storeModuleBasedPage],
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			bitrixPromises: state => state.bitrixPromises,
			hrTaskStats: state => state.hrTaskStats
		}),
		hrApprovalCount() {
			const hrApproval = this.hrTaskStats.find(x => x.type === ApiHrTasksTypesEnum.Approval);
			return hrApproval ? hrApproval.count : 0;
		},
		hrSigningCount() {
			const hrSigning = this.hrTaskStats.find(x => x.type === ApiHrTasksTypesEnum.Signing);
			return hrSigning ? hrSigning.count : 0;
		},
		items() {
			return [
				{
					...this.bitrixPromises, title: this.$t(
						"content.bitrix"), color: "orange darken-1", href: externalUrls.promises.bitrix, fields: ["processing",
						"overdueCount"]
				},
				{
					approval: this.hrApprovalCount, signing: this.hrSigningCount, title: this.$t(
						"content.hrTasks"), color: "blue darken-1", href: router.resolve(
						{ name: RouteNames.TASKS }).href, fields: ["approval", "signing"]
				}
			];
		},
		isEmpty() {
			return this.items.every((x => !x.processing && !x.overdueCount));
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: { FrpLinkBtn, FrpIcon, FrpRouterLinkBtn, HrCard }
};
</script>
