import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrFeedback } from "@/api/hr/types/apiHrFeedback";

export default class EmployeeFeedbackState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public feedback: ApiHrFeedback = {} as ApiHrFeedback
	)
	{
	}
}
