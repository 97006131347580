<template>
	<bar-content-layout>
		<v-row>
			<v-col cols="6">
				<bar-bank-account-application-information-editable-loader/>
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<template v-if="$route.name === RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE">
				<frp-btn-loader width="145" height="40"></frp-btn-loader>
				<frp-btn-loader class="ml-4" width="155" height="40"></frp-btn-loader>
			</template>
			<template v-else>
				<frp-btn-loader width="165" height="40"></frp-btn-loader>
			</template>
		</template>
	</bar-content-layout>
</template>

<script>
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";
import { RouteNames } from "@/router/bar/routes";
import BarBankAccountApplicationInformationEditableLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformationEditableLoader.vue";

export default {
	data() {
		return {
			RouteNames
		};
	},
	components: { FrpBtnLoader, BarBankAccountApplicationInformationEditableLoader, BarContentLayout }
};
</script>

<style scoped>

</style>
