import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { ApiStatusHistoryItem } from "@/api/bar/types/apiStatusHistoryItem";
import { convertToZonedTimestamp } from "@/utils/dates";

export interface StatusHistoryItem {
	id: string;
	bankAccountApplicationId: string;
	createdAt: number;
	status: BankAccountApplicationStatusTypeEnum;
	creatorId: string;
	creatorShortName: string;
}

export class StatusHistoryItemMapper {
	static map(source: ApiStatusHistoryItem): StatusHistoryItem {
		return {
			...source,
			createdAt: convertToZonedTimestamp(source.createdAt) as number
		};
	}
}