import { ApiHrDocumentTypesEnum } from "@/api/hr/types/ApiHrDocumentTypesEnum";
import { ApiHrDocumentKindsEnum } from "@/api/hr/types/ApiHrDocumentKindsEnum";
import { HrDownloadArchiveRequest } from "@/api/hr/types/hrDownloadArchiveRequest";

export interface ApiHrDownloadArchiveParameters {
	employeeIds?: string[];
	dateFrom?: string;
	dateTo?: string;
	documentTypes: ApiHrDocumentTypesEnum;
	documentKinds?: ApiHrDocumentKindsEnum;
}

export class ApiHrDownloadArchiveRequestMapper {
	static map(source: HrDownloadArchiveRequest): ApiHrDownloadArchiveParameters {
		const [dateFrom, dateTo] = source.exportAllTime ? [] : source.periodRange;
		
		return {
			employeeIds: source.employeeIds,
			dateFrom,
			dateTo,
			documentTypes: source.documentType,
			documentKinds: source?.documentKind !== ApiHrDocumentKindsEnum.All ? source.documentKind : undefined
		};
	}
}