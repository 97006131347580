<template>
	<bar-content-layout>
		<v-row>
			<v-col cols="6">
				<bar-bank-account-information-loader/>
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<frp-btn-loader height="40" width="115"/>
			<frp-btn-loader class="ml-2" height="40" width="152"/>
		</template>
	</bar-content-layout>
</template>

<script>
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";
import BarBankAccountInformationLoader from "@/views/bar/bankAccount/sections/information/BarBankAccountInformationLoader.vue";
export default {
	components: { FrpBtnLoader, BarBankAccountInformationLoader, BarContentLayout }
};
</script>

<style scoped>

</style>
