<template>
	<bar-content-layout>
		<template #title-append>
			<frp-text-btn class="pa-0"
						  :to="{ name: RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST }"
						  color="blue">
				<span>{{ $t("buttons.bankAccountApplications") }}</span>
			</frp-text-btn>
		</template>
		
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="frp-card pa-0 pt-9">
					<v-row class="px-6 pb-3">
						<v-col class="d-flex justify-center align-end" style="gap: 24px">
							<frp-text-field v-model="internalFilterValues.loanApplicationNumber"
											hide-details
											@keydown.enter="applyFilter"
											class="bar-field bar-field--filter"
											:placeholder="$t('fields.projectNumber.placeholder')">
							</frp-text-field>
							
							<frp-autocomplete v-model="internalFilterValues.counterpartyId"
											  :min-query-length="MIN_COUNTERPARTIES_QUERY_LENGTH"
											  :items="suggestedCounterparties"
											  @update:search-input="getSuggestedCounterparties"
											  item-text="shortName"
											  item-value="id"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  :loading="isFetchSuggestedCounterpartyLoading"
											  class="bar-field bar-field--filter"
											  :placeholder="$t('fields.organization.label')">
							</frp-autocomplete>
							
							<frp-autocomplete v-model="internalFilterValues.bankId"
											  :items="banks"
											  item-text="name"
											  item-value="id"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  class="bar-field bar-field--filter"
											  :placeholder="$t('fields.bank.additionPlaceholder')">
							</frp-autocomplete>
							
							<frp-autocomplete v-model="internalFilterValues.signStatuses"
											  multiple
											  :items="signStatuses"
											  item-text="title"
											  item-value="code"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  class="bar-field bar-field--filter"
											  :placeholder="$t('fields.signStatus.placeholder')">
							</frp-autocomplete>
							
							<frp-autocomplete v-model="internalFilterValues.responsibleUserId"
											  :items="bankResponsibleUsers"
											  item-text="fullName"
											  item-value="id"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  class="bar-field bar-field--filter"
											  :placeholder="$t('fields.responsibleUser.label')">
							</frp-autocomplete>
							
							<v-spacer></v-spacer>
							
							<div class="align-self-end d-flex flex-nowrap">
								<frp-btn elevation="0"
										 color="primary"
										 outlined
										 dark
										 class="px-14"
										 :disabled="isFilterEmpty && !isFilterChanged"
										 @click="resetFilter">
									{{ $t("buttons.reset") }}
								</frp-btn>
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 class="px-14"
										 :disabled="!isFilterChanged"
										 @click="applyFilter">
									{{ $t("buttons.search") }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  :items="formattedItems"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="bar-table d-flex flex-column">
								<template #item.accountNumber="{ item }">
									<span v-if="!item.account.accountNumber">{{ "" }}</span>
									<frp-text-btn v-else
												  :to="{ name: RouteNames.BANK_ACCOUNT, params: { id: item.id }}"
												  class="pa-0 bar-text-btn"
												  :text="item.account.accountNumber"
												  color="primary">
									</frp-text-btn>
								</template>
								
								<template #item.createdDate="{ item }">
									<span>{{ item.createdAt || "" }}</span>
								</template>
								
								<template #item.counterpartyShortName="{ item }">
									<span>{{ item.accountAgreements[0]?.agreement.counterparty.shortName || "" }}</span>
								</template>
								
								<template #item.projectsAndType="{ item }">
									<div class="d-flex flex-column">
										<span v-for="({agreement, accountType}, index) in item.accountAgreements"
											  :key="`${agreement.id}-${index}`">
											<a :href="`${externalUrls.lkProject}/${agreement.entryId}`"
											   class="loan-link text-decoration-none">
												{{ agreement.number }}
											</a>
											<span v-if="accountType && accountType !== BankAccountTypeEnum.UNKNOWN">
												{{ ` - ${bankAccountTypes.find(x => x.code === accountType)?.title}` }}
											</span>
										</span>
									</div>
								</template>
								
								<template #item.currency="{ item }">
									<span>{{ currencies.find(x => x.id === item.account.currencyId)?.russianName }}</span>
								</template>
								
								<template #item.bank="{ item }">
									<span>{{ banks.find(x => x.id === item.bankId)?.name || "" }}</span>
								</template>
								
								<template #item.status="{ item }">
									<span
										v-if="!signStatuses.length || !item.status || item.status === BankAccountStatusTypeEnum.UNKNOWN"></span>
									<bar-sign-status-label v-else
														   :statuses="signStatuses"
														   :status="item.status">
									</bar-sign-status-label>
								</template>
								
								<template #item.controlDate="{ item }">
									<span
										:class="{ 'secondary--text': item.controlDateHasPassed && ![BankAccountStatusTypeEnum.CONNECTED, BankAccountStatusTypeEnum.DISCONNECTED].includes(item.status) }">
										{{ item.controlDate || "" }}
									</span>
								</template>
								
								<template #item.bankResponsibleUser="{ item }">
									<span>
										{{ bankResponsibleUsers.find(x => x.id === item.controlResponsibleEmployeeId)?.fullName || "" }}
									</span>
								</template>
								
								<template #item.application="{ item }">
									<td class="text-center">
										<frp-btn v-if="item.application"
												 :to="getApplicationRouteParameters(item.application)"
												 small
												 icon
												 height="32"
												 color="blue"
												 dark>
											<frp-icon src="ico_file_bar" :color="colors.primary.darken1"></frp-icon>
										</frp-btn>
										<span v-else></span>
									</td>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		
		<template #footer-pagination>
			<frp-pagination v-if="!isItemsLoading"
							v-model="page"
							active-color="blue"
							:length="Math.ceil(paging.total / paging.pageSize)">
			</frp-pagination>
		</template>
		
		<template #footer-actions>
			<frp-btn v-if="can(Permissions.BAR_ACCOUNT_CREATE)"
					 class="ml-6 px-12"
					 elevation="0"
					 dark
					 color="blue"
					 :to="{ name: RouteNames.BANK_ACCOUNT_CREATE, query: internalFilterValues.loanApplicationNumber ? { loanApplicationNumber: internalFilterValues.loanApplicationNumber } : {} }">
				{{ $t("buttons.addBankAccount") }}
			</frp-btn>
		</template>
	</bar-content-layout>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import BarSignStatusLabel from "@/components/common/BarSignStatusLabel.vue";
import FrpPagination from "@/components/common/FrpPagination.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import LoanContentLayout from "@/components/layouts/LoanContentLayout.vue";
import { externalUrls } from "@/constants/bar/externalUrls";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/bar/routes";
import BatchService from "@/services/batchService";
import { Permissions } from "@/constants/permissions";
import { MIN_COUNTERPARTIES_QUERY_LENGTH } from "@/store/bar/constants";
import { namespace } from "@/store/bar/modules/bankAccountsList";
import { actionTypes, mutationTypes, getterTypes } from "@/store/bar/modules/bankAccountsList/types";
import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { SuggestedCounterpartiesParameterTypeEnum } from "@/store/bar/types/SuggestedCounterpartiesParameterTypeEnum";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const requestBatchService = new BatchService(({ interval: 500 }));

export default {
	metaInfo: {
		title: i18n.t("metaTitles.bankAccountsRegister")
	},
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountStatusTypeEnum,
			BankAccountTypeEnum,
			SourceTypeEnum,
			SuggestedCounterpartiesParameterTypeEnum,
			externalUrls,
			MIN_COUNTERPARTIES_QUERY_LENGTH,
			internalFilterValues: {
				loanApplicationNumber: "",
				counterpartyId: "",
				bankId: "",
				signStatuses: [],
				responsibleUserId: ""
			}
		};
	},
	computed: {
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			banks: state => state.banks,
			signStatuses: state => state.signStatuses,
			bankAccountTypes: state => state.bankAccountTypes,
			currencies: state => state.currencies,
			bankResponsibleUsers: state => state.bankResponsibleUsers,
			suggestedCounterparties: state => state.suggestedCounterparties,
			isFetchSuggestedCounterpartyLoading: state => state.isFetchSuggestedCounterpartyLoading
		}),
		headers() {
			const tableHeaders = [
				{
					text: this.$t("tables.accountNumber"),
					value: "accountNumber",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.createdDate"),
					value: "createdDate",
					class: "text-caption",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.organizationName"),
					value: "counterpartyShortName",
					class: "text-caption",
					width: "14%",
					sortable: false
				},
				{
					text: this.$t("tables.projectsAndType"),
					value: "projectsAndType",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.currency"),
					value: "currency",
					class: "text-caption",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.bank"),
					value: "bank",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.status"),
					value: "status",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.dateOfControl"),
					value: "controlDate",
					class: "text-caption",
					width: "8%",
					sortable: false
				}
			];
			
			const responsibleUserHeader = {
				text: this.$t("tables.responsiblePerson"),
				value: "bankResponsibleUser",
				class: "text-caption",
				width: "10%",
				sortable: false
			};
			
			const applicationHeader = {
				text: this.$t("tables.application"),
				value: "application",
				class: "text-center",
				width: "1%",
				sortable: false
			};
			
			const isResponsibleUserVisible = this.can([Permissions.BAR_ACCOUNT_RESPONSIBLE_READ, Permissions.BAR_ADMINISTRATOR_READ]);
			
			if(isResponsibleUserVisible)
				tableHeaders.push(responsibleUserHeader);
			
			tableHeaders.push(applicationHeader);
			
			return tableHeaders;
		},
		filter() {
			return {
				loanApplicationNumber: this.internalFilterValues.loanApplicationNumber,
				counterpartyId: this.internalFilterValues.counterpartyId,
				bankId: this.internalFilterValues.bankId,
				signStatuses: this.internalFilterValues.signStatuses,
				responsibleUserId: this.internalFilterValues.responsibleUserId
			};
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			fetchSuggestedCounterparties: actionTypes.fetchSuggestedCounterparties
		}),
		...mapMutations({
			setFilterLoanApplicationNumber: mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER,
			setFilterCounterpartyId: mutationTypes.SET_FILTER_COUNTERPARTY_ID,
			setFilterBankId: mutationTypes.SET_FILTER_BANK_ID,
			setFilterSignStatuses: mutationTypes.SET_FILTER_SIGN_STATUSES,
			setFilterResponsibleUserId: mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID,
			resetSuggestedCounterparties: mutationTypes.RESET_SUGGESTED_COUNTERPARTIES
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] = this.filterValues[key]);
		},
		resetFilter() {
			this.internalFilterValues.loanApplicationNumber = "";
			this.internalFilterValues.counterpartyId = "";
			this.internalFilterValues.bankId = "";
			this.internalFilterValues.signStatuses = [];
			this.internalFilterValues.responsibleUserId = "";
			this.applyFilter();
		},
		applyFilter() {
			this.setFilterLoanApplicationNumber(this.internalFilterValues.loanApplicationNumber || "");
			this.setFilterCounterpartyId(this.internalFilterValues.counterpartyId || "");
			this.setFilterBankId(this.internalFilterValues.bankId || "");
			this.setFilterSignStatuses(this.internalFilterValues.signStatuses || []);
			this.setFilterResponsibleUserId(this.internalFilterValues.responsibleUserId || "");
		},
		async getSuggestedCounterparties(query) {
			// мы проверяем, соответствует ли выбранная counterparty из query с тем, что есть в suggestedCounterparties. Если да, то не фетчим counterparties заново.
			const counterparty = this.suggestedCounterparties.find(x => x.shortName === query);
			if(counterparty && counterparty.id === this.internalFilterValues.counterpartyId) return;
			
			if(query?.length && query.length >= MIN_COUNTERPARTIES_QUERY_LENGTH)
				requestBatchService.push(async () => await this.fetchSuggestedCounterparties({
					parameter: query,
					type: SuggestedCounterpartiesParameterTypeEnum.QUERY
				}));
		},
		getApplicationRouteParameters({ id, number, sourceType }) {
			return {
				name: RouteNames.BANK_ACCOUNT_APPLICATION,
				params: { id },
				query: { loanApplicationNumber: number, sourceType }
			};
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpIcon,
		BarSignStatusLabel,
		FrpPagination,
		FrpAutocomplete,
		FrpTextField,
		FrpBtn,
		FrpTextBtn,
		BarContentLayout,
		LoanContentLayout
	}
};
</script>
<style lang="scss"></style>
