import { IsArray } from "class-validator";

export default class VacationPlanEmployeesFilter {
	year?: number;
	
	@IsArray()
	departmentIds: string[];
	
	@IsArray()
	employeeIds: string[];
	
	constructor(
		year?: number,
		departmentIds: string[] = [],
		employeeIds: string[] = []
	)
	{
		this.year = year;
		this.departmentIds = departmentIds;
		this.employeeIds = employeeIds;
	}
}
