<template>
	<v-row class="d-flex flex-column">
		<v-col class="pb-1">
			<bar-bank-account-application-information-base-loader/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-organization-data-loader/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-bank-loader/>
		</v-col>
		<v-col>
			<bar-bank-account-application-information-accounts-loader/>
		</v-col>
		<v-col>
			<bar-bank-account-application-account-usages-loader/>
		</v-col>
	</v-row>
</template>

<script>
import BarBankAccountApplicationInformationAccountsLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsLoader.vue";
import BarBankAccountApplicationAccountUsagesLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accountUsages/BarBankAccountApplicationAccountUsagesLoader.vue";
import BarBankAccountApplicationInformationBankLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/bank/BarBankAccountApplicationInformationBankLoader.vue";
import BarBankAccountApplicationInformationBaseLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/baseInformation/BarBankAccountApplicationInformationBaseLoader.vue";
import BarBankAccountApplicationInformationOrganizationDataLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/organizationData/BarBankAccountApplicationInformationOrganizationDataLoader.vue";

export default {
	components: {
		BarBankAccountApplicationAccountUsagesLoader,
		BarBankAccountApplicationInformationAccountsLoader,
		BarBankAccountApplicationInformationBankLoader,
		BarBankAccountApplicationInformationOrganizationDataLoader,
		BarBankAccountApplicationInformationBaseLoader,
	}
};
</script>

<style scoped>

</style>
