<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.vacationPlansDecline.title')"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="isFormValid">
				<frp-textarea class="kpi-field"
							  v-model="reason"
							  color="blue"
							  :label="$t('fields.reason.label')"
							  :placeholder="$t('fields.reason.placeholder')">
				</frp-textarea>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="red"
					 dark
					 :disabled="!reason"
					 :loading="isDeclining"
					 @click="handleDecline">
				{{ $t("buttons.vacationPlansDecline") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			isFormValid: false
		};
	},
	computed: {
		...mapState({
			isDeclining: state => state.isDeclining,
			acceptPlanRequest: state => state.acceptPlanRequest
		}),
		reason: {
			get() {
				return this.acceptPlanRequest.reason;
			},
			set(value) {
				this.setAcceptPlanRequestReason(value);
			}
		}
	},
	methods: {
		...mapMutations({
			resetAcceptPlanRequest: mutationTypes.RESET_ACCEPT_PLAN_REQUEST,
			setAcceptPlanRequestReason: mutationTypes.SET_ACCEPT_PLAN_REQUEST_REASON
		}),
		...mapActions({
			declinePlan: actionTypes.declinePlan
		}),
		async handleDecline() {
			await this.declinePlan();
			
			this.handleClose();
		},
		handleClose() {
			this.resetAcceptPlanRequest();
			
			this.$emit("update:value", false);
		}
	},
	watch: {
		async value(value) {
			if(value) {
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		FrpDateField,
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
