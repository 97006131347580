import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { ContractInfo, ContractInfoHelper } from "@/store/bar/types/contractInfo";
import { BankAccountData, BankAccountDataHelper } from "@/store/bar/types/bankAccountData";
import { ApiBankAccountApplicationInfoPersisted } from "@/api/bar/types/bankAccountApplicationInfo/apiBankAccountApplicationInfoPersisted";
import { convertToZonedTimestamp } from "@/utils/dates";
import { ApiAgreement } from "@/api/bar/types/agreement/apiAgreement";

export interface BankAccountApplicationsListItem {
	id: string;
	createdAt: number;
	source: SourceTypeEnum;
	applicationNumber: number;
	loanApplicationNumber: number;
	status: BankAccountApplicationStatusTypeEnum;
	bankId: string;
	bankBranchId: string;
	isLegalDepartmentRequestSent: boolean;
	contract: ContractInfo;
	accounts: BankAccountData[];
	agreement: ApiAgreement;
}

export class BankAccountApplicationsListItemMapper {
	static map(source: ApiBankAccountApplicationInfoPersisted): BankAccountApplicationsListItem {
		return {
			...source.bankAccountApplicationInfo,
			id: source.id,
			createdAt: convertToZonedTimestamp(source.createdAt) as number,
			loanApplicationNumber: source.bankAccountApplicationInfo.agreement.number || 0,
			contract: ContractInfoHelper.map(source.bankAccountApplicationInfo.contract),
			accounts: source.bankAccountApplicationInfo.accounts.map(x => BankAccountDataHelper.map(x))
		};
	}
}